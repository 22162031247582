export const successMessage = {
    APPLICATION_DELETE: 'Application deleted successfully',
    APPLICATION_CREATE: 'Application created successfully',
    APPLICATION_UPDATE: 'Application updated successfully',
    VALIDATE_SCRIPT_UPDATE: 'Validate script updated successfully',
    SECRET_ADDED: 'Secret added successfully',
    SECRET_REMOVED: 'Secret deleted successfully',
    CONNECTION_CREATE: 'Connection created successfully',
    CONNECTION_DELETE: 'Connection deleted successfully',
    CONNECTION_UPDATE: 'Connection updated successfully',
    CONNECTION_GROUP_CREATE: 'ConnectionGroup created successfully',
    CONNECTION_GROUP_DELETE: 'ConnectionGroup deleted successfully',
    CONNECTION_GROUP_UPDATE: 'ConnectionGroup updated successfully',
    CLOUD_KEY_CREATE: 'CloudKey created successfully',
    CLOUD_KEY_SEND: 'CloudKey link sent successfully',
    CLOUD_KEY_DELETE: 'CloudKey deleted successfully',
    ADD_LOCATION: 'Location created successfully',
    UPDATE_LOCATION: 'Location updated successfully',
    DELETE_LOCATION: 'Location deleted successfully',
    TEMPLATE_CREATE: 'Template created successfully',
    TEMPLATE_UPDATE: 'Template update successfully',
    TEMPLATE_GROUP_UPDATE: 'Template groups updated successfully',
    TEMPLATE_DUPLICATE: 'Template duplicated successfully',
    APPLY_TEMPLATE: 'Template applied successfully',
    ENDPOINT_TEMPLATE_DELETED: 'Endpoint Template deleted successfully',
    GROUP_ADD_USER: 'Users added successfully',
    GROUP_ADD_ENDPOINT: 'Endpoints added successfully',
    GROUP_UPDATE_ENDPOINT: 'Endpoints update successfully',
    GROUP_DELETE_ENDPOINT: 'Endpoints deleted successfully',
    GROUP_CREATE: 'Group created successfully',
    GROUP_DELETE: 'Group deleted successfully',
    GROUP_UPDATE: 'Group updated successfully',
    GROUP_USER_UPDATE: 'Group users updated successfully',
    AREA_ADDED: 'Area added successfully',
    AREA_UPDATE: 'Area updated successfully',
    AREA_REMOVED: 'Area removed successfully',
    BUILDING_CREATED: 'Building created successfully',
    BUILDING_REMOVED: 'Building removed successfully',
    RETRY_PLATFROM_ACTION: 'Request processed successfully',
    IMPORT_USER: 'Upload started successfully, You can see import logs after sometime',
    IMPORT_USER_GROUP_MAPPING: 'Import User Groups Mapping Successfully',
    USER_CREATED: 'User created successfully',
    USER_UPDATE: 'User updated successfully',
    USER_ACTIVATED: 'User activated successfully',
    USER_DEACTIVATED: 'User deactivated successfully',
    USER_DELETED: 'User deleted successfully',
    USER_GROUP_UPDATE: 'User Groups updated successfully',
    USER_MIGRATE: 'User migrated successfully',
    USER_DELETE_FROM_GROUP: 'Delete users from group successfully',
    USER_OLOID_ACCOUNT_CREATED: 'OloID account created successfully',
    QR_CODE_DELETED: 'QR Code deleted successfully',
    QR_CODE_SEND: 'Link for QR Code sent successfully',
    ADD_FACE_LINK_SEND: 'Link to add Face sent successfully',
    FACE_ADDED: 'Face added successfully',
    TEST_RESULT_ADDED: 'Test result added successfully',
    VACCINATION_STATUS_SUNBMIT: 'Vaccination status submitted successfully',
    WELLNESS_LINK_SEND: 'Link for Wellness Attestation sent successfully',
    ID_VERIFICATION_LINK_SEND: 'Signed link sent successfully',
    WIEGAND_CREATED: 'Wiegand saved successfully',
    REMOVE_APPLICATION_EXCEPTION: 'Removed application exception successfully',
    REMOVE_APPLICATION_INTEGRATION: 'Remove Application Integration successfully',
    CONSENT_TEMPLATE_UPDATE: "Consent Template updated successfully",
    CONSENT_CREATE: 'Consent created successfully',
    DOCUMENT_CREATE: 'Document created successfully',
    ENDPOINT_UPDATE: 'Endpoint updated successfully',
    ENDPOINT_CREATE: 'Endpoint created successfully',
    INTEGRATION_CONFIG_UPDATE: 'integration Config updated successfully',
    INTEGRATION_DELETE: 'Integration deleted successfully',
    INTEGRATION_REMOVE: 'Integration removed successfully',
    RECIPTIONS_ADDED: 'Recipients Added Successfully',
    REPORT_CREATE: 'Report created successfully',
    REPORT_UPDATE: 'Report updated successfully',
    REPORT_ENPOINT_UPDATE: 'Report Endpoints updated successfully',
    SETTING_UPDATE: 'Setting update successfully',
    TENANT_UPDATE: 'Tenant updated successfully',
    TIME_RULE_UPDATE: 'Time Rule updated successfully',
    TIME_RULE_CREATE: 'Time Rule created successfully',
    TIME_RULE_DELETE: 'Time Rule removed Successfully',
    VERIFICATION_EMAIL: 'A verification email has been sent to your mailbox.',
    INVITE_APP: 'Invite sent successfully',
    BLE_TOKEN_GENERATE: 'BLE token generated successfully',
    BLE_TOKEN_DELETE: 'BLE token deleted successfully',
    INTEGRATION_UPDATE:"Intgration updated successfully"

}