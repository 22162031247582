/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Popover } from 'antd';
import TopbarDropdownWrapper from './topbarDropdown.style';
import { history } from '../../../helpers';
import { Icon } from 'antd';
import flag from '../../../image/flag-icon.png';
import create_tenant from '../../../image/ic_CreateTenant.png';
import setting_icon from '../../../image/ic_Settings.png';
import api_icon from '../../../image/ic_api_icon.png';
import help_icon from '../../../image/ic_help_icon.png';
import ticket_icon from '../../../image/ic_ticket_icon.png';
import email_icon from '../../../image/ic_Email.png';
import logout_icon from '../../../image/ic_Logout.png';
import { logout } from '../../../modules/user/action/user.actions'
import './topbarUser.scss';

class TopbarUser extends Component {
  constructor(props) {
    super(props);
    this.handleVisibleChange = this.handleVisibleChange.bind(this);
    this.hide = this.hide.bind(this);
    this.state = {
      visible: false
    };
  }
  hide() {
    this.setState({ visible: false });
  }
  handleVisibleChange() {
    this.setState({ visible: !this.state.visible });
  }

  logout() {
    const { dispatch } = this.props;
    dispatch(logout())
    history.push('/login')
  }
  goToInviteTanant = () => {
    this.hide()
    history.push(`/tenant?tab=invite&inviteAdmin=true`)
  };

  goToTanant = () => {
    this.hide()
    history.push(`/tenant`);
  }
  
  goToAPIDocumentation = () => {
    this.hide()
    history.push(`/apiDocumentation`)
  }

  render() {
    const { user } = this.props;
    const content = (

      <TopbarDropdownWrapper className="isoUserDropdown">
        <span className="isoDropdownLink menu-tab-heading not-allowed">
          <div className="menu-icon-padding1" />
          {user.tenantName && user.tenantName.toUpperCase()}
        </span>
        <div className="isoDropdownLink menu-tab-heading not-allowed">
          <img className="menu-icon-padding" alt="flag" src={flag} width="16"></img>
          {user.region && user.region}
        </div>
        <div className="menu-devider-line"></div>
        <a className="isoDropdownLink" onClick={() => { this.goToTanant() }}>
          <img className="menu-icon-padding" alt="setting-icon" src={setting_icon} width="16"></img>
          Settings
        </a>
        <a className="isoDropdownLink" onClick={this.goToAPIDocumentation}>
          <img className="menu-icon-padding" alt="api-icon" src={api_icon} width="16"></img>
          API Documentation
        </a>
        <a className="isoDropdownLink" onClick={this.goToInviteTanant}>
          <img className="menu-icon-padding" alt="create-tenant" src={create_tenant} width="16"></img>
          Invite Admin
        </a>
        {/* <a className="isoDropdownLink" href="https://oloid.freshdesk.com/a/dashboard/default" target="_blank">
          <img className="menu-icon-padding" alt="api-icon" src={ticket_icon} width="16"></img>
          Submit Tickets
        </a>
        <a className="isoDropdownLink" href="https://www.oloid.ai/faqs" target="_blank">
          <img className="menu-icon-padding" alt="api-icon" src={help_icon} width="16"></img>
          Help
        </a> */}

        <div className="menu-devider-line"></div>
        <a className="isoDropdownLink">
          <img className="menu-icon-padding" alt="email_icon" src={email_icon} width="16"></img>
          <div className="side-menu-email-text">{user.email}</div>
        </a>
        {/* <a className="isoDropdownLink">
          <img className="menu-icon-padding" alt="profile" src={profile_icon} width="16"></img>
          View Profile
        </a> */}
        <div className="menu-devider-line"></div>
        {/* <a className="isoDropdownLink">
          <img className="menu-icon-padding" alt="account_user_icon" src={account_user_icon} width="16"></img>
          Account Usage
        </a> */}
        <a className="isoDropdownLink" onClick={this
          .logout
          .bind(this)}>
          <img className="menu-icon-padding" alt="logout_icon" src={logout_icon} width="16"></img>
          Logout
        </a>
      </TopbarDropdownWrapper>

    );

    return (
      <Popover
        content={content}
        trigger="click"
        visible={this.state.visible}
        onVisibleChange={this.handleVisibleChange}
        arrowPointAtCenter={true}
        placement="bottomLeft"
        overlayClassName="user-popover"
      >
        <div className="isoImgWrapper">
          {/* <img alt="user" src={userpic} /> */}
          <div className="login-user-bg" >{user.email && user.email.charAt(0).toUpperCase()}</div>
          <span className="userActivity online" />
        </div>
      </Popover>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user
  }
}
export default connect(mapStateToProps)(TopbarUser);