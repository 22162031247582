import { reportConstants } from '../constants';
import { combineReducers } from 'redux'
import createReducer from '../../../helpers/createReducer'

const initialState = [];

const reportReducer = createReducer(initialState)({
  [reportConstants.GET_ALL_REPORT_SUCCESS]: (state, action) => action.payload,
  [reportConstants.GET_ALL_REPORT_FAILURE]: (state, action) => action.payload,
})

const reportByTypeReducer = createReducer(initialState)({
  [reportConstants.GET_ALL_REPORT_BY_TYPE_SUCCESS]: (state, action) => action.payload,
})

const copyReportReducer = createReducer(initialState)({
  [reportConstants.GET_ALL_REPORT_COPY_SUCCESS]: (state, action) => action.payload,
})

const reportDetailReducer = createReducer({})({
  [reportConstants.GET_REPORT_DETAIL_SUCCESS]: (state, action) => action.payload,
  [reportConstants.GET_REPORT_DETAIL_FAILURE]: (state, action) => action.payload,
})

const selectedReportTypeReducer = createReducer('Endpoints')({
  [reportConstants.SET_SELECTED_REPORT]: (state, action) => action.payload,
})

export default combineReducers({
  allReport: reportReducer,
  reportsByType: reportByTypeReducer,
  allCopyReport: copyReportReducer,
  reportDetail: reportDetailReducer,
  selectedReportType: selectedReportTypeReducer,
})