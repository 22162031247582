import { timeInterpretaionConstants } from '../constants';
import { timeInterpretationService } from '../service';
import { tostor } from '../../../helpers';
import _ from 'lodash';

export const fetchTimeRuleSuccess = response => ({
  type: timeInterpretaionConstants.GET_ALL_TIME_RULE_SUCCESS,
  payload: response
})

export const fetchTimeRuleFailure = response => ({
  type: timeInterpretaionConstants.GET_ALL_TIME_RULE_FAILURE,
  payload: []
})

export const getAllTimeRule = (filter) => {
  return dispatch => {
    return timeInterpretationService.getAllTimeRule(filter)
      .then(response => {
        let timeRules = (response.data && response.data.TimeRules) || []
        timeRules = _.orderBy(timeRules, ['UpdatedAt'], ['desc']);
        dispatch(fetchTimeRuleSuccess(timeRules));
        return response;
      },
        error => {
          dispatch(fetchTimeRuleFailure(error));
          return error;
        })
  }
}

export const fetchTimeRuleDetailSuccess = response => ({
  type: timeInterpretaionConstants.GET_TIME_RULE_DETAIL_SUCCESS,
  payload: response || {}
})

export const fetchTimeRuleDetailFailure = response => ({
  type: timeInterpretaionConstants.GET_TIME_RULE_DETAIL_FAILURE,
  payload: {}
})

export const getTimeRuleById = (id) => {
  return dispatch => {
    return timeInterpretationService.getTimeRuleById(id)
      .then(response => {
        dispatch(fetchTimeRuleDetailSuccess(response.data));
        return response;
      },
        error => {
          dispatch(fetchTimeRuleDetailFailure(error));
          return error;
        })
  }
}


export const updateTimeRule = (id, data) => {
  return dispatch => {
    return timeInterpretationService.updateTimeRule(id, data)
      .then(response => {
        dispatch(getTimeRuleById(id));
        return response;
      },
        error => {
          error.error = true;
          return error;
        })
  }
}

export const saveTimeRule = (data) => {
  return dispatch => {
      return timeInterpretationService.saveTimeRule(data)
          .then(response => {
              dispatch(getAllTimeRule());
              return response;
          },
              error => {
                tostor.error(error.message);
              })
  }
}

export const deleteTimeRuleById = (id) => {
  return dispatch => {
      return timeInterpretationService.deleteTimeRule(id)
          .then(response => {
              dispatch(getAllTimeRule());
              return response;
          },
              error => {
                  error.error = true;
                  return error;
              })
  }
}