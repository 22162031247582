export const agentConstants = {
  GET_ALL_AGENT_SUCCESS: "app/agent/GET_ALL_AGENT_SUCCESS",
  GET_ALL_AGENT_COPY_SUCCESS: "app/agent/GET_ALL_AGENT_COPY_SUCCESS",
  GET_ALL_AGENT_FAILURE: "app/agent/GET_ALL_AGENT_FAILURE",
  GET_AGENT_DETAIL_SUCCESS: "app/agent/GET_AGENT_DETAIL_SUCCESS",
  GET_AGENT_DETAIL_FAILURE: "app/agent/GET_AGENT_DETAIL_FAILURE",
  GET_ALL_CONNECTION_SUCCESS: "app/agent/GET_ALL_CONNECTION_SUCCESS",
  GET_ALL_CONNECTION_FAILURE: "app/agent/GET_ALL_CONNECTION_FAILURE",
  GET_ALL_INTEGRATION_SUCCESS: "app/agent/GET_ALL_INTEGRATION_SUCCESS",
  GET_ALL_INTEGRATION_FAILURE: "app/agent/GET_ALL_INTEGRATION_FAILURE",
  GET_ALL_ENABLED_INTEGRATION_SUCCESS:
    "app/agent/GET_ALL_ENABLED_INTEGRATION_SUCCESS",
  GET_ALL_ENABLED_INTEGRATION_FAILURE:
    "app/agent/GET_ALL_ENABLED_INTEGRATION_FAILURE",
  UPDATE_AGENT_DETAIL: "app/agent/UPDATE_AGENT_DETAIL",
  GET_ALL_OKTA_SUCCESS: "app/agent/GET_ALL_OKTA_SUCCESS",
  GET_ALL_OKTA_FAILURE: "app/agent/GET_ALL_OKTA_FAILURE",
  FILTER_UPDATE: "APP_FILTER_UPDATE",
  PAGINATE_UPDATE: "APP_PAGINATE_UPDATE",
};
