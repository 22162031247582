import {
  integrations,
  userGroup,
  endpointConfigureTab,
  endpointHealth,
} from "./constant";
import {
  requestDelete,
  requestGet,
  requestPost,
  requestPut,
} from "../../../services/requests";
import _ from "lodash";

/**
 * Supports all operations exposed by the user controller.
 */

/* eslint eqeqeq: 0 */
export class EndpointsService {
  constructor(endpoint) {
    this.endpoint = endpoint;
  }

  getAllEndpoints(filter) {
    const url = this.operationUrl("/endpoint");
    return requestGet(url, filter, false);
  }

  getEndpointById(id) {
    const url = this.operationUrl(`/endpoint/${id}`);
    return requestGet(url);
  }

  createEndpoint(data) {
    const url = this.operationUrl(`/endpoint/sticker/pair`);
    return requestPost(url, data);
  }
  createCloudKeyEndpoint(data) {
    const url = this.operationUrl(`/endpoint/cloud-key/pair`);
    return requestPost(url, data);
  }

  updateEndpoint(data) {
    const url = this.operationUrl(`/endpoint/${data.EndpointID}`);
    return requestPut(url, data);
  }

  removeEndpointGroups(endpointID, data) {
    const url = this.operationUrl(`/endpoint/${endpointID}/groups/remove`);
    return requestPost(url, data);
  }
  getAllIntegrations() {
    return new Promise((resolve) => {
      resolve(integrations);
    });
  }

  testEndpointConnection(ids, ReLaunch) {
    const url = this.operationUrl(`/endpoint/health`);
    return requestPost(url, {
      requestType: "detailed",
      endpoints: ids,
      appReLaunch: ReLaunch,
    });
  }

  activateCloudKey(params) {
    const url = this.operationUrl(`/cloudkey/verify`);
    return requestPost(url, params);
  }

  getAllEnabledIntegrations() {
    return new Promise((resolve) => {
      const res = _.filter(integrations, (res) => {
        return res.instaled;
      });
      resolve(res);
    });
  }

  deleteEndpointById(endpointID, reason) {
    const url = this.operationUrl(
      `/endpoint/pair/${endpointID}?reason=${reason}`
    );
    return requestDelete(url);
  }

  getUserGroup() {
    return new Promise((resolve) => {
      resolve(userGroup);
    });
  }

  operationUrl(append) {
    return this.endpoint + append;
  }

  getEndpointConfigureTab() {
    return new Promise((resolve) => {
      resolve(endpointConfigureTab);
    });
  }

  getEndpointHealth() {
    return new Promise((resolve) => {
      resolve(endpointHealth);
    });
  }

  applyTemplate(obj) {
    const url = this.operationUrl("/endpointTemplate/apply");
    return requestPost(url, obj);
  }
}
