import { endpointTemplatesConstants } from '../constants';
import { combineReducers } from 'redux'
import createReducer from '../../../helpers/createReducer'

const initialState = [];
const defaultFilter = {};
const defaultPaging = 1;

const templateReducer = createReducer(initialState)({
  [endpointTemplatesConstants.GET_ALL_ENDPOINTS_TEMPLATES_SUCCESS]: (state, action) => action.payload,
  [endpointTemplatesConstants.GET_ALL_ENDPOINTS_TEMPLATES_FAILURE]: (state, action) => action.payload,
})

const copyTemplateReducer = createReducer(initialState)({
  [endpointTemplatesConstants.GET_ALL_ENDPOINTS_TEMPLATES_COPY_SUCCESS]: (state, action) => action.payload,
})



const templateDetailReducer = createReducer({})({
  [endpointTemplatesConstants.GET_ENDPOINTS_TEMPLATES_DETAIL_SUCCESS]: (state, action) => action.payload,
  [endpointTemplatesConstants.GET_ENDPOINTS_TEMPLATES_DETAIL_FAILURE]: (state, action) => action.payload,
})

const IntegrationReducer = createReducer(initialState)({
  [endpointTemplatesConstants.GET_ALL_INTEGRATION_SUCCESS]: (state, action) => action.payload,
  [endpointTemplatesConstants.GET_ALL_INTEGRATION_FAILURE]: (state, action) => action.payload,
})

const EnabledIntegrationReducer = createReducer(initialState)({
  [endpointTemplatesConstants.GET_ALL_ENABLED_INTEGRATION_SUCCESS]: (state, action) => action.payload,
  [endpointTemplatesConstants.GET_ALL_ENABLED_INTEGRATION_FAILURE]: (state, action) => action.payload,
})

const CameraReducer = createReducer(initialState)({
  [endpointTemplatesConstants.GET_ALL_CAMERA_SUCCESS]: (state, action) => action.payload,
  [endpointTemplatesConstants.GET_ALL_CAMERA_FAILURE]: (state, action) => action.payload,
})

const usersGroupReducer = createReducer([])({
  [endpointTemplatesConstants.GET_ALL_USER_GROUP_SUCCESS]: (state, action) => action.payload,
  [endpointTemplatesConstants.GET_ALL_USER_GROUP_FAILURE]: (state, action) => action.payload,
})

const endpointConfigureTabReducer = createReducer([])({
  [endpointTemplatesConstants.GET_ALL_ENDPOINT_CONFIGURE_TAB_SUCCESS]: (state, action) => action.payload
})
const filterReducer = createReducer(defaultFilter)({
  [endpointTemplatesConstants.FILTER_UPDATE]: (state, action) => action.payload || {},
})

const pagingReducer = createReducer(defaultPaging)({
  [endpointTemplatesConstants.PAGINATE_UPDATE]: (state, action) => action.payload || 1,
})



export default combineReducers({
  allTemplates: templateReducer,
  allCopyTemplates: copyTemplateReducer,
  templateDetail: templateDetailReducer,
  allIntegrations: IntegrationReducer,
  allEnabledIntegrations: EnabledIntegrationReducer,
  allCameras: CameraReducer,
  userGroup: usersGroupReducer,
  endpointConfigure: endpointConfigureTabReducer,
  _filterReducer: filterReducer,
  _pagingReducer: pagingReducer
})