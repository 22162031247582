import React, { Component } from 'react';
import "../../application/component/createApplication.scss";
import "../../connections/component/createConnection.scss";
import "../../tenant/component/createTenant.scss";
import { saveSecrets } from '../action/secrets.action'
import { Modal, Button, Row, Col, Tabs } from 'antd';
import { TextField } from '@material-ui/core';
import { connect } from 'react-redux';
import { successMessage, tostor } from '../../../helpers'
import '../../application/component/applicationDetail.scss'
import { JsonEditor as Editor } from 'jsoneditor-react';

const { TabPane } = Tabs

export class CreateSecret extends Component {
    constructor(props) {
        super(props);
        this.state = {
            secret: {
                SecretName: '',
                Secrets: {

                },
                Key: '',
                Value: ''
            },
            error: {},
            isSubmit: false,
            activeKey: "1"
        }
    }

    addKeyValue = () => {
        const { secret } = Object.assign({}, this.state);
        let error = {};
        if (secret.Secrets[secret.Key]) {
            error.KeyDublicate = true;
            this.setState({ error })
            return;
        }
        if (secret.Value && secret.Key) {
            secret.Secrets[secret.Key] = secret.Value
            secret.Key = '';
            secret.Value = '';
            this.setState({ secret })
        }
        else {
            error.Value = secret.Value ? false : true;
            error.Key = secret.Key ? false : true;
            this.setState({ error })
        }
    }

    handleRawJSONChange = (data) => {
        const { secret } = Object.assign({}, this.state);
        secret.Secrets = data;
        this.setState({ secret });
    }

    removeSecretKey = (key) => {
        const { secret } = this.state;
        delete secret.Secrets[key];
        this.setState({ secret })
    }

    handleOk = () => {
        this.setState({ isSubmit: true });
        const { dispatch } = this.props;
        if (this.validateForm()) {
            const secret = this.state.secret;
            const reqObj = {
                SecretKey: secret.SecretName,
                Secrets: secret.Secrets
            }
            dispatch(saveSecrets(reqObj))
                .then((res) => {
                    if (res) {
                        tostor.success(successMessage.SECRET_ADDED);
                        this.resetFrom();
                    }
                });
        }
    };

    handleInput = (e) => {
        this.setState({ isSubmit: false })
        const name = e.target.name;
        const value = e.target.value;
        const { secret, error } = Object.assign({}, this.state)
        error[name] = false

        var letters = /^[A-Za-z0-9_]+$/;
        if (name === 'SecretName' || name === 'Key') {
            error.KeyDublicate = false;
            if (value.match(letters) || e.target.value === '') {
                secret[name] = value;
                this.setState({ secret, error })
            }
        }
        else {
            secret[name] = value;
            this.setState({ secret, error })
        }

        if (this.state.isSubmit) {
            this.validateForm()
        }
    }

    validateForm = () => {
        const { secret } = this.state;
        let error = {};
        if (!secret.SecretName) {
            error.SecretName = true;
        }
       
        if (secret.SecretName?.toLocaleLowerCase() === 'secret') {
            tostor.error('Secret Name is invalid');
            error.SecretName = true;
            return false;
        }

        this.setState({ error });

        if (Object.keys(secret.Secrets).length === 0) {
            tostor.error('Add atleast one key value');
            return false;
        }
        if (secret.Key || secret.Value) {
            tostor.error('Add key value before save');
            return false;
        }
        
        return Object.keys(error).length === 0;
    }

    resetFrom = () => {
        this.setState({
            secret: {
                SecretName: '',
                Secrets: {

                },
                Key: '',
                Value: ''
            },
            error: {},
            isSubmit: false
        })
        this.props.toggle()
    };

    onChangeTab = activeKey => {
        this.setState({ activeKey });
    };

    render() {
        const { error, secret, activeKey } = this.state;
        return (
            <div>
                <Modal
                    className="create-application"
                    visible={this.props.modal}
                    onCancel={this.resetFrom}
                    footer={[
                        <div className="footer-box">
                            <Button className="create-btn" key="back" type="primary" onClick={this.handleOk}>
                                SAVE
                            </Button>
                            <Button className="cancle-btn" key="submit" onClick={this.resetFrom}>
                                CANCEL
                            </Button>
                        </div>
                    ]}
                >
                    <div className='txt-header'>Enter Key Value pair(s)</div>
                    <div className='position-rel'>
                        <TextField
                            label="Secret Name"
                            type="text"
                            className='tenant-form-textfield'
                            margin="normal"
                            variant="outlined"
                            name="SecretName"
                            value={secret.SecretName}
                            required
                            helperText={error.SecretName ? 'Required' : ''}
                            error={error.SecretName}
                            onChange={(e) => this.handleInput(e)}
                        />
                    </div>

                    <Tabs activeKey={this.state.activeKey} onChange={this.onChangeTab}>
                        <TabPane tab="Key Value" key="1">
                            <Row>
                                <Col span={10} >
                                    <div className="margin-right-cont">
                                        <TextField
                                            name="Key"
                                            label="Key"
                                            type="text"
                                            className='tenant-form-textfield margin-right-cont'
                                            margin="normal"
                                            variant="outlined"
                                            value={secret.Key}
                                            onChange={(e) => this.handleInput(e)}
                                            helperText={error.KeyDublicate ? 'Key exists' : error.Key ? 'Required' : ''}
                                            error={error.KeyDublicate || error.Key}
                                        />
                                    </div>
                                </Col>
                                <Col span={10}>
                                    <div className="margin-right-cont">
                                        <TextField
                                            name="Value"
                                            label="Value"
                                            type="text"
                                            className='tenant-form-textfield'
                                            margin="normal"
                                            variant="outlined"
                                            value={secret.Value}
                                            onChange={(e) => this.handleInput(e)}
                                            helperText={error.Value ? 'Required' : ''}
                                            error={error.Value}
                                        />
                                    </div>
                                </Col>
                                <Col span={4}>
                                    <button
                                        className='modal-secret-add-btn'
                                        onClick={() => { this.addKeyValue() }}
                                    >Add</button>
                                </Col>
                            </Row>

                            <div className="modal-secret-outputbox">
                                {
                                    secret.Secrets && Object.keys(secret.Secrets).map((key, index) => {
                                        return <div key={index}>
                                            <strong>{key}</strong>: {JSON.stringify(secret.Secrets[key], null, 2)}
                                            <div className="cross-small-icon" onClick={() => { this.removeSecretKey(key) }}></div>
                                        </div>
                                    })
                                }
                            </div>
                        </TabPane>
                        <TabPane tab="Raw Json" key="2">
                            {activeKey == 2 &&
                                <div className='string-json'>
                                    <Editor
                                        mode="tree"
                                        allowedModes={['code', 'tree']}
                                        value={secret.Secrets}
                                        onChange={this.handleRawJSONChange}
                                    />
                                </div>
                            }
                        </TabPane>
                    </Tabs>

                </Modal>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { user } = state.authentication;
    const { applicationDetail } = state.application;
    return {
        user,
        applicationDetail
    }
}
export default connect(mapStateToProps)(CreateSecret);