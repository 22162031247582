import { transactionsConstants } from '../constants';
import { combineReducers } from 'redux'
import createReducer from '../../../helpers/createReducer'

const initialState = {
  data: [
    {
      transactions: [],
      NextPageToken: ''
    }
  ],
  page: 1
};
const filter = {
  SearchField: '',
  ApplicationID: 'ALL',
  FromDate: '',
  ToDate: '',
  Mode: 'ALL',
  Status: 'ALL',
  EndpointID: 'ALL',
  ThermalCertifyView: false,
  TempAuthStatus: 'ALL',
  IsCertifyAuthorized: 'ALL'
}

const filterReducer = createReducer(filter)({
  [transactionsConstants.SET_TRANSACTIONS_FILTER]: (state, action) => action.payload,
})

const transactionsReducer = createReducer(initialState)({
  [transactionsConstants.GET_ALL_TRANSACTIONS_SUCCESS]: (state, action) => action.payload,
  [transactionsConstants.GET_ALL_TRANSACTIONS_FAILURE]: (state, action) => action.payload,
  [transactionsConstants.SET_TRANSACTIONS_PAGE]: (state, action) => {
    return {
      ...state,
      page: action.payload
    }
  }
})

const transactionsDetailReducer = createReducer({})({
  [transactionsConstants.GET_TRANSACTIONS_DETAIL_SUCCESS]: (state, action) => action.payload,
  [transactionsConstants.GET_TRANSACTIONS_DETAIL_FAILURE]: (state, action) => action.payload,
})

const transactionFilterReducer = createReducer(initialState)({
  [transactionsConstants.STORE_TRANSACTIONS_FILTER]: (state, action) => action.payload,
})
const integrationReducer = createReducer([])({
  [transactionsConstants.GET_ALL_INTEGRATION_SUCCESS]: (state, action) => action.payload,
  [transactionsConstants.GET_ALL_INTEGRATION_FAILURE]: (state, action) => action.payload,
})


const actionTranasctionfilterReducer = createReducer(filter)({
  [transactionsConstants.SET_ACTION_TRANSACTIONS_FILTER]: (state, action) => action.payload,
})
const actionTransactionFilterReducer = createReducer(initialState)({
  [transactionsConstants.STORE_ACTION_TRANSACTIONS_FILTER]: (state, action) => action.payload,
})
const actionTransactionsReducer = createReducer(initialState)({
  [transactionsConstants.GET_ALL_ACTION_TRANSACTIONS_SUCCESS]: (state, action) => action.payload,
  [transactionsConstants.GET_ALL_ACTION_TRANSACTIONS_FAILURE]: (state, action) => action.payload,
  [transactionsConstants.SET_ACTION_TRANSACTIONS_PAGE]: (state, action) => {
    return {
      ...state,
      page: action.payload
    }
  }
})

export default combineReducers({
  actionTransactions: actionTransactionsReducer,
  actionTransactionFilter: actionTransactionFilterReducer,
  actionFilter: actionTranasctionfilterReducer,
  allTransactions: transactionsReducer,
  allIntegrationsSetting: integrationReducer,
  transactionFilter: transactionFilterReducer,
  transactionsDetail: transactionsDetailReducer,
  filter: filterReducer,
})