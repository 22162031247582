import moment from 'moment';

export function dateFormat(value) {
  if (value) {
    return moment(value).format('MMM DD YYYY hh:mm A');
  }
  return moment().format('MMM DD YYYY hh:mm A');
}

export function dateUnixFormat(value) {
  if (value) {
    return moment.unix(value).format('MMM DD YYYY hh:mm A');
  }
  return moment.unix().format('MMM DD YYYY hh:mm A');
}