import { consentConstants } from '../constants';
import { combineReducers } from 'redux'
import createReducer from '../../../helpers/createReducer'

const initialState = [];

const consentReducer = createReducer(initialState)({
  [consentConstants.GET_ALL_CONSENT_SUCCESS]: (state, action) => action.payload,
  [consentConstants.GET_ALL_CONSENT_FAILURE]: (state, action) => action.payload,
})

const copyConstantReducer = createReducer(initialState)({
  [consentConstants.GET_ALL_CONSENT_COPY_SUCCESS]: (state, action) => action.payload,
})

const consentDetailReducer = createReducer({})({
  [consentConstants.GET_CONSENT_DETAIL_SUCCESS]: (state, action) => action.payload,
  [consentConstants.GET_CONSENT_DETAIL_FAILURE]: (state, action) => action.payload,
})

const legalDocumentsReducer = createReducer([])({
  [consentConstants.GET_ALL_LEGAL_DOCUMENTS_SUCCESS]: (state, action) => action.payload,
  [consentConstants.GET_ALL_LEGAL_DOCUMENTS_FAILURE]: (state, action) => action.payload,
})

const copyLegalDocumentsReducer = createReducer(initialState)({
  [consentConstants.GET_ALL_LEGAL_DOCUMENTS_COPY_SUCCESS]: (state, action) => action.payload,
})

const legalDocumentDetailReducer = createReducer({})({
  [consentConstants.GET_LEGAL_DOCUMENTS_BY_ID_SUCCESS]: (state, action) => action.payload,
  [consentConstants.GET_LEGAL_DOCUMENTS_BY_ID_FAILURE]: (state, action) => action.payload,
})




export default combineReducers({
  allConsentTemplate: consentReducer,
  allCopyConsent: copyConstantReducer,
  consentTemplateDetail: consentDetailReducer,
  allLegalDocuments: legalDocumentsReducer,
  allCopyLegalDocuments: copyLegalDocumentsReducer,
  legalDocumentDetail: legalDocumentDetailReducer,
})