/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import "../../application/component/createApplication.scss";
import { Modal, Button, Row, Col, message } from 'antd';
import TextField from "@material-ui/core/TextField";
import { connect } from 'react-redux';
import { saveReport } from '../action/report.actions';
import { successMessage, tostor } from '../../../helpers';

class CreateReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      report: {
        ReportName: '',
        ReportType: ''
      },
      error: {},
      isSubmit: false
    }
  }

  handleOk = () => {
    this.setState({ isSubmit: true });
    if (this.validateForm()) {
      const { dispatch } = this.props;
      this.setState({ loading: true });
      const report = this.state.report;
      const reqObj = {
        ReportName: report.ReportName.trim(),
        ReportType: report.ReportType
      }
      dispatch(saveReport(reqObj))
        .then(() => {
          tostor.success(successMessage.REPORT_CREATE);
          this.setState({ loading: false });
          this.resetFrom();
          this.props.toggle()
        });
    }
  };

  resetFrom = () => {
    this.setState({
      report: {
        ReportName: '',
        ReportType: ''
      },
      error: {},
      isSubmit: false
    })
  }
  handleCancel = () => {
    this.resetFrom();
    this.props.toggle()
  };

  validateForm = () => {
    const { report } = this.state;
    let error = {};
    if (!report.ReportName) {
      error.ReportName = true;
    }
    if (!report.ReportType) {
      error.ReportType = true;
    }
    this.setState({ error });
    return Object.keys(error).length == 0;
  }

  handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const { report, error } = Object.assign({}, this.state);
    const letters = /^[A-Za-z0-9- ]+$/;
    if (value.match(letters) || e.target.value == '') {
      report[name] = value.replace(/\s{2,}/g, ' ');
      error[name] = false;
    }
    this.setState({ report, error });
  }

  selectReportType = (type) => {
    const { report, error } = Object.assign({}, this.state)
    report['ReportType'] = type;
    error['ReportType'] = false
    this.setState({ report, error })
  }

  getLearnMore = () => {

  }

  render() {

    const { error, isSubmit, report } = this.state;

    return (
      <div >
        <Modal
          className="create-application"
          visible={this.props.modal}
          onCancel={this.handleCancel}
          footer={[
            <div className="footer-box">
              <Button className="create-btn" key="back" type="primary" onClick={this.handleOk}>
                CREATE
              </Button>
              <Button className="cancle-btn" key="submit" onClick={this.handleCancel}>
                CANCEL
              </Button>
            </div>
          ]}
        >
          <div className='txt-header'>Create a new Report</div>
          <div className='position-rel'>
            <TextField
              error={error.ReportName}
              name="ReportName"
              label="* Report Name"
              type="text"
              className='tenant-form-textfield'
              margin="normal"
              variant="outlined"
              value={report.ReportName}
              helperText={error.ReportName ? 'Required' : ''}
              onChange={(e) => this.handleInput(e)}
            />
            {isSubmit && !error.ReportName && <div className='success-icon position'></div>}
            {error.ReportName && <div className='success-icon position error'></div>}
          </div>

          <div className='application-type'>
            <div className='txt-type'>Choose a Report Type</div>
            <div className='types models'>
              <Row>
                <Col span={12}>
                  <div className="box-col-padding">
                    <div className=''>
                      <div className={`type-border mr-type ${error.ReportType ? 'notSelected' : report.ReportType == 'Transaction' ? 'selected' : ''}`}
                        onClick={() => this.selectReportType('Transaction')}>
                        <div className='type-icon'>
                          <div className='modal-a-icon'></div>
                        </div>
                        <div className='txt-heading'>Transaction</div>
                      </div>

                    </div>
                  </div>
                </Col>
                <Col span={12}>
                  <div className="box-col-padding">
                    <div className={`type-border ${error.ReportType ? 'notSelected' : report.ReportType == 'Onboarding' ? 'selected' : ''}`}
                      onClick={() => this.selectReportType('Onboarding')}>
                      <div className='type-icon'>
                        <div className='modal-b-icon'></div>
                      </div>
                      <div className='txt-heading'>Onboarding</div>
                    </div>

                  </div>
                </Col>

              </Row>
            </div>
            {error.ReportType && <div className='error-required'>Required</div>}
          </div>
        </Modal>
      </div>
    );
  }
}


function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user
  }
}
export default connect(mapStateToProps)(CreateReport);