/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import { Row, Col, Table, Dropdown, Menu, Icon, Modal, message } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import { TextField } from '@material-ui/core';
import { connect } from 'react-redux';
import './locationDetail.scss';
import AddArea from './addArea'
import { fetchAllBuilding, createBuilding, deleteBuilding, deleteArea } from '../action/location.actions'
import { successMessage, tostor } from '../../../helpers';
const { confirm } = Modal;

export class LocationBuilding extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          title: 'BUILDING NAME',
          dataIndex: 'BuildingName',
          key: 'BuildingName',
          width: '20%',
          sorter: (a, b) => a.BuildingName.localeCompare(b.BuildingName),
          sortDirections: ['ascend', 'descend', 'ascend'],
        },
        {
          title: 'AREA NAME',
          dataIndex: 'AreaName',
          key: 'AreaName',
          width: '70%',
          sorter: (a, b) => a.AreaName.localeCompare(b.AreaName),
          sortDirections: ['ascend', 'descend', 'ascend'],
        },
        {
          title: '',
          dataIndex: 'image',
          key: 'image',
          render: (text, obj) => {
            if (obj.BuildingName) {
              return (<div>
                <div className="cross-icon" onClick={() => { this.showConfirmBuilding(obj) }}></div>
              </div>
              )
            } else {
              return <div className="cross-icon" onClick={() => this.showConfirmArea(obj)}></div>
            }
          }

        },
        {
          title: '',
          dataIndex: 'threeDot',
          key: 'threeDot',
          sortDirections: ['ascend', 'descend', 'ascend'],
          render: (text, obj) => {
            if (obj.BuildingName) {
              return (
                <div id="locMenu">
                  <Dropdown
                    trigger={['click']}
                    getPopupContainer={() => document.getElementById('locMenu')}
                    overlay={() => this.menu(obj)}>
                    <MoreOutlined

                      style={{ pointer: 'cursor', fontSize: '30px', fontWeight: 'bold', color: '#000000' }} />
                  </Dropdown>
                </div>
              )
            }
            else {
              return (
                <div id="locAreaMenu">
                  <Dropdown
                    trigger={['click']}
                    getPopupContainer={() => document.getElementById('locAreaMenu')}
                    overlay={() => this.areaMenu(obj)}>
                    <MoreOutlined style={{ pointer: 'cursor', fontSize: '30px', fontWeight: 'bold', color: '#000000' }} />
                  </Dropdown>
                </div>
              )
            }
          }
        },
      ],
      BuildingName: "",
      formSubmit: false,
      formError: false
    }
  }

  menu(obj) {
    return (
      <Menu>
        <Menu.Item key="1">
          <div onClick={() => { this.openAddAreaModal(obj) }}>Add Area</div>
        </Menu.Item>
      </Menu>
    );
  }

  areaMenu(obj) {
    return (
      <Menu>
        <Menu.Item key="1">
          <div onClick={() => { this.openAddAreaModal(obj, true) }}>Update Area</div>
        </Menu.Item>
      </Menu>
    );
  }

  openAddAreaModal = (obj, isEdit) => {
    this.setState({ openAreaModal: true, isEdit, building: obj })
  }

  toggleAddAreaModal = () => {
    const { dispatch, location } = this.props
    dispatch(fetchAllBuilding(location.SettingType))
    this.setState({ openAreaModal: false, isEdit: false })
  }

  componentDidMount() {
    const { dispatch, location } = this.props
    dispatch(fetchAllBuilding(location.SettingType))
  }

  handleChange = (e) => {
    var value = e.target.value;
    this.setState({ BuildingName: value })
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { dispatch, location } = this.props;
    const { BuildingName } = this.state
    this.setState({ formSubmit: true })
    if (BuildingName == "") {
      this.setState({ formError: true })
    } else {
      this.setState({ formSubmit: false })
      const building = {
        SettingType: location.SettingType,
        BuildingName: BuildingName
      }
      dispatch(createBuilding(building))
        .then(() => {
          this.setState({ BuildingName: "" });
          tostor.success(successMessage.BUILDING_CREATED);
        })
    }
  }

  showConfirmBuilding = (exception) => {
    const remove = this.removeBuilding;
    confirm({
      title: "Are you sure you want to remove this Building? It may have areas attached to it.",
      onOk() {
        remove(exception);
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }
  showConfirmArea = (obj) => {
    const remove = this.removeArea;
    confirm({
      title: "Are you sure you want to remove this Area?",
      onOk() {
        remove(obj.AreaID, obj.tempBuildingID);
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  removeBuilding = (building) => {
    const { dispatch, location } = this.props;
    dispatch(deleteBuilding(building.BuildingID, location.SettingType))
      .then(() => {
        tostor.success(successMessage.BUILDING_REMOVED);
      });

  }

  removeArea = (areaId, buildingID) => {
    const { dispatch, location } = this.props;
    dispatch(deleteArea(areaId, buildingID))
      .then(() => {
        dispatch(fetchAllBuilding(location.SettingType))
        tostor.success(successMessage.AREA_REMOVED);
      });

  }

  render() {
    const { columns, BuildingName, formSubmit, formError } = this.state;
    const { allBuildings, location } = this.props
    return (
      <div className="enable-detail location-detail">
        <div className="tab-section">
          <form ref={node => this.fromNode = node} noValidate autoComplete="off" onSubmit={(e) => { this.handleSubmit(e) }}>
            <div className="location-info">
              <Row>
                <Col span={12} className="from-left-col">
                  <TextField
                    type="text"
                    className='form-textfield'
                    variant="outlined"
                    placeholder="Building Name"
                    value={BuildingName}
                    name="BuildingName"
                    onChange={(e) => this.handleChange(e)}
                    error={formSubmit && BuildingName == ""}
                    helperText={formError && formSubmit ? 'Required' : ''}
                  />
                </Col>
                <Col span={12}>
                  <div>
                    <button type="submit" className="ant-btn location-add-button ant-btn-primary"><span>ADD</span></button>
                  </div>
                </Col>
              </Row>
              <div className='table-box'>
                <Table
                  rowKey="BuildingID"
                  columns={columns}
                  dataSource={allBuildings}
                  childrenColumnName="Areas"
                  pagination={{
                    // pageSize: 10,
                    showTotal: (total, range) => `${range[0]} to ${range[1]} from ${total}`,
                    defaultCurrent: 1
                  }} />
              </div>
            </div>
          </form>
          <AddArea
            modal={this.state.openAreaModal}
            isEdit={this.state.isEdit}
            toggle={this.toggleAddAreaModal}
            building={this.state.building}
            location={location}

          />
        </div>

      </div>
    );
  }
}


function mapStateToProps(state) {
  const { user } = state.authentication;
  const { allBuildings } = state.place;
  return {
    user, allBuildings
  }
}
export default connect(mapStateToProps)(LocationBuilding);