import { activityLogConstants } from '../constants';
import { combineReducers } from 'redux'
import createReducer from '../../../helpers/createReducer'

const initialState = [];

const filter = {
  start: '',
  end: '',
  eventType: 'ALL',
  source: 'ALL',
}

const activityLogReducer = createReducer(initialState)({
  [activityLogConstants.GET_ALL_ACTIVITY_SUCCESS]: (state, action) => action.payload,
  [activityLogConstants.GET_ALL_ACTIVITY_FAILURE]: (state, action) => action.payload,
  [activityLogConstants.SET_USER_PAGE]: (state, action) => {
    return {
      ...state,
      page: action.payload
    }
  }
})


const activityLogDetailReducer = createReducer({})({
  [activityLogConstants.GET_ACTIVITY_DETAIL_SUCCESS]: (state, action) => action.payload,
  [activityLogConstants.GET_ACTIVITY_DETAIL_FAILURE]: (state, action) => action.payload,
})

const activityFilterReducers = createReducer(filter)({
  [activityLogConstants.STORE_ACTIVITY_FILTER]: (state, action) => action.payload,
})


export default combineReducers({
  allActivityLog: activityLogReducer,
  activityLogDetail: activityLogDetailReducer,
  filter: activityFilterReducers
})