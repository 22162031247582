export const applicationConstants = {
    GET_ALL_APPLICATION_SUCCESS: 'app/application/GET_ALL_APPLICATION_SUCCESS',
    GET_ALL_APPLICATION_COPY_SUCCESS: 'app/application/GET_ALL_APPLICATION_COPY_SUCCESS',
    GET_ALL_APPLICATION_FAILURE: 'app/application/GET_ALL_APPLICATION_FAILURE',
    GET_APPLICATION_DETAIL_SUCCESS: 'app/application/GET_APPLICATION_DETAIL_SUCCESS',
    GET_APPLICATION_DETAIL_FAILURE: 'app/application/GET_APPLICATION_DETAIL_FAILURE',
    GET_ALL_CONNECTION_SUCCESS:'app/application/GET_ALL_CONNECTION_SUCCESS',
    GET_ALL_CONNECTION_FAILURE:'app/application/GET_ALL_CONNECTION_FAILURE',
    GET_ALL_INTEGRATION_SUCCESS:'app/application/GET_ALL_INTEGRATION_SUCCESS',
    GET_ALL_INTEGRATION_FAILURE:'app/application/GET_ALL_INTEGRATION_FAILURE',
    GET_ALL_ENABLED_INTEGRATION_SUCCESS:'app/application/GET_ALL_ENABLED_INTEGRATION_SUCCESS',
    GET_ALL_ENABLED_INTEGRATION_FAILURE:'app/application/GET_ALL_ENABLED_INTEGRATION_FAILURE',
    UPDATE_APPLICATION_DETAIL: 'app/application/UPDATE_APPLICATION_DETAIL',
    GET_ALL_OKTA_SUCCESS:'app/application/GET_ALL_OKTA_SUCCESS',
    GET_ALL_OKTA_FAILURE:'app/application/GET_ALL_OKTA_FAILURE',
    FILTER_UPDATE:'APP_FILTER_UPDATE',
    PAGINATE_UPDATE:'APP_PAGINATE_UPDATE',
};
