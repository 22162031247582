import React, { Component } from 'react';
import './App.scss';
import ErrorBoundary from './helpers/ErrorBoundary';
import { connect } from 'react-redux';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { history } from './helpers/history';
import MainLayout from './layout/MainLayout';
import Login from './modules/user/component/login';
import ssoLogins from './modules/user/component/ssologin';
import ChangePassword from './modules/user/component/changePassword';
import Authentication from './modules/user/component/authentication';
import ResetPassword from './modules/user/component/resetPassword';
import LoginCallback from './modules/user/component/oktaLoginCallback'
import { logout } from './modules/user/action/user.actions'
import IdleTimer from 'react-idle-timer'
import loader from './image/loader.gif';

export  class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      timeout: 1000 * 60 * 15
    }

    this.idleTimer = null
    this.onAction = this._onAction.bind(this)
    this.onActive = this._onActive.bind(this)
    this.onIdle = this._onIdle.bind(this)
  }

  _onAction(e) {
    //console.log('user did something', e)

  }

  _onActive(e) {
    //console.log('user is active', e)

  }

  _onIdle(e) {
    //console.log('user is idle', e)
    const { dispatch ,user} = this.props;
    if(user && user.accesstoken){
      dispatch(logout())
      history.push('/login')
    }
  }

  render() {
    return (
      <ErrorBoundary>
        <div className="App">

          <div className="ui_block ui-block-loader" id="ui_block">
            <div style={{
              margin: "auto",
              maxHeight: "100%"
            }}>
              <div>
                <img src={loader} alt="" />
              </div>
            </div>
          </div>

          <div className="ui_block ui-block-loader" id="cst_ui_block">
            <div style={{
              margin: "auto",
              maxHeight: "100%"
            }}>
              <div>
                <img src={loader} alt="" />
              </div>
            </div>
          </div>
          <IdleTimer
            ref={ref => { this.idleTimer = ref }}
            element={document}
            onActive={this.onActive}
            onIdle={this.onIdle}
            onAction={this.onAction}
            debounce={250}
            timeout={this.state.timeout} />

          <Router history={history}>
            <Switch>
              <Redirect exact={true} from='/' to='/login' />
              <Route exact path='/login' name='Login Page' component={Login} />
              <Route exact path='/ssologin' name='SSO Login Page' component={ssoLogins} />
              <Route path="/login/callback" component={LoginCallback} />
              <Route exact path='/changePassword' name='ChangePassword' component={ChangePassword} />
              <Route exact path='/authentication' name='Authentication' component={Authentication} />
              <Route exact path='/resetPassword' name='ResetPassword' component={ResetPassword} />
              <Route exact path="/register" name="Register Page" component={(e) => { return <h3>Register Page</h3> }} />
              <Route exact path="/500" name="Page 500" component={(e) => { return <h3>not found</h3> }} />
              <Route exact path="/404" name="Page 404" component={(e) => { return <h3>not found</h3> }} />
              <Route path="/" name="Home" component={MainLayout} />
            </Switch>
          </Router>
        </div>
      </ErrorBoundary>
    );
  }
}


function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user,
  }
}
export default connect(mapStateToProps)(App);
