import { dashboardConstants } from '../constants';
import { combineReducers } from 'redux'
import createReducer from '../../../helpers/createReducer'

const initialState = [];

const metricsReducer = createReducer(initialState)({
  [dashboardConstants.GET_ALL_METRICS_SUCCESS]: (state, action) => action.payload,
  [dashboardConstants.GET_ALL_METRICS_FAILURE]: (state, action) => action.payload,
})

const transactionMetricsReducer = createReducer(initialState)({
  [dashboardConstants.GET_ALL_TRANSACTION_METRICS_SUCCESS]: (state, action) => action.payload,
  [dashboardConstants.GET_ALL_TRANSACTION_METRICS_FAILURE]: (state, action) => action.payload,
})


const dashboardReducer = createReducer(initialState)({
  [dashboardConstants.GET_ALL_DASHBOARD_SUCCESS]: (state, action) => action.payload,
  [dashboardConstants.GET_ALL_DASHBOARD_FAILURE]: (state, action) => action.payload,
})

const dashboardUrlReducer = createReducer({})({
  [dashboardConstants.GET_ALL_DASHBOARD_BYID_SUCCESS]: (state, action) => action.payload,
  [dashboardConstants.GET_ALL_DASHBOARD_BYID_FAILURE]: (state, action) => action.payload,
})

export default combineReducers({
  allMetrics: metricsReducer,
  transactionMetrics:transactionMetricsReducer,
  allDashboard: dashboardReducer,
  dashboard: dashboardUrlReducer
})