import { agentConstants } from "../constants";
import { combineReducers } from "redux";
import createReducer from "../../../helpers/createReducer";

const initialState = [];

const agentReducer = createReducer(initialState)({
  [agentConstants.GET_ALL_AGENT_SUCCESS]: (state, action) => action.payload,
  [agentConstants.GET_ALL_AGENT_FAILURE]: (state, action) => action.payload,
});

const copyAgentReducer = createReducer(initialState)({
  [agentConstants.GET_ALL_AGENT_COPY_SUCCESS]: (state, action) =>
    action.payload,
});

const agentDetailReducer = createReducer({})({
  [agentConstants.GET_AGENT_DETAIL_SUCCESS]: (state, action) => action.payload,
  [agentConstants.GET_AGENT_DETAIL_FAILURE]: (state, action) => action.payload,
  [agentConstants.UPDATE_AGENT_DETAIL]: (state, action) => action.payload,
});

const connectionReducer = createReducer(initialState)({
  [agentConstants.GET_ALL_CONNECTION_SUCCESS]: (state, action) =>
    action.payload,
  [agentConstants.GET_ALL_CONNECTION_FAILURE]: (state, action) =>
    action.payload,
});

const IntegrationReducer = createReducer(initialState)({
  [agentConstants.GET_ALL_INTEGRATION_SUCCESS]: (state, action) =>
    action.payload,
  [agentConstants.GET_ALL_INTEGRATION_FAILURE]: (state, action) =>
    action.payload,
});

const EnabledIntegrationReducer = createReducer(initialState)({
  [agentConstants.GET_ALL_ENABLED_INTEGRATION_SUCCESS]: (state, action) =>
    action.payload,
  [agentConstants.GET_ALL_ENABLED_INTEGRATION_FAILURE]: (state, action) =>
    action.payload,
});

const oktaReducer = createReducer(initialState)({
  [agentConstants.GET_ALL_OKTA_SUCCESS]: (state, action) => action.payload,
  [agentConstants.GET_ALL_OKTA_FAILURE]: (state, action) => action.payload,
});

export default combineReducers({
  allAgent: agentReducer,
  allCopyAgent: copyAgentReducer,
  allConnections: connectionReducer,
  allIntegrations: IntegrationReducer,
  allEnabledIntegrations: EnabledIntegrationReducer,
  agentDetail: agentDetailReducer,
  allOkta: oktaReducer,
});
