import { integrations } from './constant';
import { requestDelete, requestGet, requestPost, requestPut } from '../../../services/requests';

/**
 * Supports all operations exposed by the user controller.
 */

/* eslint eqeqeq: 0 */
export class EndpointTemplatesService {

  constructor(endpoint) {
    this.endpoint = endpoint;
  }

  getAllEndpointTemplates() {
    const url = this.operationUrl('/endpointTemplate/list');
    return requestGet(url,{}, false);
  };

  getEndpointTemplateById(id) {
    const url = this.operationUrl(`/endpointTemplate/${id}`);
    return requestGet(url);
  };

  createEndpointTemplate(data) {
    const url = this.operationUrl(`/endpointTemplate/create`);
    return requestPost(url, data);
  };

  updateEndpointTemplate(data) {
    const url = this.operationUrl(`/endpointTemplate/update`);
    return requestPut(url, data);
  };

  deleteEndpointTemplateById(templateID, reason) {
    const url = this.operationUrl(`/endpointTemplate/${templateID}?reason=${reason}`);
    return requestDelete(url);
  }



  // getAllEnabledIntegrations() {
  //   return new Promise((resolve) => {
  //     const res = _.filter(integrations, (res) => { return res.instaled })
  //     resolve(res)
  //   });
  // };

  
  testEndpointConnection(id) {
    const url = this.operationUrl(`/endpointTemplate/health`);
    return requestPost(url, {
      "requestType": "detailed",
      "endpoints": [id]
    });
  };

  operationUrl(append) {
    return this.endpoint + append;
  }
}



