import { OktaAuth } from '@okta/okta-auth-js';
import { getItem } from '../../../helpers';

const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
const ISSUER = process.env.REACT_APP_ISSUER;
const OKTA_TESTING_DISABLEHTTPSCHECK = false;
const BASENAME = process.env.PUBLIC_URL || '';
const REDIRECT_URI = `${window.location.origin}${BASENAME}/login/callback`;

const config = {
  oidc: {
    clientId: CLIENT_ID,
    issuer: ISSUER,
    redirectUri: REDIRECT_URI,
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
    disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
  }
}
export const oktaAuth = new OktaAuth(config.oidc);

export const getOktaAuth = () => {
  const oktaConfig = JSON.parse(getItem('ssoLoginConfig')); 
  config.oidc.clientId = oktaConfig.WebClientID
  config.oidc.issuer = `${oktaConfig.Iss}`
  return new OktaAuth(config.oidc)
}