/* eslint-disable eqeqeq */
import { transactionsConstants } from '../constants';
import { transactionsService } from '../service';

export const storeTransactionsfilter = response => ({
  type: transactionsConstants.STORE_TRANSACTIONS_FILTER,
  payload: response
})

export const fetchTransactionsSuccess = response => ({
  type: transactionsConstants.GET_ALL_TRANSACTIONS_SUCCESS,
  payload: response
})

export const fetchTransactionsFailure = response => ({
  type: transactionsConstants.GET_ALL_TRANSACTIONS_FAILURE,
  payload: []
})


export const setFilter = (obj) => {
  return dispatch => {
    dispatch({
      type: transactionsConstants.SET_TRANSACTIONS_FILTER,
      payload: { ...obj }
    })
  }
}

export const setTranasctionPage = (page) => {
  return dispatch => {
    dispatch({
      type: transactionsConstants.SET_TRANSACTIONS_PAGE,
      payload: page
    })
  }
}

export const getAllTransactions = (param) => {
  return (dispatch, state) => {
    let res = state().transactions.allTransactions;
    if (!param.page || param.page == 1) {
      dispatch(storeTransactionsfilter(param));
      return transactionsService.getAllTransactions(param, false)
        .then(response => {
          res = {
            data: [
              { ...response.data }
            ],
            page: param.page || 1
          }
          dispatch(fetchTransactionsSuccess(res));
          return response.data;
        },
          error => {
            dispatch(fetchTransactionsFailure(error));
            return error;
          })
    }
  }
}

export const addLiveTransactions = (trans) => {
  return (dispatch, state) => {
    let res = state().transactions.allTransactions;
    const transIds = res.data[0].transactions.map(t => t.TransactionID);
    const liveTrans = [];
    for (let i = 0; i < trans.length; i++) {
      if (transIds.indexOf(trans[i].TransactionID) > -1) {
        break;
      }
      liveTrans.push(trans[i]);
    }

    res.data[0].transactions = liveTrans.concat(res.data[0].transactions);
    dispatch(fetchTransactionsSuccess({ ...res }));
  }
}


export const getUserTransactions = (param) => {
  return dispatch => {
    dispatch(storeTransactionsfilter(param));
    return transactionsService.getUserTransactions(param)
      .then(response => {
        let res = {
          data: [
            { ...response.data }
          ],
          page: 1
        }
        dispatch(fetchTransactionsSuccess(res));
        return response.data;
      },
        error => {
          dispatch(fetchTransactionsFailure(error));
          return error;
        })
  }
}

export const nextTransactions = (param) => {
  return (dispatch, state) => {
    let filters = state().transactions.transactionFilter;
    param = {
      ...param,
      ...filters
    }
    return transactionsService.getAllTransactions(param, false)
      .then(response => {
        let res = state().transactions.allTransactions;
        res.data.push({ ...response.data });
        res.page = res.page + 1;
        dispatch(fetchTransactionsSuccess({ ...res }));
        return response.data;
      },
        error => {
          dispatch(fetchTransactionsFailure(error));
          return error;
        })
  }
}

export const prevTransactions = (param) => {
  return (dispatch, state) => {
    let res = state().transactions.allTransactions
    res.data.pop();
    res.page = res.page - 1;
    dispatch(fetchTransactionsSuccess({ ...res }));
  }
}

export const firstTransactions = (param) => {
  return (dispatch, state) => {
    let res = state().transactions.allTransactions
    res.data = [res.data[0]];
    res.page = 1;
    dispatch(fetchTransactionsSuccess({ ...res }));
  }
}


export const fetchTransactionsDetailSuccess = response => ({
  type: transactionsConstants.GET_TRANSACTIONS_DETAIL_SUCCESS,
  payload: response
})

export const fetchTransactionsDetailFailure = response => ({
  type: transactionsConstants.GET_TRANSACTIONS_DETAIL_FAILURE,
  payload: {}
})

export const getTransactionsById = (id) => {
  return dispatch => {
    return transactionsService.getTransactionsById(id)
      .then(response => {
        dispatch(fetchTransactionsDetailSuccess(response.data));
        return response.data;
      },
        error => {
          dispatch(fetchTransactionsDetailFailure(error));
          return error;
        })
  }
}

export const downloadTransactions = (param) => {
  return transactionsService.downloadTransactions(param)
    .then(response => {
      return response.data;
    },
      error => {
        error.error = true;
        return error;
      })
}

export const downloadKronosTransactions = (param) => {
  return transactionsService.downloadKronosTransactions(param)
    .then(response => {
      return response.data;
    },
      error => {
        error.error = true;
        return error;
      })
}

export const getAllIntegrationsSettingsSuccess = response => ({
  type: transactionsConstants.GET_ALL_INTEGRATION_SUCCESS,
  payload: response
})

export const getAllIntegrationsSettingsFailure = response => ({
  type: transactionsConstants.GET_ALL_INTEGRATION_FAILURE,
  payload: []
})

export const getAllIntegrationsSettings = (param) => {
  return dispatch => {
    return transactionsService.getAllIntegrationsSettings(param)
      .then(response => {
        dispatch(getAllIntegrationsSettingsSuccess(response));
        return response;
      },
        error => {
          error.error = true;
          dispatch(getAllIntegrationsSettingsFailure(error));
          return error;
        })
  }
}

export const createIntegrationsSettings = (data) => {
  return dispatch => {
    return transactionsService.createIntegrationsSettings(data)
      .then(response => {
        // dispatch(getAllIntegrationsSettings());
        return response;
      },
        error => {
          return error;
        })
  }
}




export const storeActionTransactionsfilter = response => ({
  type: transactionsConstants.STORE_ACTION_TRANSACTIONS_FILTER,
  payload: response
})

export const fetchActionTransactionsSuccess = response => ({
  type: transactionsConstants.GET_ALL_ACTION_TRANSACTIONS_SUCCESS,
  payload: response
})

export const fetchActionTransactionsFailure = response => ({
  type: transactionsConstants.GET_ALL_ACTION_TRANSACTIONS_FAILURE,
  payload: []
})

export const setActionFilter = (obj) => {
  return dispatch => {
    dispatch({
      type: transactionsConstants.SET_ACTION_TRANSACTIONS_FILTER,
      payload: { ...obj }
    })
  }
}

export const setActionTranasctionPage = (page) => {
  return dispatch => {
    dispatch({
      type: transactionsConstants.SET_ACTION_TRANSACTIONS_PAGE,
      payload: page
    })
  }
}


export const getActionTransactions = (param) => {
  return dispatch => {
    if (!param.page || param.page == 1) {
      dispatch(storeActionTransactionsfilter(param));
      return transactionsService.getActionTransactions(param, false)
        .then(response => {
          let res = {
            data: [
              { ...response.data }
            ],
            page: 1
          }
          dispatch(fetchActionTransactionsSuccess(res));
          return response.data;
        },
          error => {
            dispatch(fetchActionTransactionsFailure(error));
            return error;
          })
    }
  }
}

export const nextActionTransactions = (param) => {
  return (dispatch, state) => {
    let filters = state().transactions.actionTransactionFilter;
    param = {
      ...param,
      ...filters
    }
    return transactionsService.getActionTransactions(param, false)
      .then(response => {
        let res = state().transactions.actionTransactions;
        res.data.push({ ...response.data });
        res.page = res.page + 1;
        dispatch(fetchActionTransactionsSuccess({ ...res }));
        return response.data;
      },
        error => {
          dispatch(fetchActionTransactionsFailure(error));
          return error;
        })
  }
}

export const prevActionTransactions = (param) => {
  return (dispatch, state) => {
    let res = state().transactions.actionTransactions
    res.data.pop();
    res.page = res.page - 1;
    dispatch(fetchActionTransactionsSuccess({ ...res }));
  }
}

export const firstActionTransactions = (param) => {
  return (dispatch, state) => {
    let res = state().transactions.actionTransactions
    res.data = [res.data[0]];
    res.page = 1;
    dispatch(fetchActionTransactionsSuccess({ ...res }));
  }
}


export const retryPlatfromAction = (params) => {
  return dispatch => {
    return transactionsService.retryPlatfromAction(params)
      .then(response => {
        return response;
      },
        error => {
          error.error = true;
          return error;
        })
  }
}

export const getFirebaseToken = () => {
  return dispatch => {
    return transactionsService.getFirebaseToken()
      .then(response => {
        return response;
      },
        error => {
          error.error = true;
          return error;
        })
  }
}