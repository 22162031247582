/* eslint-disable eqeqeq */
import { locationConstants } from '../constants';
import { locationService } from '../service';
import _ from 'lodash';

export const createLocation = (data) => {
  return dispatch => {
    return locationService.createLocation(data)
      .then(response => {
        return dispatch(fetchAllLocation());
      },
        error => {
          error.error = true;
          return error;
        })
  }
}

export const getLocationByIdSuccess = data => ({
  type: locationConstants.GET_LOCATION_BY_ID_SUCCESS,
  payload: data
})

export const fetchLocationById = (id) => {
  return dispatch => {
    return locationService.getLocationById(id)
      .then(response => {
        return dispatch(getLocationByIdSuccess(response.data));
      },
        error => {
          error.error = true;
          return error;
        })
  }
}

export const getLocationSuccess = data => ({
  type: locationConstants.GET_ALL_LOCATION_SUCCESS,
  payload: data
})
export const copyLocationSuccess = response => ({
  type: locationConstants.GET_ALL_LOCATION_COPY_SUCCESS,
  payload: response
})

export const fetchAllLocation = () => {
  return dispatch => {
    return locationService.getAllLocation()
      .then(response => {
        dispatch(copyLocationSuccess(response.data));
        return dispatch(getLocationSuccess(response.data));
      },
        error => {
          error.error = true;
          return error;
        })
  }
}
export const filterLocation = (filter) => {
  return (dispatch, state) => {
    const data = state().place.allCopyLocation
    const allCopyLocation = JSON.parse(JSON.stringify(data));
    let filtered = allCopyLocation || [];
    if (filter.search) {
      filtered = _.filter(filtered, (res) => {
        return res.LocationName && (
          res.LocationName.toLowerCase().indexOf(filter.search.toLowerCase()) !== -1
          || res.LocationID.toLowerCase().indexOf(filter.search.toLowerCase()) !== -1);
      })
    }
    dispatch(getLocationSuccess(filtered));
    dispatch(updateFilter(filter))
  }
}


export const createBuilding = (data) => {
  return dispatch => {
    return locationService.createBuilding(data)
      .then(response => {
        return dispatch(fetchAllBuilding(data.SettingType));
      },
        error => {
          error.error = true;
          return error;
        })
  }
}

export const fetchAllBuilding = (settingType) => {
  return dispatch => {
    return locationService.fetchAllBuilding(settingType)
      .then(response => {
        var data = response.data
        if (data.length > 0) {
          data.map(building => {
            if (building.Areas != undefined && building.Areas.length > 0) {
              building.Areas.map(area => {
                area['tempBuildingID'] = building.BuildingID;
                return area;
              })
            } else if (building.Areas && building.Areas != undefined && building.Areas.length == 0) {
              delete building.Areas
            }
            return building;
          })
        }
        return dispatch(fetchAllBuildingSuccess(data));
      },
        error => {
          error.error = true;
          return error;
        })
  }
}

export const fetchAllBuildingSuccess = data => ({
  type: locationConstants.GET_BUILDING_BY_SETTING_TYPE,
  payload: data
})

export const fetchAllArea = (buildingID) => {
  return dispatch => {
    return locationService.fetchAllArea(buildingID)
      .then(response => {
        return dispatch(fetchAllAreaSuccess(response.data));
      },
        error => {
          error.error = true;
          return error;
        })
  }
}

export const fetchAllAreaSuccess = data => ({
  type: locationConstants.GET_AREA_BY_BUILDING_ID,
  payload: data
})

export const createArea = (data) => {
  return dispatch => {
    return locationService.createArea(data)
      .then(response => {
        return dispatch(fetchLocationById(data.SettingType));
        //return dispatch(fetchAllArea(data.BuildingID));
      },
        error => {
          error.error = true;
          return error;
        })
  }
}

export const deleteBuilding = (id, settingType) => {
  return dispatch => {
    return locationService.deleteBuilding(id)
      .then(response => {
        return dispatch(fetchAllBuilding(settingType));
      },
        error => {
          error.error = true;
          return error;
        })
  }
}

export const deleteArea = (areaId, buildingId) => {
  return dispatch => {
    return locationService.deleteArea(areaId, buildingId)
      .then(response => {
        return dispatch(fetchAllArea(buildingId));
      },
        error => {
          error.error = true;
          return error;
        })
  }
}

export const deleteLocation = (settingType) => {
  return dispatch => {
    return locationService.deleteLocationById(settingType)
      .then(response => {
        return dispatch(fetchAllLocation());
      },
        error => {
          error.error = true;
          return error;
        })
  }
}

export const updateLocation = (data, isID, isRaw) => {
  return dispatch => {
    return locationService.updateLocation(data, isRaw)
      .then(response => {
        if (isID) {
          return dispatch(fetchLocationById(response.data.SettingType));
        }
        else {
          return dispatch(fetchAllLocation());
        }
      },
        error => {
          error.error = true;
          return error;
        })
  }
}

export const updateFilter = payload => ({
  type: locationConstants.FILTER_UPDATE,
  payload: payload
})

export const updatePagination = payload => ({
  type: locationConstants.PAGINATE_UPDATE,
  payload: payload
})