import moment from 'moment';
export const utcToLocal = (date, format) => {
  if (date) {
    var localDate = moment.utc(date).local();
    if (format) {
      localDate = localDate.format(format);
    } else {
      localDate = localDate.format('MMM DD YYYY hh:mm A');
    }
    return localDate;
  }
};
