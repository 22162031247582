import { similarFaceConstants } from '../constants';
import { combineReducers } from 'redux'
import createReducer from '../../../helpers/createReducer'

const initialState = [];

const facesReducer = createReducer(initialState)({
  [similarFaceConstants.GET_ALL_SIMILAR_FACE_SUCCESS]: (state, action) => action.payload,
  [similarFaceConstants.GET_ALL_SIMILAR_FACE_FAILURE]: (state, action) => action.payload,
})

const copyFaceReducer = createReducer(initialState)({
  [similarFaceConstants.GET_ALL_SIMILAR_FACE_COPY_SUCCESS]: (state, action) => action.payload,
})

export default combineReducers({
  faces: facesReducer,
  allCopyFace: copyFaceReducer,
})