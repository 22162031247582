import React, { Component } from 'react';
import TopbarUser from '../Topbar/topbarUser';
import { Link } from 'react-router-dom';
import { history } from '../../../helpers';
import options from '../Sidebar/options';

import LogoImage from '../../../image/logo.png';
import LogoLoader from '../../../image/circle-loader.gif';

import Icon1 from '../../../image/ic_1.png';
import Icon2 from '../../../image/ic_2.png';
import Icon3 from '../../../image/ic_3.png';
import Icon4 from '../../../image/ic_4.png';
import Icon5 from '../../../image/ic_5.png';
import Icon6 from '../../../image/Dashboard-Icon.png';
import Icon1_Active from '../../../image/ic_1_selected.png';
import Icon2_Active from '../../../image/ic_2_selected.png';
import Icon3_Active from '../../../image/ic_3_selected.png';
import Icon4_Active from '../../../image/ic_4_selected.png';
import Icon5_Active from '../../../image/ic_5_selected.png';
import Icon6_Active from '../../../image/Dashboard-selected-Icon.png';
import _ from 'lodash';
import { connect } from "react-redux";

import { setSelectedMenu } from "../action/sideFixedBarAction";


export class SideFixedBar extends Component {

  state = {
    selectedIndex: 0,
    Lists: [
      { id: 1, inActiveIcon: Icon2, activeIcon: Icon2_Active, allowedRoles: ['admin', 'supperAdmin'] },
      { id: 2, inActiveIcon: Icon3, activeIcon: Icon3_Active, allowedRoles: ['admin', 'supperAdmin'] },
      { id: 3, inActiveIcon: Icon4, activeIcon: Icon4_Active, allowedRoles: ['admin', 'supperAdmin'] },
      { id: 4, inActiveIcon: Icon5, activeIcon: Icon5_Active, allowedRoles: ['flex', 'supperAdmin'] },
      { id: 5, inActiveIcon: Icon6, activeIcon: Icon6_Active, allowedRoles: ['supperAdmin'] },
      // { id: 6, inActiveIcon: Icon1, activeIcon: Icon1_Active, allowedRoles: ['supperAdmin'] },
    ]
  }
  componentWillMount() {
    const { location } = this.props;
    const currentItem = _.nth(_.split(location.pathname, '/'), 1);
    const menus = options.find(function (res) { return res.key === currentItem });
    if (menus) {
      this.handleActive((menus.menuid - 1), true);
    }
    else if (currentItem === 'endloc') {
      this.handleActive(2, true);
    }
  }

  handleWorkflowLink = () => {
    const { user } = this.props;

    window.open(`http://workflow.${process.env['REACT_APP_HOST_NAME']}/${user.tenantName}?token=${user.accesstoken}`, '_blank');
  }

  handleActive = (index, isSelected) => {
    this.setState({ selectedIndex: index });
    let list = this.state.Lists;
    list = list.map(x => {
      x.active = false;
      return x;
    })
    list[index].active = true;
    this.setState({ Lists: list });

    if (list[index] && list[index].id === 1) {
      if (!isSelected) {
        history.push('/applications')
      }
    }
    if (list[index] && list[index].id === 2) {
      if (!isSelected) {
        history.push('/users')
      }
    }
    if (list[index] && list[index].id === 3) {
      if (!isSelected) {
        history.push('/endpoint/all')
      }
    }
    if (list[index] && list[index].id === 4) {
      if (!isSelected) {
        history.push('/downloads/user-det')
      }
    }
    if (list[index] && list[index].id === 5) {
      if (!isSelected) {
        history.push('/activity/users')
      }
    }
    if (list[index] && list[index].id === 6) {
      if (!isSelected) {
        history.push('/workflow')
      }
    }
    this.props.setSelectedMenu(this.state.Lists[index].id)
  }

  render() {
    const { locale, user, Selectedmenu } = this.props;
    const { Lists } = this.state;
    let emailDomain = user.email && user.email.split('@');
    let isFullAccess = ['oloid.ai', 'xcdify.com'].indexOf((emailDomain && emailDomain[1])) > -1 ? 'supperAdmin' : '';
    let isFlex = ['flex.com'].indexOf((emailDomain && emailDomain[1])) > -1 ? 'flex' : '';

    const selectedList = _.filter(Lists, (route) => {
      return user && (route.allowedRoles.indexOf(user.role) > -1
        || route.allowedRoles.indexOf(isFullAccess) > -1
        || route.allowedRoles.indexOf(isFlex) > -1)
    })

    return (
      <div className="leftBar">
        <ul>
          <li>

            <div className="logo">
              <img alt="loader" className="logo-loader" id="logoLoader" src={LogoLoader} />
              <img alt="logo" id="logo" src={LogoImage} onClick={(e) => { this.handleActive((Selectedmenu - 1)) }} />
            </div>

          </li>
          {selectedList.map((value, index) => (
            <li onClick={(e) => { this.handleActive(index) }} key={`item-${index}`} className={(value.active ? 'active' : '')}>
              <div className="icon">
                <img alt='' src={value.active ? value.activeIcon : value.inActiveIcon} />
              </div>
            </li>
          ))}

          {/* {
            inHouse && <li onClick={(e) => { this.handleWorkflowLink() }}>
              <div className="icon">
                <img alt='' src={Icon1} />
              </div>
            </li>
          } */}

        </ul>
        <div className="profile-button isoUser">
          {/* <div className="notification-box">
            <NotifictationUser />
          </div> */}
          {user && <TopbarUser locale={locale} />}
        </div>
      </div>
    );
  }
}


const mapStateToProps = (state, props) => ({
  Selectedmenu: state.fixSideBar.fixSideBarReducer.menuId,
  user: state.authentication.user
});

const mapDispatchToProps = (dispatch, props) => ({
  setSelectedMenu: (id) => dispatch(setSelectedMenu(id))
});

const SideFixedBarContainer = connect(mapStateToProps, mapDispatchToProps)(
  SideFixedBar
);

export default SideFixedBarContainer;

