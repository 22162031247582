import { consentConstants } from '../constants';
import { consentService } from '../service';
import _ from 'lodash';

export const fetchConsentSuccess = response => ({
  type: consentConstants.GET_ALL_CONSENT_SUCCESS,
  payload: response
})

export const fetchConsentFailure = response => ({
  type: consentConstants.GET_ALL_CONSENT_FAILURE,
  payload: []
})
export const copyConsentSuccess = response => ({
  type: consentConstants.GET_ALL_CONSENT_COPY_SUCCESS,
  payload: response
})


export const getAllConsentTemplate = (param) => {
  return dispatch => {
    return consentService.getAllConsentTemplate(param)
      .then(response => {
        
        let template = (response.data) || []
        template = _.orderBy(template, ['CreatedAt'], ['desc']);
        dispatch(fetchConsentSuccess(template));
        dispatch(copyConsentSuccess(template));
        return template;
      },
        error => {
          dispatch(fetchConsentFailure(error));
          return error;
        })
  }
}

export const filterConsent = (filter) => {
  return (dispatch, state) => {
    const data = state().consent.allCopyConsent
    const allcopyConsent = JSON.parse(JSON.stringify(data));
    let filtered = allcopyConsent || [];
    if (filter.search) {
      filtered = _.filter(filtered, (res) => {
        return res.TemplateName && (
          res.TemplateName.toLowerCase().indexOf(filter.search.toLowerCase()) !== -1
          || res.TemplateID.toLowerCase().indexOf(filter.search.toLowerCase()) !== -1);
      })
    }
    dispatch(fetchConsentSuccess(filtered));
  }
}


export const fetchConsentDetailSuccess = response => ({
  type: consentConstants.GET_CONSENT_DETAIL_SUCCESS,
  payload: { ...response }
})

export const fetchConsentDetailFailure = response => ({
  type: consentConstants.GET_CONSENT_DETAIL_FAILURE,
  payload: {}
})

export const getConsentById = (id) => {
  return dispatch => {
    return consentService.getConsentTemplateById(id)
      .then(response => {
        dispatch(fetchConsentDetailSuccess(response.data));
        return response.data;
      },
        error => {
          dispatch(fetchConsentDetailFailure(error));
          return error;
        })
  }
}


export const createConsentTemplate = (data) => {
  return dispatch => {
    return consentService.createConsentTemplate(data)
      .then(response => {
        if (data.TemplateID) {
          dispatch(getConsentById(data.TemplateID));
        }
        else {
          dispatch(getAllConsentTemplate());
        }
        return response;
      })
  }
}

export const updateConsentTemplate = (data) => {
  return dispatch => {
    return consentService.updateConsentTemplate(data)
      .then(response => {
        return dispatch(getConsentById(data.TemplateID));
      })
  }
}

export const deleteDraftConsentTemplate = (id, reason) => {
  return dispatch => {
    return consentService.deleteDraftConsentTemplate(id, reason)
      .then(response => {
        dispatch(getAllConsentTemplate());
        return response;
      },
        error => {
          error.error = true;
          return error;
        })
  }
}


export const fetchLegalDocumentsSuccess = response => ({
  type: consentConstants.GET_ALL_LEGAL_DOCUMENTS_SUCCESS,
  payload: response
})

export const fetchLegalDocumentsFailure = response => ({
  type: consentConstants.GET_ALL_LEGAL_DOCUMENTS_FAILURE,
  payload: []
})

export const copyLegalDocumentsSuccess = response => ({
  type: consentConstants.GET_ALL_LEGAL_DOCUMENTS_COPY_SUCCESS,
  payload: response
})


export const getAllLegalDocuments = (param) => {
  return dispatch => {

    return consentService.getAllLegalDocuments(param)
      .then(response => {
        dispatch(copyLegalDocumentsSuccess(response.data));
        dispatch(fetchLegalDocumentsSuccess(response.data));
        return response.data;
      },
        error => {
          dispatch(fetchLegalDocumentsFailure(error));
          return error;
        })
  }
}


export const filterLegalDocument = (filter) => {
  return (dispatch, state) => {
    const data = state().consent.allCopyLegalDocuments
    const allCopyLegalDocuments = JSON.parse(JSON.stringify(data));
    let filtered = allCopyLegalDocuments || [];
    if (filter.search) {
      filtered = _.filter(filtered, (res) => {
        return res.DisplayName && (
          res.DisplayName.toLowerCase().indexOf(filter.search.toLowerCase()) !== -1
          || res.Identifier.toLowerCase().indexOf(filter.search.toLowerCase()) !== -1);
      })
    }
    dispatch(fetchLegalDocumentsSuccess(filtered));
  }
}


export const fetchLegalDocumentsByIDSuccess = response => ({
  type: consentConstants.GET_LEGAL_DOCUMENTS_BY_ID_SUCCESS,
  payload: { ...response }
})

export const fetchLegalDocumentsByIDFailure = () => ({
  type: consentConstants.GET_LEGAL_DOCUMENTS_BY_ID_FAILURE,
  payload: []
})

export const getAllLegalDocumentByID = (id) => {
  return dispatch => {
    return consentService.getAllLegalDocumentByID(id)
      .then(response => {

        dispatch(fetchLegalDocumentsByIDSuccess(response.data));
        return response.data;
      },
        error => {
          dispatch(fetchLegalDocumentsByIDFailure());
          return error;
        })
  }
}

export const createDocumentTemplate = (data, file) => {
  return dispatch => {
    return consentService.CreateLegalDocument(data, file)
      .then(response => {
        dispatch(getAllLegalDocuments());
        return response;
      },
        error => {
          let err = error;
          if (typeof error == 'string') {
            err = {
              message: error
            }
          }
          err.error = true;
          return err;
        })
  }
}

export const getLegalDocumentUrl = (data) => {
  return dispatch => {
    return consentService.getLegalDocumentUrl(data)
      .then(response => {
        return response;
      },
        error => {
          error.error = true;
          return error;
        })
  }
}

export const CreateConsentAdminAssisted = (data) => {
  return dispatch => {
    return consentService.CreateConsentAdminAssisted(data)
      .then(response => {
        return response;
      },
        error => {
          error.error = true;
          return error;
        })
  }
}


export const getLegalLinkedDocumentByID = (id) => {
  return dispatch => {
    return consentService.getLegalLinkedDocumentByID(id)
      .then(response => {
        return response;
      },
        error => {
          error.error = true;
          return error;
        })
  }
}

export const deleteLegalDocumentByID = (id, reason) => {
  return dispatch => {
    return consentService.deleteLegalDocumentByID(id, reason)
      .then(response => {
        dispatch(getAllLegalDocuments());
        return response;
      },
        error => {
          error.error = true;
          return error;
        })
  }
}
