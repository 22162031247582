import React, { Component } from 'react';
import { Modal, Button} from 'antd';
import { connect } from 'react-redux';
import { TextField, Select, MenuItem, InputLabel, FormControl, FormHelperText } from '@material-ui/core';

class CreateWeigand extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        WeigandName: '',
       
      },
      isSubmit: false
    }
  }

  handleCancel = () => {
    this.props.toggle()
  };


  render() {
    return (
      <div >
        <Modal
          className="create-application"
          visible={this.props.modal}
          onCancel={this.handleCancel}
          footer={[
            <div className="footer-box">
              <Button className="create-btn" key="back" type="primary" onClick={this.handleOk}>
                CREATE
              </Button>
              <Button className="cancle-btn" key="submit" onClick={this.handleCancel}>
                CANCEL
              </Button>
            </div>
          ]}
        >
          <div className='txt-header'>Create Weigand Format</div>
          <div className='position-rel'>
            <TextField
              name="WeigandName"
              label="Weigand Name"
              type="text"
              className='tenant-form-textfield'
              margin="normal"
              variant="outlined"
              onChange={(e) => this.handleInput(e)}
            />
           
          </div>
          <div className='position-rel'>
            <FormControl variant="outlined" className='region-select'>
              <InputLabel>
                Format Template
              </InputLabel>
              <Select
                  showSearch
                  placeholder="Select a template"
                  >
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Awenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </FormControl>
          </div>
        </Modal>
      </div>
    );
  }
}


function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user
  }
}
export default connect(mapStateToProps)(CreateWeigand);