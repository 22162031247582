import React, { Component } from 'react';
import { Modal, Button, Row, Col, message, Select } from 'antd';
import TextField from "@material-ui/core/TextField";
import { connect } from 'react-redux';
import _ from 'lodash';
import { fetchAllLocation } from '../../location/action/location.actions';
import { getAllApplication } from '../../application/action/application.actions'
import { saveEndpoint } from '../action/endpoints.actions';
import { v4 as uuidv4 } from 'uuid';
import "./createEndpoint.scss";
import { orderedListData, successMessage, tostor } from '../../../helpers';

const Option = Select.Option;

const initialState = {
  endpoint: {
    EndpointName: '',
    EndpointType: 'M-Tag',
    ApplicationID: '',
    LocationID: '',
    BuildingID: '',
    AreaID: '',
    StickerMACAddress: '',
    ModelNumber: '',
    StickerUUID: uuidv4(),
    InactiveEndpoint: false
  },
  error: {},
  isSubmit: false
}
/* eslint eqeqeq: 0 */
class CreateEndpoint extends Component {
  constructor(props) {
    super(props);
    this.state = { ..._.merge({}, initialState) }
  }


  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(fetchAllLocation());
    dispatch(getAllApplication());
  }

  handleOk = () => {
    const { pagePathname } = this.props;

    this.setState({ isSubmit: true });

    if (this.validateForm()) {
      const { dispatch, allLocation } = this.props;
      this.setState({ loading: true });
      const endpoint = Object.assign({}, this.state.endpoint)

      const selectedLocation = endpoint.LocationID &&
        allLocation.find((x) => {
          return x.LocationID == endpoint.LocationID
        })
      if (selectedLocation) {
        const address = selectedLocation.Address
        endpoint["Address"] = address
      }

      const reqObj = {
        ...endpoint,
        EndpointName: endpoint.EndpointName.trim(),
        StickerMACAddress: endpoint.StickerMACAddress.toLocaleUpperCase()
      }

      let statusType = 'all';
      if (pagePathname) {
        if (pagePathname == '/endpoint/active') {
          statusType = 'activeonly';
        }
        if (pagePathname == '/endpoint/inactive') {
          statusType = 'inactiveonly';
        }
      }


      dispatch(saveEndpoint(reqObj, { statusType }))
        .then((res) => {
          this.setState({ loading: false });
          if (res.error) {
            tostor.error(res.message);
            return
          }
          tostor.success(successMessage.ENDPOINT_CREATE);
          this.resetFrom();
          this.props.toggle(true)
        });
    }
  };

  resetFrom = () => {
    this.setState({ ..._.merge({}, initialState) })
  }

  handleCancel = () => {
    this.resetFrom();
    this.props.toggle()
  };

  validateForm = () => {
    const { endpoint } = this.state;
    let error = {};
    if (!endpoint.EndpointName) {
      error.EndpointName = true;
    }
    if (!endpoint.EndpointType) {
      error.EndpointType = true;
    }
    if (!endpoint.StickerMACAddress) {
      error.StickerMACAddress = true;
    }
    if (!endpoint.ApplicationID) {
      error.ApplicationID = true;
    }
    if (endpoint.StickerMACAddress) {
      let macAddress = /^([0-9A-Fa-f]{2}[:-]?){5}([0-9A-Fa-f]{2})$/;
      if (!macAddress.test(endpoint.StickerMACAddress)) {
        error.InValidMACAddress = true;
      }
    }
    this.setState({ error })
    return Object.keys(error).length == 0;
  }

  handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const { endpoint, error } = Object.assign({}, this.state)

    if (name == 'EndpointName') {
      var letters = /^[A-Za-z0-9-_# ]+$/;
      if (value.match(letters) || e.target.value == '') {
        endpoint[name] = value.replace(/\s{2,}/g, ' ');
        error[name] = false
      }
    } else {
      endpoint[name] = value;
      error[name] = false
    }

    if (name == 'StickerMACAddress') {
      error.InValidMACAddress = false;
    }

    this.setState({ endpoint, error })
  }

  selectEndpointType = (type) => {
    const { endpoint, error } = Object.assign({}, this.state)
    endpoint['EndpointType'] = type;
    error['EndpointType'] = false
    this.setState({ endpoint, error })
  }

  handleDropdownChange = (value, name) => {
    const { endpoint, error } = Object.assign({}, this.state)
    error[name] = false
    endpoint[name] = value

    if (name == 'LocationID') {
      endpoint['BuildingID'] = undefined
      endpoint['AreaID'] = undefined

    }
    if (name == 'BuildingID') {
      endpoint['AreaID'] = undefined
    }

    this.setState({ endpoint, error })
  }

  render() {

    const { error, isSubmit, endpoint } = this.state;
    const { allLocation, allApplication } = this.props
    const LocationID = (endpoint.LocationID != "" && endpoint.LocationID) || undefined
    const selectedLocation = (endpoint.LocationID && allLocation.find((x) => { return x.LocationID == endpoint.LocationID })) || {};
    const selectedBuilding = (endpoint.BuildingID && selectedLocation.Buildings &&
      selectedLocation.Buildings.find((x) => { return x.BuildingID == endpoint.BuildingID })) || {};

    return (
      <div >
        <Modal
          className="create-endpoint"
          visible={this.props.modal}
          onCancel={this.handleCancel}
          footer={[
            <div className="footer-box" key="action">
              <Button className="create-btn" key="back" type="primary" onClick={this.handleOk}>
                CREATE
              </Button>
              <Button className="cancle-btn" key="submit" onClick={this.handleCancel}>
                CANCEL
              </Button>
            </div>
          ]}
        >
          <div className='txt-header'>Create a new Endpoint</div>
          <div className='position-rel'>
            <TextField
              error={error.EndpointName}
              name="EndpointName"
              label="Endpoint Name"
              type="text"
              className='tenant-form-textfield'
              margin="normal"
              variant="outlined"
              value={endpoint.EndpointName}
              helperText={error.EndpointName ? 'Required' : ''}
              onChange={(e) => this.handleInput(e)}
            />
            {isSubmit && !error.EndpointName && <div className='success-icon position'></div>}
            {error.EndpointName && <div className='success-icon position error'></div>}
          </div>

          <div className='position-rel' id="selectapplication-area">
            <Select
              placeholder="Select Application"
              name="ApplicationID"
              id="ApplicationID"
              value={endpoint.ApplicationID || undefined}
              className={`select-input ${error.ApplicationID ? 'error' : ''}`}
              onChange={(value) => this.handleDropdownChange(value, "ApplicationID")}
              getPopupContainer={() => document.getElementById('selectapplication-area')}
            >
              {allApplication && orderedListData(allApplication||[], 'ApplicationName').map((application, i) => {
                  return <Option key={i} value={application.ApplicationID}>{application.ApplicationName}</Option>
                })
              }
            </Select>
            <span className="helper-text">{error.ApplicationID ? 'Required' : ''}</span>
          </div>

          <div className='position-rel'>
            <TextField
              error={(error.StickerMACAddress || error.InValidMACAddress)}
              name="StickerMACAddress"
              label="Mac Address"
              type="text"
              className='tenant-form-textfield'
              margin="normal"
              variant="outlined"
              value={endpoint.StickerMACAddress}
              helperText={
                (error.StickerMACAddress) ? 'Required' : error.InValidMACAddress ? 'Invalid Mac Address' : ''
              }
              onChange={(e) => this.handleInput(e)}
            />
            {isSubmit && !(error.StickerMACAddress || error.InValidMACAddress) && <div className='success-icon position'></div>}
            {(error.StickerMACAddress || error.InValidMACAddress) && <div className='success-icon position error'></div>}
          </div>
          <small>Ex: 3D:F2:C9:A6:B3:4F</small>

          <div className='position-rel'>
            <TextField
              error={error.ModelNumber}
              name="ModelNumber"
              label="Model Number"
              type="text"
              className='tenant-form-textfield'
              margin="normal"
              variant="outlined"
              value={endpoint.ModelNumber}
              onChange={(e) => this.handleInput(e)}
            />
          </div>

          <div className='position-rel' id="location-area">
            <Select
              placeholder="Location"
              name="LocationID"
              id="LocationID"
              className={`select-input`}
              value={LocationID}
              onChange={(value) => this.handleDropdownChange(value, "LocationID")}
              getPopupContainer={() => document.getElementById('location-area')}
            >
              {allLocation && orderedListData(allLocation, 'LocationName').map(location => {
                  return <Option key={location.LocationID} value={location.LocationID}>{location.LocationName}</Option>
                })
              }
            </Select>
          </div>

          <div className='position-rel'>
            <Select
              disabled={!(selectedLocation && selectedLocation.LocationID)}
              placeholder="Select Building"
              name="BuildingID"
              id="BuildingID"
              className={`select-input`}
              value={endpoint.BuildingID || undefined}
              onChange={(value) => this.handleDropdownChange(value, "BuildingID")}
            >
              {
                selectedLocation.Buildings && orderedListData(selectedLocation.Buildings||[], 'BuildingName').map(building => {
                  return (
                    <Option key={building.BuildingID} value={building.BuildingID}>{building.BuildingName}</Option>
                  )
                })
              }

            </Select>
          </div>

          <div className='position-rel'>
            <Select
              disabled={!(selectedBuilding && selectedBuilding.BuildingID)}
              placeholder="Select Area"
              name="AreaID"
              id="AreaID"
              className={`select-input`}
              value={endpoint.AreaID || undefined}
              onChange={(value) => this.handleDropdownChange(value, "AreaID")}
            >
              {
                selectedBuilding.Areas && orderedListData(selectedBuilding.Areas, 'AreaName').map(area => {
                  return (
                    <Option key={area.AreaID} value={area.AreaID}>{area.AreaName}</Option>
                  )
                })
              }
            </Select>
          </div>

          <div className='position-rel' id="activearear">
            <Select
              placeholder="Select Status"
              name="InactiveEndpoint"
              id="InactiveEndpoint"
              className={`select-input`}
              value={endpoint.InactiveEndpoint}
              onChange={(value) => this.handleDropdownChange(value, "InactiveEndpoint")}
              getPopupContainer={() => document.getElementById('activearear')}
            >
              <Option value={false}>Active</Option>
              <Option value={true}>Inactive</Option>
            </Select>
          </div>

        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  const { allLocation } = state.place;
  const { allApplication } = state.application;
  return {
    user,
    allLocation,
    allApplication
  }
}
export default connect(mapStateToProps)(CreateEndpoint);
