import { similarityUsersConstants } from '../constants';
import { combineReducers } from 'redux'
import createReducer from '../../../helpers/createReducer'
import moment from 'moment'

const filter = {
  search: '',
  ToDate: moment(),
  FromDate: moment().subtract(1, "days"),
  ConnectionID: 'ALL'
}

const usersReducer = createReducer({})({
  [similarityUsersConstants.GET_ALL_SIMILARITY_USERS_SUCCESS]: (state, action) => action.payload,
  [similarityUsersConstants.GET_ALL_SIMILARITY_USERS_FAILURE]: (state, action) => action.payload,
  [similarityUsersConstants.SET_SIMILARITY_USER_PAGE]: (state, action) => {
    return {
      ...state,
      page: action.payload
    }
  }
})

const filterReducer = createReducer(filter)({
  [similarityUsersConstants.SET_SIMILARITY_USERS_FILTER]: (state, action) => action.payload,
})


export default combineReducers({
  allUsers: usersReducer,
  filter: filterReducer
})