import React, { Component } from "react";
import { connect } from "react-redux";
import { Layout } from "antd";
import appActions from "../action/user.actions";
import TopbarWrapper from "./topbar.style";
import _ from "lodash";
import DashBoardTopbar from "./dashBoard";
import ApplicationTopBar from "../../../modules/application/component/applicationTopBar";
import UserTopBar from "../../../modules/users/component/UserTopBar";
import ImportUserTopBar from "../../../modules/users/component/ImportUserTopBar";
import GroupsTopBar from "../../../modules/groups/component/GroupsTopBar";
import ReportTopBar from "../../../modules/report/component/reportTopBar";
import ScheduleReportTopBar from "../../../modules/report/component/scheduleReportsTopBar";
import options from "../Sidebar/options";
import ConsetTopBar from "../../../modules/consent/component/ConsentTopBar";
import LegalDocumentTopBar from "../../../modules/consent/component/LegalDocumentTopBar";
import WiegandTopBar from "../../../modules/access/component/wiegandTopBar";
import TemplateTopBar from "../../../modules/endpointTemplate/component/endpointTemplateTopBar";
import LocationTopBar from "../../../modules/location/component/locationTopBar";
import SecretsTopBar from "../../../modules/secrets/component/secretsTopBar";
import TimeInterpretationTopBar from "../../../modules/timeInterpretation/component/timeInterpretationTopbar";
import EndpointTopBar from "../../../modules/endpoint/component/endpointTopBar";

const { Header } = Layout;
const { toggleCollapsed } = appActions;

class Topbar extends Component {
  constructor(props) {
    super(props);
    this.getTopBar = this.getTopBar.bind(this);
  }

  getTopBar(page) {
    const {
      toggleCollapsed,
      allIntegrationsSetting,
      customizedTheme,
      allConnections,
      allDashboard,
    } = this.props;
    //const collapsed = this.props.collapsed ; ;
    const collapsed = this.props.collapsed;
    let pageName = page.pathname.split("/");
    const menu = options.find(function (res) {
      return res.key === _.last(pageName);
    });
    let pagePathname = page.pathname && page.pathname.toLowerCase();

    if (pagePathname.indexOf("detail") > -1) {
      pageName = "";
    } else if (pagePathname.indexOf("/livenessfailure") > -1) {
      pageName = "Liveliness Failure";
    } else if (pagePathname.indexOf("/transactions/") > -1) {
      pageName = "Transactions";
    } else if (pagePathname.indexOf("/integrations/") > -1) {
      let integrationMenu =
        allIntegrationsSetting.find(function (res) {
          return res.IntegrationID === _.last(pageName);
        }) || {};
      pageName = integrationMenu.DisplayName || _.last(pageName);
    } else if (pagePathname === "/access/wiegand/new") {
      pageName = "Create Wiegand Format";
    } else if (pagePathname === "/access/wiegand/duplicate") {
      pageName = "Create Duplicate Wiegand Format";
    } else if (pagePathname === "/downloads/user-det") {
      pageName = "Download Users";
    } else if (pagePathname === "/downloads/synched") {
      pageName = "Download Users";
    } else if (pagePathname.indexOf("/similarfaces/") > -1) {
      let connMenu =
        allConnections.find(function (res) {
          return res.ConnectionID === _.last(pageName);
        }) || {};
      pageName = connMenu.ConnectionDisplayName || _.last(pageName);
    } else if (pagePathname.indexOf("/dashboard/") > -1) {
      let connMenu =
        allDashboard.find(function (res) {
          return res.DashboardID === _.last(pageName);
        }) || {};
      pageName = connMenu.DashboardName || _.last(pageName);
    } else if (
      pagePathname.indexOf("/endpoint/") > -1 ||
      pagePathname.indexOf("/endloc/") > -1
    ) {
      pageName = "Endpoints";
    } else if (pagePathname.indexOf("/workflow/") > -1) {
      pageName = "Workflow";
    }

    if (pagePathname === "/dashboard") {
      return <DashBoardTopbar />;
    } else if (
      pagePathname === "/applications" ||
      pagePathname === "/connections" ||
      pagePathname === "/connectiongroup"
    ) {
      return <ApplicationTopBar pathname={pagePathname.replace("/", "")} />;
    } else if (pagePathname === "/users") {
      return <UserTopBar />;
    } else if (pagePathname.indexOf("/import/") > -1) {
      return <ImportUserTopBar pagePathname={pagePathname} />;
    } else if (pagePathname === "/groups") {
      return <GroupsTopBar />;
    } else if (pagePathname === "/report/one-time") {
      return <ReportTopBar />;
    } else if (pagePathname === "/report/scheduled") {
      return <ScheduleReportTopBar />;
    } else if (pagePathname === "/consent/template") {
      return <ConsetTopBar />;
    } else if (pagePathname === "/consent/documents") {
      return <LegalDocumentTopBar />;
    } else if (pagePathname === "/access/wiegand") {
      return <WiegandTopBar />;
    } else if (pagePathname === "/template") {
      return <TemplateTopBar />;
    } else if (pagePathname === "/location") {
      return <LocationTopBar />;
    } else if (pagePathname === "/secrets") {
      return <SecretsTopBar />;
    } else if (pagePathname === "/timeinterpretation") {
      return <TimeInterpretationTopBar />;
    } else if (
      pagePathname === "/endpoint/all" ||
      pagePathname === "/endpoint/active" ||
      pagePathname === "/endpoint/inactive"
    ) {
      return (
        <EndpointTopBar pathname="Endpoints" pagePathname={pagePathname} />
      );
    } else {
      return (
        <div style={{ width: "100%" }}>
          <div
            className={collapsed ? "leftBtn menuCollapsed" : "leftBtn menuOpen"}
            style={{ color: customizedTheme.textColor }}
            onClick={toggleCollapsed}
          />
          <span className="page_name">{menu ? menu.label : pageName}</span>
        </div>
      );
    }
  }
  render() {
    const { customizedTheme, location } = this.props;
    //const collapsed = this.props.collapsed ; ;
    const collapsed = this.props.collapsed;
    const styling = {
      background: customizedTheme.backgroundColor,
      position: "fixed",
      width: "100%",
      height: 70,
    };
    return (
      <TopbarWrapper>
        <Header
          style={styling}
          className={collapsed ? "platformTopbar collapsed" : "platformTopbar"}
        >
          {this.getTopBar(location)}
        </Header>
      </TopbarWrapper>
    );
  }
}

export default connect(
  (state) => ({
    ...state.APP.App.toJS(),
    customizedTheme: "themedefault",
    allIntegrationsSetting: state.transactions.allIntegrationsSetting,
    allConnections: state.connection.allConnections,
    allDashboard: state.dashboard.allDashboard,
  }),
  { toggleCollapsed }
)(Topbar);
