export const RuleTypes = [
    { value: "Access", lable: "Access" },
    { value: "TimeAttendance", lable: "Time Attendance" },
    { value: "Custom", lable: "Custom" }
]

export const UserType = [
    { value: "Employee", label: "Employee" },
    { value: "Direct Labor", label: "Direct Labor" },
    { value: "Indirect Labor", label: "Indirect Labor" },
    { value: "Temporary", label: "Temporary" },
    { value: "Contractor", label: "Contractor" },
    { value: "Visitor", label: "Visitor" },
    { value: "Member", label: "Member" }
]

export const ODD_EVEN = [
    { value: "Even", label: "Even" },
    { value: "Odd", label: "Odd" }
]

export const APPLICATION_TYPE = [
    { value: "Generic", label: "Generic" },
]

export const FIELD_TYPE = [
    { value: "String", label: "String" },
    { value: "Number", label: "Number" },
    { value: "List", label: "List" }
]

export const PROTOCOL_TYPE = [
    { value: "RTSP", label: "RTSP" },
    { value: "HTTP", label: "HTTP" },
    { value: "HTTPS", label: "HTTPS" }
]

export const FR_MODE = [
    { value: "Cloud", label: "Cloud" },
    { value: "Hybrid", label: "Hybrid" },
    { value: "OnDevice", label: "On Device" }
]
export const SAVE_MODE = [
    { value: "APPEND", label: "APPEND" },
    { value: "NEWFILE", label: "NEWFILE" },
    { value: "OVERWRITE", label: "OVERWRITE" },
    { value: "DAILY", label: "DAILY" }
]

export const INTERFACE_TYPE = [
    { value: "WORKFORCE", label: "WORKFORCE" },
    { value: "SAASHR", label: "SAASHR" }
]
export const INTERFACE_TYPE_V2 = [
    { value: "CSVFILETAJ", label: "SFTP" },
    { value: "CSVFILES3", label: "S3" }
]
export const STATUS_TYPE = [
    { value: "Draft", label: "Draft" },
    { value: "Publish", label: "Publish" }
]

export const STATUS_TYPE_V2 = [
    { value: "Error", label: "Error"},
    { value: "Pending", label: "Pending"},
    { value: "Failed", label: "Failed"},
    { value: "Completed", label: "Completed"}
]

export const AUTH_TYPE = [
    { value: "Basic", label: "Basic" },
    { value: "Bearer", label: "Bearer" }
]
export const AUTH_TYPE_v2 = [
    { value: "Basic", label: "Basic" },
    { value: "Digest", label: "Digest" },
    { value: "Bearer", label: "Bearer" }
]
export const METHOD_TYPE = [
    { value: "GET", label: "GET" },
    { value: "PUT", label: "PUT" },
    { value: "POST", label: "POST" },
    { value: "DELETE", label: "DELETE" }
]
export const ENDPOINT_TYPE = [
    { value: "Android Tablet", label: "Android Tablet" },
    { value: "Android Phone", label: "Android Phone" },
    { value: "iPad", label: "iPad" },
    { value: "iPhone", label: "iPhone" },
    { value: "IOS-Native", label: "IOS-Native" },
    { value: "IP Camera JN", label: "IP Camera JN" },
    { value: "RealSense JN", label: "RealSense JN" },
    { value: "S61", label: "S61" }
]
export const SHIFT_TYPE = [
    { value: "Shift Start", label: "Shift Start" },
    { value: "Shift End", label: "Shift End" },
    { value: "Break Start", label: "Break Start" },
    { value: "Break End", label: "Break End" }
]
export const MODE_TYPE = [
    { value: "Offline", label: "Offline" },
    { value: "Online", label: "Online" }
]
export const MODE_TYPE_V2 = [
    { value: "Found", label: "Found" },
    { value: "Not-Found", label: "Not Found"},
    { value: "Pending", label: "Pending"},
    { value: "Error", label: "Error"}
]
export const EXEMPTION_REASON = [
    { value: "Religious Exemption", label: "Religious Exemption"},
    { value: "Severe Allergies To Vaccine", label: "Severe Allergies To Vaccine"},
    { value: "Extremely Frail", label: "Extremely Frail"},
    { value: "Other", label: "Other"}
]
