import { secretConstants } from '../constants';
import { combineReducers } from 'redux'
import createReducer from '../../../helpers/createReducer'

const initialState = [];

const secretsReducer = createReducer(initialState)({
  [secretConstants.GET_ALL_SECRETS_SUCCESS]: (state, action) => action.payload,
})

const copySecretsReducer = createReducer(initialState)({
  [secretConstants.GET_ALL_SECRETS_COPY_SUCCESS]: (state, action) => action.payload,
})

export default combineReducers({
  allSecrets: secretsReducer,
  allCopySecrets: copySecretsReducer,
})