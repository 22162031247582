import { requestGet, requestPost } from '../../../services/requests';

/* eslint eqeqeq: 0 */
export class DashboardService {

  constructor(endpoint) {
    this.endpoint = endpoint;
  }

  getAllDashboard() {
    const url = this.operationUrl('/dashboard');
    return requestGet(url, {}, false);
  }

  getAllMetrics() {
    const url = this.operationUrl('/metrics/users');
    return requestPost(url);
  }

  captureAllMetrics() {
    const url = this.operationUrl('/metrics/capture');
    return requestPost(url);
  }

  getAllTransactionMetrics() {
    const url = this.operationUrl('/metrics/transaction');
    return requestPost(url);
  }

  getDashboardByID(id) {
    const url = this.operationUrl(`/dashboard/${id}`);
    return requestGet(url);
  }


  operationUrl(append) {
    return this.endpoint + append;
  }
}
