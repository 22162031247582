export const orderedListData = (data, key, order) => {
  const compareValues =
    (key, order = "asc") =>
    (elemA, elemB) => {
      if (!elemA.hasOwnProperty(key) || !elemB.hasOwnProperty(key)) {
        
        let aString = elemA,
          bString = elemB;

        if (typeof elemA === "object") {
          aString = elemA[key] || "";
        }
        if (typeof elemB === "object") {
          bString = elemB[key] || "";
        }
        
        const comparison = aString?.toString().localeCompare(bString);
        return order === "desc" ? comparison * -1 : comparison;
      }

      const comparison = elemA[key]?.toString().localeCompare(elemB[key]);
      return order === "desc" ? comparison * -1 : comparison;
    };
    
  return data?.sort(compareValues(key, order));
};

export const CheckNoNull = (value) => {
  let isNotNull = true;
  if (value && value.trim().length === 0) {
    isNotNull = false;
  }
  return isNotNull;
};
