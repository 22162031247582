export function getObjectDiff(obj1, obj2) {
    let diff = {};

    // Compare keys
    Object.keys(obj1).forEach((key) => {
        if (typeof obj1[key] === 'object' && typeof obj2[key] === 'object') {
            // Compare inner objects recursively
            const innerDiff = getObjectDiff(obj1[key], obj2[key]);

            // Add inner differences to diff object
            if (Object.keys(innerDiff).length > 0) {
                diff[key] = innerDiff;
            }
        } else if (obj1[key] !== obj2[key]) {
            // Compare values
            diff[key] = [obj1[key], obj2[key]];
        }
    });

    // Check for additional keys in obj2
    Object.keys(obj2).forEach((key) => {
        if (!obj1.hasOwnProperty(key)) {
            if (typeof obj2[key] === 'object') {
                // Add entire inner object to diff object
                diff[key] = obj2[key];
            } else {
                // Add new key and value to diff object
                diff[key] = [undefined, obj2[key]];
            }
        }
    });

    return diff;
}