import { requestDelete, requestGet, requestPost } from '../../../services/requests';
import _ from 'lodash';

/* eslint eqeqeq: 0 */
export class ConsentService {

  constructor(endpoint) {
    this.endpoint = endpoint;
  }

  getAllConsentTemplate(param) {
    const url = this.operationUrl('/document/Template/ListConsentTemplates?TemplateStatus=all');
    param = param || {};
    return requestGet(url, param, false);
  }

  getConsentTemplateById(id) {
    const url = this.operationUrl(`/document/Template/${id}`);
    return requestGet(url);
  }

  createConsentTemplate(data) {
    const url = this.operationUrl('/document/CreateConsentTemplate');
    return requestPost(url, data);
  }

  updateConsentTemplate(data) {
    const url = this.operationUrl('/document/ModifyConsentTemplate');
    return requestPost(url, data);
  }

  deleteDraftConsentTemplate(id, reason) {
    const url = this.operationUrl(`/document/Template/${id}?reason=${reason}`);
    return requestDelete(url);
  }

  getAllLegalDocuments(param) {
    const url = this.operationUrl('/document/ListLegalDocument');
    param = param || {};
    return requestGet(url, param, false);
  }

  getAllLegalDocumentByID(id) {
    const url = this.operationUrl(`/document/Legal/${id}`);
    return requestGet(url);
  }


  getLegalLinkedDocumentByID(id) {
    const url = this.operationUrl(`/document/Legal/Linked/${id}`);
    return requestGet(url);
  }

  getLegalDocumentUrl(param) {
    const url = this.operationUrl('/document/GetLegalDocumentUrl');
    return requestPost(url, param);
  }

  CreateConsentAdminAssisted(param) {
    const url = this.operationUrl('/CreateConsentAdminAssisted');
    return requestPost(url, param);
  }

  CreateLegalDocument(param, file) {
    const url = this.operationUrl('/document/CreateLegalDocument');
    param.Action = "upload";

    return new Promise((resolve, reject) => {
      return this.getLegalDocumentUrl(param)
        .then((res) => {
          fetch(res.data.docUrl, {
            method: 'PUT',
            body: file.originFileObj,
            headers: {
              "Content-Type": 'application/pdf'
            }
          }).then(function (res1) {
            if (res1.status === 200) {
              delete param.Action;
              param.FileURL = res.data.Key;
              param.CreatedAt = _.last(res.data.Key.split('_'));
              return requestPost(url, param)
                .then((data) => {
                  resolve(data)
                });
            }
          }).catch(err => {
            console.log(err);
            reject(err);
          });
        })
    });

  }



  deleteLegalDocumentByID(id, reason) {
    const url = this.operationUrl(`/document/Legal/${id}?reason=${reason}`);
    return requestDelete(url);
  }


  operationUrl(append) {
    return this.endpoint + append;
  }
}
