import { connectionGroupConstants } from '../constants';
import { connectionGroupService } from '../service';
import _ from 'lodash';

export const fetchConnectionGroupSuccess = response => ({
  type: connectionGroupConstants.GET_ALL_CONNECTION_GROUP_SUCCESS,
  payload: response
})

export const copyConnectionGroupSuccess = response => ({
  type: connectionGroupConstants.GET_ALL_CONNECTION_GROUP_COPY_SUCCESS,
  payload: response
})

export const fetchConnectionGroupFailure = response => ({
  type: connectionGroupConstants.GET_ALL_CONNECTION_GROUP_FAILURE,
  payload: []
})

export const getAllConnectionGroup = () => {
  return dispatch => {
    return connectionGroupService.getAllConnectionGroup()
      .then(response => {
        let result = response.data || [];
        result = _.orderBy(result, ['UpdatedAt'], ['desc']);
        dispatch(fetchConnectionGroupSuccess(result));
        dispatch(copyConnectionGroupSuccess(result));
        return result;
      },
        error => {
          dispatch(fetchConnectionGroupFailure(error));
          return error;
        })
  }
}

export const filterConnectionGroup = (filter) => {
  
  return (dispatch, state) => {
    const data = state().connectionGroup.allCopyConnectionGroup
    const allCopyConnectionGroup = JSON.parse(JSON.stringify(data));
    let filtered = allCopyConnectionGroup || [];
    if (filter.search) {
      filtered = _.filter(filtered, (res) => {
        return res.ConnectionGroupName && (
          res.ConnectionGroupName.toLowerCase().indexOf(filter.search.toLowerCase()) !== -1
          || res.ConnectionGroupID.toLowerCase().indexOf(filter.search.toLowerCase()) !== -1);
      })
    }
    dispatch(updateFilter(filter))
    dispatch(fetchConnectionGroupSuccess(filtered));
  }
}

export const fetchConnectionGroupDetailSuccess = response => ({
  type: connectionGroupConstants.GET_CONNECTION_GROUP_DETAIL_SUCCESS,
  payload: response || {}
})

export const fetchConnectionGroupDetailFailure = response => ({
  type: connectionGroupConstants.GET_CONNECTION_GROUP_DETAIL_FAILURE,
  payload: {}
})

export const getConnectionGroupById = (id) => {
  return dispatch => {
    return connectionGroupService.getConnectionGroupById(id)
      .then(response => {
        dispatch(fetchConnectionGroupDetailSuccess(response.data));
        return response.data;
      },
        error => {
          dispatch(fetchConnectionGroupDetailFailure(error));
          error.error = true;
          return error;
        })
  }
}

export const deleteConnectionGroupById = (id) => {
  return dispatch => {
    return connectionGroupService.deleteConnectionGroupById(id)
      .then(response => {
        dispatch(getAllConnectionGroup());
        return response;
      },
        error => {
          error.error = true;
          return error;
        })
  }
}

export const saveConnectionGroup = (data) => {
  return dispatch => {
    return connectionGroupService.saveConnectionGroup(data)
      .then(response => {
        dispatch(getAllConnectionGroup());
        return response;
      },
        error => {
          error.error = true;
          return error;
        })
  }
}


export const updateConnectionGroup = (data) => {
  return dispatch => {
    return connectionGroupService.updateConnectionGroup(data)
      .then(response => {
        return dispatch(getConnectionGroupById(data.ConnectionGroupID));
      },
        error => {
          error.error = true;
          return error;
        })
  }
}


export const updateFilter = payload => ({
  type: connectionGroupConstants.FILTER_UPDATE,
  payload: payload
})

export const updatePagination = payload => ({
  type: connectionGroupConstants.PAGINATE_UPDATE,
  payload: payload
})