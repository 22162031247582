import Error from "../../../image/error.svg";
import KronosConnector from '../../../image/integrationsLogos/kronos.png';
import TLWiegandConvertor from '../../../image/integrationsLogos/wiegand.png';
import InvokeAdam6060Relay from '../../../image/integrationsLogos/relay.png';

export const integrationImage = {
  'TLWiegandConvertor': TLWiegandConvertor,
  'InvokeAdam6060Relay': InvokeAdam6060Relay,
  'KronosConnector': KronosConnector
}

export const integrations = [
  {
    endpointName: 'FIAS Access ',
    descriptions: 'FIAS Access',
    IntegrationID: 'MiralAccess',
  },
  {
    endpointName: 'FIAS Payment',
    descriptions: 'FIAS Payment',
    IntegrationID: 'MiralPayment',
  },
  {
    endpointName: 'Relay',
    descriptions: 'Adam6060 Relay Access',
    IntegrationID: 'InvokeAdam6060Relay',
    UnlockFlag: true,
    InputPin: "DO0",
    ResetDelay: 5,
    ShouldReset: true,
    IP: "",
    Port: ""
  },
  {
    endpointName: 'Kronos',
    descriptions: 'Invoke Kronos API after Authentication',
    IntegrationID: 'KronosConnector',
    AuthKey: '',
    AuthType: '',
    PunchTimeUrl: '',
    PunchVerifyUrl: '',
    TimeZone: '',
    URL: '',
    KronosTimezoneCode: ''
  },
  {
    endpointName: 'Wiegand Access',
    descriptions: 'Wiegand Access',
    IntegrationID: 'TLWiegandConvertor',
    Direction: '',
    IP: '',
    Port: '',
    DummyBinary: '',
    Debug: false
  },
  {
    endpointName: 'HTTP Request',
    descriptions: 'HTTP Request',
    UseAuth: true,
    IntegrationID: "EndpointHTTPRequest",
    Token: "",
    Method: "Post",
    AuthType: "Basic",
    URL: "",
    Username: "",
    Password: ""
  }
]

export const userGroup = [
  {
    key: '1',
    id: 1,
    group: 'Group 1',
    description: 'Desc 1',
    assignment: 'Manual'
  },
  {
    key: '2',
    id: 2,
    group: 'Group 2',
    description: 'Desc 2',
    assignment: 'Programmatic'
  },
  {
    key: '3',
    id: 3,
    group: 'Group 3',
    description: 'Desc 3',
    assignment: 'Manual'
  },
  {
    key: '4',
    id: 4,
    group: 'Group 4',
    description: 'Desc 4',
    assignment: 'Programmatic'
  },
  {
    key: '5',
    id: 5,
    group: 'Group 5',
    description: 'Desc 5',
    assignment: 'Manual'
  },
  {
    key: '6',
    id: 6,
    group: 'Group 6',
    description: 'Desc 6',
    assignment: 'Manual'
  },
  {
    key: '7',
    id: 7,
    group: 'Group 7',
    description: 'Desc 7',
    assignment: 'Programmatic'
  },
  {
    key: '8',
    id: 8,
    group: 'Group 8',
    description: 'Desc 8',
    assignment: 'Manual'
  },
  {
    key: '9',
    id: 9,
    group: 'Group 9',
    description: 'Desc 9',
    assignment: 'Programmatic'
  },
  {
    key: '10',
    id: 10,
    group: 'Group 10',
    description: 'Desc 10',
    assignment: 'Programmatic'
  },
  {
    key: '11',
    id: 11,
    group: 'Group 11',
    description: 'Desc 11',
    assignment: 'Manual'
  },
]

export const endpointConfigureTab = [
  {
    key: '1',
    id: 1,
    integration: 'Kronos',
    url: 'http//amazonaws.com/',
    icon: ""
  },
  {
    key: '2',
    id: 2,
    integration: 'Amazone',
    url: 'http//amazonaws.com/',
    icon: ""
  },
  {
    key: '3',
    id: 3,
    integration: 'iTunes',
    url: 'http//amazonaws.com/',
    icon: ""
  },
  {
    key: '4',
    id: 4,
    integration: 'Kronos',
    url: 'http//amazonaws.com/',
    icon: ""
  },
  {
    key: '5',
    id: 5,
    integration: 'Amazone',
    url: 'http//amazonaws.com/',
    icon: ""
  },
  {
    key: '6',
    id: 6,
    integration: 'iTunes',
    url: 'http//amazonaws.com/',
    icon: ""
  },
  {
    key: '7',
    id: 7,
    integration: 'Kronos',
    url: 'http//amazonaws.com/',
    icon: ""
  },
  {
    key: '8',
    id: 8,
    faces: '',
    integration: 'Amazone',
    url: 'http//amazonaws.com/',
    icon: ""
  },
  {
    key: '9',
    id: 9,
    integration: 'iTunes',
    url: 'http//amazonaws.com/',
    icon: ""
  },
  {
    key: '10',
    id: 10,
    integration: 'Kronos',
    url: 'http//amazonaws.com/',
    icon: ""
  },
  {
    key: '11',
    id: 11,
    integration: 'Amazone',
    url: 'http//amazonaws.com/',
    icon: ""
  },
]

export const endpointHealth = [
  {
    key: '1',
    id: 1,
    integration: 'Face Search',
    url: 'http//amazonaws.com/',
    icon: Error,
    healthName: 'Endpoint IP',
    healthStatus: '07.24.2020'
  },
  {
    key: '2',
    id: 2,
    integration: 'Amazone',
    url: 'http//amazonaws.com/',
    icon: "",
    healthName: 'Battery Level',
    healthStatus: '98%'
  },
  {
    key: '3',
    id: 3,
    integration: 'iTunes',
    url: 'http//amazonaws.com/',
    icon: "",
    healthName: 'Battery State',
    healthStatus: 'Unplugged'
  },
  {
    key: '4',
    id: 4,
    integration: 'Kronos',
    url: 'http//amazonaws.com/',
    icon: "",
    healthName: 'Application State',
    healthStatus: 'Active'
  },

]

export const EndpointTypes = [
  "Android Tablet",
  "Android Phone",
  "iPad",
  "iPhone",
  "IP Camera JN",
  "RealSense JN",
  "S61",
  "Oloid Verify",
  "Oloid Connect",
  "M-Tag",
  "OloidWorkflow",
  "Workflow"
]

