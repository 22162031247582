import { accessConstants } from '../constants';
import { combineReducers } from 'redux'
import createReducer from '../../../helpers/createReducer'

const initialState = [];

const wiegandReducer = createReducer(initialState)({
  [accessConstants.GET_ALL_WIEGAND_SUCCESS]: (state, action) => action.payload,
  [accessConstants.GET_ALL_WIEGAND_FAILURE]: (state, action) => action.payload,
})

const wiegandDetailReducer = createReducer({})({
  [accessConstants.GET_WIEGAND_DETAIL_SUCCESS]: (state, action) => action.payload,
  [accessConstants.GET_WIEGAND_DETAIL_FAILURE]: (state, action) => action.payload,
})


export default combineReducers({
  allWiegand: wiegandReducer,
  wiegandDetail: wiegandDetailReducer,
})