import { activityLogConstants } from '../constants';
import { activityLogService } from '../service';
import _ from 'lodash';
export const fetchActivityLogSuccess = response => ({
  type: activityLogConstants.GET_ALL_ACTIVITY_SUCCESS,
  payload: response
})

export const fetchActivityLogFailure = response => ({
  type: activityLogConstants.GET_ALL_ACTIVITY_FAILURE,
  payload: []
})

export const setFilter = response => {
  return {
    type: activityLogConstants.STORE_ACTIVITY_FILTER,
    payload: response
  }
}

export const setActivityPage = (page) => {
  return dispatch => {
    dispatch({
      type: activityLogConstants.SET_USER_PAGE,
      payload: page
    })
  }
}

export const getAllActivityLog = (filter) => {
  return dispatch => {
    if (!filter || !filter.page || filter.page == 1) {
      return activityLogService.getAllActivityLog(filter)
        .then(response => {

          let events = (response.data && response.data.events) || [];
          events = events.filter(ev => { return ev });
          events = _.map(events, (res) => {
            if (res.message) {
              if (typeof res.message == 'string') {
                try {
                  res.message = JSON.parse(res.message)
                  res.message.details = JSON.parse(res.message.details)
                  res.message.eventId = res.eventId;
                } catch (error) {

                }
              }
            }
            return res.message || {};
          })
          events = _.orderBy(events, ['date'], ['desc']);

          let res = {
            data: [
              {
                ...response.data,
                events
              }
            ],
            page: 1
          }

          dispatch(fetchActivityLogSuccess(res));
          return events;
        },
          error => {
            dispatch(fetchActivityLogFailure(error));
            error.error = true;
            return error;
          })
    }
  }
}


export const nextActivityLog = (param) => {
  return (dispatch, state) => {

    return activityLogService.getAllActivityLog(param)
      .then(response => {
        let res = state().activityLog.allActivityLog;

        let events = (response.data && response.data.events) || [];
        events = events.filter(ev => { return ev });
        events = _.map(events, (res) => {
          if (res.message) {
            if (typeof res.message == 'string') {
              try {
                res.message = JSON.parse(res.message)
                res.message.details = JSON.parse(res.message.details);
                res.message.eventId = res.eventId;
              } catch (error) {

              }
            }
          }
          return res.message || {};
        })
        events = _.orderBy(events, ['date'], ['desc']);

        res.data.push({
          ...response.data,
          events
        });
        res.page = res.page + 1;
        dispatch(fetchActivityLogSuccess({ ...res }));
        return response.data;
      },
        error => {
          dispatch(fetchActivityLogFailure(error));
          error.error = true;
          return error;
        })
  }
}

export const prevActivityLog = (param) => {
  return (dispatch, state) => {
    let res = state().activityLog.allActivityLog;
    res.data.pop();
    res.page = res.page - 1;
    dispatch(fetchActivityLogSuccess({ ...res }));
  }
}

export const firstActivityLog = (param) => {
  return (dispatch, state) => {
    let res = state().activityLog.allActivityLog;
    res.data = [res.data[0]];
    res.page = 1;
    dispatch(fetchActivityLogSuccess({ ...res }));
  }
}


export const fetchActivityLogDetailSuccess = response => ({
  type: activityLogConstants.GET_ACTIVITY_DETAIL_SUCCESS,
  payload: response || {}
})

export const fetchActivityLogDetailFailure = response => ({
  type: activityLogConstants.GET_ACTIVITY_DETAIL_FAILURE,
  payload: {}
})

export const getActivityLogById = (param) => {
  return (dispatch, state) => {
    return activityLogService.getActivityLogById(param)
      .then(response => {
        const activityLogs = response.data;

        try {
          activityLogs.details = JSON.parse(activityLogs.details);
        } catch (error) {

        }

        dispatch(fetchActivityLogDetailSuccess(response.data || {}));
        return response;
      },
        error => {
          dispatch(fetchActivityLogDetailFailure(error));
          return error;
        })
  }
}