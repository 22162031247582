import { requestDelete, requestGet, requestPost, requestPut } from '../../../services/requests';

/**
 * Supports all operations exposed by the user controller.
 */

/* eslint eqeqeq: 0 */
export class LocationService {

  constructor(endpoint) {
    this.endpoint = endpoint;
  }

  createLocation(data) {
    const url = this.operationUrl('/location/create');
    return requestPost(url, data);
  }
  getAllLocation() {
    const url = this.operationUrl(`/location/list`);
    return requestGet(url,{}, false);
  };

  getLocationById(id) {
    const url = this.operationUrl(`/location/${id}`);
    return requestGet(url);
  };

  updateLocation(data, isRaw) {
    const url = this.operationUrl(`/location/update${isRaw ? '?save=raw' : ''}`);
    return requestPut(url, data,);
  };

  deleteLocationById(locationID) {
    const url = this.operationUrl(`/location/${locationID}`);
    return requestDelete(url);
  }

  createBuilding(data) {
    const url = this.operationUrl('/building/add');
    return requestPost(url, data);
  }
  fetchAllBuilding(settingType) {
    const url = this.operationUrl(`/building/list/${settingType}`);
    return requestGet(url);
  };

  deleteBuilding(id) {
    const url = this.operationUrl(`/building/${id}`);
    return requestDelete(url);
  }

  createArea(data) {
    const url = this.operationUrl('/area/add');
    return requestPost(url, data);
  }
  fetchAllArea(buildingID) {
    const url = this.operationUrl(`/building/area/list/${buildingID}`);
    return requestGet(url);
  };

  deleteArea(areaId, buildingId) {
    const url = this.operationUrl(`/building/area/${areaId}?BuildingId=${buildingId}`);
    return requestDelete(url);
  }
  operationUrl(append) {
    return this.endpoint + append;
  }
}



