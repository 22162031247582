import React, { Component } from 'react';
import "./createApplication.scss";
import "../../tenant/component/createTenant.scss";
import { Modal, Button, Row, Col, message } from 'antd';
import TextField from "@material-ui/core/TextField";
import { saveApplication } from '../action/application.actions';
import { connect } from 'react-redux';
import _ from 'lodash';
import { successMessage, tostor } from '../../../helpers';

const initialState = {
  application: {
    ApplicationName: '',
    ApplicationType: 'Generic',
    CoreUrls: {
      AmazonAWS: 'https://s3.amazonaws.com',
      Crashlytics: 'https://crashlytics.com',
      PlatformPortal: '',
    },
    OnDeviceBBoxSize: 0.2,
    CloudBBoxSize: 0.0,
    LivelinessTargetConfidence: 90.0,
    LandmarksTargetConfidence: 0.84,
    ErrorPromptAutoDismissAfter: 1,
    DisableLiveliness: false,
    FRMode: 'Cloud',
    DisableFR: false,
    MovingMinAverage: false,
    MovingMaxAverage: false,
    EnableMovingAverage: false,
    EnableThermalErrorCorrection: false,
  },
  error: {},
  isSubmit: false
}
/* eslint eqeqeq: 0 */
class CreateApplication extends Component {
  constructor(props) {
    super(props);
    this.state = { ..._.merge({}, initialState) }
  }

  handleOk = () => {
    this.setState({ isSubmit: true });
    if (this.validateForm()) {
      const { dispatch } = this.props;
      this.setState({ loading: true });
      const application = this.state.application;
      const reqObj = {
        ...application,
        ApplicationName: application.ApplicationName.trim(),
        ApplicationType: application.ApplicationType,
        DisplayName: application.ApplicationType.replace(/\s/g, '')
      }
      reqObj.CoreUrls.PlatformPortal = window.location.origin;
      
      if (application.ApplicationType === 'Oloid Verify') {
        // reqObj.AuthenticationType = 'Basic';
        reqObj.PingTimeOut = 3;
        reqObj.ActionProcessingLabel = 'In Progress';
        reqObj.ActionFailureLabel = 'Failure';
        reqObj.ActionSucessLabel = 'Success';

        reqObj.EnableThermal = false;
        reqObj.DisplayThermalReading = false;

        reqObj.OloidCertify = false;
        reqObj.CertifyType = 'AirTouch';
        reqObj.SelfAttestationExpiry = 86400;
        reqObj.DisplayInstructionScreen = true;
        reqObj.EnableBadgeInput = false;
        reqObj.Credentials = {
          EnableBadgeInput: false,
          EnableFace: true,
          EnableQRCode: false,
          EnableBluetooth: false,
          EnableCloudKey: false
        }
        reqObj.DisableAuth = "QR Code,Badge,Bluetooth,CloudKey";
        reqObj.UseGroupsforAuth = false;
        reqObj.OfflineMode = false;
        reqObj.LandmarksTargetConfidence = 0.84;
        // reqObj.FaceAuthType = 'Cloud';
        reqObj.OnDeviceModel = 'ModelV';
        reqObj.TransactionType = 'FaceAuth';
        reqObj.TimeZone = 'America/Los_Angeles';
        reqObj.Timezone = 'America/Los_Angeles';
        reqObj.IdleScreenSoftTimeLimit = 31556952;
        reqObj.IdleScreenHardTimeLimit = 31556952;
        reqObj.ShowIntentScreen = false;
        reqObj.ShowActionStatus = false;
        reqObj.ShowIndividualActionStatus = false;
      }
      // if (application.ApplicationType === 'Oloid Connect') {
      //   reqObj.Unit = 'F';
      //   reqObj.ShowAbsoluteTemperature = true;
      //   reqObj.UseThermalPattern = true;
      //   reqObj.ContinuesCalibration = true;
      //   reqObj.ESTMode = true;
      //   reqObj.ESTRetryCount = true;
      //   reqObj.NormalHumanTempMin = 97;
      //   reqObj.NormalHumanTempMax = 104;
      //   reqObj.MeanTemprature = 98.4;
      //   reqObj.StandardDeviationSampleSize = 0.5;
      //   reqObj.WarmUpTime = 120;
      //   reqObj.Threshold = 100;
      //   reqObj.AccuracyThreshold = 0.5;
      //   reqObj.MaxDisplayThresholdRange = 104;
      //   reqObj.MinDisplayThresholdRange = 97;
      //   reqObj.PauseAfterCalibration = 5;
      //   reqObj.UpperLimitZ = 3.2;
      //   reqObj.CalibrationInterval = 600;
      //   reqObj.AlarmTempMargin = 2;
      //   reqObj.OloidCertify = false;
      //   reqObj.QRCodeExpiry = 24;
      //   reqObj.TransactionType = 'Thermal';
      // }
      if (application.ApplicationType === 'Password Reset') {
        reqObj.TransactionType = 'FaceAuth~PWReset';
      }

      dispatch(saveApplication(reqObj))
        .then(() => {
          tostor.success(successMessage.APPLICATION_CREATE);
          this.setState({ loading: false });
          this.resetFrom();
          this.props.toggle()
        });
    }
  };

  resetFrom = () => {
    this.setState({ ..._.merge({}, initialState) })
  }

  handleCancel = () => {
    this.resetFrom();
    this.props.toggle()
  };

  validateForm = () => {
    const { application } = this.state;
    let error = {};
    if (!application.ApplicationName) {
      error.ApplicationName = true;
    }
    if (!application.ApplicationType) {
      error.ApplicationType = true;
    }
    this.setState({ error })
    return Object.keys(error).length == 0;
  }

  handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const { application, error } = Object.assign({}, this.state)
    var letters = /^[A-Za-z0-9- ]+$/;
    if (value.match(letters) || e.target.value == '') {
      application[name] = value.replace(/\s{2,}/g, ' ');
      error[name] = false
    }
    this.setState({ application, error })
  }

  selectApplicationType = (type) => {
    const { application, error } = Object.assign({}, this.state)
    application['ApplicationType'] = type;
    error['ApplicationType'] = false
    this.setState({ application, error })
  }

  render() {

    const { error, isSubmit, application } = this.state;

    return (
      <div >
        <Modal
          className="create-application"
          visible={this.props.modal}
          onCancel={this.handleCancel}
          footer={[
            <div className="footer-box">
              <Button className="create-btn" key="back" type="primary" onClick={this.handleOk}>
                CREATE
              </Button>
              <Button className="cancle-btn" key="submit" onClick={this.handleCancel}>
                CANCEL
              </Button>
            </div>
          ]}
        >
          <div className='txt-header'>Create a new Application</div>
          <div className='position-rel'>
            <TextField
              error={error.ApplicationName}
              name="ApplicationName"
              label="Application Name"
              type="text"
              className='tenant-form-textfield'
              margin="normal"
              variant="outlined"
              value={application.ApplicationName}
              helperText={error.ApplicationName ? 'Required' : ''}
              onChange={(e) => this.handleInput(e)}
            />
            {isSubmit && !error.ApplicationName && <div className='success-icon position'></div>}
            {error.ApplicationName && <div className='success-icon position error'></div>}
          </div>
          <div className='feild-desc'>Special characters are not allowed.</div>
          {/* <div className='application-type'>
            <div className='txt-type'>Application Type</div>
            <div className='types'>
              <Row>
                <Col span={8}>
                  <div className="box-col-padding">
                    <div className={`type-border ${error.ApplicationType ? 'notSelected' : application.ApplicationType == 'FaceAuth' ? 'selected' : ''}`}
                      onClick={() => this.selectApplicationType('FaceAuth')}>
                      <div className='type-icon'>
                        <div className='create-app-type-name'><span>FA</span></div>
                      </div>
                      <div className='txt-heading'>FaceAuth</div>
                    </div>
                  </div>
                </Col>
                
                <Col span={8}>
                  <div className="box-col-padding">
                    <div className={`type-border mr-type ${error.ApplicationType ? 'notSelected' : application.ApplicationType == 'Oloid Verify' ? 'selected' : ''}`}
                      onClick={() => this.selectApplicationType('Oloid Verify')}>
                      <div className='type-icon'>
                        <div className='create-app-type-name'><span>OV</span></div>
                      </div>
                      <div className='txt-heading'>Oloid Verify</div>
                    </div>
                  </div>
                </Col>

                <Col span={8}>
                  <div className="box-col-padding">
                    <div className={`type-border mr-type ${error.ApplicationType ? 'notSelected' : application.ApplicationType == 'Generic' ? 'selected' : ''}`}
                      onClick={() => this.selectApplicationType('Generic')}>
                      <div className='type-icon'>
                        <div className='create-app-type-name'><span>G</span></div>
                      </div>
                      <div className='txt-heading'>Generic</div>
                    </div>
                  </div>
                </Col>
                
              </Row>
            </div>
            {error.ApplicationType && <div className='error-required'>Required</div>}
          </div> */}
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user
  }
}
export default connect(mapStateToProps)(CreateApplication);
