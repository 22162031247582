import { falsePositivesConstants } from '../constants';
import { combineReducers } from 'redux'
import createReducer from '../../../helpers/createReducer'

const initialState = {
  data: [
    {
      transactions: [],
      NextPageToken:''
    }
  ],
  page: 1
};
const filter = {
  SearchField: '',
  ApplicationID: 'ALL',
  FromDate: '',
  ToDate: '',
  Mode: 'ALL',
  Status: 'ALL',
  EndpointID: 'ALL',
  ThermalCertifyView: false,
  TempAuthStatus: 'ALL',
  IsCertifyAuthorized: 'ALL'
}

const filterReducer = createReducer(filter)({
  [falsePositivesConstants.SET_TRANSACTIONS_FILTER]: (state, action) => action.payload,
})


const falsePositivesReducer = createReducer(initialState)({
  [falsePositivesConstants.GET_ALL_TRANSACTIONS_SUCCESS]: (state, action) => action.payload,
  [falsePositivesConstants.GET_ALL_TRANSACTIONS_FAILURE]: (state, action) => action.payload,
})

const transactionFilterReducer = createReducer(initialState)({
  [falsePositivesConstants.STORE_TRANSACTIONS_FILTER]: (state, action) => action.payload,
})

export default combineReducers({
  allFalsePositives: falsePositivesReducer,
  transactionFilter: transactionFilterReducer,
  filter: filterReducer
})