import React, { Component } from "react";

import "../../tenant/component/createTenant.scss";
import { Modal, Button, Collapse, Upload, message } from "antd";
import { InboxOutlined } from '@ant-design/icons';
import { getAllConnection } from '../../connections/action/connection.actions';
import { usersService } from '../service';
import _ from 'lodash';
import { connect } from 'react-redux';
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText
} from "@material-ui/core";
import csvFile from './User_Import_Template.csv'
import { orderedListData, SelectMenuProps, successMessage, tostor } from "../../../helpers";
// import { Select, Table } from 'antd';
// const Option = Select.Option;
const { Panel } = Collapse;
const { Dragger } = Upload;

class ImportUser extends Component {
  intervalID = 0;
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      error: {
        fileType: false
      },
      isSubmit: false,
      s3Url: '',
      info: {},
      activeKey: ['1'],
      selectedConnectionId: null,
      fileType: '',
      enablePublish: false,
      fileStatus: '',
      fileData: [],
      fileList: [],
      errorCount: 0
    }
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getAllConnection());
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
    this.handleCancel();
  }

  handleCancel = () => {
    this.setState({
      connection: {}, error: {}, user: {}, isSubmit: false, s3Url: '',
      info: {}, activeKey: ['1'], selectedConnectionId: null,
      fileType: '',
      enablePublish: false,
      fileStatus: '',
      fileData: [],
      fileList: [],
      errorCount: 0
    });
    clearInterval(this.intervalID);
    this.props.toggle();
  };


  handleInput = e => {
    const name = e.target.name;
    const value = e.target.value;
    const { user, error } = Object.assign({}, this.state);
    user[name] = value;
    error[name] = false;
    this.setState({ user, error });
  };

  handleSelect = (e, field) => {
    const { user, error } = Object.assign({}, this.state);
    user[field] = e.target.value;
    error[field] = false;
    this.setState({ user, error });
  };



  handleConnectionSelect = (e) => {
    this.setState({ selectedConnectionId: e.target.value });
  }

  uploadFile = () => {
    let { info, s3Url } = this.state;
    let fileType = _.last(_.split(info.file.name, '.'));
    let contentType = fileType === 'csv' ? 'text/csv' : 'text/json';
    this.hideShow('flex');
    fetch(s3Url, {
      method: 'PUT',
      body: info.file,
      headers: {
        "Content-Type": contentType
      }
    }).then(function (res) {
      if (res.status === 200) {
        this.setState({ activeKey: ['3'], fileType });
      }
      this.hideShow('none');
    }.bind(this)).catch(err => {
      console.log(err)
      this.hideShow('none');
    });
  }

  analyzeData = () => {
    if (this.state.selectedConnectionId) {
      let body = {
        "action": "analyze",
        "fileType": this.state.fileType,
        "ConnectionID": this.state.selectedConnectionId,
        "fileId": this.state.fileId
      };
      usersService.processImport(body).then(function (res) {
        if (res && res.message === "Success") {
          this.setState({ fileStatus: 'PENDING' });
          this.getStatusInInterval();
        }
      }.bind(this));
    }
  }

  hideShow = (flag) => {
    if (document.getElementById("cst_ui_block")) {
      document.getElementById("cst_ui_block").style.display = flag;
    }
  }
  getStatusInInterval = () => {
    this.hideShow('flex');
    this.intervalID = setInterval(this.getStatus, 5000);
  }

  getStatus = () => {
    if (this.state.fileStatus && this.state.fileStatus !== 'COMPLETED') {
      usersService.getImportStatus(this.state.fileId).then(function (res) {
        if (_.get(res.data, 'result.status')) {
          this.setState({
            fileStatus: res.data.result.status,
            errorCount: res.data.result.errorCount,
            fileData: res.data.result.results
          });
        }
      }.bind(this));
    } else if (this.state.fileStatus && this.state.fileStatus === 'COMPLETED') {
      this.hideShow('none');
      this.setState({ enablePublish: (!this.state.errorCount) });
      clearInterval(this.intervalID);
    }
  }

  publishFile = () => {
    if (this.state.selectedConnectionId) {
      let body = {
        "action": "publish",
        "fileType": this.state.fileType,
        "ConnectionID": this.state.selectedConnectionId,
        "fileId": this.state.fileId
      };
      usersService.processImport(body).then(function (res) {
        if (res && res.message === "Success") {
          tostor.success(successMessage.IMPORT_USER)
          this.handleCancel();
        }
      }.bind(this));
    }
  }

  render() {
    const { error, info, fileList, errorCount } = this.state;
    const { allConnections } = this.props
    const props = {
      accept: ".csv,.json",
      action: this.state.s3Url,
      multiple: false,
      onChange: info => {
        let fileType = _.last(_.split(info.file.name, '.'));
        if (fileType !== 'csv' && fileType !== 'json') {
          return false;
        }
        this.setState({ info });
      },
      beforeUpload: file => {
        let fileType = _.last(_.split(file.name, '.'));
        if (fileType !== 'csv' && fileType !== 'json') {
          tostor.error('Please Upload CSV or JSON file');
          return false;
        }
        let { s3Url } = this.state;
        let contentType = fileType === 'csv' ? 'text/csv' : 'text/json';
        let body = {
          "action": "Upload",
          "fileType": "IMPORT_USER_PENDING",
          contentType: contentType
        };
        usersService.getS3Url(body).then(function (res) {
          s3Url = res.data.url;
          let fileId = res.data.fileId;
          this.setState({ s3Url, fileId, activeKey: ['2'] });
        }.bind(this));
        return false;
      },
    }
    return (
      <div>
        <Modal
          className="create-application"
          visible={this.props.modal}
          onclose
          onCancel={this.handleCancel}
          footer={[
            <div className="footer-box">
              <Button
                className="create-btn"
                key="back"
                type="primary"
                onClick={() => this.publishFile()}
                disabled={!this.state.enablePublish}
              >
                PUBLISH
              </Button>
              <Button
                className="cancle-btn"
                key="submit"
                onClick={this.handleCancel}
              >
                CANCEL
              </Button>
            </div>
          ]}
        >
          <div className="txt-header">Import User(s)</div>
          <Collapse activeKey={this.state.activeKey}>
            <Panel header="Upload" key="1">
              <Dragger {...props} multiple={false} fileList={fileList} >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                <p className="ant-upload-hint">
                  Import CSV or JSON file
                </p>
                <a target="_blank"
                  onClick={(e) => { e.stopPropagation() }}
                  rel="noopener noreferrer"
                  href={csvFile}
                  download={'User_Import_Template.csv'}>
                  Download template</a>
              </Dragger>
              <span>{info && info.file && info.file.name}</span>
            </Panel>
            <Panel header="Validation" className="validation" key="2">
              <h2>Summary</h2>
              <p><span>File Size: </span><span>{this.state.info.file && this.state.info.file.size} kb</span></p>
              <p><span>File name: </span><span>{this.state.info.file && this.state.info.file.name}</span></p>
              <p className="button-content"><Button className="create-btn" onClick={(e) => this.uploadFile(e)}>Import</Button></p>
            </Panel>
            <Panel header="Analyze" key="3">
              <div className='position-rel'>
                <FormControl variant="outlined" className='region-select' error={error.ConnectionID}>
                  <InputLabel id="demo-simple-select-outlined-label">
                    Connection
                  </InputLabel>
                  <Select
                    labelId="ConnectionID"
                    id="demo-simple-select-outlined"
                    name="ConnectionID"
                    onChange={(e) => this.handleConnectionSelect(e)}
                    value={this.state.selectedConnectionId}
                    placeholder="Connection"
                    MenuProps={SelectMenuProps}
                  >
                    {
                      allConnections && orderedListData(allConnections||[], 'ConnectionDisplayName').map((conn, index) => {
                        return <MenuItem key={index} value={conn.ConnectionID}>{conn.ConnectionDisplayName}</MenuItem>
                      })
                    }
                  </Select>
                  {error.ConnectionID && <FormHelperText>Required</FormHelperText>}
                </FormControl>
              </div>
              {this.state.fileData && this.state.fileData.length > 0 && <div className="position-rel">
                <pre className="json-area">
                  {JSON.stringify(this.state.fileData, null, 2)}
                </pre>
              </div>}
              <div className='position-rel'>
                <p className="button-content"><Button className="create-btn" disabled={!this.state.selectedConnectionId} onClick={(e) => this.analyzeData(e)}>Analyze</Button></p>
              </div>
            </Panel>
          </Collapse>

          {errorCount > 0 && <div className="error-msg">There are validation errors in the file</div>}
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { allConnections } = state.connection;
  return {
    allConnections
  }
}

export default connect(mapStateToProps)(ImportUser);