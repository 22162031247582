//import Auth from '@aws-amplify/auth';
import Amplify, { Auth } from 'aws-amplify';
import axios from 'axios';
/**
 * Supports all operations exposed by the user controller.
 */

export class UserService {

  constructor(authEndpoint) {
    this.authEndpoint = authEndpoint;
  }


  login(username, password, type) {
    return new Promise((resolve, reject) => {
      const currentConfig = Auth.configure();
      Auth.signIn(username, password)
        .then(success => resolve(success))
        .catch(err => reject(err));
    });
  }


  getUsersById(id, token) {
    const url = this.authUrl(`/users/${id}`);
    return new Promise((resolve, reject) => {
      return axios({
        url,
        method: 'GET',
        headers: {
          Authorization: token
        }
      })
        .then(
          (response) => resolve(response.data && response.data.data),
          (error) => reject((error.response && error.response.data) || error))
    })
  };


  setNewPassword(user, newPassword) {
    return new Promise((resolve, reject) => {
      Auth.completeNewPassword(user, newPassword)
        .then(success => resolve(success))
        .catch(err => reject(err));
    });
  }

  forgotPasswordSubmit(username, code, new_password) {
    return new Promise((resolve, reject) => {
      Auth.forgotPasswordSubmit(username, code, new_password)
        .then(success => resolve({ message: "success" }))
        .catch(err => reject({ message: "Something went wrong please try again" }));
    });
  }

  forgotPassword(username, tenantName) {
    // return new Promise((resolve, reject) => {
    //   Auth.forgotPassword(username)
    //     .then(success => resolve(success))
    //     .catch(err => reject(err));
    // });
    const url = this.authUrl(`/login/password/reset`);
    return new Promise((resolve, reject) => {
      return axios({
        url,
        method: 'PUT',
        data: {
          "TenantName": tenantName,
          "UserName": username
        },
        isLoading: true
      })
        .then(
          (response) => {
            resolve(response.data)
          },
          (response) => {
            const res = (response.response && response.response.data) || 'Something went wrong Please try again';
            reject(res)
          }
        )
        .catch((error) => {
          reject((error.response && error.response.data) || error)
        }
        )
    })
  }


  logout() {
    // remove user from local storage to log user out
    Auth.signOut();
    localStorage.clear();
    localStorage.removeItem('user');
  }


  googleLogin(params) {
    const url = this.authUrl(`/federated-logins/google`);
    return new Promise((resolve, reject) => {
      return axios({
        url,
        method: 'POST',
        data: params,
        isLoading: true
      })
        .then(
          (response) => resolve(response.data && response.data.data),
          (error) => reject((error.response && error.response.data) || error))
    })
  };

  azureLogin(params) {
    const url = this.authUrl(`/federated-logins/azure`);
    return new Promise((resolve, reject) => {
      return axios({
        url,
        method: 'POST',
        data: params,
        isLoading: true
      })
        .then(
          (response) => resolve(response.data && response.data.data),
          (error) => reject((error.response && error.response.data) || error))
    })
  };

  oktaLogin(params) {
    const url = this.authUrl(`/federated-logins/okta`);
    return new Promise((resolve, reject) => {
      return axios({
        url,
        method: 'POST',
        data: params,
        isLoading: true
      })
        .then(
          (response) => resolve(response.data && response.data.data),
          (error) => reject((error.response && error.response.data) || error))
    })
  };


  getTenantInfo(tenant) {
    const url = `${process.env.REACT_APP_USER_API_ENDPOINT}/user-tenant/info?tenant=${tenant}`;
    return new Promise((resolve, reject) => {
      return axios({
        url,
        method: 'GET',
        headers: {
          'Authorization': process.env.REACT_APP_USER_API_KEY
        }
      })
        .then(
          (response) => resolve(response.data && response.data.data),
          (error) => reject((error.response && error.response.data) || error))
    })
  };

  authUrl(append) {
    return this.authEndpoint + append;
  }
}
