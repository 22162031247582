import { secretConstants } from '../constants';
import { secretsService } from '../service';
import _ from 'lodash';
import { message } from 'antd'
import { tostor } from '../../../helpers';

export const fetchSecretsSuccess = response => ({
    type: secretConstants.GET_ALL_SECRETS_SUCCESS,
    payload: response
})

export const copySecretsSuccess = response => ({
    type: secretConstants.GET_ALL_SECRETS_COPY_SUCCESS,
    payload: response
})

export const getAllSecretsById = () => {
    return dispatch => {
        return secretsService.getAllSecretsById()
            .then(response => {
                const reverseOrder = response.data && response.data.reverse()
                dispatch(fetchSecretsSuccess(reverseOrder));
                dispatch(copySecretsSuccess(reverseOrder));
                return response;
            })
    }
}

export const saveSecrets = (data) => {
    return dispatch => {
        return secretsService.saveSecret(data)
            .then(response => {
                dispatch(getAllSecretsById());
                return response;
            },
                error => {
                    if(error.message && error.message.includes('already exists')){
                        tostor.error("Secret name is already exists");
                    }else{
                        tostor.error(error.message);
                    }
                })
    }
}

export const filterSecrets = (search) => {
    return (dispatch, state) => {
        const data = state().secrets.allCopySecrets;
        const allcopySecrets = JSON.parse(JSON.stringify(data));
        let filtered = allcopySecrets || [];
        if (search.search) {
            filtered = _.filter(filtered, (res) => {
                return res && (
                    res.toLowerCase().indexOf(search.search.toLowerCase()) !== -1);
            })
        }
        dispatch(fetchSecretsSuccess(filtered));
    }
}

export const deleteSecretById = (id) => {
    return dispatch => {
        return secretsService.deleteSecret(id)
            .then(response => {
                dispatch(getAllSecretsById());
                return response;
            },
                error => {
                    error.error = true;
                    return error;
                })
    }
}