import React from 'react';
import Loadable from 'react-loadable';
import MainLayout from './layout/MainLayout';
import { LocationBuilding } from './modules/location/component/locationBuilding';

export const Routes = {
  UNKNOWN: '',
  LOGIN: '/login',
  ROOT: '/',
  DASHBOARD: '/dashboard',
  INVOICES: '/invoices',
  INVOICE_CREATE: '/create-upload',
  JOURNALS: '/journals',
  MANAGE_USERS: '/manage-users',
  SETTINGS: '/settings/node',
  SETTINGS_AZURE: '/settings/azure',
  PROFILE: '/profile',
  REPORT_DRILL_DOWN: '/report/drill-down/:accountId',
  REPORT_AGING: '/report/aging/:accountId'
}

function Loading() {
  return <div>Loading...</div>;
}

const Application = Loadable({
  loader: () => import('./modules/application/component/application'),
  loading: Loading,
});

const Agents = Loadable({
  loader: () => import('./modules/agents/component/agents'),
  loading: Loading,
});


const ApplicationDetail = Loadable({
  loader: () => import('./modules/application/component/applicationDetail'),
  loading: Loading,
});

const Connections = Loadable({
  loader: () => import('./modules/connections/component/connection'),
  loading: Loading,
});

const ConnectionDetail = Loadable({
  loader: () => import('./modules/connections/component/connectionDetail'),
  loading: Loading,
});
const ConnectionGroup = Loadable({
  loader: () => import('./modules/connectionGroup/component/connectionGroup'),
  loading: Loading,
});

const ConnectionGroupDetail = Loadable({
  loader: () => import('./modules/connectionGroup/component/connectionGroupDetail'),
  loading: Loading,
});

const Users = Loadable({
  loader: () => import('./modules/users/component/users'),
  loading: Loading,
});
const Groups = Loadable({
  loader: () => import('./modules/groups/component/groups'),
  loading: Loading,
});

const UsersDetail = Loadable({
  loader: () => import('./modules/users/component/usersDetail'),
  loading: Loading,
});
const GroupsDetail = Loadable({
  loader: () => import('./modules/groups/component/groupsDetail'),
  loading: Loading,
});
const UserImport = Loadable({
  loader: () => import('./modules/users/component/import'),
  loading: Loading,
});
const UserSpoofAtempts = Loadable({
  loader: () => import('./modules/users/component/spoofAtempts'),
  loading: Loading,
});

const UserSpoofAtemptDetail = Loadable({
  loader: () => import('./modules/users/component/spoofAtemptsDetail'),
  loading: Loading,
});

const Activitylog = Loadable({
  loader: () => import('./modules/activityLog/component/activityLog'),
  loading: Loading,
});

const ActivitylogDetails = Loadable({
  loader: () => import('./modules/activityLog/component/activityLogDetails'),
  loading: Loading,
});

const Tenant = Loadable({
  loader: () => import('./modules/tenant/component/tenant'),
  loading: Loading,
});

const APIDocumentation = Loadable({
  loader: () => import('./modules/apiDocumentation/component/apiDocumentation'),
  loading: Loading,
});

const Endpoint = Loadable({
  loader: () => import('./modules/endpoint/component/endpoints'),
  loading: Loading,
});

const EndpointMap = Loadable({
  loader: () => import('./modules/endpointMap/component/endpointsMap'),
  loading: Loading,
});

const EndpointsDetail = Loadable({
  loader: () => import('./modules/endpoint/component/endpointsDetail'),
  loading: Loading,
});

const Transactions = Loadable({
  loader: () => import('./modules/transactions/component/transactions'),
  loading: Loading,
});
const Integration = Loadable({
  loader: () => import('./modules/transactions/component/integration'),
  loading: Loading,
});
const TransactionsDetail = Loadable({
  loader: () => import('./modules/transactions/component/transactionsDetail'),
  loading: Loading,
});

const Consent = Loadable({
  loader: () => import('./modules/consent/component/consentTemplate'),
  loading: Loading,
});

const ConsentTemplateDetail = Loadable({
  loader: () => import('./modules/consent/component/consentTemplateDetail'),
  loading: Loading,
});

const Reports = Loadable({
  loader: () => import('./modules/report/component/report'),
  loading: Loading,
});

const ReportDetail = Loadable({
  loader: () => import('./modules/report/component/reportDetail'),
  loading: Loading,
});

const OneTime = Loadable({
  loader: () => import('./modules/report/component/oneTime'),
  loading: Loading,
});

const LegalDocuments = Loadable({
  loader: () => import('./modules/consent/component/legalDocumenis'),
  loading: Loading,
});

const LegalDocumentDetail = Loadable({
  loader: () => import('./modules/consent/component/legalDocumentDetail'),
  loading: Loading,
});

const IntegrationsSetting = Loadable({
  loader: () => import('./modules/transactions/component/integrationsSetting'),
  loading: Loading,
});

const wiegand = Loadable({
  loader: () => import('./modules/access/component/wiegand'),
  loading: Loading,
});

const wiegandDetail = Loadable({
  loader: () => import('./modules/access/component/wiegandDetail'),
  loading: Loading,
});

const Downloads = Loadable({
  loader: () => import('./modules/downloads/component/downloadsUsersDetail'),
  loading: Loading,
});
const DownloadsSynched = Loadable({
  loader: () => import('./modules/downloads/component/downloadsUsersSynched'),
  loading: Loading,
});

const Location = Loadable({
  loader: () => import('./modules/location/component/location'),
  loading: Loading,
});
const LocationDetail = Loadable({
  loader: () => import('./modules/location/component/locationDetail'),
  loading: Loading,
});

const AdminFaces = Loadable({
  loader: () => import('./modules/adminFace/component/adminFaces'),
  loading: Loading,
});

const EndpointTemplate = Loadable({
  loader: () => import('./modules/endpointTemplate/component/endpointTemplate'),
  loading: Loading,
});
const EndpointTemplateDetail = Loadable({
  loader: () => import('./modules/endpointTemplate/component/endpointTemplateDetail'),
  loading: Loading,
});

const TransactionDoc = Loadable({
  loader: () => import('./modules/documents/transaction/transactionAPI'),
  loading: Loading,
});


const SimilarFace = Loadable({
  loader: () => import('./modules/similarFace/component/similarFace'),
  loading: Loading,
});

const Secrets = Loadable({
  loader: () => import('./modules/secrets/component/secrets'),
  loading: Loading,
});

const FalsePositives = Loadable({
  loader: () => import('./modules/falsePositives/component/falsePositives'),
  loading: Loading,
});

const TimeInterpetation = Loadable({
  loader: () => import('./modules/timeInterpretation/component/timeInterpretation'),
  loading: Loading,
});

const TimeInterpetationDetail = Loadable({
  loader: () => import('./modules/timeInterpretation/component/timeInterpretationDetail'),
  loading: Loading,
});

const Dashboard = Loadable({
  loader: () => import('./modules/dashboard/component/dashboard'),
  loading: Loading,
});
const TenantActivity = Loadable({
  loader: () => import('./modules/dashboard/component/tenantActivity'),
  loading: Loading,
});

const TransactionActivity = Loadable({
  loader: () => import('./modules/dashboard/component/transactionActivity'),
  loading: Loading,
});

const SimilarityUsers = Loadable({
  loader: () => import('./modules/similarityUsers/component/similarityUsers'),
  loading: Loading,
});
const VaccinesTemplate = Loadable({
  loader: () => import('./modules/dashboard/component/vaccinesTemplate'),
  loading: Loading,
});
const Settings = Loadable({
  loader: () => import('./modules/settings/component/settings'),
  loading: Loading,
});
const SettingsDetail = Loadable({
  loader: () => import('./modules/settings/component/settingsDetail'),
  loading: Loading,
});

/* Workflow module */
const WorkflowComponent = Loadable({
  loader: () => import('./modules/workflow/component'),
  loading: Loading,
});
const WorkflowDetailComponent = Loadable({
  loader: () => import('./modules/workflow/component/workflowDetail'),
  loading: Loading,
});

// admin logs
const AdminLogs = Loadable({
  loader: () => import('./modules/adminLogs/component/adminLogs'),
  loading: Loading,
});


// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  { path: '/', exact: true, name: 'Home', component: MainLayout, allowedRoles: ['admin'] },
  { path: '/applications', exact: true, name: 'Applications', component: Application, allowedRoles: ['admin'] },
  { path: '/agents', exact: true, name: 'Agents', component: Agents, allowedRoles: ['admin'] },
  { path: '/applications/:id/detail', exact: true, name: 'ApplicationDetail', component: ApplicationDetail, allowedRoles: ['admin'] },
  { path: '/connections', exact: true, name: 'Connections', component: Connections, allowedRoles: ['admin'] },
  { path: '/connections/:id/detail', exact: true, name: 'ConnectionDetail', component: ConnectionDetail, allowedRoles: ['admin'] },
  { path: '/connectionGroup', exact: true, name: 'ConnectionGroup', component: ConnectionGroup, allowedRoles: ['admin'] },
  { path: '/connectionGroup/:id/detail', exact: true, name: 'ConnectionGroupDetail', component: ConnectionGroupDetail, allowedRoles: ['admin'] },
  { path: '/users', exact: true, name: 'Users', component: Users, allowedRoles: ['admin'] },
  { path: '/users/:id/detail', exact: true, name: 'UsersDetail', component: UsersDetail, allowedRoles: ['admin'] },
  { path: '/groups', exact: true, name: 'Groups', component: Groups, allowedRoles: ['admin'] },
  { path: '/groups/:id/detail', exact: true, name: 'GroupsDetail', component: GroupsDetail, allowedRoles: ['admin'] },

  { path: '/import/:type', exact: true, name: 'Import', component: UserImport, allowedRoles: ['admin'] },

  { path: '/spoofAtempts', exact: true, name: 'UserSpoofAtempts', component: UserSpoofAtempts, allowedRoles: ['admin'] },
  { path: '/spoofAtempts/:id/detail', exact: true, name: 'UserSpoofAtempts', component: UserSpoofAtemptDetail, allowedRoles: ['admin'] },
  { path: '/activitylog', exact: true, name: 'Activity Log', component: Activitylog, allowedRoles: ['admin'] },
  { path: '/activitylog/:id/detail', exact: true, name: 'Activity Details', component: ActivitylogDetails, allowedRoles: ['admin'] },
  { path: '/tenant', exact: true, name: 'Tenant', component: Tenant, allowedRoles: ['admin'] },

  // { path: '/endpoint', exact: true, name: 'Tenant Details', component: Endpoint, allowedRoles: ['admin'] },
  // { path: '/endpoint/:id/detail', exact: true, name: 'EndpointsDetail', component: EndpointsDetail, allowedRoles: ['admin'] },
  { path: '/endpoint-map', exact: true, name: 'Endpoint Map', component: EndpointMap, allowedRoles: ['admin'] },

  { path: '/endpoint/:status', exact: true, name: 'Tenant Details', component: Endpoint, allowedRoles: ['admin'] },
  { path: '/endpoint/:status/:id/detail', exact: true, name: 'EndpointsDetail', component: EndpointsDetail, allowedRoles: ['admin'] },

  { path: '/endloc/:locationID/:buildingID/', exact: true, name: 'Endpoints Details', component: Endpoint, allowedRoles: ['admin'] },
  { path: '/endloc/:locationID/:buildingID/:id/detail', exact: true, name: 'EndpointsDetail', component: EndpointsDetail, allowedRoles: ['admin'] },


  { path: '/endpoint/bulding/:areaId', exact: true, name: 'Tenant Details', component: Endpoint, allowedRoles: ['admin'] },

  { path: '/apiDocumentation', exact: true, name: 'APIDocumentation', component: APIDocumentation, allowedRoles: ['admin'] },

  { path: '/transactions/:type', exact: true, name: 'Transactions', component: Transactions, allowedRoles: ['admin'] },
  { path: '/transactions/:type/:id/detail', exact: true, name: 'TransactionsDetail', component: TransactionsDetail, allowedRoles: ['admin'] },

  { path: '/consent/template', exact: true, name: 'Consent', component: Consent, allowedRoles: ['admin'] },
  { path: '/consent/template/:id/detail', exact: true, name: 'ConsentTemplateDetail', component: ConsentTemplateDetail, allowedRoles: ['admin'] },

  { path: '/adminFaces', exact: true, name: 'AdminFaces', component: AdminFaces, allowedRoles: ['admin'] },

  { path: '/consent/documents', exact: true, name: 'LegalDocuments', component: LegalDocuments, allowedRoles: ['admin'] },
  { path: '/consent/documents/:id/detail', exact: true, name: 'LegalDocumentDetail', component: LegalDocumentDetail, allowedRoles: ['admin'] },

  { path: '/report/scheduled', exact: true, name: 'Reports', component: Reports, allowedRoles: ['admin'] },
  { path: '/report/scheduled/:id/detail', exact: true, name: 'ReportDetail', component: ReportDetail, allowedRoles: ['admin'] },
  { path: '/report/one-time', exact: true, name: 'Ad-Hoc', component: OneTime, allowedRoles: ['admin'] },

  { path: '/integrations/settings', exact: true, name: 'IntegrationsSetting', component: IntegrationsSetting, allowedRoles: ['admin'] },
  { path: '/integrations/action/:type', exact: true, name: 'Transactions', component: Integration, allowedRoles: ['admin'] },
  { path: '/integrations/action/:type/:id/detail', exact: true, name: 'TransactionsDetail', component: TransactionsDetail, allowedRoles: ['admin'] },

  { path: '/access/:type', exact: true, name: 'Wiegand', component: wiegand, allowedRoles: ['admin'] },
  { path: '/access/:type/:id/detail', exact: true, name: 'Wiegand', component: wiegandDetail, allowedRoles: ['admin'] },
  { path: '/access/wiegand/new', exact: true, name: 'Wiegand', component: wiegandDetail, allowedRoles: ['admin'] },
  { path: '/access/wiegand/duplicate', exact: true, name: 'Wiegand', component: wiegandDetail, allowedRoles: ['admin'] },

  { path: '/template', exact: true, name: 'Endpoint Template', component: EndpointTemplate, allowedRoles: ['admin'] },
  { path: '/template/:id/detail', exact: true, name: 'Endpoint Template Detail', component: EndpointTemplateDetail, allowedRoles: ['admin'] },

  { path: '/downloads/user-det', exact: true, name: 'Downloads User', component: Downloads, allowedRoles: ['house'] },
  { path: '/downloads/synched', exact: true, name: 'Downloads synched', component: DownloadsSynched, allowedRoles: ['house'] },

  { path: '/location', exact: true, name: 'Location', component: Location, allowedRoles: ['admin'] },
  { path: '/location/:settingType/detail', exact: true, name: 'Location Detail', component: LocationDetail, allowedRoles: ['admin'] },
  { path: '/building', exact: true, name: 'Location Detail', component: LocationBuilding, allowedRoles: ['admin'] },
  { path: '/transaction-doc', exact: true, name: 'transaction-doc', component: TransactionDoc, allowedRoles: ['house'] },
  { path: '/similarfaces/:id', exact: true, name: 'transaction-doc', component: SimilarFace, allowedRoles: ['admin', 'house'] },

  { path: '/secrets', exact: true, name: 'Secrets', component: Secrets, allowedRoles: ['admin'] },

  { path: '/falsepositives', exact: true, name: 'FalsePositives', component: FalsePositives, allowedRoles: ['admin'] },
  { path: '/timeinterpretation', exact: true, name: 'TimeInterpretation', component: TimeInterpetation, allowedRoles: ['admin'] },
  { path: '/timeinterpretation/:settingType/detail', exact: true, name: 'TimeInterpretationDetail', component: TimeInterpetationDetail, allowedRoles: ['admin'] },
  { path: '/dashboard/:id', exact: true, name: 'dashboard', component: Dashboard, allowedRoles: ['house'] },

  { path: '/similarityusers', exact: true, name: 'similarityUsers', component: SimilarityUsers, allowedRoles: ['admin'] },

  { path: '/activity/users', exact: true, name: 'Users Activity', component: TenantActivity, allowedRoles: ['admin'] },
  { path: '/activity/transactions', exact: true, name: 'Transactions Activity', component: TransactionActivity, allowedRoles: ['admin'] },
  { path: '/vaccination', exact: true, name: 'Vaccines Template', component: VaccinesTemplate, allowedRoles: ['admin'] },

  { path: '/settings', exact: true, name: 'Settings', component: Settings, allowedRoles: ['admin'] },
  { path: '/setting/:type/detail', exact: true, name: 'SettingsDetail', component: SettingsDetail, allowedRoles: ['admin'] },

  { path: '/workflow', exact: true, name: 'WorkflowDetail', component: WorkflowComponent, allowedRoles: ['admin'] },
  { path: '/workflow/:id/detail', exact: true, name: 'Tenant Details', component: EndpointsDetail, allowedRoles: ['admin'] },

  { path: '/admin-logs', exact: true, name: 'WorkflowDetail', component: AdminLogs, allowedRoles: ['admin'] },
];

export default routes;


