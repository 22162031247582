/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import { Modal, Button, Row, Col } from 'antd';
import TextField from "@material-ui/core/TextField";
import { saveConnectionGroup } from '../action/connectionGroup.actions';
import { connect } from 'react-redux';
import { tostor, successMessage } from '../../../helpers';

import "../../application/component/createApplication.scss";
import "./createConnectionGroup.scss";

class CreateConnectionGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      connectionGroup: {
        ConnectionGroupName: '',
        OnlineModel: ''
      },
      error: {},
      isSubmit: false
    }
  }


  handleOk = () => {
    this.setState({ isSubmit: true });
    if (this.validateForm()) {
      const { dispatch } = this.props;
      this.setState({ loading: true });
      const connectionGroup = this.state.connectionGroup;
      const reqObj = {
        ConnectionGroupName: connectionGroup.ConnectionGroupName.trim(),
        Description: '',
        OnlineModel: connectionGroup.OnlineModel,
        EnableAddFaceBestShot: true
      }
      dispatch(saveConnectionGroup(reqObj))
        .then((res) => {
          this.setState({ loading: false });
          if (res.error) {
            tostor.error(res.message)
          }
          else {
            tostor.success(successMessage.CONNECTION_GROUP_CREATE);
            this.resetFrom();
            this.props.toggle();
          }
        });
    }
  };

  resetFrom = () => {
    this.setState({
      connectionGroup: {
        ConnectionGroupName: '',
        OnlineModel: ''
      },
      error: {},
      isSubmit: false
    })
  }
  handleCancel = () => {
    this.resetFrom();
    this.props.toggle()
  };

  validateForm = () => {
    const { connectionGroup } = this.state;
    let error = {};
    if (!connectionGroup.ConnectionGroupName) {
      error.ConnectionGroupName = true;
    }
    if (!connectionGroup.OnlineModel) {
      error.OnlineModel = true;
    }
    this.setState({ error });
    return Object.keys(error).length == 0;
  }

  handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const { connectionGroup, error } = Object.assign({}, this.state);
    var letters = /^[A-Za-z0-9-_# ]+$/;
    if (value.match(letters) || e.target.value == '') {
      connectionGroup[name] = value.replace(/\s{2,}/g, ' ');
      error[name] = false
    }
    this.setState({ connectionGroup, error })
  }

  selectConnectionType = (type) => {
    const { connectionGroup, error } = Object.assign({}, this.state)
    connectionGroup['OnlineModel'] = type;
    error['OnlineModel'] = false
    this.setState({ connectionGroup, error })
  }

  getLearnMore = () => {

  }

  render() {

    const { error, isSubmit, connectionGroup } = this.state;

    return (
      <div >
        <Modal
          className="create-application"
          visible={this.props.modal}
          onCancel={this.handleCancel}
          footer={[
            <div className="footer-box">
              <Button className="create-btn" key="back" type="primary" onClick={this.handleOk}>
                CREATE
              </Button>
              <Button className="cancle-btn" key="submit" onClick={this.handleCancel}>
                CANCEL
              </Button>
            </div>
          ]}
        >
          <div className='txt-header'>Create a new ConnectionGroup</div>
          <div className='position-rel'>
            <TextField
              error={error.ConnectionGroupName}
              name="ConnectionGroupName"
              label="ConnectionGroup Name"
              type="text"
              className='tenant-form-textfield'
              margin="normal"
              variant="outlined"
              value={connectionGroup.ConnectionGroupName}
              helperText={error.ConnectionGroupName ? 'Required' : ''}
              onChange={(e) => this.handleInput(e)}
            />
            {isSubmit && !error.ConnectionGroupName && <div className='success-icon position'></div>}
            {error.ConnectionGroupName && <div className='success-icon position error'></div>}
          </div>

          <div className='application-type'>
            <div className='txt-type'>Choose a Facial Recognition Model</div>
            <div className='types models'>
              <Row>
                <Col span={12}>
                  <div className="box-col-padding">
                    <div className=''>
                      <div className={`type-border mr-type ${error.OnlineModel ? 'notSelected' : connectionGroup.OnlineModel == 'AR' ? 'selected' : ''}`}
                        onClick={() => this.selectConnectionType('AR')}>
                        <div className='type-icon'>
                          <div className='modal-a-icon'></div>
                        </div>
                        <div className='txt-heading'>AR</div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col span={12}>
                  <div className="box-col-padding">
                    <div className={`type-border ${error.OnlineModel ? 'notSelected' : connectionGroup.OnlineModel == 'VL' ? 'selected' : ''}`}
                      onClick={() => this.selectConnectionType('VL')}>
                      <div className='type-icon'>
                        <div className='modal-b-icon'></div>
                      </div>
                      <div className='txt-heading'>VL</div>
                    </div>
                  </div>
                </Col>

              </Row>
            </div>
            {error.OnlineModel && <div className='error-required'>Required</div>}
          </div>
        </Modal>
      </div>
    );
  }
}


function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user
  }
}
export default connect(mapStateToProps)(CreateConnectionGroup);