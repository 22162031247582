import { requestDelete, requestGet, requestPost, requestPut } from '../../../services/requests';

export class SecretsService {

  constructor(endpoint) {
    this.endpoint = endpoint;
  }

  getAllSecretsById() {
    const url = this.operationUrl(`/secrets/list`);
    return requestGet(url, {}, false);
  };

  getAllSecrets() {
    const url = this.operationUrl(`/secrets/list`);
    return requestGet(url);
  };

  saveSecret(data) {
    const url = this.operationUrl(`/secrets/create`);
    return requestPost(url, data);
  };

  updateSeceret(data) {
    const url = this.operationUrl(`/applications/${data.ApplicationID}`);
    return requestPut(url, data);
  };

  deleteSecret(id, reason) {
    const url = this.operationUrl(`/secrets/delete/${id}`);
    return requestDelete(url);
  }

  operationUrl(append) {
    return this.endpoint + append;
  }
}
