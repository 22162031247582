/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Button, Select, Row, Col, DatePicker, message } from 'antd';
import _ from 'lodash';
import { getAllApplication } from '../../application/action/application.actions'
import { downloadEndpointReports } from '../action/report.actions'
import './report.scss';
import progressbar from '../../../image/progressbar-indeterminate.gif';
import { setSelectedReport } from '../action/report.actions'
import moment from 'moment';
import { orderedListData, tostor } from '../../../helpers';

const Option = Select.Option;
const dateFormat = 'YYYY-MM-DD HH:mm';

export class ExportModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      endpointReportParams: {
        LastActivityBefore: moment().subtract(7, "days"),
        // Timezone: "America/Guatemala",
        ApplicationID: "",
        EndpointReportType: "NotUsed"
      },
      isReportGenerate: false,
      formSubmit: false,
      formError: false
    }
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getAllApplication())
  }

  handleOk = () => {
    this.validateForm();
    this.setState({ isSubmit: true })
  };

  handleCancel = () => {
    this.setState({
      endpointReportParams: {
        LastActivityBefore: moment().subtract(7, "days"),
        // Timezone: "America/Guatemala",
        ApplicationID: "",
        EndpointReportType: "NotUsed"
      },
      isReportGenerate: false,
      formSubmit: false,
      formError: false
    })
    this.props.toggle()
  };

  handleDateChange = (value, field) => {
    const endpointReportParams = Object.assign({}, this.state.endpointReportParams);
    endpointReportParams[field] = value;
    this.setState({ endpointReportParams });
  }

  handleDropdownChange = (value, name) => {
    const endpointReportParams = Object.assign({}, this.state.endpointReportParams);
    // const selected = allApplications.find((app) => { return app.ApplicationID == value }) || {};
    endpointReportParams[name] = value;
    this.setState({ endpointReportParams });
  }

  handleOk = () => {
    this.setState({ formSubmit: true })
    let param = {};
    const { endpointReportParams } = this.state;
    const { allApplication } = this.props;
    let tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

    if (endpointReportParams.LastActivityBefore) {
      param.LastActivityBefore = endpointReportParams.LastActivityBefore
    }
    if (endpointReportParams.ApplicationID) {
      param.ApplicationID = endpointReportParams.ApplicationID;
      const application = _.find(allApplication, (res) => { return res.ApplicationID == param.ApplicationID })
      tz = application.Timezone || application.TimeZone || 'UTC'
    }
    if (endpointReportParams.LastActivityAfter) {
      param.LastActivityAfter = endpointReportParams.LastActivityAfter
    }
    if (endpointReportParams.EndpointReportType) {
      param.ReportType = endpointReportParams.EndpointReportType
    }

    param.Timezone = tz
    if (!endpointReportParams.LastActivityBefore) {
      this.setState({ formError: true })
    } else {
      this.setState({ isReportGenerate: true });
      this.generateReport(param)
    }
  }

  generateReport(param) {
    downloadEndpointReports(param)
      .then(res => {

        if (res.error) {
          tostor.error('Server Error!! Please contact support');
          this.setState({ isReportGenerate: false });
          return;
        }
        if (res.isComplete) {
          const link = document.createElement('a');
          link.href = res.url;
          link.setAttribute('download', 'EndpointReports.xlsx');
          document.body.appendChild(link);
          link.click()
          const { dispatch, selectedReportType } = this.props;
          dispatch(setSelectedReport("Endpoints"))
          this.handleCancel(true);
        } else {
          setTimeout(() => {
            const { showModal } = this.props;
            if (showModal) {
              param.EntityID = res.entityId;
              this.generateReport(param);
            }
          }, 10000)
        }

      }).catch(err => {
        tostor.error('Server Error!! Please contact support');
      });
  }

  render() {
    const { allApplication } = this.props;
    const { isReportGenerate, endpointReportParams, formSubmit } = this.state;
    const application = _.uniqBy(allApplication, 'ApplicationName');
    return (
      <div>
        <Modal
          onOk={this.handleOk}
          visible={this.props.showModal}
          onCancel={this.handleCancel}
          footer={[
            <div key="footer" className="footer-box export-footer">
              <Button key="downlod" className="create-btn" type="primary" onClick={this.handleOk}>
                DOWNLOAD
              </Button>
              <Button key="cancel" className="cancle-btn" onClick={this.handleCancel}>
                CANCEL
              </Button>
            </div>
          ]}
        >
          <div className="endpoint-report-view">
            <div className='txt-header'>Endpoint Report</div>
            <Row className="report-export-box">
              <Col span={24} className="export-input-box">
                <label className="txt-customer">Application Name</label>
                <div className="from-left-col">
                  <Select
                    placeholder='Application Name'
                    name="Applications"
                    className="select-input"
                    value={endpointReportParams.ApplicationID || undefined}
                    onChange={(e) => this.handleDropdownChange(e, "ApplicationID")}
                  >
                    {
                      application && orderedListData(application||[], 'ApplicationName').map((res, index) => {
                        return <Option key={index} value={res.ApplicationID}> {res.ApplicationName} </Option>
                      })
                    }
                  </Select>
                </div>
              </Col>
              <Col span={24} className="export-input-box">
                <div className='txt-customer'>Last Activity Logged on Endpoint Date</div>
                <div>
                  <DatePicker showTime
                    className={`export-date-box unerror ${(formSubmit && !endpointReportParams.LastActivityBefore) ? 'error' : ''}`}
                    onChange={(e) => { this.handleDateChange(e, 'LastActivityBefore') }}
                    value={endpointReportParams.LastActivityBefore}
                    placeholder="Last Activity Logged on Endpoint Date" format={dateFormat} />
                </div>
                <span className="helper-text">{(formSubmit && !endpointReportParams.LastActivityBefore) ? 'Required' : ''}</span>
              </Col>
              <Col span={24} className="export-input-box">
                <label className="txt-customer">Endpoint Report Type</label>
                <div className="from-left-col">
                  <Select
                    value={endpointReportParams.EndpointReportType || undefined}
                    onChange={(e) => this.handleDropdownChange(e, "EndpointReportType")}
                    name="EndpointReportType"
                    className="select-input">
                    <Option key="ActivelyUsed" value="Used">Actively Used</Option>
                    <Option key="Unused" value="NotUsed">Unused</Option>
                  </Select>
                </div>
              </Col>
            </Row>
          </div>
          {
            isReportGenerate &&
            <Row className="export-box">
              <Col span={24} className="export-input-box">
                <div>Report Generating...</div>
                <img className="activity-progress" src={progressbar} alt="gif" />
              </Col>
            </Row>
          }
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  const { allApplication } = state.application;
  const { selectedReportType } = state.report
  return {
    user,
    allApplication,
    selectedReportType
  }
}
export default connect(mapStateToProps)(ExportModal);