const menuAccessPermission = {
  application: {
    read: true
  },
  connections: {
    read: true
  },
  connectionGroup: {
    read: false
  },
  timeinterpretation: {
    read: false
  },
  groups: {
    read: false
  },
  access: {
    read: true,
    wiegand: {
      read: true
    },
  },
  secrets: {
    read: true
  },
  activitylog: {
    read: true
  },
  report: {
    read: true,
    oneTime: {
      read: true
    },
    scheduled: {
      read: true
    },
  },
  consent: {
    read: false,
    template: {
      read: false
    },
    documents: {
      read: false
    },
  },
  agents: {
    read: true
  },
  users: {
    read: true
  },
  import: {
    read: true,
    faces: {
      read: false
    },
    importusers: {
      read: true
    },
    userGroupMapping: {
      read: false
    }
  },
  transactions: {
    read: true,
    all: {
      read: true
    },
    success: {
      read: true
    },
    livenessfailure: {
      read: false
    },
    thermalcertify: {
      read: false
    },
  },
  integrations: {
    read: false,
    setting: {
      read: false
    },
  },
  endpoint: {
    read: true,
    all: {
      read: true
    },
    active: {
      read: true
    },
    inactive: {
      read: true
    },

  },
  endpointMap: {
    read: true
  },
  template: {
    read: false
  },
  location: {
    read: true
  },
  similarityusers: {
    read: false
  },
  downloads: {
    read: true,
    details: {
      read: true
    },
    synched: {
      read: false
    },
  },
  Apis: {
    read: true
  },
  similarfaces: {
    read: false
  },
  falsepositives: {
    read: false
  },
  AdminFaces: {
    read: false
  },
  activity: {
    read: true,
    users: {
      read: true
    },
    transactions: {
      read: true
    },
  },
  dashboard: {
    read: false,
    vaccination: {
      read: false
    },
  },
  workflow: {
    read: true,
  },
  settings: {
    read: true,
  }
}
const options = [
  {
    menuid: 1,
    key: "applications",
    urlPath: "applications",
    label: "Applications",
    leftIcon: "applicationbuttonBg",
    isRead: menuAccessPermission.application.read
  },
  {
    menuid: 1,
    key: "connections",
    urlPath: "connections",
    label: "Connections",
    leftIcon: "connectionbuttonBg",
    isRead: menuAccessPermission.connections.read
  },
  {
    menuid: 1,
    key: "connectionGroup",
    urlPath: "connectionGroup",
    label: "ConnectionGroup",
    leftIcon: "connectionbuttonBg",
    isRead: menuAccessPermission.connectionGroup.read
  },
  {
    menuid: 1,
    key: "timeinterpretation",
    urlPath: "timeinterpretation",
    label: "Time Interpretation",
    leftIcon: "timeInterpretationBg",
    isRead: menuAccessPermission.timeinterpretation.read
  },
  {
    menuid: 1,
    key: "groups",
    urlPath: "groups",
    label: "Groups",
    leftIcon: "usersbuttonBg",
    isRead: menuAccessPermission.groups.read
  },
  {
    menuid: 1,
    key: "access",
    urlPath: "access",
    label: "Access",
    leftIcon: "accessIconBg",
    isRead: menuAccessPermission.access.read,
    children: [{
      key: "wiegand",
      urlPath: "wiegand",
      withParent: true,
      label: "Wiegand",
      isRead: menuAccessPermission.access.wiegand.read,
    }]
  },
  {
    menuid: 1,
    key: "secrets",
    urlPath: "secrets",
    label: "Secrets",
    leftIcon: "secretsBg",
    isRead: menuAccessPermission.secrets.read
  },
  {
    menuid: 1,
    key: "activitylog",
    urlPath: "activitylog",
    label: "Activity Log",
    leftIcon: "activityLogsBg",
    isRead: menuAccessPermission.activitylog.read
  },
  {
    menuid: 1,
    key: "report",
    urlPath: "report",
    label: "Reports",
    leftIcon: "reportIconBg",
    isRead: menuAccessPermission.report.read,
    children: [{
      key: "one-time",
      urlPath: "one-time",
      withParent: true,
      label: "Ad-Hoc",
      isRead: menuAccessPermission.report.oneTime.read,
    },
    {
      key: "scheduled",
      urlPath: "scheduled",
      withParent: true,
      label: "Scheduled",
      isRead: menuAccessPermission.report.scheduled.read,
    }]
  },
  {
    menuid: 1,
    key: "consent",
    urlPath: "consent",
    label: "Consent",
    leftIcon: "consentIconBg",
    isRead: menuAccessPermission.consent.read,
    children: [{
      key: "template",
      urlPath: "template",
      withParent: true,
      label: "Consent Template",
      isRead: menuAccessPermission.consent.template.read
    },
    {
      key: "documents",
      urlPath: "documents",
      withParent: true,
      label: "Legal Documents",
      isRead: menuAccessPermission.consent.documents.read
    }]
  },
  {
    menuid: 1,
    key: "agents",
    urlPath: "agents",
    label: "Agents",
    leftIcon: "timeInterpretationBg",
    isRead: menuAccessPermission.agents.read
  },
  {
    menuid: 2,
    key: "users",
    urlPath: "users",
    label: "Users",
    leftIcon: "usersbuttonBg",
    isRead: menuAccessPermission.users.read
  },
  {
    menuid: 2,
    key: "import",
    urlPath: "import",
    label: "Import",
    leftIcon: "userImportIconBg",
    isRead: menuAccessPermission.import.read,
    children: [{
      key: "faces",
      urlPath: "faces",
      withParent: true,
      label: "Faces",
      isRead: menuAccessPermission.import.faces.read,
    },
    {
      key: "importusers",
      urlPath: "importusers",
      withParent: true,
      label: "Users",
      isRead: menuAccessPermission.import.importusers.read,
    },
    {
      key: "user-group-mapping",
      urlPath: "user-group-mapping",
      withParent: true,
      label: "User Groups Mapping",
      isRead: menuAccessPermission.import.userGroupMapping.read,
    }]
  },
  {
    menuid: 2,
    key: "transactions",
    urlPath: "transactions",
    label: "Transactions",
    leftIcon: "appdashboardbuttonBg",
    isRead: menuAccessPermission.transactions.read,
    children: [{
      key: "all",
      urlPath: "all",
      withParent: true,
      label: "All",
      isRead: menuAccessPermission.transactions.all.read,
    },
    {
      key: "success",
      urlPath: "success",
      withParent: true,
      label: "Success",
      isRead: menuAccessPermission.transactions.success.read,
    },
    {
      key: "livenessfailure",
      urlPath: "livenessfailure",
      withParent: true,
      label: "Liveness Failure",
      isRead: menuAccessPermission.transactions.livenessfailure.read,
    },
    {
      key: "thermalcertify",
      urlPath: "thermalcertify",
      withParent: true,
      label: "Thermal Certify",
      isRead: menuAccessPermission.transactions.thermalcertify.read,
    }
    ]
  },

  {
    menuid: 2,
    key: "integrations",
    urlPath: "integrations",
    label: "Integrations",
    leftIcon: "integrationIconBg",
    isRead: menuAccessPermission.integrations.read,
    children: [{
      key: "settings",
      urlPath: "settings",
      withParent: true,
      label: "Settings",
      isRead: menuAccessPermission.integrations.setting.read
    }
    ]
  },
  {
    menuid: 3,
    key: "endpoint",
    urlPath: "endpoint",
    label: "Endpoints",
    leftIcon: "apiEndpointbuttonBg",
    isRead: menuAccessPermission.endpoint.read,
    children: [{
      key: "all",
      urlPath: "all",
      withParent: true,
      label: "All",
      isRead: menuAccessPermission.endpoint.all.read
    },
    {
      key: "active",
      urlPath: "active",
      withParent: true,
      label: "Active",
      isRead: menuAccessPermission.endpoint.active.read
    },
    {
      key: "inactive",
      urlPath: "inactive",
      withParent: true,
      label: "Inactive",
      isRead: menuAccessPermission.endpoint.inactive.read
    }]
  },
  {
    menuid: 3,
    key: "endpoint-map",
    urlPath: "endpoint-map",
    label: "Map",
    leftIcon: "endpointMapIconBg",
    isRead: menuAccessPermission.endpointMap.read
  },
  {
    menuid: 3,
    key: "template",
    urlPath: "template",
    label: "Templates",
    leftIcon: "apiTemplatebuttonBg",
    isRead: menuAccessPermission.template.read
  },

  {
    menuid: 3,
    key: "location",
    urlPath: "location",
    label: "Location",
    leftIcon: "apiLocationbuttonBg",
    isRead: menuAccessPermission.location.read
  },

  {
    menuid: 4,
    key: "similarityusers",
    urlPath: "similarityusers",
    label: "Users",
    leftIcon: "apiStatusbuttonBg",
    access: ['oloid.ai', 'xcdify.com'],
    isRead: menuAccessPermission.similarityusers.read
  },
  {
    menuid: 4,
    key: "downloads",
    urlPath: "downloads",
    label: "Download Users",
    leftIcon: "apiStatusbuttonBg",
    isRead: menuAccessPermission.downloads.read,
    access: ['oloid.ai', 'xcdify.com'],
    children: [{
      key: "user-det",
      urlPath: "user-det",
      withParent: true,
      label: "Details",
      isRead: menuAccessPermission.downloads.details.read,
    },
    {
      key: "synched",
      urlPath: "synched",
      withParent: true,
      label: "Synched",
      isRead: menuAccessPermission.downloads.synched.read,
    }]
  },

  {
    menuid: 4,
    key: "transaction-doc",
    urlPath: "transaction-doc",
    label: "Apis",
    leftIcon: "apiStatusbuttonBg",
    isRead: menuAccessPermission.Apis.read,
    access: ['oloid.ai', 'xcdify.com']
  },

  {
    menuid: 4,
    key: "similarfaces",
    urlPath: "similarfaces",
    label: "Similar Faces",
    leftIcon: "apiStatusbuttonBg",
    isRead: menuAccessPermission.similarfaces.read,
    access: ['oloid.ai', 'xcdify.com','flex.com'],
    children: []
  },

  {
    menuid: 4,
    key: "falsepositives",
    urlPath: "falsepositives",
    label: "False Positives",
    leftIcon: "apiStatusbuttonBg",
    isRead: menuAccessPermission.falsepositives.read,
    access: ['oloid.ai', 'xcdify.com'],
  },
  {
    menuid: 4,
    key: "AdminFaces",
    urlPath: "faces",
    label: "Faces",
    leftIcon: "apiStatusbuttonBg",
    isRead: menuAccessPermission.AdminFaces.read,
    access: ['oloid.ai', 'xcdify.com'],

  },
  {
    menuid: 4,
    key: "settings",
    urlPath: "settings",
    label: "Settings",
    leftIcon: "settinglogBg",
    isRead: menuAccessPermission.settings.read,
  },
  {
    menuid: 4,
    key: "admin-logs",
    urlPath: "logs",
    label: "Logs",
    leftIcon: "apiStatusbuttonBg",

  },
  {
    menuid: 5,
    key: "activity",
    urlPath: "activity",
    label: "Dashboard",
    leftIcon: "apiActivitybuttonBg",
    isRead: menuAccessPermission.activity.read,
    access: ['oloid.ai', 'xcdify.com'],
    children: [{
      key: "users",
      urlPath: "users",
      withParent: true,
      label: "Users",
      isRead: menuAccessPermission.activity.users.read,
    },
    {
      key: "transactions",
      urlPath: "transactions",
      withParent: true,
      label: "Transactions",
      isRead: menuAccessPermission.activity.transactions.read,
    }]
  },
  {
    menuid: 5,
    key: "dashboard",
    urlPath: "dashboard",
    label: "Dashboard",
    leftIcon: "apiStatusbuttonBg",
    isRead: menuAccessPermission.dashboard.read,
    access: ['oloid.ai', 'xcdify.com'],
    children: [
      {
        key: "vaccination",
        urlPath: "vaccination",
        withParent: false,
        label: "Vaccination",
        access: ['oloid.ai', 'xcdify.com'],
        isRead: menuAccessPermission.dashboard.vaccination.read,
      }
    ]

  },
  {
    menuid: 6,
    key: "workflow",
    urlPath: "workflow",
    label: "Workflow Instances",
    leftIcon: "settinglogBg",
    isRead: menuAccessPermission.workflow.read,
  },
];

export default options;
