import { wfsConstants } from "../constants";
import { combineReducers } from "redux";
import createReducer from "../../../helpers/createReducer";

const initialState = [];
const filter = {
  search: "",
  appType: "ALL",
  endpointType: "Workflow",
  areaID: "ALL",
  currentPage: 1,
};

const filterReducer = createReducer(filter)({
  [wfsConstants.SET_WF_FILTER]: (state, action) => action.payload,
  [wfsConstants.SET_WF_PAGE]: (state, action) => {
    return {
      ...state,
      currentPage: action.payload,
    };
  },
});

const WFSReducer = createReducer(initialState)({
  [wfsConstants.GET_ALL_WFS_SUCCESS]: (state, action) => action.payload,
  [wfsConstants.GET_ALL_WF_FAILURE]: (state, action) => action.payload,
});

const copyEndpointsReducer = createReducer(initialState)({
  [wfsConstants.GET_ALL_WF_COPY_SUCCESS]: (state, action) =>
    action.payload,
});


const endpointDetailReducer = createReducer({})({
  [wfsConstants.GET_WF_DETAIL_SUCCESS]: (state, action) =>
    action.payload,
  [wfsConstants.GET_WF_DETAIL_FAILURE]: (state, action) =>
    action.payload,
});

const IntegrationReducer = createReducer(initialState)({
  [wfsConstants.GET_ALL_INTEGRATION_SUCCESS]: (state, action) => action.payload,
  [wfsConstants.GET_ALL_INTEGRATION_FAILURE]: (state, action) => action.payload,
});

const EnabledIntegrationReducer = createReducer(initialState)({
  [wfsConstants.GET_ALL_ENABLED_INTEGRATION_SUCCESS]: (state, action) =>
    action.payload,
  [wfsConstants.GET_ALL_ENABLED_INTEGRATION_FAILURE]: (state, action) =>
    action.payload,
});

const CameraReducer = createReducer(initialState)({
  [wfsConstants.GET_ALL_CAMERA_SUCCESS]: (state, action) => action.payload,
  [wfsConstants.GET_ALL_CAMERA_FAILURE]: (state, action) => action.payload,
});

const usersGroupReducer = createReducer([])({
  [wfsConstants.GET_ALL_USER_GROUP_SUCCESS]: (state, action) => action.payload,
  [wfsConstants.GET_ALL_USER_GROUP_FAILURE]: (state, action) => action.payload,
});

const endpointConfigureTabReducer = createReducer([])({
  [wfsConstants.GET_ALL_WF_CONFIGURE_TAB_SUCCESS]: (state, action) =>
    action.payload,
  [wfsConstants.GET_ALL_ENDPOINT_CONFIGURE_TAB_FAILURE]: (state, action) =>
    action.payload,
});

const endpointHealthReducer = createReducer([])({
  [wfsConstants.GET_ALL_WF_HEALTH_PARAMETER_SUCCESS]: (state, action) =>
    action.payload,
  [wfsConstants.GET_ALL_ENDPOINT_HEALTH_FAILURE]: (state, action) =>
    action.payload,
});

const endpointHealthParameterReducer = createReducer([])({
  [wfsConstants.GET_ALL_ENDPOINT_HEALTH_PARAMETER_SUCCESS]: (state, action) =>
    action.payload,
});

export default combineReducers({
  filter: filterReducer,
  _WFSReducer: WFSReducer,
  allCopyEndpoints: copyEndpointsReducer,
  endpointDetail: endpointDetailReducer,
  allIntegrations: IntegrationReducer,
  allEnabledIntegrations: EnabledIntegrationReducer,
  allCameras: CameraReducer,
  userGroup: usersGroupReducer,
  endpointConfigureTab: endpointConfigureTabReducer,
  endpointHealth: endpointHealthReducer,
  endpointParameter: endpointHealthParameterReducer,
});
