/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import "./deleteTenant.scss";
import { Modal, Button } from 'antd';
import TextField from "@material-ui/core/TextField";
import { fromValidate } from '../../../helpers'

class DeleteTenant extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formError: {},
      formSubmit: false,
      customer: {
        tenantName: '',
        description: ''
      }
    }
  }



  handleCancel = () => {
    var customer = this.state.customer
    customer['tenantName'] = ""
    customer['description'] = ""
    this.setState({ customer })
    this.props.toggle()
  };

  handleOk = e => {
    let { modalName } = this.props;
    let { customer } = this.state;
    e.preventDefault();
    this.setState({ formError: {}, formSubmit: true })
    const formValidation = fromValidate(this.fromNode);
    const typeName = this.getTypeName();
    if (!formValidation.valid) {
      this.setState({ formError: formValidation.invalidationEle })
    }
    else if (modalName != 'User' && !(typeName == customer.tenantName)) {
      this.setState({ formError: { tenantName: { valid: false } } })
    } else {
      this.handleToggleComponent();

    }
  };

  handleToggleComponent = () => {
    let { typeDetails, modalName } = this.props;
    let { customer } = this.state;
    switch (modalName) {
      case 'User':
        this.props.toggle(typeDetails && typeDetails.OloID);
        break;
      case 'Application':
        this.props.toggle(typeDetails && typeDetails.ApplicationID, customer && customer.description);
        break;
      case 'Integrations':
        this.props.toggle(typeDetails && typeDetails.ApplicationName);
        break;
      case 'ConnectionGroup':
        this.props.toggle(typeDetails && typeDetails.ConnectionGroupID, customer && customer.description);
        break;
      case 'Connection':
        this.props.toggle(typeDetails && typeDetails.ConnectionID, customer && customer.description);
        break;
      case 'Endpoint':
        this.props.toggle(typeDetails && typeDetails.EndpointID, customer && customer.description);
        break;
      case 'Group':
        this.props.toggle(typeDetails && typeDetails.GroupID, customer && customer.description);
        break;
      case 'Report':
        this.props.toggle(typeDetails && typeDetails.ReportID, customer && customer.description);
        break;
      case 'LegalDocument':
        this.props.toggle(typeDetails && typeDetails.Identifier, customer && customer.description);
        break;
      case 'ConsentTemplate':
        this.props.toggle(typeDetails && typeDetails.TemplateID, customer && customer.description);
        break;
      case 'Template':
        this.props.toggle(typeDetails && typeDetails.EndpointTemplateID, customer && customer.description);
        break;
      case 'Wiegand':
        this.props.toggle(typeDetails && typeDetails.FormatID, customer && customer.description);
        break;
      case 'secret key':
        this.props.toggle(typeDetails, customer && customer.description);
        customer['tenantName'] = ""
        customer['description'] = ""
        this.setState({ customer });
        break;
      case 'Location':
        this.props.toggle(typeDetails && typeDetails.SettingType, customer && customer.description);
        break;
      case 'TimeInterpretation':
        this.props.toggle(typeDetails && typeDetails.SettingType, customer && customer.description);
        break;
      case 'RemoveUserFromGroups':
        this.props.toggle(typeDetails.groupId);
        break;
      default:
        this.props.toggle();
    }
  }

  getTypeName = () => {
    let { typeDetails, modalName } = this.props;
    var typeName;
    if (typeDetails) {
      switch (modalName) {
        case 'User':
          typeName = typeDetails.DisplayName;
          break;
        case 'Application':
          typeName = typeDetails.ApplicationName;
          break;
        case 'Integrations':
          typeName = typeDetails.ApplicationName;
          break;
        case 'Connection':
          typeName = typeDetails.ConnectionDisplayName;
          break;
        case 'ConnectionGroup':
          typeName = typeDetails.ConnectionGroupName;
          break;
        case 'Endpoint':
          typeName = typeDetails.EndpointName;
          break;
        case 'Group':
          typeName = typeDetails.GroupName;
          break;
        case 'Report':
          typeName = typeDetails.ReportName
          break;
        case 'LegalDocument':
          typeName = typeDetails.DisplayName;
          break;
        case 'ConsentTemplate':
          typeName = typeDetails.TemplateName;
          break;
        case 'Template':
          typeName = typeDetails.EndpointTemplateName;
          break;
        case 'Wiegand':
          typeName = typeDetails.Name;
          break;
        case 'secret key':
          typeName = typeDetails;
          break;
        case 'Location':
          typeName = typeDetails.LocationName;
          break;
        case 'TimeInterpretation':
          typeName = typeDetails.Name;
          break;
        case 'RemoveUserFromGroups':
          typeName = typeDetails.groupName;
          break;
        default:
          typeName = ''
      }
    }
    return typeName;
  }

  handleChange = (e) => {
    const customer = Object.assign({}, this.state.customer)
    const name = e.target.name;
    const value = e.target.value;
    customer[name] = value;
    this.setState({ customer });

    if (this.state.formSubmit) {
      this.setState({ formError: {} })
      const formValidation = fromValidate(this.fromNode);
      if (!formValidation.valid) {
        this.setState({ formError: formValidation.invalidationEle })
      }
    }
  }

  render() {
    const { customer, formError, formSubmit } = this.state;
    let {
      modalName,
      promptText,
      placeholderText,
      namePlaceholderText,
      btnText,
      headerText,
      needConfirmation
    } = this.props;

    modalName = modalName || "Tenant";

    return (
      <div >
        <Modal
          className={`delete-account ${modalName}`}
          visible={this.props.modal}
          onCancel={this.handleCancel}
          footer={[
            <div className="footer-box" key="delete">
              <Button className="delete-btn" key="back" onClick={this.handleOk}>
                {btnText}
              </Button>
              <Button className="cancle-btn" key="submit" type="primary" onClick={this.handleCancel}>
                CANCEL
              </Button>
            </div>
          ]}
        >
          <form ref={node => this.fromNode = node} noValidate autoComplete="off">
            <div className="heading-text">Are you absolutely sure?</div>
            {headerText && <div className="sub-heading-text">{headerText}</div>}
            {
              promptText &&
              <div className="sub-heading-text marign-bottom-20">
                {promptText} <b>- {this.getTypeName() || 'oloid.com'}</b>
              </div>
            }

            {needConfirmation && <div>
              <TextField
                id="tenant"
                label={namePlaceholderText}
                type="text"
                className='education-form-textfield'
                margin="normal"
                variant="outlined"
                placeholder={namePlaceholderText}
                required
                value={customer.tenantName}
                name="tenantName"
                error={formSubmit && formError['tenantName'] && (!formError['tenantName'].valid)}
                onChange={(e) => { this.handleChange(e) }}
              />
              <TextField
                id="description"
                label={placeholderText}
                multiline
                rows="6"
                className='education-form-textfield'
                margin="normal"
                variant="outlined"
                placeholder={placeholderText}
                required
                value={customer.description}
                name="description"
                error={formSubmit && formError['description'] && (!formError['description'].valid)}
                onChange={(e) => { this.handleChange(e) }}
              />
            </div>}
          </form>
        </Modal>
      </div>
    );
  }
}

export default DeleteTenant;