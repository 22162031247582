export const groupsConstants = {
    GET_ALL_GROUPS_SUCCESS: 'app/groups/GET_ALL_GROUPS_SUCCESS',
    GET_ALL_COPY_GROUPS_SUCCESS: 'app/groups/GET_ALL_COPY_GROUPS_SUCCESS',
    GET_ALL_GROUPS_FAILURE: 'app/groups/GET_ALL_GROUPS_FAILURE',
    GET_GROUPS_DETAIL_SUCCESS: 'app/groups/GET_GROUPS_DETAIL_SUCCESS',
    GET_GROUPS_DETAIL_FAILURE: 'app/groups/GET_GROUPS_DETAIL_FAILURE',
    GET_ALL_GROUP_ENDPOINT_SUCCESS:'app/groups/GET_ALL_GROUP_ENDPOINT_SUCCESS',
    GET_ALL_GROUP_ENDPOINT_FAILURE : 'app/groups/GET_ALL_GROUP_ENDPOINT_FAILURE',
    GET_ALL_GROUP_USER_SUCCESS:'app/groups/GET_ALL_GROUP_USER_SUCCESS',
    GET_ALL_GROUP_USER_FAILURE : 'app/groups/GET_ALL_GROUP_USER_FAILURE',
    GET_ALL_GROUP_USER_FACES_SUCCESS:'app/groups/GET_ALL_GROUP_USER_FACES_SUCCESS',
    GET_ALL_GROUP_USER_FACES_FAILURE : 'app/groups/GET_ALL_GROUP_USER_FACES_FAILURE',
    FILTER_UPDATE: 'GROUP_FILTER_UPDATE',
    PAGINATE_UPDATE: 'GROUP_PAGINATE_UPDATE',
};
