import { requestPost } from '../../../services/requests';

/**
 * Supports all operations exposed by the user controller.
 */

/* eslint eqeqeq: 0 */
export class DownloadService {

  constructor(endpoint) {
    this.endpoint = endpoint;
  }

  downloadUser(data) {
    const url = this.operationUrl('/downloads/users');
    return requestPost(url, data, false);
  };


  operationUrl(append) {
    return this.endpoint + append;
  }

}



