import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { googleLogin, azureLogin } from '../action/user.actions'
import { getItem, history, tostor } from '../../../helpers';
import { getOktaAuth } from './oktaAuth';
import _ from "lodash";

import './login.scss';


const SSOLogin = () => {

  const dispatch = useDispatch();


  window.addEventListener(
    "message",
    function (event) {
      // Do something
      console.log(event);
      if (event.data?.sender === 'emailLogin') {
        history.push('/login');
      }
      if (event.data?.sender === 'googleLogin') {
        handleGoogleLogin(event.data?.token)
      }
      if (event.data?.sender === 'oktaLogin') {
        handleOktaLogin()
      }
      if (event.data?.sender === 'azureLogin') {
        handleAzureLogin(event.data?.token)
      }
    },
    false);


  const getSubdomain = () => {
    var full = window.location.hostname;
    var domain, onlySubDomain, parts, subDomain;
    domain = process.env['REACT_APP_HOST_NAME'] || "";
    onlySubDomain = full.replace(domain, "")
    parts = onlySubDomain.split('.')
    _.remove(parts, (res) => { return ["", 'www', 'admin', 'api', 'dev', 'prod', 'ui', 'localhost'].indexOf(res) > -1 });
    if (parts.length > 0) {
      subDomain = parts[0];
    }
    return subDomain
  }

  const handleGoogleLogin = (token) => {

    console.log(JSON.stringify(token));
    const subDomain = getSubdomain();
    // store returned user somehow
    dispatch(googleLogin({
      "TenantName": subDomain || "lift",
      "Token": token
    })).then((res) => {
      if (res.accesstoken) {
        history.push('/applications')
      }
      if (res.message) {
        if (res.role == 'supervisor') {
          tostor.error(res.message);
          return;
        }
        const msg = res.message.indexOf('Clientmatadata') > -1 ? 'Something went wrong please try again!' : res.message;
        tostor.error(msg);
      }
    });
  }

  const handleOktaLogin = async () => {

    if (!getItem('ssoLoginConfig')) {
      tostor.error('Okta SSO Configuration not Found')
      return;
    }
    const oktaAuth = getOktaAuth()
    oktaAuth.token
      .getWithRedirect({
        responseType: ['token', 'id_token'],
        state: 'defaultrandomstring',
      })
      .catch(function (err) {
        // eslint-disable-next-line
        tostor.error('Not able to connect to server')
        console.error('Error OKTA login redirect', err.message)
      })
  };

  const handleAzureLogin = (userAccountInfo) => {
    console.log(userAccountInfo);
    const subDomain = getSubdomain();

    dispatch(azureLogin({
      "TenantName": subDomain || "lift",
      "Token": userAccountInfo.idToken
    })).then((res) => {
      if (res.accesstoken) {
        history.push('/applications')
      }
      if (res.message) {
        if (res.role === 'supervisor') {
          tostor.error(res.message);
          return;
        }
        const msg = res.message.indexOf('Clientmatadata') > -1 ? 'Something went wrong please try again!' : res.message;
        tostor.error(msg);
      }
    });

  };

  return (
    <div className='sso-login-container'>
      <iframe
        class="googleLogin"
        style={{
          height: '100vh',
          width: '100%',
          border: 'none'
        }}
        title='googleLogin'
        src={`${process.env.REACT_APP_SYS_ADMIN_SSO_URL}?tenant=${getSubdomain()}&platfrom='TenantAdmin'`} />
    </div>
  )
}
export default SSOLogin;