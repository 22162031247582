import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Button, message, Upload, Icon } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import _ from 'lodash';
import TextField from "@material-ui/core/TextField";
import './createConsentTemplate.scss';
import { createDocumentTemplate } from '../action/consent.actions';
import { successMessage, tostor } from '../../../helpers';

const { Dragger } = Upload;

export class CreateLegalDocument extends Component {
  constructor(props) {
    super(props);
    this.state = {
      document: {
        "Identifier": "",
        "DisplayName": "",
        "Language": "en",
        "Version": "1.0",
        "FileURL": "",
        File: null
      },
      error: {},
      isSubmit: false
    }
  }
  validateForm = () => {
    const { document } = this.state;
    let error = {};
    if (!document.File) {
      error.File = true;
    }
    if (!document.DisplayName) {
      error.DisplayName = true;
    }
    if (!document.Language) {
      error.Language = true;
    }
    if (!document.Version) {
      error.Version = true;
    }
    this.setState({ error })
    return Object.keys(error).length === 0;
  }

  handleInput = (e) => {
    const name = e.target.name;
    let value= e.target.value;
    if(e.target.name=='Language' || e.target.name== 'DisplayName'){
      value= value.replace(/[^\w\s]/gi, "");
    }
    const { document, error } = Object.assign({}, this.state)
    document[name] = value;
    error[name] = false
    if (name === 'DisplayName') {
      document.Identifier = value.replace(/ /g, '');
    }
    this.setState({ document, error })
  }

  handleOk = () => {
    this.setState({ isSubmit: true });
    if (this.validateForm()) {
      const { dispatch } = this.props;
      this.setState({ loading: true });
      const document = this.state.document;
      const reqObj = {
        "Identifier": document.Identifier.trim(),
        "DisplayName": document.DisplayName,
        "Language": document.Language,
        "Version": document.Version,
      }
      if (reqObj.Identifier == 'OloIDCustomerTOC') {
        reqObj.ISSingleDOC = true;
      }

      dispatch(createDocumentTemplate(reqObj, document.File))
        .then((res) => {
          if (res.error) {
            tostor.error(res.message)
            return;
          }
          tostor.success(successMessage.DOCUMENT_CREATE);
          this.setState({ loading: false });
          this.resetFrom();
          this.props.toggle()
        });
    }
  };

  resetFrom = () => {
    this.setState({
      document: {
        Identifier: "",
        DisplayName: "",
        Language: "en",
        Version: "1.0",
        FileURL: "",
        File: null
      },
      error: {},
      isSubmit: false
    })
  }
  handleCancel = () => {
    this.resetFrom();
    this.props.toggle()
  };

  onChangeDocument = (files) => {
    const { document, error } = Object.assign({}, this.state);

    let fileType = _.last(_.split(files.file.name, '.'));
    error.File = false;

    if (fileType !== 'pdf') {
      error.invalidFile = true
      this.setState({ error })
      return false;
    }

    document.File = files.file;
    error.invalidFile = false
    this.setState({ document })
  }

  render() {
    const { showModal } = this.props;
    const { error, document } = this.state;

    return (
      <div>
        <Modal
          visible={showModal}
          // title="fhhfhfh"
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <div className="footer-box">
              <Button className="create-btn" key="back" type="primary" onClick={this.handleOk}>
                UPLOAD
              </Button>
              <Button className="cancle-btn" key="submit" onClick={this.handleCancel}>
                CANCEL
              </Button>
            </div>
          ]}

        >
          <div className='txt-header'>Upload Legal Document</div>
          <div className='position-rel'>
            <TextField
              label="Document Name"
              type="text"
              className='tenant-form-textfield'
              margin="normal"
              variant="outlined"
              name="DisplayName"
              error={error.DisplayName}
              value={document.DisplayName}
              helperText={error.DisplayName ? 'Required' : ''}
              onChange={(e) => this.handleInput(e)}
            />
            <TextField

              label="Version"
              type="text"
              className='tenant-form-textfield'
              margin="normal"
              variant="outlined"
              name="Version"
              error={error.Version}
              value={document.Version}
              helperText={error.Version ? 'Required' : ''}
              onChange={(e) => this.handleInput(e)}
            />
            <TextField
              label="Language"
              type="text"
              className='tenant-form-textfield'
              margin="normal"
              variant="outlined"
              name="Language"
              error={error.Language}
              value={document.Language}
              helperText={error.Language ? 'Required' : ''}
              onChange={(e) => this.handleInput(e)}
            />

            <div className="file-uploader">
              <Dragger
                multiple={false}
                fileList={[]}
                beforeUpload={(file) => {
                  let fileType = _.last(_.split(file.name, '.'));
                  if (fileType !== 'pdf') {
                    tostor.error('Only PDFs accepted');
                    return false;
                  }
                }
                }
                onChange={(files) => { this.onChangeDocument(files) }}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                <p className="ant-upload-hint">
                  PDF Document
                </p>
              </Dragger>
              <div className="document-hint-txt">Acceptable file types PDF</div>
              <span>{document.File && document.File.name}</span>
              <div className="error-msg" >{error.File && 'Please Upload Document'}</div>
              <div className="error-msg">{error.invalidFile && 'Only PDFs accepted'}</div>
            </div>

          </div>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user
  }
}
export default connect(mapStateToProps)(CreateLegalDocument);

