import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Button, message, Select, Row, Col, DatePicker, Input } from 'antd';
import TextField from "@material-ui/core/TextField";
import _ from 'lodash';
import { downloadTransactions } from '../action/transactions.actions';
import './transactions.scss';
import { MODE_TYPE, MODE_TYPE_V2, orderedListData, timeZoneList, tostor } from '../../../helpers';
// eslint-disable-next-line no-unused-vars
import momentTZ from 'moment-timezone';
import moment from 'moment';
import progressbar from '../../../image/progressbar-indeterminate.gif'
import { setSelectedReport } from '../../report/action/report.actions'

const Option = Select.Option;
const dateFormat = 'YYYY-MM-DD HH:mm';

export class TransactionReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      filter: {
        SearchField: '',
        Applications: [],
        Endpoints: [],
        FromDate: '',
        ToDate: '',
        PrimaryID: '',
        Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        Mode: 'ALL',
        Status: 'ALL'
      },
      isReportGenerate: false
    }
  }

  componentDidMount() {
  }
  handleCancel = e => {
    this.setState({
      filter: {
        SearchField: '',
        Applications: [],
        Endpoints: [],
        FromDate: '',
        PrimaryID: '',
        ToDate: '',
        Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        Mode: 'ALL',
        Status: 'ALL'
      },
      isReportGenerate: false
    }, () => {
      this.props.toggle()
    });
  };

  handleOk = e => {
    const { filter } = this.state;
    const { IntegrationID } = this.props
    let param = {}
    param.IntegrationID = IntegrationID
    if (filter.Applications && filter.Applications.length > 0) {
      param.Applications = filter.Applications;
    }
    if (filter.Endpoints && filter.Endpoints.length > 0) {
      param.Endpoints = filter.Endpoints;
    }
    if (filter.FromDate !== '') {
      param.FromDate = filter.FromDate;
    }
    if (filter.ToDate !== '') {
      param.ToDate = filter.ToDate;
    }
    if (filter.Mode !== 'ALL') {
      param.Mode = filter.Mode;
    }
    if (filter.Status !== 'ALL') {
      param.Status = filter.Status;
    }
    if (filter.LivelinessStatus) {
      param.LivelinessStatus = filter.LivelinessStatus;
    }

    if (filter.PrimaryID) {
      param.PrimaryID = filter.PrimaryID;
    }

    if (filter.Timezone) {
      param.Timezone = filter.Timezone;
      param.TimezoneOffset = moment().tz(filter.Timezone).zone();
    }
    else {
      param.Timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      param.TimezoneOffset = new Date().getTimezoneOffset();
    }
    this.setState({ isReportGenerate: true });
    this.generateReport(param)
  };

  generateReport(param) {
    downloadTransactions(param)
      .then(res => {

        if (res.error) {
          tostor.error('Server Error!! Please contact support');
          this.setState({ isReportGenerate: false });
          return;
        }
        if (res.isComplete) {
          const link = document.createElement('a');
          link.href = res.url;
          link.setAttribute('download', 'TransactionReport.xlsx');
          document.body.appendChild(link);
          link.click();
          const { dispatch } = this.props;
          dispatch(setSelectedReport("Transactions"))
          this.handleCancel();
        } else {
          setTimeout(() => {
            const { showModal } = this.props;
            if (showModal) {
              param.FileID = res.fileId;
              this.generateReport(param);
            }
          }, 10000)
        }

      }).catch(err => {
        tostor.error('Server Error!! Please contact support');
      });
  }

  onChangeFilterOption = (value, field) => {
    const { filter } = this.state
    filter[field] = value;
    this.setState({ filter });
  }


  render() {
    const { showModal } = this.props;
    const { filter, isReportGenerate } = this.state;
    const { allApplication } = this.props
    const application = _.uniqBy(allApplication, 'ApplicationName');
    const { allEndpoints } = this.props;
    return (
      <div>
        <Modal
          maskClosable={false}
          visible={showModal}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <div className="footer-box export-footer">
              <Button
                disabled={isReportGenerate}
                className="create-btn" key="back" type="primary" onClick={this.handleOk}>
                DOWNLOAD
              </Button>
              <Button className="cancle-btn" key="submit" onClick={this.handleCancel}>
                CANCEL
              </Button>
            </div>
          ]}
        >
          <div className='txt-header'>Export To XL</div>
          <Row className="export-box" id="exportToXl">
            <Col span={12} className="export-input-box">
              <div className='txt-customer'>From Date Time</div>
              <div>
                <DatePicker showTime
                  onChange={(e) => { this.onChangeFilterOption(e, 'FromDate') }}
                  value={filter.FromDate}
                  className="export-date-box"
                  placeholder="From Date Time" format={dateFormat} />
              </div>
            </Col>
            <Col span={12} className="export-input-box">
              <div className='txt-customer'>To Date Time</div>
              <div>
                <DatePicker showTime
                  onChange={(e) => { this.onChangeFilterOption(e, 'ToDate') }}
                  value={filter.ToDate}
                  placeholder="To Date Time" format={dateFormat} />
              </div>
            </Col>
          </Row>

          <Row className="export-box">
            <Col span={24} className="export-input-box">
              <label className="txt-customer">Time-zone</label>
              <div className='relative'>
                <Select
                  placeholder='Time-zone'
                  getPopupContainer={() => document.getElementById('exportToXl')}
                  name="Timezone"
                  onChange={(e) => { this.onChangeFilterOption(e, 'Timezone') }}
                  value={filter.Timezone}
                  className="select-input">
                  {
                    timeZoneList && orderedListData(timeZoneList, null).map((res, index) => {
                      return <Option key={index} value={res}> {res} </Option>
                    })
                  }
                </Select>
              </div>
            </Col>
          </Row>

          <Row className="export-box">
            <Col span={24} className="export-input-box">
              <label className="txt-customer">Application Name</label>
              <div className='relative'>
                <Select
                  placeholder='Application Name'
                  getPopupContainer={() => document.getElementById('exportToXl')}
                  mode="multiple"
                  name="Applications"
                  onChange={(e) => { this.onChangeFilterOption(e, 'Applications') }}
                  value={filter.Applications}
                  className="select-input-multiple">
                  {
                    application && orderedListData(application || [], 'ApplicationName').map((res, index) => {
                      return <Option className="select-input-multiple" key={index} value={res.ApplicationID}> {res.ApplicationName} </Option>
                    })
                  }
                </Select>
              </div>
            </Col>
          </Row>

          <Row className="export-box">
            <Col span={24} className="export-input-box">
              <label className="txt-customer">Endpoint Name</label>
              <div>
                <Select placeholder='Endpoint Name'
                  getPopupContainer={() => document.getElementById('exportToXl')}
                  mode="multiple"
                  name="Endpoints"
                  onChange={(e) => { this.onChangeFilterOption(e, 'Endpoints') }}
                  value={filter.Endpoints}
                  className="select-input-multiple">

                  {
                    allEndpoints && orderedListData(allEndpoints || [], 'EndpointName').map((type, index) => {
                      return <Option className="select-input-multiple" key={index} value={type.EndpointID}>{type.EndpointName}({type.EndpointID})</Option>
                    })
                  }
                </Select>
              </div>
            </Col>
          </Row>
          <Row className="export-box">
            <Col span={24} className="export-input-box">
              <label className="txt-customer">Primary ID</label>
              <div className="primary-field">
                <Input
                  id="PrimaryID"
                  type="text"
                  className='form-textfield'
                  onChange={(e) => { this.onChangeFilterOption(e.target.value, 'PrimaryID') }}
                  variant="outlined"
                  placeholder="PrimaryID"
                  value={filter.PrimaryID}
                  name="PrimaryID"
                />
              </div>
            </Col>
          </Row>
          <Row className="export-box">
            <Col span={12} className="export-input-box">
              <label className="txt-customer">Mode</label>
              <div>
                <Select defaultValue='ALL'
                  name="Mode"
                  getPopupContainer={() => document.getElementById('exportToXl')}
                  onChange={(e) => { this.onChangeFilterOption(e, 'Mode') }}
                  value={filter.Mode}
                  className="select-input">
                  <Option key="ALL" value="ALL">ALL</Option>
                  {orderedListData(MODE_TYPE, 'label').map((item) => {
                    return <Option key={item.value} value={item.value}>{item.label}</Option>
                  })}
                </Select>
              </div>
            </Col>

            <Col span={12} className="export-input-box">
              <label className="txt-customer">Status</label>
              <div>
                <Select defaultValue='ALL'
                  name="Status"
                  getPopupContainer={() => document.getElementById('exportToXl')}
                  onChange={(e) => { this.onChangeFilterOption(e, 'Status') }}
                  value={filter.Status}
                  className="select-input">
                  <Option key="ALL" value="ALL">ALL</Option>
                  {orderedListData(MODE_TYPE_V2 || [], 'label').map((item, index) => {
                    return <Option key={`stv_${index}`} value={item.value}>{item.label}</Option>
                  })}
                </Select>
              </div>
            </Col>

          </Row>

          {
            isReportGenerate &&
            <Row className="export-box">
              <Col span={24} className="export-input-box">
                <div>Report Generating...</div>
                <img className="activity-progress" src={progressbar} alt="gif" />
              </Col>
            </Row>
          }

        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  const { allApplication } = state.application;
  const { allEndpoints } = state.endpoints;
  const { selectedReportType } = state.report
  return {
    user,
    allApplication,
    allEndpoints,
    selectedReportType
  }
}
export default connect(mapStateToProps)(TransactionReport);