import { requestDelete, requestGet, requestPost, requestPut } from '../../../services/requests';

/* eslint eqeqeq: 0 */
export class ReportService {

  constructor(endpoint) {
    this.endpoint = endpoint;
  }

  getAllReport() {
    const url = this.operationUrl(`/reports`);
    return requestGet(url, {}, false);
  };

  downloadEndpointReports(params) {
    const url = this.operationUrl(`/downloads/report/endpoints`);
    return requestPost(url, params, false);
  }

  getAllReportsByType(params) {
    const url = this.operationUrl(`/downloads/report/list-files`);
    return requestPost(url, params, false);
  }

  getReportById(id) {
    const url = this.operationUrl(`/reports/${id}`);
    return requestGet(url);
  };

  saveReport(data) {
    const url = this.operationUrl(`/reports`);
    return requestPost(url, data);
  };

  updateReport(data) {
    const url = this.operationUrl(`/reports/${data.ReportID}`);
    return requestPut(url, data);
  };

  deleteReportById(id, reason) {
    const url = this.operationUrl(`/reports/${id}?reason=${reason}`);
    return requestDelete(url);
  }

  downloadVaccineReport(param) {
    const url = this.operationUrl('/downloads/report/vaccine');
    param = param || {};
    return requestPost(url, param, false);
  }

  operationUrl(append) {
    return this.endpoint + append;
  }
}
