import React, { Component } from 'react';
import { Modal, Button, message } from 'antd';
import { TextField } from '@material-ui/core';
import { connect } from 'react-redux';
import "../../tenant/component/createTenant.scss";
import { createLocation } from '../action/location.actions';
import {tostor, successMessage} from '../../../helpers';


class AddLocation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locationDetail: {
        LocationName: "",
        ShowInNavigation: false,
      },
      formSubmit: false,
      formError: false
    }
  }

  handleCancel = () => {
    this.setState({
      locationDetail: {
        LocationName: "",
        ShowInNavigation: false,
      },
      isSubmit: false,
      error: {}
    })
    this.props.toggle()
  };

  handleChange = (e) => {
    var name = e.target.name;
    var value = e.target.value;
    var locationDetail = {}
    locationDetail[name] = value
    this.setState({ locationDetail })
  }

  handleSave = () => {
    const { dispatch } = this.props
    const { locationDetail } = this.state
    this.setState({ formSubmit: true })
    if (!locationDetail.LocationName) {
      this.setState({ formError: true })
    } else {
      this.setState({ formSubmit: false })
      dispatch(createLocation({
        ...locationDetail,
        LocationDescription: `${locationDetail.LocationName} site`
      }))
        .then((res) => {
          if (res.error) {
            return tostor.error(res.message)
          }
          this.handleCancel()
          tostor.success(successMessage.ADD_LOCATION)
        })
       
    }
  }
  render() {
    const { locationDetail, formError, formSubmit } = this.state
    return (
      <div>
        <Modal
          className="create-application"
          visible={this.props.modal}
          onCancel={this.handleCancel}
          footer={[
            <div className="footer-box">
              <Button className="create-btn" key="back" type="primary" onClick={this.handleSave}>
                ADD
              </Button>
              <Button className="cancle-btn" key="submit" onClick={this.handleCancel}>
                CANCEL
            </Button>
            </div>
          ]}
        >
          <div className='txt-header'>Add Location</div>
          <div className='position-rel'>
            <TextField
              name="LocationName"
              label="Location Name"
              type="text"
              value={locationDetail.LocationName}
              className='tenant-form-textfield'
              margin="normal"
              variant="outlined"
              onChange={(e) => this.handleChange(e)}
              error={formSubmit && !locationDetail.LocationName}
              helperText={formError && formSubmit ? 'Required' : ''}
            />
          </div>

        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user
  }
}
export default connect(mapStateToProps)(AddLocation);