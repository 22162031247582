export const inputNumber = (temp, min, max) => {
  let value;
  if (temp) {
    value = temp.replace(/[^0-9\.]/g, "");
    if (value && value.split(".").length > 2) {
      value = value.replace(/\.+$/, "");
    }
    if (min && parseInt(value) < parseInt(min)) {
      value = parseInt(min);
    } else if (max && parseInt(value) > parseInt(max)) {
      value = parseInt(max);
    }
  }
  return value;
};
export const checkDigitsAfterDecimal = (
  value,
  maxDigitAllowedAfterDecimal = 2
) => {
  let isValidDigit = true;
  if (value?.toString()?.split(".")[1]?.length > maxDigitAllowedAfterDecimal) {
    isValidDigit = false;
  }
  return isValidDigit;
};
