export const usersConstants = {
    SET_USERS_FILTER: 'app/users/SET_USERS_FILTER',
    SET_USER_PAGE: 'app/users/SET_USER_PAGE',
    GET_ALL_USERS_SUCCESS: 'app/users/GET_ALL_USERS_SUCCESS',
    GET_ALL_USERS_COPY_SUCCESS: 'app/users/GET_ALL_USERS_COPY_SUCCESS',
    GET_ALL_USERS_FAILURE: 'app/users/GET_ALL_USERS_FAILURE',
    GET_USERS_DETAIL_SUCCESS: 'app/users/GET_USERS_DETAIL_SUCCESS',
    GET_USERS_DETAIL_FAILURE: 'app/users/GET_USERS_DETAIL_FAILURE',
    GET_ALL_USER_END_POINT_SUCCESS:'app/users/GET_ALL_USER_END_POINT_SUCCESS',
    GET_ALL_USER_HISTORY_SUCCESS: 'app/users/GET_ALL_USER_HISTORY_SUCCESS',
    GET_ALL_USER_HISTORY_FAILURE: 'app/users/GET_ALL_USER_HISTORY_FAILURE',
    GET_ALL_USER_JSON_SUCCESS: 'app/users/GET_ALL_USER_JSON_SUCCESS',
    GET_ALL_USER_JSON_FAILURE: 'app/users/GET_ALL_USER_JSON_FAILURE',
    GET_ALL_USER_CONSENT_SUCCESS : 'app/user/GET_ALL_USER_CONSENT_SUCCESS',
    GET_ALL_USER_CONSENT_FAILURE :'app/user/GET_ALL_USER_CONSENT_FAILURE',
    GET_ALL_USER_IMPORT_SUCCESS: 'app/users/GET_ALL_USER_IMPORT_SUCCESS',
    GET_ALL_USER_IMPORT_FAILURE: 'app/users/GET_ALL_USER_IMPORT_FAILURE',
    GET_ALL_USER_COPY_IMPORT_SUCCESS: 'app/users/GET_ALL_USER_COPY_IMPORT_SUCCESS',
    GET_ALL_USER_FACES_SUCCESS:'app/users/GET_ALL_USER_FACES_SUCCESS',
    GET_ALL_USER_FACES_FAILURE : 'app/users/GET_ALL_USER_FACES_FAILURE',
    GET_ALL_USER_GROUP_SUCCESS:'app/users/GET_ALL_USER_GROUP_SUCCESS',
    GET_ALL_USER_GROUP_FAILURE : 'app/users/GET_ALL_USER_GROUP_FAILURE',
    GET_ALL_SPOOF_ATEMPTS_SUCCESS:'app/users/GET_ALL_SPOOF_ATEMPTS_SUCCESS',
    GET_ALL_SPOOF_ATEMPTS_FAILURE : 'app/users/GET_ALL_SPOOF_ATEMPTS_FAILURE',
    GET_SPOOF_ATEMPTS_DETAIL_SUCCESS:'app/users/GET_SPOOF_ATEMPTS_DETAIL_SUCCESS',
    GET_SPOOF_ATEMPTS_DETAIL_FAILURE:'app/users/GET_SPOOF_ATEMPTS_DETAIL_FAILURE',
    ADD_FACE_OVERRIDE_SUCCESS: 'app/users/ADD_FACE_OVERRIDE_SUCCESS'
};
