/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import './login.scss';
import { Button, Row, Col, } from 'antd';
import TextField from "@material-ui/core/TextField";
import logo from '../../../image/Logo-Horizontal.png';
import { fromValidate } from '../../../helpers';
import { history, tostor, setItem, getItem } from '../../../helpers';
import { login, setNewPassword, forgotPasswordSubmit, ssologin, googleLogin, azureLogin, getTenantInfo, getUsersByIdToken } from '../action/user.actions'
import Amplify from 'aws-amplify';
import _ from "lodash";
import axios from 'axios';
import { apiEndpoint } from '../../../services/endpoint';
import * as qs from 'qs';

import GoogleIcon from '../../../image/google-icon.png';
import oktaIcon from '../../../image/okta.png';
import oneLoginIcon from '../../../image/Onelogin.png';
import emailIcon from '../../../image/emailIcon.png';

// import { GoogleLogin } from '@react-oauth/google';

import GoogleLogin from '../../../components/shared/SSOLogin/googleLogin'

import AzureAuthenticationButton from "./azure/azure-authentication-component";
import { getOktaAuth } from './oktaAuth';
import { get } from 'lodash';

const hideShow = (flag) => {
  if (document.getElementById("cst_ui_block")) {
    document.getElementById("cst_ui_block").style.display = flag;
  }
}


class Login extends Component {

  constructor(props) {
    super(props);
    this.state = {
      formError: {},
      formSubmit: false,
      newPasswordReq: false,
      user: {},
      passwordSet: false,
      isSSOLogin: false,
      tenantInfo: {}
    }
  }

  componentDidMount() {
    const { location, dispatch } = this.props;
    const searchParam = qs.parse(location.search, { ignoreQueryPrefix: true });

    this.getUserPoolId();
    this.getTenantInformation();
    let user = searchParam && searchParam.user;
    if (user) {
      try {
        user = JSON.parse(user);
      } catch (error) {
        console.log(error)
      }
      if (user && user.accesstoken) {
        dispatch(ssologin(user))
        setTimeout(() => {
          history.push('/applications');
        }, 50);
      }
    }

    let resetPassword = searchParam && searchParam.resetPassword;
    if (resetPassword) {
      this.setState({ resetPasswordReq: true })
    }
    let token = searchParam && searchParam.token;
    let oloid = searchParam && searchParam.oloid;
    if (token && oloid) {
      this.tokenLogin(token, oloid)
    }
    // window.google.accounts.id.intermediate.notifyParentDone();
  }

  tokenLogin = (token, oloid) => {
    const { dispatch } = this.props;
    dispatch(getUsersByIdToken(oloid, token))
      .then((res) => {
        if (res.accesstoken) {
          history.push('/applications')
        }
      })
  }

  getTenantInformation = () => {
    const { dispatch } = this.props;
    const subDomain = this.getSubdomain();
    dispatch(getTenantInfo(subDomain))
      .then((res) => {
        setItem('ssoLoginConfig', get(res, 'Tenants[0].SSOLoginConfig.Okta'))
      });
  }

  handleLogin = (token) => {
    const { dispatch } = this.props;
    console.log(JSON.stringify(token));
    const subDomain = this.getSubdomain();
    // store returned user somehow
    dispatch(googleLogin({
      "TenantName": subDomain || "lift",
      "Token": token
    })).then((res) => {
      if (res.accesstoken) {
        history.push('/applications')
      }
      if (res.message) {
        if (res.role == 'supervisor') {
          tostor.error(res.message);
          return;
        }
        const msg = res.message.indexOf('Clientmatadata') > -1 ? 'Something went wrong please try again!' : res.message;
        tostor.error(msg);
      }
    });
  }
  handleGoogleAuthFailure = (error) => {
    console.log(error);
    //tostor.error('Not able to connect to server')
  }

  triggerLogin = async () => {

    if (!getItem('ssoLoginConfig')) {
      tostor.error('Okta SSO Configuration not Found')
      return;
    }
    const oktaAuth = getOktaAuth()
    oktaAuth.token
      .getWithRedirect({
        responseType: ['token', 'id_token'],
        state: 'defaultrandomstring',
      })
      .catch(function (err) {
        // eslint-disable-next-line
        tostor.error('Not able to connect to server')
        console.error('Error OKTA login redirect', err.message)
      })
  };

  onAuthenticated = (userAccountInfo) => {
    console.log(userAccountInfo);
    const { dispatch } = this.props;;
    const subDomain = this.getSubdomain();

    dispatch(azureLogin({
      "TenantName": subDomain || "lift",
      "Token": userAccountInfo.idToken
    })).then((res) => {
      if (res.accesstoken) {
        history.push('/applications')
      }
      if (res.message) {
        if (res.role == 'supervisor') {
          tostor.error(res.message);
          return;
        }
        const msg = res.message.indexOf('Clientmatadata') > -1 ? 'Something went wrong please try again!' : res.message;
        tostor.error(msg);
      }
    });

  };

  getSubdomain = () => {
    var full = window.location.hostname;
    var domain, onlySubDomain, parts, subDomain;
    domain = process.env['REACT_APP_HOST_NAME'] || "";
    onlySubDomain = full.replace(domain, "")
    parts = onlySubDomain.split('.')
    _.remove(parts, (res) => { return ["", 'www', 'admin', 'api', 'dev', 'prod', 'ui', 'localhost'].indexOf(res) > -1 });
    if (parts.length > 0) {
      subDomain = parts[0];
    }
    return subDomain
  }

  getUserPoolId = () => {
    const subDomain = this.getSubdomain();
    this.setState({ subDomain })
    return axios({ url: `${apiEndpoint}/tenants/subdomain/${subDomain}`, isLoading: true })
      .then(res => {
        let tenant = (res.data && res.data.result) || {};
        Amplify.configure({
          Auth: {
            region: tenant.Region,
            userPoolId: tenant.PoolID,
            userPoolWebClientId: tenant.AppClientID,
          }
        });
        return tenant;
      })
  }

  handleChange = (e) => {
    const user = Object.assign({}, this.state.user)
    const name = e.target.name;
    const value = e.target.value || '';

    if (name === 'workEmail') {
      user[name] = value.trim();
    } else {
      user[name] = value;
    }

    this.setState({ user });

    if (this.state.formSubmit) {
      this.setState({ formError: {} })
      const formValidation = fromValidate(this.fromNode);
      if (!formValidation.valid) {
        this.setState({ formError: formValidation.invalidationEle })
      }
    }
  }

  goToResetPassword = () => {
    history.push('/resetPassword');
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { dispatch } = this.props;
    const { user } = this.state;

    this.setState({ formSubmit: true, formError: {} })
    const formValidation = fromValidate(this.fromNode);
    if (!formValidation.valid) {
      this.setState({ formError: formValidation.invalidationEle })
    }

    hideShow('flex');
    this.getUserPoolId()
      .then(() => {
        dispatch(login(user.workEmail, user.password))
          .then((res) => {
            hideShow('none');
            if (res.challengeName === 'NEW_PASSWORD_REQUIRED') {
              this.setState({ formSubmit: false, formError: {}, newPasswordReq: true, userData: res })
            }
            else if (res.accesstoken) {
              history.push('/applications')
            }
            if (res.message) {
              if (res.role == 'supervisor') {
                tostor.error(res.message);
                return;
              }
              const msg = res.message.indexOf('Clientmatadata') > -1 ? 'Something went wrong please try again!' : 'Incorrect username or password.';
              tostor.error(msg);
            }
          });
      })
  }


  passwordValidation = (email, password) => {

    var minMaxLength = /^[\s\S]{8,100}$/,
      upper = /[A-Z]/,
      lower = /[a-z]/,
      number = /[0-9]/,
      dictionaryWords = ['password', 'helpme', 'pa$$word'];

    if (!minMaxLength.test(password)) {
      return false;
    }
    if (!upper.test(password)) {
      return false;
    }
    if (!lower.test(password)) {
      return false;
    }
    if (!number.test(password)) {
      return false;
    }

    if (/(.)\1\1/.test(password)) {
      return false;
    }
    if (dictionaryWords.indexOf(password.toLowerCase()) > -1) {
      return false;
    }

    var partsOfThreeLetters = email.match(/.{4}/g).concat(
      email.substr(1).match(/.{4}/g),
      email.substr(2).match(/.{4}/g));

    RegExp.quote = function (str) {
      return str.replace(/([.?*+^$[\]\\(){}-])/g, "\\$1");
    };
    if (new RegExp(RegExp.quote(partsOfThreeLetters.join("|")), "i").test(password.toLowerCase())) {
      return false;
    }

    // Check for sequential numerical characters
    for (var i in password) {
      if (+password[+i + 1] == +password[i] + 1 && +password[+i + 2] == +password[i] + 2) {
        return false;
      }
    }
    // Check for sequential alphabetical characters
    for (var pass in password) {
      if (String.fromCharCode(password.charCodeAt(pass) + 1) == password[+pass + 1] &&
        String.fromCharCode(password.charCodeAt(pass) + 2) == password[+pass + 2]) {
        return false;
      }
    }
    return true;
  }

  handleSetPassword = (e) => {
    e.preventDefault();
    const { dispatch } = this.props;
    const { user, userData } = this.state;
    if (!(user.newPassword || user.confirmPassword)) {
      tostor.error('Password and confirm password should not blank');
      return;
    }
    if (user.newPassword != user.confirmPassword) {
      tostor.error('Password and confirm password not match');
      return;
    }

    const pwdVal = this.passwordValidation(user.workEmail, user.newPassword)
    if (!pwdVal) {
      this.setState({ pwdError: 'Password must contain at least one uppercase, one lowercase letter, one number, no consecutive or sequence characters and password should not contain any part of username' });
      tostor.error('password criteria does not match');
      return;
    }


    // var reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    // if (!reg.test(user.newPassword)) {
    // 	tostor.error('Password must be alphanumeric with minimum 8 characters');
    // 	return;
    // }
    hideShow('flex');
    dispatch(setNewPassword(userData, user.newPassword))
      .then((res) => {
        hideShow('none');
        if (res.accesstoken) {
          history.push('/applications')
        }
        if (res.role == "supervisor") {
          this.setState({ passwordSet: true })
        }
        else if (res.message) {
          tostor.error(res.message);
        }
      })

  }

  forgotPasswordSubmit = (e) => {
    e.preventDefault();
    const { dispatch } = this.props;
    const { user } = this.state;
    if (!(user.newPassword) || !(user.code)) {
      tostor.error('Please fill the all the required field');
      return;
    }

    const pwdVal = this.passwordValidation(user.workEmail, user.newPassword)
    if (!pwdVal) {
      this.setState({ pwdError: 'Password must contain at least one uppercase, one lowercase letter, one number, no consecutive or sequence characters and password not contain any part of username' });
      tostor.error('password criteria does not match');
      return;
    }

    hideShow('flex');
    dispatch(forgotPasswordSubmit(user.workEmail, user.code, user.newPassword))
      .then((res) => {
        hideShow('none');
        if (res.error) {
          tostor.error(res.message)
        }
        else if (res.message && !res.accesstoken && res.role != 'admin') {
          this.setState({ passwordSet: true })
        }
        else if (res.accesstoken) {
          this.setState({ passwordSet: false })
          history.push('/applications');
        }

      })

  }
  redirectLogin = () => {
    this.setState({ passwordSet: false, resetPasswordReq: false, newPasswordReq: false })
    history.push('/login');
  }

  setSSOLogin = (isShow) => {
    // this.setState({ isSSOLogin: isShow })
    history.push('/ssologin');
    window.location.reload();
  }
  render() {
    const { user, formError, pwdError, formSubmit, newPasswordReq, resetPasswordReq, passwordSet, isSSOLogin } = this.state;
    return (
      <div className='login-container'>
        <div className='oloid-box'>
          <img src={logo} alt="logo"></img>
          {/* <div className='text-oloid'>Oloid</div> */}
        </div>
        {/* {
          !isSSOLogin && */}
        <div>
          {(!newPasswordReq && !resetPasswordReq) &&
            <div className='login-box'>
              <div className='txt-login'>LOGIN</div>
              <form ref={node => this.fromNode = node} noValidate autoComplete="off" onSubmit={(e) => { this.handleSubmit(e) }}>
                <div>
                  <div>
                    <TextField
                      required
                      id="email"
                      label="Username or Email"
                      type="text"
                      className='form-textfield'
                      variant="outlined"
                      placeholder="Username or Email"
                      value={user.workEmail}
                      name="workEmail"
                      error={formSubmit && formError['workEmail'] && (!formError['workEmail'].valid)}
                      onChange={(e) => { this.handleChange(e) }}
                    />
                  </div>
                  <div>
                    <TextField
                      required
                      id="password"
                      label="Password"
                      type="password"
                      autoComplete='off'
                      className='form-textfield secure'
                      margin="normal"
                      variant="outlined"
                      placeholder="Password"
                      value={user.password}
                      name="password"
                      error={formSubmit && formError['password'] && (!formError['password'].valid)}

                      onChange={(e) => { this.handleChange(e) }}
                    />
                  </div>

                  <div className='forgot-password-secction'>
                    <div className='txt-forgot' onClick={this.goToResetPassword}>Forgot your password?</div>
                  </div>


                  <div className='login-btn-conta'>
                    <Button htmlType="submit" className='btn-login'>LOGIN</Button>
                  </div>

                  <div>
                    <div className="a-divider a-divider-break"><span className='or-txt'>OR</span></div>

                    <Row>
                      <Col span={24} className="text-center">
                        <Button className='sso-btn'
                          onClick={() => this.setSSOLogin(true)}
                        >
                          Log in with SSO
                        </Button>
                      </Col>
                    </Row>
                  </div>

                </div>
              </form>
            </div>
          }
          {
            (newPasswordReq && !passwordSet) &&
            <div className='login-box'>
              <div className='txt-login'>Set New Password</div>
              <form ref={node => this.fromNode = node} noValidate autoComplete="off"
                onSubmit={(e) => { this.handleSetPassword(e) }}>
                <div>
                  <div>
                    <TextField
                      required
                      id="newPassword"
                      label="New Password"
                      type="password"
                      className='form-textfield'
                      variant="outlined"
                      placeholder="New Password"
                      value={user.newPassword}
                      name="newPassword"
                      error={formSubmit && formError['newPassword'] && (!formError['newPassword'].valid)}
                      onChange={(e) => { this.handleChange(e) }}
                    />
                  </div>
                  <div>
                    <TextField
                      required
                      id="confirmPassword"
                      label="Confirm Password"
                      type="password"
                      className='form-textfield'
                      margin="normal"
                      variant="outlined"
                      placeholder="Confirm Password"
                      value={user.confirmPassword}
                      name="confirmPassword"
                      error={formSubmit && formError['confirmPassword'] && (!formError['confirmPassword'].valid)}
                      helperText={pwdError}
                      onChange={(e) => { this.handleChange(e) }}
                    />
                  </div>
                  <div>
                    <Button htmlType="submit" className='btn-login'>LOGIN</Button>
                  </div>
                </div>
              </form>
            </div>
          }

          {
            (resetPasswordReq && !passwordSet) &&
            <div className='login-box'>
              <div className='txt-login'>Set New Password</div>
              <form ref={node => this.fromNode = node} noValidate autoComplete="off"
                onSubmit={(e) => { this.forgotPasswordSubmit(e) }}>
                <div>
                  <div>
                    <TextField
                      id="email"
                      label="Work Email"
                      type="text"
                      className='form-textfield'
                      variant="outlined"
                      placeholder="Work Email"
                      value={user.workEmail}
                      name="workEmail"
                      error={formSubmit && formError['workEmail'] && (!formError['workEmail'].valid)}
                      onChange={(e) => { this.handleChange(e) }}
                    />
                  </div>
                  <div>
                    <TextField
                      required
                      id="code"
                      label="Code"
                      type="text"
                      className='form-textfield'
                      margin="normal"
                      variant="outlined"
                      placeholder="Code"
                      value={user.code}
                      name="code"
                      error={formSubmit && formError['code'] && (!formError['code'].valid)}
                      onChange={(e) => { this.handleChange(e) }}
                    />
                  </div>
                  <div>
                    <TextField
                      required
                      id="newPassword"
                      label="New Password"
                      type="password"
                      className='form-textfield'
                      margin="normal"
                      variant="outlined"
                      placeholder="New Password"
                      value={user.newPassword}
                      name="newPassword"
                      error={formSubmit && formError['newPassword'] && (!formError['newPassword'].valid)}
                      helperText={pwdError}
                      onChange={(e) => { this.handleChange(e) }}
                    />
                  </div>

                  <div className="mg-top">
                    <Button htmlType="submit" className='btn-login'>LOGIN</Button>
                  </div>
                </div>
              </form>
            </div>
          }
          {
            (passwordSet) &&
            <div className='login-success-box'>
              <div className='success-txt-login'>
                Your password for Supervisor is reset successfully. Please login to Oloid Connect or Oloid Verify App to verify
              </div>
              <div className="successfull-icon-container">
                <div className="successfull-icon"></div>
                <Button type="primary" className='btn-ok' onClick={this.redirectLogin}>OK</Button>
              </div>

            </div>
          }
        </div>
        {/* } */}

        {/* {
          isSSOLogin &&
          <div className='sso-logins'>

            <div className='sso-logins-header'>Login option(s)</div>

            <GoogleLogin
              clientId={process.env.REACT_APP_Google_APP_CLIENT_ID}
              buttonText="Log in with Google"
              render={renderProps => (
                <div className='sso-login-box' onClick={renderProps.onClick}>
                  <div className='image-logo-container'>
                    <img className='login-icons' src={GoogleIcon} alt="GoogleIcon"></img>
                  </div>
                  <div className='sso-name'>Continue with Google</div>
                </div>
              )}
              onSuccess={this.handleLogin}
              onFailure={this.handleGoogleAuthFailure}
              cookiePolicy={'single_host_origin'}
            />

            <GoogleLogin
              onGoogleSignIn={credentialResponse => {
                console.log(credentialResponse);
                this.handleLogin(credentialResponse)
              }}
              onError={() => {
                console.log('Login Failed');
              }}

            />


            <AzureAuthenticationButton onAuthenticated={this.onAuthenticated} />

            <div className='sso-login-box' onClick={this.triggerLogin}>
              <div className='image-logo-container'>
                <img className='login-icons' src={oktaIcon} alt="GoogleIcon"></img>
              </div>
              <div className='sso-name'>Continue with Okta</div>
            </div>

            <div className='sso-login-box'>
              <div className='image-logo-container'>
                <img className='login-icons' src={oneLoginIcon} alt="oneLoginIcon"></img>
              </div>
              <div className='sso-name'>Continue with OneLogin</div>
            </div>

            <div className='a-divider a-divider-break'>
              <span className="or-txt">Or</span>
            </div>

            <div className='sso-login-box' onClick={() => this.setSSOLogin(false)}>
              <div className='image-logo-container'>
                <img className='login-icons' src={emailIcon} alt="emailIcon"></img>
              </div>
              <div className='sso-name'>Continue with Email</div>
            </div>
          </div>
        } */}

      </div>
    )
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user,
  }
}
export default connect(mapStateToProps)(Login);