import Okta from '../../../image/integrationsLogos/okta.png';
import AuthO from '../../../image/integrationsLogos/auth.png';
import ActiveDirectory from '../../../image/integrationsLogos/Active-Directory.jpg';
import GSuite from '../../../image/integrationsLogos/gsuite.jpg';
import Workday from '../../../image/integrationsLogos/Workday.png';
import Saml from '../../../image/integrationsLogos/saml.png';
import Csv from '../../../image/integrationsLogos/csv.png';
import Webhooks from '../../../image/integrationsLogos/webhooks.png';
import Api from '../../../image/integrationsLogos/api.png';
import Openpath from '../../../image/integrationsLogos/openpath.png';
import S2Security from '../../../image/integrationsLogos/s2-security.png';
import Wiegand from '../../../image/integrationsLogos/wiegand.png';
import Relay from '../../../image/integrationsLogos/relay.png';
import Flir from '../../../image/integrationsLogos/flir.png';
import HumanApi from '../../../image/integrationsLogos/huminApi.png';
import ReturnSafe from '../../../image/integrationsLogos/returnSafe.png';
import Kronos from '../../../image/integrationsLogos/kronos.png';
import Adp from '../../../image/integrationsLogos/adp.png';
import Workerly from '../../../image/integrationsLogos/workely.png';
import PrologicFirst from '../../../image/integrationsLogos/prologicFirst.png';
import TimeStation from '../../../image/integrationsLogos/timeStation.png';
import SmartSheet from '../../../image/integrationsLogos/smartsheet.png';
import Zapier from '../../../image/integrationsLogos/zapier.png';
import Sdk from '../../../image/integrationsLogos/sdk.png';
import Ezeep from '../../../image/integrationsLogos/ezeep.png';
import OloidWorkflow from '../../../image/integrationsLogos/oloidWorkloow.png';
import JavaScript from '../../../image/integrationsLogos/javaScript.png';
import RuleEngine from '../../../image/integrationsLogos/ruleEngian.png';

export const integrationImage = {
  'Okta': Okta,
  'AuthO': AuthO,
  'ActiveDirectory': ActiveDirectory,
  'GSuite': GSuite,
  'Workday': Workday,
  'Saml': Saml,
  'Csv': Csv,
  'Webhooks': Webhooks,
  'Api': Api,
  'Openpath': Openpath,
  'S2Security': S2Security,
  'Wiegand': Wiegand,
  'Relay': Relay,
  'Flir': Flir,
  'HumanApi': HumanApi,
  'ReturnSafe': ReturnSafe,
  'Kronos': Kronos,
  'Adp': Adp,
  'Workerly': Workerly,
  'PrologicFirst': PrologicFirst,
  'TimeStation': TimeStation,
  'SmartSheet': SmartSheet,
  'Zapier': Zapier,
  'Sdk': Sdk,
  'Ezeep': Ezeep,
  'OloidWorkflow': OloidWorkflow,
  'JavaScript': JavaScript,
  'RuleEngine': RuleEngine,
  // 'HTTPRequest': HTTPRequest,
}

export const integrations = [
  {
    IntegrationID: 'OloidWorkflow',
    IntegrationName: 'Oloid Workflow',
    Descriptions: 'Invoke Oloid Workflow Enabled Actions',
  },
  {
    IntegrationName: 'Email',
    Descriptions: 'Send Email Notification after Authentication',
    IntegrationID: 'Email',

  },
  {
    IntegrationID: 'Workerly',
    IntegrationName: 'Workerly',
    Descriptions: 'Workerly',
    IntegrationUrl: '',
    ApiKey: '',
    WorkerlyTenant: ''
  },
  {
    IntegrationID: 'Okta',
    IntegrationName: 'Okta',
    Descriptions: 'Okta',
  },
  {
    IntegrationID: 'AuthO',
    IntegrationName: 'AuthO',
    Descriptions: 'AuthO',
  },
  {
    IntegrationID: 'ActiveDirectory',
    IntegrationName: 'Active Directory',
    Descriptions: 'Active Directory',
  },

  {
    IntegrationID: 'GSuite',
    IntegrationName: 'GSuite',
    Descriptions: 'GSuite',
  },
  {
    IntegrationID: 'Workday',
    IntegrationName: 'Workday',
    Descriptions: 'Workday',
  },
  {
    IntegrationID: 'Saml',
    IntegrationName: 'Saml',
    Descriptions: 'Saml',
  },
  {
    IntegrationID: 'Csv',
    IntegrationName: 'Csv',
    Descriptions: 'Csv',
  },
  {
    IntegrationID: 'Webhooks',
    IntegrationName: 'Webhooks',
    Descriptions: 'Webhooks',
  },
  {
    IntegrationID: 'Api',
    IntegrationName: 'Api',
    Descriptions: 'Api',
  },
  {
    IntegrationID: 'Openpath',
    IntegrationName: 'Openpath',
    Descriptions: 'Openpath',
  },
  {
    IntegrationID: 'S2Security',
    IntegrationName: 'S2 Security',
    Descriptions: 'S2 Security',
  },
  {
    IntegrationID: 'Wiegand',
    IntegrationName: 'Wiegand',
    Descriptions: 'Wiegand',
  },
  {
    IntegrationID: 'Relay',
    IntegrationName: 'Relay',
    Descriptions: 'Relay',
  },
  {
    IntegrationID: 'Flir',
    IntegrationName: 'Flir',
    Descriptions: 'Flir',
  },
  {
    IntegrationID: 'HumanApi',
    IntegrationName: 'Human Api',
    Descriptions: 'Human Api',
  },
  {
    IntegrationID: 'ReturnSafe',
    IntegrationName: 'ReturnSafe',
    Descriptions: 'ReturnSafe',
  },
  {
    IntegrationID: 'Kronos',
    IntegrationName: 'Kronos',
    Descriptions: 'Kronos',
  },
  {
    IntegrationID: 'Adp',
    IntegrationName: 'Adp',
    Descriptions: 'Adp',
  },
  {
    IntegrationID: 'PrologicFirst',
    IntegrationName: 'Prologic First',
    Descriptions: 'Prologic First',
  },
  {
    IntegrationID: 'TimeStation',
    IntegrationName: 'Time Station',
    Descriptions: 'Time Station',
  },
  {
    IntegrationID: 'SmartSheet',
    IntegrationName: 'Smart Sheet',
    Descriptions: 'Smart Sheet',
  },
  {
    IntegrationID: 'Zapier',
    IntegrationName: 'Zapier',
    Descriptions: 'Zapier',
  },
  {
    IntegrationID: 'Sdk',
    IntegrationName: 'Sdk',
    Descriptions: 'Sdk',
  },
  {
    IntegrationID: 'Ezeep',
    IntegrationName: 'Ezeep',
    Descriptions: 'Ezeep',
  },

  {
    IntegrationID: 'JavaScript',
    IntegrationName: 'JavaScript',
    Descriptions: 'JavaScript',
  },
  {
    IntegrationID: 'RuleEngine',
    IntegrationName: 'Rule Engine',
    Descriptions: 'Rule Engine',
  },
  {
    IntegrationID: 'HTTPRequest',
    IntegrationName: 'HTTP Request',
    Descriptions: 'HTTP Request',
    TimeZone: "America/New_York",
    FetchSecret: true,
    UseAuth: true,
  },
  {
    IntegrationID: 'KronosCloud',
    IntegrationName: 'Kronos Cloud',
    Descriptions: 'Kronos Cloud',
  },
  {
    IntegrationID: 'Sql',
    IntegrationName: 'Sql',
    Descriptions: 'Sql',
  },
  {
    IntegrationID: 'Paycheck',
    IntegrationName: 'Paycheck',
    Descriptions: 'Paycheck',
    Auth: "Body",
    TimeZone: "America/New_York",
    FetchSecret: true,
    IsOfflinePunch: true,
    AvoidDuplicates: true,
    PunchGraceMins: 1,
  },
  {
    IntegrationID: 'Dayforce',
    IntegrationName: 'Dayforce',
    Descriptions: 'Dayforce',
    PunchDevice: "API",
    PunchGraceMins: 1,
    TimeZone: "America/New_York",
    FetchSecret: true,
    AvoidDuplicates: true,
    Auth: "Bearer"
  }

]
export const ApplicationTypes = [
  "Generic"
]