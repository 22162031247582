/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import clone from 'clone';
import { Link } from 'react-router-dom';
import { Layout } from 'antd';
import options from './options';
import Scrollbars from '../utility/customScrollBar.js';
import { Menu } from 'antd';
import SideFixedBar from '../SideFixedBar/sideFixedBar.js';
import SidebarWrapper from './sidebar.style';
import appActions from '../action/user.actions';
import Logo from '../logo';
import _ from 'lodash';
import { getAllIntegrationsSettings } from '../../../modules/transactions/action/transactions.actions';
import { getAllConnection } from '../../../modules/connections/action/connection.actions';
import { fetchAllLocation } from '../../../modules/location/action/location.actions';
import { getAllDashboard } from '../../../modules/dashboard/action/dashboard.actions'

const SubMenu = Menu.SubMenu;
const { Sider } = Layout;

const {
  toggleOpenDrawer,
  changeOpenKeys,
  changeCurrent,
  toggleCollapsed,
} = appActions;
const stripTrailingSlash = str => {
  if (str.substr(-1) === '/') {
    return str.substr(0, str.length - 1);
  }
  return str;
};

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.onOpenChange = this.onOpenChange.bind(this);
  }

  componentDidMount() {
    this.getIntegrations();
    this.getConnections();
    this.getLocations();
    this.getDashboards();
  }

  getIntegrations() {
    const { getAllIntegrationsSettings } = this.props;
    //getAllIntegrationsSettings();
  }

  getConnections() {
    const { getAllConnection } = this.props;
    getAllConnection();
  }

  getLocations() {
    const { fetchAllLocation } = this.props;
    fetchAllLocation();
  }
  getDashboards() {
    const { getAllDashboard } = this.props;
    //getAllDashboard();
  }

  handleClick(e) {
    this.props.changeCurrent([e.key]);
    if (this.props.app.view === 'MobileView') {
      setTimeout(() => {
        this.props.toggleCollapsed();
        this.props.toggleOpenDrawer();
      }, 100);
    }
  }
  onOpenChange(newOpenKeys) {
    const { app, changeOpenKeys } = this.props;
    const latestOpenKey = newOpenKeys.find(
      key => !(app.openKeys.indexOf(key) > -1)
    );
    const latestCloseKey = app.openKeys.find(
      key => !(newOpenKeys.indexOf(key) > -1)
    );
    let nextOpenKeys = [];
    if (latestOpenKey) {
      nextOpenKeys = this.getAncestorKeys(latestOpenKey).concat(latestOpenKey);
    }
    if (latestCloseKey) {
      nextOpenKeys = this.getAncestorKeys(latestCloseKey);
    }
    changeOpenKeys(nextOpenKeys);
  }
  getAncestorKeys = key => {
    const map = {
      sub3: ['sub2'],
    };
    return map[key] || [];
  };
  getMenuItem = ({ singleOption, submenuStyle, submenuColor }) => {
    const { key, label, leftIcon, children, urlPath } = singleOption;
    const url = stripTrailingSlash(this.props.url);
    if (children) {
      return (
        <SubMenu
          key={key}
          title={
            <span className="isoMenuHolder isoSubMenuHolder" style={submenuColor}>
              {leftIcon && <i className={leftIcon} />}
              <span className="nav-text">
                {label}
              </span>
            </span>
          }
        >
          {children.map(child => {
            const linkTo = child.withParent
              ? `${url}/${urlPath}/${child.urlPath}`
              : `${url}/${child.urlPath}`;
            return (
              <Menu.Item style={submenuStyle} key={child.key}>
                <Link style={submenuColor} to={linkTo}>
                  <span className="isoMenuHolder" style={submenuColor}>
                    {child.leftIcon && <i className={child.leftIcon} />}
                    <span className="nav-text">
                      {child.label}
                    </span>
                  </span>
                </Link>
              </Menu.Item>
            );
          })}
        </SubMenu>
      );
    }
    return (
      <Menu.Item key={key}>
        <Link to={`${url}/${key}`}>
          <span className="isoMenuHolder" style={submenuColor}>
            <i className={leftIcon}></i>
            <span className="nav-text">
              {label}
            </span>
            {(key === 'templates') ? <div className="menu-counter">25</div> : <div></div>}
          </span>
        </Link>
      </Menu.Item>
    );
  };
  render() {
    const { app, toggleOpenDrawer, allConnections, allLocation, allDashboard,
      allIntegrationsSetting, customizedTheme, height, menuId, user } = this.props;
    const collapsed = clone(app.collapsed) && !clone(app.openDrawer);
    const { openDrawer } = app;
    const mode = collapsed === true ? 'vertical' : 'inline';
    const onMouseEnter = () => {
      if (openDrawer === false) {
        toggleOpenDrawer();
      }
      return;
    };
    const onMouseLeave = () => {
      if (openDrawer === true) {
        toggleOpenDrawer();
      }
      return;
    };
    const styling = {
      backgroundColor: customizedTheme.backgroundColor,
    };
    const submenuStyle = {
      backgroundColor: 'rgba(0,0,0,0.3)',
      color: customizedTheme.textColor,
    };
    const submenuColor = {
      color: customizedTheme.textColor,
    };

    let emailDomain = user.email && user.email.split('@');

    const menuOptions = JSON.parse(JSON.stringify(options));

    let menus = menuOptions.filter(function (res) { return res.menuid === menuId });
    menus = menus.filter(function (res) {
      if (res.access) {
        return res.isRead && res.access.indexOf((emailDomain && emailDomain[1])) > -1;
      }
      else {
        return res.isRead;
      }
    });
    menus = menus.map(menu => {
      if (menu.children) {
        menu.children = menu.children.filter(function (res) {
          return res.isRead;
        })
      }
      return menu
    })

    menus = JSON.parse(JSON.stringify(menus));

    let index = menus.findIndex((res) => { return res.key === 'integrations' });
    if (index > -1) {
      allIntegrationsSetting.map(menu => {
        if (menu.IsEnabled) {
          menus[index].children.push({
            key: menu.IntegrationID,
            urlPath: `action/${menu.IntegrationID}`,
            withParent: true,
            label: menu.DisplayName,
          });
        }
        return menu;
      })
    }

    index = menus.findIndex((res) => { return res.key === 'similarfaces' });
    if (index > -1) {
      allConnections.map(menu => {
        menus[index].children.push({
          key: menu.ConnectionID,
          urlPath: `${menu.ConnectionID}`,
          withParent: true,
          label: menu.ConnectionDisplayName,
        });
        return menu;
      })
    }

    if (menuId == 3) {
      allLocation.map((menu, index) => {
        if (menu.ShowInNavigation) {
          let locMenu = {
            menuid: 3,
            label: menu.LocationName,
            urlPath: "endloc",
            key: `endloc-${index}`,
            leftIcon: "apiBuldingbuttonBg",
            children: [
              {
                key: `${menu.LocationID}/All`,
                urlPath: `${menu.LocationID}/All`,
                withParent: true,
                label: `All`,
              }
            ]
          }
          menu.Buildings && menu.Buildings.map(bulding => {
            locMenu.children.push({
              key: `${menu.LocationID}/${bulding.BuildingID}`,
              urlPath: `${menu.LocationID}/${bulding.BuildingID}`,
              withParent: true,
              label: `${bulding.BuildingName}`,
            });
            return bulding;
          })
          menus.push(locMenu);
        }
        return menu;
      })
    }

    index = menus.findIndex((res) => { return res.key === 'dashboard' });
    if (index > -1) {
      allDashboard.map(menu => {
        menus[index].children.push({
          key: menu.DashboardID,
          urlPath: `${menu.DashboardID}`,
          withParent: true,
          label: menu.DashboardName,
        });
        return menu;
      })
    }

    const { location } = this.props;
    const paths = _.split(location.pathname, '/');

    let currentItem = _.nth(paths, 1);
    let selectedItem = _.last(paths)
    if (location.pathname.indexOf('detail') > -1) {
      selectedItem = _.nth(paths, paths.length - 3);
    }

    return (
      <SidebarWrapper>
        <SideFixedBar  {...this.props} />
        <Sider
          trigger={null}
          collapsible={true}
          collapsed={app.collapsed}
          width="240"
          className="platformSidebar"
          // onMouseEnter={onMouseEnter}
          // onMouseLeave={onMouseLeave}
          style={styling}
        >
          <Logo collapsed={app.collapsed} menuId={menuId} />
          <Scrollbars style={{ height: height - 70 }}>
            <Menu
              onClick={this.handleClick}
              // theme="dark"
              className="isoDashboardMenu"
              mode={mode}
              // openKeys={collapsed ? [] : app.openKeys}
              selectedKeys={[selectedItem]}
              defaultOpenKeys={[currentItem]}
              onOpenChange={this.onOpenChange}
            >
              {menus.map(singleOption =>
                this.getMenuItem({ submenuStyle, submenuColor, singleOption })
              )}
            </Menu>
          </Scrollbars>
        </Sider>
      </SidebarWrapper>
    );
  }
}

export default connect(
  state => ({
    app: state.APP.App.toJS(),
    customizedTheme: 'themedefault',
    height: state.APP.App.toJS().height,
    menuId: state.fixSideBar.fixSideBarReducer.menuId,
    allIntegrationsSetting: state.transactions.allIntegrationsSetting,
    user: state.authentication.user,
    allConnections: state.connection.allConnections,
    allLocation: state.place.allLocation,
    allDashboard: state.dashboard.allDashboard
  }),
  {
    toggleOpenDrawer,
    changeOpenKeys,
    changeCurrent,
    toggleCollapsed,
    getAllIntegrationsSettings,
    getAllConnection,
    fetchAllLocation,
    getAllDashboard
  }
)(Sidebar);
