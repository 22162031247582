import { endpointTemplatesConstants } from '../constants';
import { endpointTemplatesService } from '../service';
import { endpointsService } from '../../endpoint/service';
import _ from 'lodash';

export const fetchEndpointsSuccess = response => ({
  type: endpointTemplatesConstants.GET_ALL_ENDPOINTS_TEMPLATES_SUCCESS,
  payload: response
})

export const copyEndpointsSuccess = response => ({
  type: endpointTemplatesConstants.GET_ALL_ENDPOINTS_TEMPLATES_COPY_SUCCESS,
  payload: response
})

export const fetchEndpointsFailure = response => ({
  type: endpointTemplatesConstants.GET_ALL_ENDPOINTS_TEMPLATES_FAILURE,
  payload: []
})

export const getAllEndpointTemplates = () => {
  return dispatch => {
    return endpointTemplatesService.getAllEndpointTemplates()
      .then(response => {
        let endpoints = (response.data) || [];
        endpoints = _.orderBy(endpoints, ['UpdatedAt'], ['desc']);
        dispatch(fetchEndpointsSuccess(endpoints));
        dispatch(copyEndpointsSuccess(endpoints));
        return response;
      },
        error => {
          dispatch(fetchEndpointsFailure(error));
          return error;
        })
  }
}



export const filterEndpoints = (filter) => {
  return (dispatch, state) => {
    const data = state().endpointTemplates.allCopyTemplates
    const allCopyTemplates = JSON.parse(JSON.stringify(data));
    let filtered = allCopyTemplates || [];
    if (filter.search) {
      filtered = _.filter(filtered, (res) => {
        return res.EndpointTemplateName && (
          res.EndpointTemplateName.toLowerCase().indexOf(filter.search.toLowerCase()) !== -1
          || res.EndpointTemplateID.toLowerCase().indexOf(filter.search.toLowerCase()) !== -1);
      })
    }
    if (filter.appType) {
      filtered = _.filter(filtered, (res) => {
        return res.Endpoint.ApplicationType && res.Endpoint.ApplicationType.toLowerCase() === filter.appType.toLowerCase();
      })
    }
    if (filter.endpointType) {
      filtered = _.filter(filtered, (res) => {
        return res.Endpoint.EndpointType && res.Endpoint.EndpointType.toLowerCase() === filter.endpointType.toLowerCase();
      })
    }
    dispatch(updateFilter(filter))
    dispatch(fetchEndpointsSuccess(filtered));
  }
}

export const fetchEndpointsDetailSuccess = response => ({
  type: endpointTemplatesConstants.GET_ENDPOINTS_TEMPLATES_DETAIL_SUCCESS,
  payload: response
})

export const fetchEndpointsDetailFailure = response => ({
  type: endpointTemplatesConstants.GET_ENDPOINTS_TEMPLATES_DETAIL_FAILURE,
  payload: {}
})

export const getEndpointTemplateById = (id) => {
  return dispatch => {
    return endpointTemplatesService.getEndpointTemplateById(id)
      .then(response => {
        let template = (response.data && response.data) || {};
        dispatch(fetchEndpointsDetailSuccess(template));

        let IntegrationUrls = [];
        if (template.Endpoint && template.Endpoint.IntegrationUrls) {
          for (const key in template.Endpoint.IntegrationUrls) {
            if (template.Endpoint.IntegrationUrls.hasOwnProperty(key)) {
              const element = template.Endpoint.IntegrationUrls[key];
              IntegrationUrls.push({
                Integration: key,
                Url: element
              })
            }
          }
        }

        dispatch(getEndpointConfigure(IntegrationUrls));
        return template;
      },
        error => {
          dispatch(fetchEndpointsDetailFailure(error));
          return error;
        })
  }
}


export const fetchConfigureTabSuccess = response => ({
  type: endpointTemplatesConstants.GET_ALL_ENDPOINT_CONFIGURE_TAB_SUCCESS,
  payload: response
})

export const getEndpointConfigure = (response) => {
  return dispatch => {
    dispatch(fetchConfigureTabSuccess(response));
  }
}




export const updateEndpointTemplate = (data) => {
  return dispatch => {
    return endpointTemplatesService.updateEndpointTemplate(data)
      .then(response => {
        return dispatch(getEndpointTemplateById(data.EndpointTemplateID));
      },
        error => {
          error.error = true;
          return error;
        })
  }
}


export const createEndpointTemplate = (data) => {
  return dispatch => {
    return endpointTemplatesService.createEndpointTemplate(data)
      .then(response => {
        return dispatch(getAllEndpointTemplates());
      },
        error => {
          error.error = true;
          return error;
        })
  }
}

export const fetchIntegrationSuccess = response => ({
  type: endpointTemplatesConstants.GET_ALL_INTEGRATION_SUCCESS,
  payload: response
})

export const fetchIntegrationFailure = response => ({
  type: endpointTemplatesConstants.GET_ALL_INTEGRATION_FAILURE,
  payload: []
})

export const deleteEndpointTemplateById = (id, reason) => {
  return dispatch => {
    return endpointTemplatesService.deleteEndpointTemplateById(id, reason)
      .then(response => {
        dispatch(getAllEndpointTemplates());
        return response;
      },
        error => {
          error.error = true;
          return error;
        })
  }
}

export const getAllIntegrations = () => {
  return dispatch => {
    return endpointsService.getAllIntegrations()
      .then(response => {
        dispatch(fetchIntegrationSuccess(response));
        return response;
      },
        error => {
          dispatch(fetchIntegrationFailure(error));
          return error;
        })
  }
}

export const updateFilter = payload => ({
  type: endpointTemplatesConstants.FILTER_UPDATE,
  payload: payload
})

export const updatePagination = payload => ({
  type: endpointTemplatesConstants.PAGINATE_UPDATE,
  payload: payload
})