/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import { Modal, Button } from 'antd';
import { TextField, Select, MenuItem, InputLabel, FormControl, FormHelperText } from '@material-ui/core';
import { connect } from 'react-redux';
import "../../application/component/createApplication.scss";
import "../../connections/component/createConnection.scss";
import "../../tenant/component/createTenant.scss";
import { createEndpointTemplate } from '../action/endpointTemplates.actions';
import { EndpointTypes } from '../../endpoint/service/constant';
import { APPLICATION_TYPE, orderedListData, SelectMenuProps, successMessage, tostor } from '../../../helpers';

class AddEndpointTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      template: {
        EndpointTemplateName: '',
        ApplicationType: '',
        EndpointType: ''
      },
      error: {},
      isSubmit: false
    }
  }

  handleOk = () => {
    if (this.validateForm()) {
      const { dispatch } = this.props;
      const template = this.state.template;
      var reqObj = {
        EndpointTemplateName: template.EndpointTemplateName,
        Endpoint: {
          ApplicationType: template.ApplicationType,
          EndpointType: template.EndpointType
        }
      }
      dispatch(createEndpointTemplate(reqObj))
        .then((res) => {
          if (res.error) {
            tostor.error(res.message);
            return;
          }
          tostor.success(successMessage.TEMPLATE_CREATE);
          this.resetFrom();
          this.props.toggle()
        });
    }
  };

  resetFrom = () => {
    this.setState({
      template: {
        EndpointTemplateName: '',
        ApplicationType: '',
        EndpointType: ''
      },
      error: {},
      isSubmit: false
    })
  }

  handleCancel = () => {
    this.resetFrom();
    this.props.toggle()
  };

  validateForm = () => {
    let error = {};
    const { template } = this.state;
    if (!template.EndpointTemplateName) {
      error.EndpointTemplateName = true;
    }
    if (!template.ApplicationType) {
      error.ApplicationType = true;
    }
    if (!template.EndpointType) {
      error.EndpointType = true;
    }
    this.setState({ error })
    return Object.keys(error).length == 0;
  }

  handleInput = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    const { template, error } = Object.assign({}, this.state)

    if (name == 'EndpointTemplateName') {
      var letters = /^[A-Za-z0-9-_# ]+$/;
      if (value.match(letters) || e.target.value == '') {
        template[name] = value.replace(/\s{2,}/g, ' ');
        error[name] = false
      }
    } else {
      template[name] = value;
      error[name] = false
    }

    error[name] = false
    this.setState({ template, error })
  }

  render() {
    const { error, isSubmit, template } = this.state;
    return (
      <div>
        <Modal
          className="create-application"
          visible={this.props.modal}
          onCancel={this.handleCancel}
          footer={[
            <div className="footer-box">
              <Button className="create-btn" key="back" type="primary" onClick={this.handleOk}>
                CREATE
              </Button>
              <Button className="cancle-btn" key="submit" onClick={this.handleCancel}>
                CANCEL
              </Button>
            </div>
          ]}
        >
          <div className='txt-header'>Create a New Template</div>
          <div className='position-rel'>
            <TextField
              error={error.EndpointTemplateName}
              name="EndpointTemplateName"
              label="Template Name"
              type="text"
              className='tenant-form-textfield'
              margin="normal"
              variant="outlined"
              value={template.EndpointTemplateName}
              helperText={error.EndpointTemplateName ? 'Required' : ''}
              onChange={(e) => this.handleInput(e)}
            />
            {isSubmit && !error.EndpointTemplateName && <div className='success-icon position'></div>}
            {error.EndpointTemplateName && <div className='success-icon position error'></div>}
          </div>

          <div className='position-rel'>
            <FormControl variant="outlined" className='region-select' error={error.ApplicationType}>
              <InputLabel id="demo-simple-select-outlined-label">
                Application Type
              </InputLabel>
              <Select
                labelId="ApplicationType"
                id="demo-simple-select-outlined"
                name="ApplicationType"
                onChange={(e) => this.handleInput(e)}
                value={template.ApplicationType}
                placeholder="Application Type"
                MenuProps={SelectMenuProps}
              >
                {APPLICATION_TYPE && orderedListData(APPLICATION_TYPE || [], 'label').map((item) => {
                  return (
                    <MenuItem key={item.value} value={item?.value}>{item.label}</MenuItem>
                  )
                })}
              </Select>
              {error.ApplicationType && <FormHelperText>Required</FormHelperText>}
            </FormControl>
            {isSubmit && !error.ApplicationType && <div className='success-icon position'></div>}
            {error.ApplicationType && <div className='success-icon position error'></div>}
          </div>

          <div className='position-rel'>
            <FormControl variant="outlined" className='region-select' error={error.EndpointType}>
              <InputLabel id="demo-simple-select-outlined-label">
                Endpoint Type
              </InputLabel>
              <Select
                labelId="EndpointType"
                id="demo-simple-select-outlined"
                name="EndpointType"
                onChange={(e) => this.handleInput(e)}
                value={template.EndpointType}
                placeholder="Endpoint Type"
                MenuProps={SelectMenuProps}
              >
                {
                  EndpointTypes && orderedListData(EndpointTypes, null).map((type, index) => {
                    return <MenuItem key={index} value={type}>{type}</MenuItem>
                  })
                }
              </Select>
              {error.EndpointType && <FormHelperText>Required</FormHelperText>}
            </FormControl>
            {isSubmit && !error.EndpointType && <div className='success-icon position'></div>}
            {error.EndpointType && <div className='success-icon position error'></div>}
          </div>

        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user
  }
}
export default connect(mapStateToProps)(AddEndpointTemplate);