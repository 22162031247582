import React, { Component } from 'react';
import "../../application/component/createApplication.scss";
import "../../connections/component/createConnection.scss";
import { fetchAllLocation } from '../../location/action/location.actions'
import "../../tenant/component/createTenant.scss";
import { saveTimeRule } from '../action/timeInterpretation.actions'
import { Modal, Button, Select, message } from 'antd';
import './timeInterpretation.scss'
import { TextField } from '@material-ui/core';
import { connect } from 'react-redux';
import { orderedListData, RuleTypes, successMessage, tostor } from '../../../helpers';
const Option = Select.Option;
/* eslint eqeqeq: 0 */
export class CreateRule extends Component {
    constructor(props) {
        super(props);
        this.state = {
            timeRule: {
                Location: '',
                Name: '',
                RuleType: ''
            },
            error: {},
            isSubmit: false
        }
    }

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(fetchAllLocation())
    }

    handleOk = () => {
        this.setState({ isSubmit: true });
        const { dispatch } = this.props;
        if (this.validateForm()) {
            const timeRule = this.state.timeRule;
            const reqObj = {
                Name: timeRule.Name,
                Location: timeRule.Location || "",
                RuleType: timeRule.RuleType
            }
            dispatch(saveTimeRule(reqObj))
                .then((res) => {
                    if (res) {
                        tostor.success(successMessage.TIME_RULE_CREATE);
                        this.resetFrom();
                    }
                });
        }
    };

    validateForm = () => {
        const { timeRule } = this.state;
        let error = {};
        if (!timeRule.Name) {
            error.Name = true;
        }
        if (!timeRule.Location) {
            error.Location = true;
        }
        if (!timeRule.RuleType) {
            error.RuleType = true;
        }
        this.setState({ error })
        return Object.keys(error).length === 0;
    }

    handleInput = (e) => {
        this.setState({ isSubmit: false })
        const name = e.target.name;
        const value = e.target.value;
        const { timeRule, error } = Object.assign({}, this.state)
        error[name] = false
        timeRule[name] = value;
        this.setState({ timeRule, error })
    }

    resetFrom = () => {
        this.setState({
            timeRule: {
                Location: '',
                Name: '',
                RuleType: ''
            },
            error: {},
            isSubmit: false
        })
        this.props.toggle()
    };

    handleDropdownChange = (value, name) => {
        const { timeRule, error } = Object.assign({}, this.state)
        error[name] = false
        timeRule[name] = value
        this.setState({ timeRule, error })
    }

    render() {
        const { error, timeRule } = this.state;
        const { allLocation } = this.props
        const location = (timeRule.Location != "" && timeRule.Location) || undefined
        return (
            <div>
                <Modal
                    className="create-rule"
                    visible={this.props.modal}
                    onCancel={this.resetFrom}
                    footer={[
                        <div className="footer-box">
                            <Button className="create-btn" key="back" type="primary" onClick={this.handleOk}>
                                CREATE
                            </Button>
                            <Button className="cancle-btn" key="submit" onClick={this.resetFrom}>
                                CANCEL
                            </Button>
                        </div>
                    ]}
                >
                    <div className='txt-header'>Create a Time Interpretation Rule</div>
                    <div className='position-rel'>
                        <TextField
                            name="Name"
                            label="Rule Name"
                            type="text"
                            className='tenant-form-textfield'
                            margin="normal"
                            variant="outlined"
                            value={timeRule.Name}
                            required
                            helperText={error.Name ? 'Required' : ''}
                            error={error.Name}
                            onChange={(e) => this.handleInput(e)}
                        />
                    </div>
                    <div className='position-rel'>
                        <Select
                            required
                            placeholder="Rule Type"
                            name="ruleType"
                            id="ruleType"
                            value={timeRule.RuleType || undefined}
                            className={`select-input ${error.RuleType ? 'error' : ''}`}
                            onChange={(value) => this.handleDropdownChange(value, "RuleType")}
                        >
                            {orderedListData(RuleTypes||[], 'lable').map((item)=>{
                                return <Option key={item.value} value={item.value}>{item.lable}</Option>    
                            })}
                        </Select>
                        <span className="helper-text">{error.RuleType ? 'Required' : ''}</span>
                    </div>
                    <div className='position-rel'>
                        <Select
                            placeholder="Location"
                            name="location"
                            id="location"
                            className={`select-input`}
                            value={location}
                            onChange={(value) => this.handleDropdownChange(value, "Location")}
                        >
                            {
                                orderedListData(allLocation||[], 'LocationName').map(location => {
                                    return <Option key={location.LocationID} value={location.LocationID}>{location.LocationName}</Option>
                                })
                            }
                        </Select>
                    </div>

                </Modal>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { user } = state.authentication;
    const { allLocation } = state.place;
    return {
        user,
        allLocation
    }
}
export default connect(mapStateToProps)(CreateRule);