import React, { Component } from 'react';
import axios from 'axios';
import _ from "lodash";
import { connect } from 'react-redux';
import { Button } from 'antd';
import TextField from "@material-ui/core/TextField";
import logo from '../../../image/Logo-Horizontal.png';
import { fromValidate, successMessage, tostor, ValidateEmail } from '../../../helpers'
import { forgotPassword } from '../action/user.actions'

import { apiEndpoint } from '../../../services/endpoint';
import Amplify from 'aws-amplify';
import { history } from '../../../helpers';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import './login.scss';
const hideShow = (flag) => {
	if (document.getElementById("ui_block")) {
		document.getElementById("ui_block").style.display = flag;
	}
}

class ResetPassword extends Component {
	constructor(props) {
		super(props);
		this.state = {
			formError: {},
			formSubmit: false,
			user: {},
			userNumber: '****7890',
			isMailSent: false
		}
	}


	componentDidMount() {
		//this.getUserPoolId();
	}

	getSubdomain = () => {
		var full = window.location.hostname;
		var domain, onlySubDomain, parts, subDomain;
		domain = process.env['REACT_APP_HOST_NAME'] || "";
		onlySubDomain = full.replace(domain, "")
		parts = onlySubDomain.split('.')
		_.remove(parts, (res) => { return ["", 'www', 'admin', 'api', 'dev', 'prod', 'ui'].indexOf(res) > -1 });
		if (parts.length > 0) {
			subDomain = parts[0];
		}
		return subDomain;
	}

	getUserPoolId = () => {
		const subDomain = this.getSubdomain();
		axios({ url: `${apiEndpoint}/tenants/subdomain/${subDomain}`, isLoading: true })
			.then(res => {
				let tenant = (res.data && res.data.result) || {};
				Amplify.configure({
					Auth: {
						region: tenant.Region,
						userPoolId: tenant.PoolID,
						userPoolWebClientId: tenant.AppClientID,
					}
				});
			})
	}

	postInputChange = (user) => {
		this.setState({ user });
		if (this.state.formSubmit) {
			this.setState({ formError: {} })
			const formValidation = fromValidate(this.fromNode);
			if (!formValidation.valid) {
				this.setState({ formError: formValidation.invalidationEle })
			}
		}
	}

	handlePhoneChange = (phone, key) => {
		let user = {
			...this.state.user
		}
		user[key] = phone;
		user['workEmail'] = ''
		this.postInputChange(user)
	}

	handleChange = (e) => {
		const user = Object.assign({}, this.state.user)
		const name = e.target.name;
		const value = e.target.value;
		user[name] = value;
		user['userNumber'] = ''
		this.postInputChange(user)
	}


	handleSubmit = (e) => {
		e.preventDefault();
		const { dispatch } = this.props;
		let { user } = this.state;
		this.setState({ formSubmit: true, formError: {} })
		let error = {};
		if (!user.workEmail && !user.userNumber) {
			error = { ...error, workEmail: { required: false } }
		}
		if (user.workEmail && !ValidateEmail(user.workEmail)) {
			error = { ...error, workEmail: { valid: false } }
		}
		if (user.userNumber && !isValidPhoneNumber(user.userNumber)) {
			error = { ...error, userNumber: { valid: false } }
		}
		if (Object.keys(error).length > 0) {
			this.setState({ formError: error });
			return;
		}
		const subDomain = this.getSubdomain();

		hideShow('flex');
		dispatch(forgotPassword((user.workEmail || user.userNumber), subDomain))
			.then((res) => {
				hideShow('none');
				if (res.error) {
					tostor.error('Something went wrong Please try again');
					return;
				}
				// history.push('/login?resetPassword=true');
				tostor.success(successMessage.VERIFICATION_EMAIL);
				this.setState({ isMailSent: true })
			});

	}

	redirectLogin = () => {
		history.push('/login');
	}

	render() {
		const { user, formError, isMailSent } = this.state;
		return (
			<div className='login-container'>
				<div className='oloid-box'>
					<img src={logo} alt='logo'></img>
				</div>

				{!isMailSent && <div className='login-box'>
					<div className='txt-login'>RESET PASSWORD</div>
					<div className='txt-desc mg-bottom-10'>Enter your email/mobile number below and we will send you reset password instructions</div>
					<form ref={node => this.fromNode = node} noValidate autoComplete="off" onSubmit={(e) => { this.handleSubmit(e) }}>
						<div>
							<div>
								<label>Email ID</label>
								<TextField
									inputProps={{
										readOnly: false,
									}}
									role="presentation" 
									autocomplete="off" 
									type="email"
									className='form-textfield no-margin'
									margin="normal"
									variant="outlined"
									placeholder="Work Email"
									name="workEmail"
									value={user.workEmail}
									onChange={(e) => { this.handleChange(e) }}
									error={formError['workEmail'] && (!formError['workEmail'].valid)}
									helperText={formError['workEmail'] && (!formError['workEmail'].valid) ? 'Invalid Email' : ''}
								/>
							</div>
							<div className='seprator-row'>
								<span>OR</span>
							</div>
							<div>
								<label>Phone Number</label>
								<PhoneInput
									placeholder="Enter phone number"
									className='form-textfield'
									name="userNumber"
									country="US"
									defaultCountry="US"
									value={user.userNumber}
									onChange={(value) => { this.handlePhoneChange(value, 'userNumber') }}
								/>
								<div className="error-msg">
									{formError['userNumber'] && (!formError['userNumber'].valid) ? 'Enter valid Phone' : ''}
								</div>
							</div>
							<div>
								<Button htmlType="submit" className='btn-login mg-top-10'>RESET</Button>
							</div>
						</div>
					</form>
				</div>}

				{
					isMailSent &&
					<div className='login-success-box'>
						<div className='success-txt-login'>
							A verification email has been sent to your mailbox.
						</div>
						<div className="successfull-icon-container">
							<div className="successfull-icon"></div>
							<Button type="primary" className='btn-ok' onClick={this.redirectLogin}>OK</Button>
						</div>
					</div>
				}

			</div>
		)
	}
}

function mapStateToProps(state) {
	const { user } = state.authentication;
	return {
		user,
	}
}
export default connect(mapStateToProps)(ResetPassword);