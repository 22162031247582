import { requestGet, requestPost } from '../../../services/requests';

/**
 * Supports all operations exposed by the user controller.
 */

/* eslint eqeqeq: 0 */
export class ActivityLogService {

  constructor(endpoint) {
    this.endpoint = endpoint;
  }

  getAllActivityLog(filter) {
    filter = filter || {};
    filter.limit = 10;
    const url = this.operationUrl('/filterActivity');
    return requestPost(url, filter, false);
  };

  getActivityLogById(param) {
    const url = this.operationUrl('/activityEvent');
    return requestGet(url, param);
  };

  operationUrl(append) {
    return this.endpoint + append;
  }
}
