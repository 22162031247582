import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Button, message, Select, Row, Col } from 'antd';
import _ from 'lodash';
import UsersReport from '../../downloads/component/downloadsUsersDetail'

/* eslint eqeqeq: 0 */
export class UsersReportModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    }
  }

  handleCancel = () => {
    this.props.toggle()
  };

  render() {
    const { showModal } = this.props;
    return (
      <div>
        <Modal
          maskClosable={false}
          visible={showModal}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={false}
        >
          <div className='txt-header'>Users Report</div>
          <UsersReport isAdHocReport onCloseUserModal={this.handleCancel} />
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  const { selectedReportType } = state.report
  return {
    user,
    selectedReportType
  }
}
export default connect(mapStateToProps)(UsersReportModal);