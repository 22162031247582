import { connectionConstants } from '../constants';
import { combineReducers } from 'redux'
import createReducer from '../../../helpers/createReducer';
const defaultFilter = {};
const defaultPaging = 1;

const connectionReducer = createReducer([])({
  [connectionConstants.GET_ALL_CONNECTION_SUCCESS]: (state, action) => action.payload,
  [connectionConstants.GET_ALL_CONNECTION_FAILURE]: (state, action) => action.payload,
})

const copCconnectionReducer = createReducer([])({
  [connectionConstants.GET_ALL_CONNECTION_COPY_SUCCESS]: (state, action) => action.payload
})


const connectionDetailReducer = createReducer({})({
  [connectionConstants.GET_CONNECTION_DETAIL_SUCCESS]: (state, action) => action.payload,
  [connectionConstants.GET_CONNECTION_DETAIL_FAILURE]: (state, action) => action.payload,
})

const filterReducer = createReducer(defaultFilter)({
  [connectionConstants.FILTER_UPDATE]: (state, action) => action.payload || {},
})

const pagingReducer = createReducer(defaultPaging)({
  [connectionConstants.PAGINATE_UPDATE]: (state, action) => action.payload || 1,
})


export default combineReducers({
  allConnections: connectionReducer,
  allCopyConnections: copCconnectionReducer,
  connectionDetail: connectionDetailReducer,
  _filterReducer: filterReducer,
  _pagingReducer: pagingReducer
})