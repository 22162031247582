export const tenantConstants = {
    GET_ALL_TENANT_SUCCESS: 'app/tenant/GET_ALL_TENANT_SUCCESS',
    GET_ALL_TENANT_FAILURE: 'app/tenant/GET_ALL_TENANT_FAILURE',
    GET_TENANT_DETAIL_SUCCESS: 'app/tenant/GET_TENANT_DETAIL_SUCCESS',
    GET_TENANT_DETAIL_FAILURE: 'app/tenant/GET_TENANT_DETAIL_FAILURE',
    GET_TENANT_PRODUCT_SUCCESS: 'app/tenant/GET_TENANT_PRODUCT_SUCCESS',
    GET_TENANT_PRODUCT_FAILURE: 'app/tenant/GET_TENANT_PRODUCT_FAILURE',
    GET_TENANT_ADMIN_SUCCESS: 'app/tenant/GET_TENANT_ADMIN_SUCCESS',
    GET_TENANT_ADMIN_FAILURE: 'app/tenant/GET_TENANT_ADMIN_FAILURE',
    GET_TENANT_SUPERVISOR_SUCCESS: 'app/tenant/GET_TENANT_SUPERVISOR_SUCCESS',
    GET_TENANT_SUPERVISOR_FAILURE: 'app/tenant/GET_TENANT_SUPERVISOR_FAILURE',
    GET_BLUETOOTH_SUCCESS: 'app/tenant/GET_BLUETOOTH_SUCCESS',
    GET_BLUETOOTH_FAILURE: 'app/tenant/GET_BLUETOOTH_FAILURE',
};
