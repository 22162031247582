import { requestDelete, requestGet, requestPost, requestPut } from '../../../services/requests';
/**
 * Supports all operations exposed by the user controller.
 */

/* eslint eqeqeq: 0 */
export class TimeInterpretationService {

  constructor(endpoint) {
    this.endpoint = endpoint;
  }

  getTimeRuleById(id) {
    const url = this.operationUrl(`/time-rules/${id}`);
    return requestGet(url);
  };

  getAllTimeRule(filter) {
    const url = this.operationUrl(`/time-rules`);
    return requestGet(url, filter, false);
  };

  saveTimeRule(data) {
    const url = this.operationUrl(`/time-rules`);
    return requestPost(url, data);
  };

  updateTimeRule(id, data) {
    const url = this.operationUrl(`/time-rules/${id}`);
    return requestPut(url, data);
  };

  deleteTimeRule(id, reason) {
    const url = this.operationUrl(`/time-rules/${id}`);
    return requestDelete(url);
  }

  operationUrl(append) {
    return this.endpoint + append;
  }

}
