import { connectionGroupConstants } from '../constants';
import { combineReducers } from 'redux'
import createReducer from '../../../helpers/createReducer';
const defaultFilter = {};
const defaultPaging = 1;

const connectionGroupReducer = createReducer([])({
  [connectionGroupConstants.GET_ALL_CONNECTION_GROUP_SUCCESS]: (state, action) => action.payload,
  [connectionGroupConstants.GET_ALL_CONNECTION_GROUP_FAILURE]: (state, action) => [],
})

const copyConnectionGroupReducer = createReducer([])({
  [connectionGroupConstants.GET_ALL_CONNECTION_GROUP_COPY_SUCCESS]: (state, action) => action.payload
})


const connectionGroupDetailReducer = createReducer({})({
  [connectionGroupConstants.GET_CONNECTION_GROUP_DETAIL_SUCCESS]: (state, action) => action.payload,
  [connectionGroupConstants.GET_CONNECTION_GROUP_DETAIL_FAILURE]: (state, action) => action.payload,
})

const filterReducer = createReducer(defaultFilter)({
  [connectionGroupConstants.FILTER_UPDATE]: (state, action) => action.payload || {},
})

const pagingReducer = createReducer(defaultPaging)({
  [connectionGroupConstants.PAGINATE_UPDATE]: (state, action) => action.payload || 1,
})


export default combineReducers({
  allConnectionGroup: connectionGroupReducer,
  allCopyConnectionGroup: copyConnectionGroupReducer,
  connectionGroupDetail: connectionGroupDetailReducer,
  _filterReducer: filterReducer,
  _pagingReducer: pagingReducer
})