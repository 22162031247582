import { requestDelete, requestGet, requestPost, requestPut } from '../../../services/requests';
/**
 * Supports all operations exposed by the user controller.
 */

/* eslint eqeqeq: 0 */
export class ConnectionGroupService {

  constructor(authEndpoint) {
    this.authEndpoint = authEndpoint;
  }

  getAllConnectionGroup() {
    const url = this.authUrl('/connectionGroup');
    return requestGet(url, {}, false);
  };

  getConnectionGroupById(id) {
    const url = this.authUrl(`/connectionGroup/${id}`);
    return requestGet(url);
  };

  saveConnectionGroup(data) {
    const url = this.authUrl('/connectionGroup');
    return requestPost(url, data);
  };

  updateConnectionGroup(data) {
    const url = this.authUrl(`/connectionGroup/${data.ConnectionGroupID}`);
    return requestPut(url, data);
  };

  deleteConnectionGroupById(id) {
    const url = this.authUrl(`/connectionGroup/${id}`);
    return requestDelete(url);
  }

  authUrl(append) {
    return this.authEndpoint + append;
  }
}
