import { locationConstants } from '../constants';
import { combineReducers } from 'redux'
import createReducer from '../../../helpers/createReducer'
const initialState = [];
const defaultFilter = {};
const defaultPaging = 1;

const locationsReducer = createReducer(initialState)({
    [locationConstants.GET_ALL_LOCATION_SUCCESS]: (state, action) => action.payload,
})

const copyLocationsReducer = createReducer(initialState)({
    [locationConstants.GET_ALL_LOCATION_COPY_SUCCESS]: (state, action) => action.payload,
})

const locationByIdReducer = createReducer(initialState)({
    [locationConstants.GET_LOCATION_BY_ID_SUCCESS]: (state, action) => action.payload,
})

const createLocationReducer = createReducer(initialState)({
    [locationConstants.CREATE_LOCATION]: (state, action) => action.payload,
})
const createBuildingReducer = createReducer(initialState)({
    [locationConstants.CREATE_BUILDING]: (state, action) => action.payload,
})

const buildingReducer = createReducer(initialState)({
    [locationConstants.GET_BUILDING_BY_SETTING_TYPE]: (state, action) => action.payload,
})

const createAreaReducer = createReducer(initialState)({
    [locationConstants.CREATE_AREA]: (state, action) => action.payload,
})

const areaReducer = createReducer(initialState)({
    [locationConstants.GET_AREA_BY_BUILDING_ID]: (state, action) => action.payload,
})
const filterReducer = createReducer(defaultFilter)({
    [locationConstants.FILTER_UPDATE]: (state, action) => action.payload || {},
})

const pagingReducer = createReducer(defaultPaging)({
    [locationConstants.PAGINATE_UPDATE]: (state, action) => action.payload || 1,
})

// const locationDetailReducer = createReducer({})({
//     [locationConstants.GET_LOCATION_DETAIL_SUCCESS]: (state, action) => action.payload,
//     [locationConstants.GET_LOCATION_DETAIL_FAILURE]: (state, action) => action.payload,
// })

export default combineReducers({
    allLocation: locationsReducer,
    locationDetail: locationByIdReducer,
    createdLocation: createLocationReducer,
    createdBuilding: createBuildingReducer,
    allBuildings: buildingReducer,
    createdArea: createAreaReducer,
    allArea: areaReducer,
    allCopyLocation: copyLocationsReducer,
    _filterReducer: filterReducer,
    _pagingReducer: pagingReducer
    // endpointConfigureTabDetail: endpointConfigureTabDetailReducer,
})