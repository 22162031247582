import { settingConstants } from '../constants';
import { combineReducers } from 'redux'
import createReducer from '../../../helpers/createReducer'

const initialState = [];

const settingReducer = createReducer(initialState)({
  [settingConstants.GET_ALL_SETTINGS_SUCCESS]: (state, action) => action.payload,
  [settingConstants.GET_ALL_SETTINGS_FAILURE]: (state, action) => action.payload,
})

const copySettingReducer = createReducer(initialState)({
  [settingConstants.GET_ALL_SETTINGS_COPY_SUCCESS]: (state, action) => action.payload,
})


const settingDetailReducer = createReducer({})({
  [settingConstants.GET_SETTINGS_DETAIL_SUCCESS]: (state, action) => action.payload,
  [settingConstants.GET_SETTINGS_DETAIL_FAILURE]: (state, action) => action.payload,
})





export default combineReducers({
  allSettings: settingReducer,
  allCopySettings: copySettingReducer,
  settingDetail: settingDetailReducer,
})