import { dashboardConstants } from '../constants';
import { dashboardService } from '../service';

export const fetchMetricsSuccess = response => ({
  type: dashboardConstants.GET_ALL_METRICS_SUCCESS,
  payload: response
})

export const fetchMetricsnFailure = response => ({
  type: dashboardConstants.GET_ALL_METRICS_FAILURE,
  payload: []
})

export const getAllMetrics = () => {
  return dispatch => {
    return dashboardService.getAllMetrics()
      .then(response => {
        dispatch(fetchMetricsSuccess(response.data));
        return response;
      },
        error => {
          dispatch(fetchMetricsnFailure(error));
          error.error = true;
          return error;
        })
  }
}

export const fetchTransactionMetricsSuccess = response => ({
  type: dashboardConstants.GET_ALL_TRANSACTION_METRICS_SUCCESS,
  payload: response
})

export const fetchTransactionMetricsnFailure = response => ({
  type: dashboardConstants.GET_ALL_TRANSACTION_METRICS_FAILURE,
  payload: []
})

export const getAllTransactionMetrics = () => {
  return dispatch => {
    return dashboardService.getAllTransactionMetrics()
      .then(response => {
        dispatch(fetchTransactionMetricsSuccess(response.data));
        return response;
      },
        error => {
          dispatch(fetchTransactionMetricsnFailure(error));
          error.error = true;
          return error;
        })
  }
}
export const fetchDashboardSuccess = response => ({
  type: dashboardConstants.GET_ALL_DASHBOARD_SUCCESS,
  payload: response
})

export const fetchDashboardFailure = response => ({
  type: dashboardConstants.GET_ALL_DASHBOARD_FAILURE,
  payload: []
})

export const getAllDashboard = () => {
  return dispatch => {
    return dashboardService.getAllDashboard()
      .then(response => {
        dispatch(fetchDashboardSuccess(response.data));
        return response;
      },
        error => {
          dispatch(fetchDashboardFailure(error));
          error.error = true;
          return error;
        })
  }
}

export const fetchDashboardIDSuccess = response => ({
  type: dashboardConstants.GET_ALL_DASHBOARD_BYID_SUCCESS,
  payload: response
})

export const fetchDashboardIDFailure = response => ({
  type: dashboardConstants.GET_ALL_DASHBOARD_BYID_FAILURE,
  payload: []
})

export const getDashboardByID = (id) => {
  return dispatch => {
    return dashboardService.getDashboardByID(id)
      .then(response => {
        dispatch(fetchDashboardIDSuccess(response.data));
        return response;
      },
        error => {
          dispatch(fetchDashboardIDFailure(error));
          error.error = true;
          return error;
        })
  }
}