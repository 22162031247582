/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import { Modal, Button } from 'antd';
import { TextField } from '@material-ui/core';
import { connect } from 'react-redux';
import { createArea, updateLocation } from '../action/location.actions'
import { successMessage, tostor } from '../../../helpers';

class AddArea extends Component {
    constructor(props) {
        super(props);
        this.state = {
            areaName: "",
            maxCapacity: "",
            formError: false,
            formSubmit: false
        }
    }

    componentWillReceiveProps(props) {
        const { isEdit, building } = props;
        if (isEdit) {
            this.setState({
                areaName: building.AreaName,
                maxCapacity: building.MaxCapacity,
            })
        }
    }
    handleCancel = () => {
        this.setState({
            areaName: "",
            maxCapacity: 0,
            formSubmit: false,
            formError: false,
        })
        this.props.toggle()
    };
    handleChange = (e, field) => {
        var value = e.target.value;
        var number = /^[0-9]+$/;
        if (field == 'maxCapacity') {
            if (value.match(number) || e.target.value == '') {
                this.setState({ [field]: value })
            }
        } else {
            this.setState({ [field]: value })
        }
    };

    saveApplicationModal = () => {
        const { areaName, maxCapacity } = this.state
        const { building, dispatch, isEdit ,locationDetail} = this.props
        this.setState({ formSubmit: true });
        if (areaName == "") {
            this.setState({ formError: true })
        } else {

            if (isEdit) {
                const req = locationDetail;
                req.Buildings = req.Buildings.map(res => {
                    if (res.BuildingID == building.tempBuildingID) {
                        let aIndex = res.Areas.findIndex((area) => { return area.AreaID == building.AreaID });
                        res.Areas[aIndex].AreaName = areaName;
                        res.Areas[aIndex].MaxCapacity = maxCapacity;
                    }
                    return res;
                })
                this.onUpdateLocation(req, true)
            }
            else {
                this.setState({ formSubmit: false })
                const Area = {
                    SettingType: locationDetail.SettingType,
                    BuildingID: building.BuildingID,
                    AreaName: areaName,
                    MaxCapacity: maxCapacity
                }
                dispatch(createArea(Area))
                    .then(() => {
                        tostor.success(successMessage.AREA_ADDED)
                        this.handleCancel()
                    })
            }
        }

    }


    onUpdateLocation = (data, isRaw) => {
        const { dispatch } = this.props
        dispatch(updateLocation(data, true, isRaw))
            .then(() => {
                tostor.success(successMessage.AREA_UPDATE)
                this.handleCancel()
            })
    }


    render() {
        const { areaName, maxCapacity, formError, formSubmit } = this.state;
        const { isEdit } = this.props
        return (
            <div>
                <Modal
                    className="invite-tenant add-area-modal"
                    visible={this.props.modal}
                    onCancel={this.handleCancel}
                    width={500}
                    maskClosable={false}
                    footer={[
                        <div className="footer-box">
                            <Button className="create-btn" key="back" type="primary" onClick={this.saveApplicationModal}>
                                SAVE
                            </Button>
                            <Button className="cancle-btn" key="submit" onClick={this.handleCancel}>
                                CANCEL
                            </Button>
                        </div>
                    ]}
                >
                    <div className='txt-header'>{isEdit ? 'Update' : 'Add'} Area</div>
                    <TextField
                        type="text"
                        className='form-textfield'
                        variant="outlined"
                        placeholder="Area Name"
                        value={areaName}
                        name="AreaName"
                        onChange={(e) => this.handleChange(e, 'areaName')}
                        error={formSubmit && areaName == ""}
                        helperText={formError && formSubmit ? 'Required' : ''}
                    />

                    <TextField
                        type="text"
                        className='form-textfield'
                        variant="outlined"
                        placeholder="Max Capacity"
                        value={maxCapacity}
                        name="maxCapacity"
                        onChange={(e) => this.handleChange(e, 'maxCapacity')}
                    // error={formSubmit && maxCapacity == ""}
                    // helperText={formError && formSubmit ? 'Required' : ''}
                    />
                </Modal>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { user } = state.authentication;
    const { allApplication } = state.application;
    const { locationDetail } = state.place
    return {
        user, 
        allApplication,
        locationDetail
    }
}
export default connect(mapStateToProps)(AddArea);