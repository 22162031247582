export const dashboardConstants = {
    GET_ALL_METRICS_SUCCESS: 'app/dashboard/GET_ALL_METRICS_SUCCESS',
    GET_ALL_METRICS_FAILURE: 'app/dashboard/GET_ALL_METRICS_FAILURE',
    GET_ALL_DASHBOARD_SUCCESS: 'app/dashboard/GET_ALL_DASHBOARD_SUCCESS',
    GET_ALL_DASHBOARD_FAILURE: 'app/dashboard/GET_ALL_DASHBOARD_FAILURE',
    GET_ALL_DASHBOARD_BYID_FAILURE: 'app/dashboard/GET_ALL_DASHBOARD_BYID_FAILURE',
    GET_ALL_DASHBOARD_BYID_SUCCESS: 'app/dashboard/GET_ALL_DASHBOARD_BYID_SUCCESS',
    GET_ALL_TRANSACTION_METRICS_FAILURE: 'app/dashboard/GET_ALL_TRANSACTION_METRICS_FAILURE',
    GET_ALL_TRANSACTION_METRICS_SUCCESS: 'app/dashboard/GET_ALL_TRANSACTION_METRICS_SUCCESS',
    
};
