import { timeInterpretaionConstants } from '../constants';
import { combineReducers } from 'redux'
import createReducer from '../../../helpers/createReducer'

const initialState = [];

const timeRuleReducer = createReducer(initialState)({
  [timeInterpretaionConstants.GET_ALL_TIME_RULE_SUCCESS]: (state, action) => action.payload,
  [timeInterpretaionConstants.GET_ALL_TIME_RULE_FAILURE]: (state, action) => action.payload,
})


const timeRuleDetailReducer = createReducer({})({
  [timeInterpretaionConstants.GET_TIME_RULE_DETAIL_SUCCESS]: (state, action) => action.payload,
  [timeInterpretaionConstants.GET_TIME_RULE_DETAIL_FAILURE]: (state, action) => action.payload,
})


export default combineReducers({
  allTimeRule: timeRuleReducer,
  timeRuleDetail: timeRuleDetailReducer,
})