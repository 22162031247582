import React, { Component } from "react";
import "../../tenant/component/createTenant.scss";
import { Modal, Button, Upload, message, Row, Col, Select } from "antd";
import { InboxOutlined } from '@ant-design/icons';
import { getAllGroups } from '../../groups/action/groups.actions';
import { usersService } from '../service';
import { ImportUserGroupMapping } from "../action/users.actions";
import _ from 'lodash';
import { connect } from 'react-redux';
import DeleteTenant from '../../tenant/component/deleteTenant'
import csvFile from './import_user_group.csv'
import { orderedListData, successMessage, tostor } from "../../../helpers";

const { Dragger } = Upload;
const Option = Select.Option;

class ImportGroupDelete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userGroup: {
        groupId: 'ALL',
        groupName: 'ALL',
        action: 'Remove',
        fileName: '',
      },
      info: {},
      fileList: [],
      error: {},
      isSubmit: false,
      s3Url: '',
    }
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getAllGroups());
  }

  openDeleteModal = (isBlock) => {
    if (this.validateForm()) {
      this.setState({ deleteModal: true, isEdit: false, isBlock });
    }
  }

  toggleDeleteModal = (id) => {
    if (id) {
      this.handleOk()
    }
    else {
      this.setState({ deleteModal: false })
    }
  }

  handleCancel = () => {
    this.setState({
      userGroup: {
        groupId: 'ALL',
        groupName: 'ALL',
        action: 'Remove',
        fileName: '',
      },
      info: {},
      fileList: [],
      error: {},
      isSubmit: false,
      s3Url: '',
    });
    this.props.toggle();
  };


  handleOk = () => {
    this.setState({ isSubmit: true });
    const { dispatch } = this.props;
    if (this.validateForm()) {
      const userGroup = this.state.userGroup;
      let req = {
        ...userGroup,
        fileId: this.state.fileId
      }
      this.uploadFile()
        .then(() => {
          dispatch(ImportUserGroupMapping(req))
            .then((res) => {
              this.setState({ deleteModal: false })
              if (res.error) {
                tostor.error(res.message || "Something went wrong please try again");
                return
              }
              tostor.success(successMessage.USER_DELETE_FROM_GROUP);
              this.handleCancel();
            });
        })

    }
  };

  handleInput = (name, value) => {
    const { userGroup, error } = Object.assign({}, this.state);
    const { allGroups } = this.props;
    const selectedGroup = allGroups.find((res) => { return res.GroupID === value });
    userGroup[name] = value;
    userGroup['groupName'] = (selectedGroup && selectedGroup.GroupName) || 'ALL';

    error[name] = false
    this.setState({ userGroup, error })
  }

  validateForm = () => {
    const { userGroup } = this.state;
    let error = {};
    if (userGroup.groupId === 'ALL') {
      error.groupId = true;
    }
    if (!userGroup.fileName) {
      error.fileName = true;
    }
    this.setState({ error })
    return Object.keys(error).length === 0;
  }


  uploadFile = () => {
    let { info, s3Url } = this.state;
    let fileType = _.last(_.split(info.file.name, '.'));
    let contentType = fileType === 'csv' ? 'text/csv' : 'text/json';
    return fetch(s3Url, {
      method: 'PUT',
      body: info.file,
      headers: {
        "Content-Type": contentType
      }
    }).then(function (res) {
      if (res.status === 200) {
        return res;
      }
    }).catch(err => {
      console.log(err)
    });
  }

  render() {
    const { error, userGroup, fileList, info } = this.state;
    const { allGroups } = this.props
    const props = {
      action: this.state.s3Url,
      accept: ".csv,.json",
      multiple: false,
      onChange: info => {
        let fileType = _.last(_.split(info.file.name, '.'));
        if (fileType !== 'csv' && fileType !== 'json') {
          return false;
        }
        userGroup.fileName = info.file.name;
        this.setState({ info, userGroup });
      },
      beforeUpload: file => {
        let { s3Url } = this.state;
        let fileType = _.last(_.split(file.name, '.'));
        let contentType = fileType === 'csv' ? 'text/csv' : 'text/json';
        if (fileType !== 'csv' && fileType !== 'json') {
          tostor.error('Please Upload CSV or JSON file');
          return false;
        }
        let body = {
          "action": "Upload",
          "fileType": "IMPORT_GROUP_PENDING",
          contentType: contentType
        };

        usersService.getS3Url(body).then(function (res) {
          s3Url = res.data.url;
          let fileId = res.data.fileId;
          this.setState({ s3Url, fileId });
        }.bind(this));
        return false;
      },
    }

    return (
      <div>
        <Modal
          className="create-application"
          visible={this.props.modal}
          onCancel={this.handleCancel}
          footer={[
            <div key="fotter" className="footer-box">
              <Button
                className="create-btn-delete"
                type="primary"
                onClick={() => { this.openDeleteModal(false) }}
              >
                DELETE USER
              </Button>
              <Button
                className="cancle-btn"
                onClick={this.handleCancel}
              >
                CANCEL
              </Button>
            </div>
          ]}
        >
          <div className="txt-header">Delete Users from Group</div>

          <Row>
            <Col span={24}>
              <Dragger {...props} multiple={false} fileList={fileList}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                <p className="ant-upload-hint">
                   Acceptable file type: CSV
                </p>
                <a target="_blank"
                  onClick={(e) => { e.stopPropagation() }}
                  rel="noopener noreferrer"
                  href={csvFile}
                  download={'import_user_group.csv'}>
                  Download template</a>
              </Dragger>
              <span>{info && info.file && info.file.name}</span>
              {error.fileName && <div className="error-msg">File Required </div>}
            </Col>
            <Col span={24}>
              <div className="from-left-col">
                <Select
                  defaultValue='ALL'
                  name="groupId"
                  className="select-input"
                  required
                  value={userGroup.groupId}
                  helperText={error.groupId ? 'Required' : ''}
                  error={error.groupId}
                  onChange={(e) => this.handleInput('groupId', e)}
                >
                  <Option key="GroupName" value="ALL">Group Name</Option>
                  {
                    allGroups && orderedListData(allGroups, 'GroupName').map((res, index) => {
                      return <Option key={index} value={res.GroupID}>{res.GroupName}</Option>
                    })
                  }
                </Select>
                {error.groupId && <div className="error-msg">Required </div>}
              </div>
            </Col>
          </Row>
        </Modal>
        <DeleteTenant
          modal={this.state.deleteModal}
          toggle={this.toggleDeleteModal}
          modalName='RemoveUserFromGroups'
          typeName='oloid.com'
          typeDetails={userGroup}
          namePlaceholderText="Type the name of the Group"
          placeholderText="Please type the reason you are deleting the Group"
          headerText="This action cannot be undone."
          promptText="This will permanently delete the User from the Group"
          btnText='DELETE'
          needConfirmation={true}

        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { allGroups } = state.groups;
  return {
    allGroups
  }
}

export default connect(mapStateToProps)(ImportGroupDelete);