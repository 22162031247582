export const consentConstants = {
    GET_ALL_CONSENT_SUCCESS: 'app/consent/GET_ALL_CONSENT_SUCCESS',
    GET_ALL_CONSENT_COPY_SUCCESS: 'app/consent/GET_ALL_CONSENT_COPY_SUCCESS',
    GET_ALL_CONSENT_FAILURE: 'app/consent/GET_ALL_CONSENT_FAILURE',
    GET_CONSENT_DETAIL_SUCCESS: 'app/consent/GET_CONSENT_DETAIL_SUCCESS',
    GET_CONSENT_DETAIL_FAILURE: 'app/consent/GET_CONSENT_DETAIL_FAILURE',

    GET_ALL_LEGAL_DOCUMENTS_SUCCESS: 'app/consent/GET_ALL_LEGAL_DOCUMENTS_SUCCESS',
    GET_ALL_LEGAL_DOCUMENTS_FAILURE: 'app/consent/GET_ALL_LEGAL_DOCUMENTS_FAILURE',
    GET_LEGAL_DOCUMENTS_BY_ID_SUCCESS: 'app/consent/GET_LEGAL_DOCUMENTS_BY_ID_SUCCESS',
    GET_LEGAL_DOCUMENTS_BY_ID_FAILURE: 'app/consent/GET_LEGAL_DOCUMENTS_BY_ID_FAILURE',
    GET_ALL_LEGAL_DOCUMENTS_COPY_SUCCESS: 'app/consent/GET_ALL_LEGAL_DOCUMENTS_COPY_SUCCESS',
};
