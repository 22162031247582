import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Icon, Menu, Dropdown, Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import '../../application/component/applicationTopBar.scss';
import appActions from '../../../layout/MainLayout/action/user.actions';
import '../../tenant/component/tenants.scss';
import ExportReport from './exportModal';
import TransactionReport from '../../transactions/component/exportModal';
import ExportKronosModal from '../../transactions/component/exportKronosModal'
import ExportVaccinationModal from './exportVaccinationModal'
import { getAllReportsByType } from '../action/report.actions'
import UsersReportModal from './usersReportModal'
const { toggleCollapsed } = appActions;

/* eslint eqeqeq: 0 */
export class ReportTopBar extends Component {

  constructor(props) {
    super(props);
    this.state = {
      createModal: false,
      exportKronosModal: false,
      exportVaccinationModal: false,
      getReportByTypeParam: {
        ReportType: "",
        PageSize: 10,
        page: 1,
      }
    }
  }

  openCreateTransactionModal = () => {
    this.setState({ createTransactionModal: true, isEdit: false })
  }

  toggleCreateTransactionModal = () => {
    const { selectedReportType } = this.props;
    this.getReportsByType(selectedReportType)
    this.setState({ createTransactionModal: false })
  }

  openCreateExportModal = () => {
    this.setState({ createModal: true, isEdit: false })
  }
  toggleCreateExportModal = () => {
    const { selectedReportType } = this.props;
    this.getReportsByType(selectedReportType)
    this.setState({ createModal: false })
  }


  getReportsByType = (name) => {
    const { getAllReportsByType } = this.props;
    const req = {
      ReportType: name,
      PageSize: 10,
      page: 1,
    }
    if (name === 'Vaccines') {
      req.FolderPath = 'Reports/Users/Vaccines/';
      req.ReportType = '';
    }
    
    getAllReportsByType(req)
  }

  openExportKronosModal = () => {
    this.setState({ exportKronosModal: true })
  }
  toggleExportKronosModal = () => {
    const { selectedReportType } = this.props;
    this.getReportsByType(selectedReportType)
    this.setState({ exportKronosModal: false })
  }
  openExportVaccinationModal = () => {
    this.setState({ exportVaccinationModal: true })
  }
  toggleExportVaccinationModal = () => {
    const { selectedReportType } = this.props;
    this.getReportsByType(selectedReportType)
    this.setState({ exportVaccinationModal: false })
  }
  openUsersReportModal = () => {
    this.setState({ usersReportModal: true })
  }
  toggleUsersReportModal = () => {
    this.setState({ usersReportModal: false })
  }

  render() {
    const { toggleCollapsed, customizedTheme, selectedReport } = this.props;
    const collapsed = this.props.collapsed;;
    const menu = (
      <Menu className="tenant-action-menu" onClick={this.handleActionChange}>
        <Menu.Item key="1">
          <div className="ant-dropdown-menu-item-group"
            key="submit"
            onClick={() => { this.openCreateExportModal() }}>
            Endpoint Report</div>
        </Menu.Item>
        <Menu.Item key="2">
          <div className="ant-dropdown-menu-item-group"
            key="submit"
            onClick={() => { this.openCreateTransactionModal() }}>
            Transaction Report</div>
        </Menu.Item>
        {/* <Menu.Item key="3">
          <div className="ant-dropdown-menu-item-group"
            key="submit"
            onClick={() => { this.openExportKronosModal() }}>
            Kronos Report</div>
        </Menu.Item>
        <Menu.Item key="4">
          <div className="ant-dropdown-menu-item-group"
            key="submit"
            onClick={() => { this.openExportVaccinationModal() }}>
            Vaccination Report</div>
        </Menu.Item> */}
        <Menu.Item key="5">
          <div className="ant-dropdown-menu-item-group"
            key="submit"
            onClick={() => { this.openUsersReportModal() }}>
            Users Report</div>
        </Menu.Item>
      </Menu>
    );
    return (
      <div className='tenant-top-bar'>
        <div
          className={
            collapsed ? 'menu-bar-icon menuCollapsed' : 'menu-bar-icon menuOpen'
          }
          style={{ color: customizedTheme.textColor }}
          onClick={toggleCollapsed}
        />
        <div className='txt-tenant-header'>Ad-Hoc Reports</div>
        <div className="action-item" id="reportMenu">
          <Dropdown overlay={menu}
            trigger={['click']}
            getPopupContainer={() => document.getElementById('reportMenu')}>

            <Button>
              Reports <DownOutlined />
            </Button>
          </Dropdown>
        </div>

        <ExportReport
          showModal={this.state.createModal}
          toggle={this.toggleCreateExportModal}
        />
        <TransactionReport
          showModal={this.state.createTransactionModal}
          toggle={this.toggleCreateTransactionModal}
        />
        <ExportKronosModal
          showModal={this.state.exportKronosModal}
          toggle={this.toggleExportKronosModal}
          IntegrationID='KronosConnector'
        />
        <ExportVaccinationModal
          showModal={this.state.exportVaccinationModal}
          toggle={this.toggleExportVaccinationModal}
        />
        <UsersReportModal
          showModal={this.state.usersReportModal}
          toggle={this.toggleUsersReportModal}
        />
      </div>
    );
  }
}

export default connect(
  state => ({
    ...state.APP.App.toJS(),
    customizedTheme: 'themedefault',
    ...state.authentication,
    ...state.report
  }),
  { toggleCollapsed, getAllReportsByType }
)(ReportTopBar);

