export const transactionsConstants = {
    GET_ALL_TRANSACTIONS_SUCCESS: 'app/transactions/GET_ALL_TRANSACTIONS_SUCCESS',
    SET_TRANSACTIONS_PAGE: 'app/transactions/SET_TRANSACTIONS_PAGE',
    STORE_TRANSACTIONS_FILTER: 'app/transactions/STORE_TRANSACTIONS_FILTER',
    GET_ALL_TRANSACTIONS_FAILURE: 'app/transactions/GET_ALL_TRANSACTIONS_FAILURE',
    GET_TRANSACTIONS_DETAIL_SUCCESS: 'app/transactions/GET_TRANSACTIONS_DETAIL_SUCCESS',
    GET_TRANSACTIONS_DETAIL_FAILURE: 'app/transactions/GET_TRANSACTIONS_DETAIL_FAILURE',
    GET_ALL_INTEGRATION_SUCCESS: 'app/transactions/GET_ALL_INTEGRATION_SUCCESS',
    GET_ALL_INTEGRATION_FAILURE: 'app/transactions/GET_ALL_INTEGRATION_FAILURE',
    
    STORE_ACTION_TRANSACTIONS_FILTER: 'app/transactions/STORE_ACTION_TRANSACTIONS_FILTER',
    SET_ACTION_TRANSACTIONS_FILTER: 'app/transactions/SET_ACTION_TRANSACTIONS_FILTER',
    SET_ACTION_TRANSACTIONS_PAGE: 'app/transactions/SET_ACTION_TRANSACTIONS_PAGE',
    GET_ALL_ACTION_TRANSACTIONS_SUCCESS: 'app/transactions/GET_ALL_ACTION_TRANSACTIONS_SUCCESS',
    GET_ALL_ACTION_TRANSACTIONS_FAILURE: 'app/transactions/GET_ALL_ACTION_TRANSACTIONS_FAILURE',
    SET_TRANSACTIONS_FILTER: 'app/transactions/SET_TRANSACTIONS_FILTER',
};
