import { requestGet, requestPost } from '../../../services/requests';
import _ from 'lodash';
import { integrationSettings } from './constant'
/* eslint eqeqeq: 0 */
export class TransactionsService {

  constructor(endpoint) {
    this.endpoint = endpoint;
  }

  getAllTransactions(param, isLoading = true) {
    const url = this.operationUrl('/transactions');
    param = param || {};
    param.ReturnAllFields = true;
    return requestPost(url, param, isLoading);
  }

  getUserTransactions(param, isLoading = true) {
    const url = this.operationUrl('/transactions');
    param = param || {};
    return requestPost(url, param, isLoading);
  }

  getActionTransactions(param, isLoading = true) {
    const url = this.operationUrl('/transactions/endpoint-actions/view');
    param = param || {};
    return requestPost(url, param, isLoading);
  }

  getTransactionsById(id) {
    const url = this.operationUrl(`/transactions/${id}`);
    return requestGet(url);
  }

  downloadTransactions(param) {
    const url = this.operationUrl('/transactions/export');
    param = param || {};
    return requestPost(url, param, false);
  }

  downloadKronosTransactions(param) {
    const url = this.operationUrl('/transactions/export-kronos-punch');
    param = param || {};
    return requestPost(url, param);
  }

  getAllIntegrationsSettings() {
    const url = this.operationUrl('/enabled-integrations');
    // const url = 'http://localhost:3000/dev/enabled-integrations';
    return requestGet(url, {}, false)
      .then(res => {
        let enabled = res.data;
        let allIntegraion = JSON.parse(JSON.stringify(integrationSettings));
        allIntegraion = allIntegraion.map((data) => {
          const index = _.findIndex(enabled, (en) => {
            return en.IntegrationID == data.IntegrationID;
          })
          if (index > -1) {
            data.IsEnabled = true;
          }
          return data;
        })
        return allIntegraion;
      });
  }

  createIntegrationsSettings(data) {
    const url = this.operationUrl('/enabled-integrations');
    return requestPost(url, data);
  }

  transactionsSync(data) {
    const url = this.operationUrl('/transactions/sync');
    return requestPost(url, data);
  }

  transactionsSimilarity(data) {
    const url = this.operationUrl('/utility/transactions/faces/similar');
    return requestPost(url, data);
  }

  retryPlatfromAction(data) {
    const url = this.operationUrl(`/platform-integrations/retry`);
    return requestPost(url, data);
  }

  getFirebaseToken() {
    const url = `${process.env.REACT_APP_USER_API_ENDPOINT}/oloid-firebase/custom/token`;
    return requestGet(url);
  }

  operationUrl(append) {
    return this.endpoint + append;
  }
}
