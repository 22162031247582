export const locationConstants = {
    GET_ALL_LOCATION_SUCCESS: 'app/LOCATION/GET_ALL_LOCATION_SUCCESS',
    GET_ALL_LOCATION_COPY_SUCCESS: 'app/LOCATION/GET_ALL_LOCATION_COPY_SUCCESS',
    CREATE_LOCATION: 'app/LOCATION/CREATE_LOCATION',
    GET_LOCATION_BY_ID_SUCCESS: 'app/LOCATION/GET_LOCATION_BY_ID_SUCCESS',
    CREATE_BUILDING: 'app/LOCATION/CREATE_BUILDING',
    GET_BUILDING_BY_SETTING_TYPE: 'app/LOCATION/GET_BUILDING_BY_SETTING_TYPE',
    REMOVE_BUILDING: 'app/LOCATION/REMOVE_BUILDING',
    CREATE_AREA: 'app/LOCATION/CREATE_AREA',
    REMOVE_AREA_BY_BUILDING_ID: 'app/LOCATION/REMOVE_AREA_BY_BUILDING_ID',
    GET_AREA_BY_BUILDING_ID: 'app/LOCATION/GET_AREA_BY_BUILDING_ID',
    FILTER_UPDATE: 'LOCATION_FILTER_UPDATE',
    PAGINATE_UPDATE: 'LOCATION_PAGINATE_UPDATE',
};
