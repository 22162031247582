import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Button, message, Select, Row, Col, DatePicker, Checkbox } from 'antd';
import _ from 'lodash';
import { downloadKronosTransactions } from '../action/transactions.actions';
import './transactions.scss';
import progressbar from '../../../image/progressbar-indeterminate.gif';
import {setSelectedReport} from '../../report/action/report.actions';
import { getAllApplication } from '../../application/action/application.actions';
import { getAllEndpoints } from '../../endpoint/action/endpoints.actions';

// eslint-disable-next-line no-unused-vars
import momentTZ from 'moment-timezone';
import moment from 'moment';
import { MODE_TYPE, orderedListData, STATUS_TYPE_V2, tostor } from '../../../helpers';

const Option = Select.Option;
const dateFormat = 'YYYY-MM-DD HH:mm';

/* eslint eqeqeq: 0 */
export class ExportKronosModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      filter: {
        SearchField: '',
        Applications: "",
        Endpoints: "",
        FromDate: moment().subtract(7, "days"),
        ToDate: moment(),
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        Mode: 'ALL',
        Status: 'ALL',
        KronosAdvance: true,
      },
      formSubmit: false,
      formError: false,
      isReportGenerate: false
    }
  }

  componentDidMount() {
    const { allApplication , allEndpoints} = this.props;
    if(!(allApplication && allApplication.length>0)){
      this.getAllApplication();
    }
    if(!(allEndpoints && allEndpoints.length>0)){
      this.getAllEndpoints();
    }
    
  }

  getAllApplication = () => {
    const { dispatch } = this.props;
    dispatch(getAllApplication());
  }
  getAllEndpoints = () => {
    const { dispatch } = this.props;
    let statusType = "all";
    let param = {};
    if (statusType != "all") {
      param.statusType = statusType;
    }
    dispatch(getAllEndpoints(param))
  };
  handleCancel = e => {
    this.setState({
      filter: {
        SearchField: '',
        Applications: "",
        Endpoints: "",
        FromDate: moment().subtract(7, "days"),
        ToDate: moment(),
        Mode: 'ALL',
        Status: 'ALL',
        KronosAdvance: true,
      },
      formSubmit: false,
      formError: false,
      isReportGenerate: false
    }, () => {
      this.props.toggle()
    });
  };

  handleOk = e => {
    const { filter } = this.state;
    const { IntegrationID } = this.props
    let param = {}
    this.setState({ formSubmit: true })
    param.IntegrationID = IntegrationID
    if (filter.Applications) {
      param.Applications = [filter.Applications];
    }
    if (filter.Endpoints) {
      param.Endpoints = [filter.Endpoints];
    }
    if (filter.FromDate !== '') {
      param.FromDate = filter.FromDate;
    }
    if (filter.ToDate !== '') {
      param.ToDate = filter.ToDate;
    }
    if (filter.Mode !== 'ALL') {
      param.Mode = filter.Mode;
    }
    if (filter.Status !== 'ALL') {
      param.ActionStatus = filter.Status;
    }
    if (filter.KronosAdvance) {
      param.KronosAdvance = filter.KronosAdvance;
    }

    if (!filter.Applications && !filter.Endpoints) {
      this.setState({ formError: true })
    } else {

      this.setState({ isReportGenerate: true });
      this.generateReport(param)
    }
  };


  generateReport(param) {
    downloadKronosTransactions(param)
      .then(res => {

        if (res.error) {
          tostor.error('Server Error!! Please contact support');
          this.setState({ isReportGenerate: false });
          return;
        }
        if (res.isComplete) {
          const link = document.createElement('a');
          link.href = res.url;
          link.setAttribute('download', 'TransactionReport.xlsx');
          document.body.appendChild(link);
          link.click();
          const {dispatch} = this.props;
          dispatch(setSelectedReport("KronosTransactions"))
          this.handleCancel();
        } else {
          setTimeout(() => {
            const { showModal } = this.props;
            if (showModal) {
              param.FileID = res.fileId;
              this.generateReport(param);
            }
          }, 10000)
        }

      }).catch(err => {
        tostor.error('Server Error!! Please contact support');
      });
  }

  onChangeFilterOption = (value, field) => {
    const { filter } = this.state
    filter[field] = value;
    this.setState({ filter });
  }

  handleCheck = (e) => {
    const { filter } = this.state
    const name = e.target.name;
    const value = e.target.checked;
    filter[name] = value;
    this.setState({ filter });
  }

  render() {
    const { showModal, IntegrationID } = this.props;
    const { filter, formSubmit, isReportGenerate } = this.state;
    const { allApplication } = this.props
    const application = _.uniqBy(allApplication, 'ApplicationName');
    const { allEndpoints } = this.props

    const filteredEndpoint = allEndpoints && allEndpoints.filter((endpoint) => {
      return endpoint.Integrations && endpoint.Integrations.filter(integration => integration.IntegrationID == IntegrationID).length > 0
    })


    return (
      <div>
        <Modal
          maskClosable={false}
          visible={showModal}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <div key="footer" className="footer-box export-footer">
              <Button
                disabled={isReportGenerate}
                key="downlod" className="create-btn" type="primary" onClick={this.handleOk}>
                DOWNLOAD
              </Button>
              <Button key="cancel" className="cancle-btn" onClick={this.handleCancel}>
                CANCEL
              </Button>
            </div>
          ]}
        >
          <div className='txt-header'>Export To XL</div>
          <Row className="export-box">
            <Col span={12} className="export-input-box">
              <div className='txt-customer'>From Date Time</div>
              <div>
                <DatePicker showTime
                  onChange={(e) => { this.onChangeFilterOption(e, 'FromDate') }}
                  value={filter.FromDate}
                  className="export-date-box"
                  placeholder="From Date Time" format={dateFormat} />
              </div>
            </Col>
            <Col span={12} className="export-input-box">
              <div className='txt-customer'>To Date Time</div>
              <div>
                <DatePicker showTime
                  onChange={(e) => { this.onChangeFilterOption(e, 'ToDate') }}
                  value={filter.ToDate}
                  placeholder="To Date Time" format={dateFormat} />
              </div>
            </Col>
          </Row>

          <Row className="export-box">
            <Col span={24} className="export-input-box">
              <label className="txt-customer">Application Name</label>
              <div>
                <Select
                  required={filter.Endpoints == ""}
                  placeholder='Application Name'
                  name="Applications"
                  onChange={(e) => { this.onChangeFilterOption(e, 'Applications') }}
                  value={filter.Applications}
                  className={`select-input ${(formSubmit && (filter.Endpoints == "" && filter.Applications == "")) ? 'error' : ''}`}>
                  {/* <Option key="Select" value="">Select</Option>   */}
                  {
                    application && orderedListData(application||[], 'ApplicationName').map((res, index) => {
                      return <Option key={index} value={res.ApplicationID}> {res.ApplicationName} </Option>
                    })
                  }
                </Select>
                <span className="helper-text">{(formSubmit && (filter.Endpoints == "" && filter.Applications == "")) ? 'Required' : ''}</span>
              </div>
            </Col>
          </Row>

          <Row className="export-box">
            <Col span={24} className="export-input-box">
              <label className="txt-customer">Endpoint Name</label>
              <div>
                <Select placeholder='Endpoint Name'
                  required={filter.Applications == ""}
                  className={`select-input ${(formSubmit && (filter.Applications == "" && filter.Endpoints == "")) ? 'error' : ''}`}
                  name="Endpoints"
                  onChange={(e) => { this.onChangeFilterOption(e, 'Endpoints') }}
                  value={filter.Endpoints}>
                    {/* <Option key="Select" value="">Select</Option> */}
                  {
                    filteredEndpoint && orderedListData(filteredEndpoint||[], 'EndpointName').map((type, index) => {
                      return <Option key={index} value={type.EndpointID}>{type.EndpointName}({type.EndpointID})</Option>
                    })
                  }
                </Select>
                <span className="helper-text">{(formSubmit && (filter.Applications == "" && filter.Endpoints == "")) ? 'Required' : ''}</span>
              </div>
            </Col>
          </Row>

          <Row className="export-box">
            <Col span={12} className="export-input-box">
              <label className="txt-customer">Mode</label>
              <div>
                <Select defaultValue='ALL'
                  name="Mode"
                  onChange={(e) => { this.onChangeFilterOption(e, 'Mode') }}
                  value={filter.Mode}
                  className="select-input">
                  <Option key="ALL" value="ALL">ALL</Option>
                  {orderedListData(MODE_TYPE||[], 'label').map((item)=>{
                    return <Option key={item.value} value={item.value}>{item.label}</Option>
                  })}
                </Select>
              </div>
            </Col>

            <Col span={12} className="export-input-box">
              <label className="txt-customer">Status</label>
              <div>
                <Select defaultValue='ALL'
                  name="Status"
                  onChange={(e) => { this.onChangeFilterOption(e, 'Status') }}
                  value={filter.Status}
                  className="select-input">
                  <Option key="ALL" value="ALL">ALL</Option>
                  {orderedListData(STATUS_TYPE_V2||[], 'label').map((item)=>{
                    return <Option key={item.value} value={item.value}>{item.label}</Option>
                  })}
                </Select>
              </div>
            </Col>
          </Row>

          <Row className="export-box">
            <Col span={24} className="export-input-box">
              <div className="default-Identical-checkbox">
                <Checkbox
                  checked={filter.KronosAdvance}
                  name="KronosAdvance"
                  onChange={(e) => { this.handleCheck(e) }}
                >
                  <label className="txt-customer"> Kronos Advanced</label>

                </Checkbox>
              </div>
            </Col>
          </Row>

          {
            isReportGenerate &&
            <Row className="export-box">
              <Col span={24} className="export-input-box">
                <div>Report Generating...</div>
                <img className="activity-progress" src={progressbar} alt="gif" />
              </Col>
            </Row>
          }

        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  const { allApplication } = state.application;
  const { allEndpoints } = state.endpoints;
  const {selectedReportType} = state.report
  return {
    user,
    allApplication,
    allEndpoints,
    selectedReportType
  }
}
export default connect(mapStateToProps)(ExportKronosModal);