import { endpointsConstants } from "../constants";
import { endpointsService } from "../service";
import _ from "lodash";

export const fetchEndpointsSuccess = (response) => ({
  type: endpointsConstants.GET_ALL_ENDPOINTS_SUCCESS,
  payload: response,
});

export const fetchEndpointTypesSuccess = (response) => ({
  type: endpointsConstants.GET_ALL_ENDPOINT_TYPES_SUCCESS,
  payload: response,
});

export const copyEndpointsSuccess = (response) => ({
  type: endpointsConstants.GET_ALL_ENDPOINTS_COPY_SUCCESS,
  payload: response,
});

export const fetchEndpointsFailure = (response) => ({
  type: endpointsConstants.GET_ALL_ENDPOINTS_FAILURE,
  payload: [],
});

export const setFilter = (obj) => {
  return (dispatch) => {
    dispatch({
      type: endpointsConstants.SET_ENDPOINT_FILTER,
      payload: { ...obj },
    });
  };
};

export const setEndpointPage = (page) => {
  return (dispatch) => {
    dispatch({
      type: endpointsConstants.SET_ENDPOINT_PAGE,
      payload: page,
    });
  };
};

export const getAllEndpoints = (filter) => {
  return (dispatch) => {
    return endpointsService.getAllEndpoints(filter).then(
      (response) => {
        let endpoints = (response.data && response.data.endpoints) || [];
        let endpointTypes =
          (response.data && response.data.endpointTypes) || [];
        endpoints = _.orderBy(endpoints, ["UpdatedAt"], ["desc"]);
        dispatch(fetchEndpointTypesSuccess(endpointTypes));
        dispatch(fetchEndpointsSuccess(endpoints));
        dispatch(copyEndpointsSuccess(endpoints));
        return endpoints;
      },
      (error) => {
        dispatch(fetchEndpointsFailure(error));
        return error;
      }
    );
  };
};

export const filterEndpoints = (filter) => {
  return (dispatch, state) => {
    const data = state().endpoints.allCopyEndpoints;
    const allCopyEndpoints = JSON.parse(JSON.stringify(data));
    let filtered = allCopyEndpoints || [];
    if (filter.search) {
      filtered = _.filter(filtered, (res) => {
        return (
          res.EndpointName &&
          (res.EndpointName.toLowerCase().indexOf(
            filter.search.toLowerCase()
          ) !== -1 ||
            res.EndpointID.toLowerCase().indexOf(
              filter.search.toLowerCase()
            ) !== -1)
        );
      });
    }
    if (filter.appType) {
      filtered = _.filter(filtered, (res) => {
        return res.ApplicationType === filter.appType;
      });
    }
    if (filter.endpointType) {
      filtered = _.filter(filtered, (res) => {
        return res.EndpointType === filter.endpointType;
      });
    }
    if (filter.areaID) {
      filtered = _.filter(filtered, (res) => {
        return res.AreaID === filter.areaID;
      });
    }
    dispatch(fetchEndpointsSuccess(filtered));
    return filtered;
  };
};

export const fetchEndpointsDetailSuccess = (response) => ({
  type: endpointsConstants.GET_ENDPOINTS_DETAIL_SUCCESS,
  payload: response,
});

export const fetchEndpointsDetailFailure = (response) => ({
  type: endpointsConstants.GET_ENDPOINTS_DETAIL_FAILURE,
  payload: {},
});

export const getEndpointById = (id) => {
  return (dispatch) => {
    return endpointsService.getEndpointById(id).then(
      (response) => {
        let endpoint = (response.data && response.data) || {};
        dispatch(fetchEndpointsDetailSuccess(endpoint));

        let IntegrationUrls = [];
        if (endpoint.IntegrationUrls) {
          for (const key in endpoint.IntegrationUrls) {
            if (endpoint.IntegrationUrls.hasOwnProperty(key)) {
              const element = endpoint.IntegrationUrls[key];
              IntegrationUrls.push({
                Integration: key,
                Url: element,
              });
            }
          }
        }
        let allIntegration = [];
        if (
          endpoint.EndpointHealthResponse &&
          endpoint.EndpointHealthResponse.IntegrationConnectivity
        ) {
          for (const key in endpoint.EndpointHealthResponse
            .IntegrationConnectivity) {
            if (
              endpoint.EndpointHealthResponse.IntegrationConnectivity.hasOwnProperty(
                key
              )
            ) {
              const connectivity =
                endpoint.EndpointHealthResponse.IntegrationConnectivity[key];
              allIntegration.push({
                Integration: key,
                Url: connectivity.Value,
                Status: connectivity.StatusCode,
              });
            }
          }
        }
        if (
          endpoint.EndpointHealthResponse &&
          endpoint.EndpointHealthResponse.CoreConnectivity
        ) {
          for (const key in endpoint.EndpointHealthResponse.CoreConnectivity) {
            if (
              endpoint.EndpointHealthResponse.CoreConnectivity.hasOwnProperty(
                key
              )
            ) {
              const connectivity =
                endpoint.EndpointHealthResponse.CoreConnectivity[key];
              allIntegration.push({
                Integration: key,
                Url: connectivity.Value,
                Status: connectivity.StatusCode,
              });
            }
          }
        }

        let healthParameter = [];
        if (
          endpoint.EndpointHealthResponse &&
          endpoint.EndpointHealthResponse.Resource
        ) {
          for (const key in endpoint.EndpointHealthResponse.Resource) {
            if (endpoint.EndpointHealthResponse.Resource.hasOwnProperty(key)) {
              const param = endpoint.EndpointHealthResponse.Resource[key];
              healthParameter.push({
                Name: key,
                Status: param,
              });
            }
          }
        }
        dispatch(getEndpointHealth(allIntegration));
        dispatch(getHealthParameter(healthParameter));
        dispatch(getEndpointConfigureTab(IntegrationUrls));
        return endpoint;
      },
      (error) => {
        dispatch(fetchEndpointsDetailFailure(error));
        return error;
      }
    );
  };
};

export const fetchHealthParameterSuccess = (response) => ({
  type: endpointsConstants.GET_ALL_ENDPOINT_HEALTH_PARAMETER_SUCCESS,
  payload: response,
});

export const getHealthParameter = (response) => {
  return (dispatch) => {
    dispatch(fetchHealthParameterSuccess(response));
  };
};

export const fetchConfigureTabSuccess = (response) => ({
  type: endpointsConstants.GET_ALL_ENDPOINT_CONFIGURE_TAB_SUCCESS,
  payload: response,
});

export const getEndpointConfigureTab = (response) => {
  return (dispatch) => {
    dispatch(fetchConfigureTabSuccess(response));
  };
};

export const fetchHealthSuccess = (response) => ({
  type: endpointsConstants.GET_ALL_ENDPOINT_HEALTH_SUCCESS,
  payload: response,
});

export const getEndpointHealth = (response) => {
  return (dispatch) => {
    dispatch(fetchHealthSuccess(response));
  };
};

export const saveEndpoint = (data, param) => {
  return (dispatch) => {
    return endpointsService.createEndpoint(data).then(
      (response) => {
        return dispatch(getAllEndpoints(param));
      },
      (error) => {
        error.error = true;
        return error;
      }
    );
  };
};
export const saveCloudKey = (data, param) => {
  return (dispatch) => {
    return endpointsService.createCloudKeyEndpoint(data).then(
      () => {
        return dispatch(getAllEndpoints(param));
      },
      (error) => {
        error.error = true;
        return error;
      }
    );
  };
};

export const updateEndpoint = (data) => {
  return (dispatch) => {
    return endpointsService.updateEndpoint(data).then(
      (response) => {
        return dispatch(getEndpointById(data.EndpointID));
      },
      (error) => {
        error.error = true;
        return error;
      }
    );
  };
};

export const fetchIntegrationSuccess = (response) => ({
  type: endpointsConstants.GET_ALL_INTEGRATION_SUCCESS,
  payload: response,
});

export const fetchIntegrationFailure = (response) => ({
  type: endpointsConstants.GET_ALL_INTEGRATION_FAILURE,
  payload: [],
});

export const deleteCameraId = (id) => {
  return (dispatch) => {
    return endpointsService.deleteCameraId(id).then(
      (response) => {
        return response;
      },
      (error) => {
        error.error = true;
        return error;
      }
    );
  };
};

export const deleteEndpointById = (id, reason) => {
  return (dispatch) => {
    return endpointsService.deleteEndpointById(id, reason).then(
      (response) => {
        dispatch(getAllEndpoints());
        return response;
      },
      (error) => {
        error.error = true;
        return error;
      }
    );
  };
};

export const getAllIntegrations = () => {
  return (dispatch) => {
    return endpointsService.getAllIntegrations().then(
      (response) => {
        dispatch(fetchIntegrationSuccess(response));
        return response;
      },
      (error) => {
        dispatch(fetchIntegrationFailure(error));
        return error;
      }
    );
  };
};

export const fetchEnabledIntegrationSuccess = (response) => ({
  type: endpointsConstants.GET_ALL_ENABLED_INTEGRATION_SUCCESS,
  payload: response,
});

export const fetchEnabledIntegrationFailure = (response) => ({
  type: endpointsConstants.GET_ALL_ENABLED_INTEGRATION_FAILURE,
  payload: [],
});

export const getAllEnabledIntegrations = () => {
  return (dispatch) => {
    return endpointsService.getAllEnabledIntegrations().then(
      (response) => {
        dispatch(fetchEnabledIntegrationSuccess(response));
        return response;
      },
      (error) => {
        dispatch(fetchEnabledIntegrationFailure(error));
        return error;
      }
    );
  };
};

export const removeEndpointGroups = (EndpointID, data) => {
  return (dispatch) => {
    return endpointsService.removeEndpointGroups(EndpointID, data).then(
      (response) => {
        dispatch(getEndpointById(EndpointID));
        return response;
      },
      (error) => {
        error.error = true;
        return error;
      }
    );
  };
};

export const testEndpointConnection = (ids, ReLaunch) => {
  return (dispatch) => {
    return endpointsService.testEndpointConnection(ids, ReLaunch).then(
      (response) => {
        if (ids && ids.length === 1) {
          dispatch(getEndpointById(ids[0]));
        }
        return response;
      },
      (error) => {
        error.error = true;
        return error;
      }
    );
  };
};

export const applyTemplate = (obj) => {
  return (dispatch) => {
    return endpointsService.applyTemplate(obj).then(
      (response) => {
        dispatch(getEndpointById(obj.EndpointID));
        return response;
      },
      (error) => {
        error.error = true;
        return error;
      }
    );
  };
};

export const activateCloudKey = (data) => {
  return (dispatch) => {
    return endpointsService.activateCloudKey(data).then(
      (response) => {
        //return dispatch(getEndpointById(data.EndpointID));
        return response;
      },
      (error) => {
        error.error = true;
        return error;
      }
    );
  };
};
