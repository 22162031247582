import { userConstants } from '../constants';
import { userService } from '../service';
import _ from 'lodash';
import { setItem } from '../../../helpers';

export const fetchLoginRequest = request => ({
  type: userConstants.LOGIN_REQUEST,
  payload: {
    loggingIn: true,
    user: request
  }
})

export const fetchLoginSuccess = request => ({
  type: userConstants.LOGIN_SUCCESS,
  payload: { user: { ...request } }
})
export const fetchLoginFailure = () => ({
  type: userConstants.LOGIN_FAILURE,
  payload: {}
})


export const ssologin = (user) => {
  return dispatch => {
    setItem('user', user);
    dispatch(fetchLoginSuccess(user));
  }
}

export const getUsersByIdToken = (username, accesstoken) => {
  return dispatch => {
    var user = { username: username, accesstoken: accesstoken };
    return userService.getUsersById(username, accesstoken)
      .then((res) => {
        if (res.Role === 'admin') {

          user["email"] = res.Email;
          user["role"] = res.Role;
          user["tenantName"] = res.TenantName;
          user["tenantID"] = res.TenantID;
          user["username"] = res.OloID;
          user["region"] = process.env.REACT_APP_HOSTED_REGION;

          setItem('user', user);
          dispatch(fetchLoginSuccess(user));
          return user;
        }
        else {
          return { message: 'You are not authorised to login to the Tenant Admin Portal. Please login to Oloid Connect or Oloid Verify App.', status: 'error', role: res.Role }
        }
      }, () => {
        return { message: 'You are not authorized to login' }
      })
  }
}

export const login = (username, password, type) => {
  return dispatch => {
    dispatch(fetchLoginRequest({ username }));
    return userService.login(username, password, type)
      .then(response => {
        if (response.challengeName === 'NEW_PASSWORD_REQUIRED') {
          return response;
        }
        response.accesstoken = _.get(response, 'signInUserSession.idToken.jwtToken');
        return dispatch(getUsersByIdToken(response.username, response.accesstoken))
      },
        error => {
          dispatch(fetchLoginFailure(error));
          error.error = true;
          return error
        })
  }
}

export const setNewPassword = (user, newPassword) => {
  return dispatch => {
    return userService.setNewPassword(user, newPassword)
      .then(response => {
        response.accesstoken = _.get(response, 'signInUserSession.idToken.jwtToken');
        return dispatch(getUsersByIdToken(response.username, response.accesstoken))
      },
        error => {
          dispatch(fetchLoginFailure(error));
          error.error = true;
          return error
        })
  }
}

const ssoLoginResponse = (response) => {
  return dispatch => {
    console.log(response.CognitoToken)
    if (_.get(response, 'User.Role') === 'admin') {
      var user = {
        ..._.get(response, 'User'),
        username: _.get(response, 'User.OloID'),
        accesstoken: _.get(response, 'CognitoToken'),
        email: _.get(response, 'User.Email'),
        role: _.get(response, 'User.Role'),
        tenantName: _.get(response, 'User.TenantName'),
        tenantID: _.get(response, 'User.TenantID'),
        region: process.env.REACT_APP_HOSTED_REGION
      };
      setItem('user', user);
      dispatch(fetchLoginSuccess(user));
      return user;
    }
    else {
      return { message: 'You are not authorized to login', status: 'error', role: _.get(response, 'User.Role') }
    }
  }
}

export const googleLogin = (params) => {
  return dispatch => {
    return userService.googleLogin(params)
      .then(response => {
        return dispatch(ssoLoginResponse(response))
      },
        error => {
          dispatch(fetchLoginFailure(error));
          error.error = true;
          return error
        })
  }
}

export const azureLogin = (params) => {
  return dispatch => {
    return userService.azureLogin(params)
      .then(response => {
        return dispatch(ssoLoginResponse(response))
      },
        error => {
          dispatch(fetchLoginFailure(error));
          error.error = true;
          return error
        })
  }
}

export const oktaLogin = (params) => {
  return dispatch => {
    return userService.oktaLogin(params)
      .then(response => {
        return dispatch(ssoLoginResponse(response))
      },
        error => {
          dispatch(fetchLoginFailure(error));
          error.error = true;
          return error
        })
  }
}



export const forgotPasswordSubmit = (username, code, new_password) => {
  return dispatch => {
    return userService.forgotPasswordSubmit(username, code, new_password)
      .then(() => {
        return dispatch(login(username, new_password))
          .then((response) => {
            return response;
          })
      },
        error => {
          dispatch(fetchLoginFailure(error));
          error.error = true;
          return error
        })
  }
}

export const forgotPassword = (username, tenantName) => {
  return () => {
    return userService.forgotPassword(username, tenantName)
      .then(response => {
        return response || {};
      },
        error => {
          error.error = true;
          return error
        })
  }
}

export const userlogout = () => ({
  type: userConstants.LOGOUT,
  payload: {}
})


export const logout = () => {
  return dispatch => {
    userService.logout();
    return dispatch(userlogout());
  }
}

export const getTenantInfo = (tenant) => {
  return dispatch => {
    return userService.getTenantInfo(tenant)
      .then((response) => {
        return response;
      },
        error => {
          error.error = true;
          return error
        })
  }
}
