import React, { Component } from 'react';
import { Select, Row, Col, message } from 'antd';
import { connect } from 'react-redux';
import './usersDetail.scss';
import { downloadService } from '../service';
import { getAllGroups } from '../../groups/action/groups.actions';
import { getAllConnection } from '../../connections/action/connection.actions';
import { orderedListData, tostor } from '../../../helpers';

const Option = Select.Option;
export class UsersDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      DownloadType: 'connection',
      filter: [],
      error: {}
    }
  }

  componentDidMount() {
    const { dispatch } = this.props;
    // dispatch(getAllGroups());
    dispatch(getAllConnection());
  }

  onClose = () => {
    this.props.onCloseUserModal && this.props.onCloseUserModal()
  }

  handleSubmit = () => {
    const { DownloadType, filter } = this.state;

    this.setState({ isSubmit: true })
    if (!(filter && filter.length > 0)) {
      this.setState({
        error: {
          filter: {
            isValid: false,
            message: `Please select ${DownloadType}`
          }
        }
      });
      tostor.error(`Please select ${DownloadType}`);
      return;
    }
    let req = {
      "source": DownloadType
    }
    req.filter = filter.map((res) => {
      let type = DownloadType === 'group' ? 'GroupID' : 'ConnectionID';
      return {
        [type]: res
      }
    })
    this.hideShow('flex')
    this.downloadUser(req);
  }

  hideShow = (flag) => {
    if (document.getElementById("cst_ui_block")) {
      document.getElementById("cst_ui_block").style.display = flag;
    }
  }

  downloadUser(req) {
    downloadService.downloadUser(req)
      .then(res => {
        const response = res.data || {};
        if (!response.isComplete) {
          setTimeout(() => {
            req.entityId = response.entityId;
            this.downloadUser(req);
          }, 2000)
          return;
        }
        this.hideShow('none');
        const link = document.createElement('a');
        link.href = response.url;
        link.setAttribute('download', 'Users.xlsx');
        document.body.appendChild(link);
        link.click();
        this.setState({ isSubmit: false, error: {}, filter: [] });

        this.props.onCloseUserModal && this.props.onCloseUserModal()
      },
        err => {
          this.hideShow('none');
          tostor.error(err.message)
        })
  }

  onChangeFilterOption = (value) => {
    this.setState({ filter: value });
  }

  onChangeFilterSelection = (e) => {
    this.setState({ DownloadType: e.target.value, filter: [] });
  }

  render() {
    const { DownloadType, filter, isSubmit, error } = this.state;
    const { allConnections, allGroups, isAdHocReport } = this.props;
    return (
      <div>
        <div className={isAdHocReport ? "report-detail-box" : "user-detail-box"}>
          {/* <Row className="redio-box">
            <Col span={6}>
              <div className="redio-btn-box">
                <input
                  className="group-redio-input-btn"
                  type="radio"
                  name="UsersDetail"
                  checked={DownloadType === 'group'}
                  onClick={(e) => { this.onChangeFilterSelection(e) }}
                  value='group'
                />
                <label className="report-label-input-btn" htmlFor="hour">Group</label>
              </div>
            </Col>
            <Col span={6}>
              <div className="redio-btn-box">
                <input
                  className="group-redio-input-btn"
                  type="radio"
                  name="UsersDetail"
                  onClick={(e) => { this.onChangeFilterSelection(e) }}
                  checked={DownloadType === 'connection'}
                  value='connection'
                />
                <label className="report-label-input-btn" htmlFor="hour">Connection</label>
              </div>
            </Col>
            <Col span={12}></Col>
          </Row> */}
          <Row>
            <Col span={isAdHocReport ? 24 : 12}>
              <label className="dropdown-level-txt">Name</label>

              {
                DownloadType === 'group' && <div className="from-left-col">
                  <Select
                    mode="multiple"
                    placeholder="Group Name"
                    defaultValue='Group Name'
                    className={`select-input ${(isSubmit && error['filter'] && !error['filter'].isValid) ? 'error' : ''}`}
                    onChange={(e) => { this.onChangeFilterOption(e) }}
                    value={filter}
                  >
                    {
                      allGroups && orderedListData(allGroups || [], 'GroupName').map((res, index) => {
                        return <Option key={index} value={res.GroupID}>{res.GroupName} ({res.GroupID})</Option>
                      })
                    }

                  </Select>
                </div>}

              {DownloadType === 'connection' && <div className="from-left-col">
                <Select
                  mode="multiple"
                  placeholder="Connection Name"
                  defaultValue='Connection Name'
                  className={`select-input ${(isSubmit && error['filter'] && !error['filter'].isValid) ? 'error' : ''}`}
                  onChange={(e) => { this.onChangeFilterOption(e) }}
                  value={filter}
                >
                  {
                    allConnections && orderedListData(allConnections || [], 'ConnectionDisplayName').map((res, index) => {
                      return <Option key={index} value={res.ConnectionID}>
                        {res.ConnectionDisplayName} ({res.ConnectionID})</Option>
                    })
                  }

                </Select>
              </div>}


              <div className="input-sub-txt">Download the list of users in a group or connection</div>
            </Col>
            {
              !isAdHocReport &&
              <Col span={12}>
                <button
                  type="button"
                  class="ant-btn user-download-button"
                  onClick={this.handleSubmit}
                >
                  <span>DOWNLOAD</span>
                </button>
              </Col>
            }

            {
              isAdHocReport && <div className="user-report-footer">
                 <Col span={24}>
                  <button type="button" class="ant-btn ant-btn-primary report-create-btn" onClick={this.handleSubmit}><span>DOWNLOAD</span></button>
                  <button type="button" class="ant-btn ant-btn-default report-cancle-btn" onClick={this.onClose}><span>CANCEL</span></button>
                </Col>
              </div>
            }

          </Row>
        </div>
      </div>
    );
  }
}


function mapStateToProps(state) {
  const { user } = state.authentication;
  const { allConnections } = state.connection;
  const { allGroups } = state.groups;
  return {
    user,
    allConnections,
    allGroups
  }
}
export default connect(mapStateToProps)(UsersDetail);