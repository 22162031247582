import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Button, message, Select, Row, Col } from 'antd';
import _ from 'lodash';
import { downloadVaccineReport } from '../action/report.actions';
import progressbar from '../../../image/progressbar-indeterminate.gif'
import { setSelectedReport } from '../../report/action/report.actions'
import { getAllConnection } from '../../connections/action/connection.actions';

import { CheckNoNull, orderedListData, tostor } from '../../../helpers';

const Option = Select.Option;

/* eslint eqeqeq: 0 */
export class ExportVaccinationModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      filter: {
        Connections: [],
      },
      formSubmit: false,
      formError: false,
      isReportGenerate: false
    }
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getAllConnection())
  }
  handleCancel = () => {
    this.setState({
      filter: {
        Connections: [],
      },
      formSubmit: false,
      formError: false,
      isReportGenerate: false
    }, () => {
      this.props.toggle()
    });
  };

  handleOk = () => {
    const { filter } = this.state;
    let param = {}
    this.setState({ formSubmit: true })
    if (!(filter.Connections && filter.Connections.length > 0)) {
      tostor.error('Required at least on connection');
      return;
    }

    if (filter.Connections) {
      param.Connections = filter.Connections;
    }

    this.setState({ isReportGenerate: true });
    this.generateReport(param)
  };


  generateReport(param) {
    downloadVaccineReport(param)
      .then(res => {

        if (res.error) {
          tostor.error('Server Error!! Please contact support');
          this.setState({ isReportGenerate: false });
          return;
        }
        if (res.isComplete) {
          const link = document.createElement('a');
          link.href = res.url;
          link.setAttribute('download', 'VaccineReport.xlsx');
          document.body.appendChild(link);
          link.click();
          const { dispatch } = this.props;
          dispatch(setSelectedReport("Vaccines"))
          this.handleCancel();
        } else {
          setTimeout(() => {
            const { showModal } = this.props;
            if (showModal) {
              param.FileID = res.fileId;
              this.generateReport(param);
            }
          }, 10000)
        }

      }).catch(() => {
        tostor.error('Server Error!! Please contact support');
      });
  }

  onChangeFilterOption = (value, field) => {
    const { filter } = this.state
    filter[field] = value;
    this.setState({ filter });
  }

  handleCheck = (e) => {
    const { filter } = this.state
    const name = e.target.name;
    const value = e.target.checked;
    filter[name] = value;
    this.setState({ filter });
  }

  render() {
    const { showModal } = this.props;
    const { filter, formSubmit, isReportGenerate } = this.state;
    const { allConnections } = this.props;


    return (
      <div>
        <Modal
          maskClosable={false}
          visible={showModal}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <div key="footer" className="footer-box export-footer">
              <Button
                disabled={isReportGenerate}
                key="downlod" className="create-btn" type="primary" onClick={this.handleOk}>
                DOWNLOAD
              </Button>
              <Button key="cancel" className="cancle-btn" onClick={this.handleCancel}>
                CANCEL
              </Button>
            </div>
          ]}
        >
          <div className='txt-header'>Export To Vaccination</div>
          <Row className="export-box">
            <Col span={24} className="export-input-box">
              <label className="txt-customer">Connection Name</label>
              <div>
                <Select placeholder='Endpoint Name'
                  mode='multiple'
                  required
                  className={`select-input ${(formSubmit && (filter.Connections == "")) ? 'error' : ''}`}
                  name="Connections"
                  onChange={(e) => { this.onChangeFilterOption(e, 'Connections') }}
                  value={filter.Connections}>
                  {
                    allConnections && orderedListData(allConnections || [], 'ConnectionDisplayName').map((type, index) => {
                      if(CheckNoNull(type.ConnectionDisplayName)){
                        return <Option key={index} value={type.ConnectionID}>{type.ConnectionDisplayName}</Option>
                      }
                    })
                  }
                </Select>
                <span className="helper-text">{(formSubmit && (filter.Connections == "")) ? 'Required' : ''}</span>
              </div>
            </Col>
          </Row>
          {
            isReportGenerate &&
            <Row className="export-box">
              <Col span={24} className="export-input-box">
                <div>Report Generating...</div>
                <img className="activity-progress" src={progressbar} alt="gif" />
              </Col>
            </Row>
          }

        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  const { allConnections } = state.connection;
  const { selectedReportType } = state.report
  return {
    user,
    allConnections,
    selectedReportType
  }
}
export default connect(mapStateToProps)(ExportVaccinationModal);