import React from 'react';
import { Link } from 'react-router-dom';
import { siteConfig } from './settings';
import { history } from '../../helpers';

export default ({ collapsed, menuId }) => {
  let siteName = '';
  switch (menuId) {
    case 1:
      siteName = 'Oloid';
      break;
    case 2:
      siteName = 'Users';
      break;
    case 3:
      siteName = 'Endpoints';
      break;
    case 5:
      siteName = 'Dashboard';
      break;
    default:
      siteName = 'Admin';
      break;
  }
  const handleClick = () => {
    if (menuId === 1) {
      history.push('/applications')
    }
    if (menuId === 2) {
      history.push('/users')
    }
    if (menuId === 3) {
      history.push('/endpoint/all')
    }
    if (menuId === 4) {
      history.push('/similarityusers')
    }
    if (menuId === 5) {
      history.push('/activity/users')
    }
  }
  return (
    <div className="isoLogoWrapper" onClick={() => handleClick(siteName)}>
      {collapsed ? (
        <div>
          <h3>
            <i className={siteConfig.siteIcon} />
          </h3>
        </div>
      ) : (
        <h3>
          {siteName}
        </h3>
      )}
    </div>
  );
};
