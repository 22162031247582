export const endpointsConstants = {
    SET_ENDPOINT_FILTER: 'app/endpoints/SET_ENDPOINT_FILTER',
    SET_ENDPOINT_PAGE: 'app/endpoints/SET_ENDPOINT_PAGE',
    GET_ALL_ENDPOINTS_SUCCESS: 'app/endpoints/GET_ALL_ENDPOINTS_SUCCESS',
    GET_ALL_ENDPOINT_TYPES_SUCCESS: 'app/endpoints/GET_ALL_ENDPOINT_TYPES_SUCCESS',
    GET_ALL_ENDPOINTS_COPY_SUCCESS: 'app/endpoints/GET_ALL_ENDPOINTS_COPY_SUCCESS',
    GET_ALL_ENDPOINTS_FAILURE: 'app/endpoints/GET_ALL_ENDPOINTS_FAILURE',
    GET_ENDPOINTS_DETAIL_SUCCESS: 'app/endpoints/GET_ENDPOINTS_DETAIL_SUCCESS',
    GET_ENDPOINTS_DETAIL_FAILURE: 'app/endpoints/GET_ENDPOINTS_DETAIL_FAILURE',
    GET_ALL_INTEGRATION_SUCCESS:'app/endpoints/GET_ALL_INTEGRATION_SUCCESS',
    GET_ALL_INTEGRATION_FAILURE:'app/endpoints/GET_ALL_INTEGRATION_FAILURE',
    GET_ALL_ENABLED_INTEGRATION_SUCCESS:'app/endpoints/GET_ALL_ENABLED_INTEGRATION_SUCCESS',
    GET_ALL_ENABLED_INTEGRATION_FAILURE:'app/endpoints/GET_ALL_ENABLED_INTEGRATION_FAILURE',
    GET_ALL_CAMERA_SUCCESS:'app/endpoints/GET_ALL_CAMERA_SUCCESS',
    GET_ALL_CAMERA_FAILURE:'app/endpoints/GET_ALL_CAMERA_FAILURE',
    GET_ALL_USER_GROUP_SUCCESS:'app/users/GET_ALL_USER_GROUP_SUCCESS',
    GET_ALL_USER_GROUP_FAILURE : 'app/users/GET_ALL_USER_GROUP_FAILURE',
    GET_ALL_ENDPOINT_CONFIGURE_TAB_SUCCESS:'app/users/GET_ALL_ENDPOINT_CONFIGURE_TAB_SUCCESS',
    GET_ALL_ENDPOINT_CONFIGURE_TAB_FAILURE : 'app/users/GET_ALL_ENDPOINT_CONFIGURE_TAB_FAILURE',
    GET_ALL_ENDPOINT_HEALTH_SUCCESS:'app/users/GET_ALL_ENDPOINT_HEALTH_SUCCESS',
    GET_ALL_ENDPOINT_HEALTH_PARAMETER_SUCCESS:'app/users/GET_ALL_ENDPOINT_HEALTH_PARAMETER_SUCCESS',
    GET_ALL_ENDPOINT_HEALTH_FAILURE : 'app/users/GET_ALL_ENDPOINT_HEALTH_FAILURE',
    APPLY_TEMPLATE: 'app/users/APPLY_TEMPLATE'
};
