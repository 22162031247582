import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import routes from '../../routes';
import './MainLayout.scss'
import _ from 'lodash';
import AppHolder from "./commonStyle";
import { Layout } from "antd";
import { Debounce } from "react-throttle";
import WindowResizeListener from "react-window-size-listener";
import Sidebar from "./Sidebar/Sidebar";
import Topbar from "./Topbar/Topbar";
import appActions from "./action/user.actions";
import themes from "./settings/themes";
import { ThemeProvider } from "styled-components";
import {
  setTranasctionPage,
  setActionTranasctionPage
} from '../../modules/transactions/action/transactions.actions';

import { setUserPage } from '../../modules/users/action/users.actions';
import { setFilter } from '../../modules/endpoint/action/endpoints.actions';
import { setActivityPage } from '../../modules/activityLog/action/activityLog.actions';

const { Content } = Layout;
const { toggleAll } = appActions;

const endpointFilter = {
  search: '',
  appType: 'ALL',
  endpointType: 'ALL',
  areaID: 'ALL',
  currentPage: 1
};
/* eslint eqeqeq: 0 */
export class MainLayout extends Component {

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged(prevProps.location, this.props.location);
    }
  }

  onRouteChanged(prevLoc, currentLoc) {
    const prevPath = prevLoc.pathname || '';
    const currPath = currentLoc.pathname || '';
    if (prevPath.indexOf('/transactions/') > -1 || currPath.indexOf('/transactions/') > -1) {
      if (!(prevPath.indexOf('/transactions/') > -1 && currPath.indexOf('/transactions/') > -1)) {
        this.props.setTranasctionPage(1);
      }
    }

    if (prevPath.indexOf('/integrations/') > -1 || currPath.indexOf('/integrations/') > -1) {
      if (!(prevPath.indexOf('/integrations/') > -1 && currPath.indexOf('/integrations/') > -1)) {
        this.props.setActionTranasctionPage(1);
      }
    }

    if (prevPath.indexOf('/endpoint/') > -1 || currPath.indexOf('/endpoint/') > -1) {
      if (!(prevPath.indexOf('/endpoint/') > -1 && currPath.indexOf('/endpoint/') > -1)) {
        this.props.setFilter({ ...endpointFilter });
      }
    }

    if (prevPath.indexOf('/endloc/') > -1 || currPath.indexOf('/endloc/') > -1) {
      if (!(prevPath.indexOf('/endloc/') > -1 && currPath.indexOf('/endloc/') > -1)) {
        this.props.setFilter({ ...endpointFilter });
      }
    }

    if (prevPath.indexOf('/users') > -1 || currPath.indexOf('/users') > -1) {
      if (!(prevPath.indexOf('/users') > -1 && currPath.indexOf('/users') > -1)) {
        this.props.setUserPage(1);
      }
    }

    if (prevPath.indexOf('/activitylog') > -1 || currPath.indexOf('/activitylog') > -1) {
      if (!(prevPath.indexOf('/activitylog') > -1 && currPath.indexOf('/activitylog') > -1)) {
        this.props.setActivityPage(1);
      }
    }
  }

  render() {
    const { user } = this.props;
    let emailDomain = user.email && user.email.split('@');
    let inHouse = ['oloid.ai', 'xcdify.com'].indexOf((emailDomain && emailDomain[1])) > -1 ? 'house' : '';

    const filteredRoutes = _.filter(routes, (route) => {
      return user && (route.allowedRoles.indexOf(user.role) > -1 || route.allowedRoles.indexOf(inHouse) > -1)
    })
    const { url } = this.props.match;
    const { location } = this.props;
    const { height, selectedTheme } = this.props;

    return (
      <ThemeProvider theme={themes[selectedTheme]}>
        <AppHolder>
          <Layout style={{ height: "100vh" }}>
            <Debounce time="1000" handler="onResize">
              <WindowResizeListener
                onResize={windowSize =>
                  this.props.toggleAll(
                    windowSize.windowWidth,
                    windowSize.windowHeight
                  )
                }
              />
            </Debounce>
            <Topbar url={url} location={location} />
            <Layout style={{ flexDirection: "row", overflowX: "hidden" }}>
              <Sidebar url={url} {...this.props} />
              <Layout
                className="platformContentMainLayout"
                // style={{
                //   height: height
                // }}
              >
                <Content
                  className="platformContent"
                  style={{
                    padding: "70px 0 0",
                    flexShrink: "0",
                    background: "#f1f3f6",
                    position: "relative"
                  }}
                >
                  <Switch>
                    {
                      filteredRoutes.map((route, idx) => {
                        return route.component ? (<Route key={idx} path={route.path} exact={route.exact}
                          name={route.name}
                          render={props => (
                            localStorage.getItem('user')
                              ? <route.component {...props} />
                              : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
                          )} />)
                          : (localStorage.getItem('user')
                            ? ''
                            : <Redirect key={idx} to={{ pathname: '/login' }} />);
                      })
                    }

                    {
                      (!user || !user.accesstoken) &&
                      <Redirect to={{ pathname: '/login' }} />
                    }
                    {
                      user && user.accesstoken &&
                      <Redirect from="/" to="/applications" />
                    }

                  </Switch>
                </Content>
              </Layout>
            </Layout>
          </Layout>
        </AppHolder>
      </ThemeProvider>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user,
    selectedTheme: 'themedefault',
    height: state.APP.App.toJS().height
  }
}

export default connect(
  mapStateToProps,
  {
    toggleAll,
    setTranasctionPage,
    setActionTranasctionPage,
    setUserPage,
    setFilter,
    setActivityPage
  }
)(MainLayout);
