import { reportConstants } from '../constants';
import { reportService } from '../service';
import _ from 'lodash';

export const fetchReportSuccess = response => ({
  type: reportConstants.GET_ALL_REPORT_SUCCESS,
  payload: response
})

export const fetchReportByTypeSuccess = response => ({
  type: reportConstants.GET_ALL_REPORT_BY_TYPE_SUCCESS,
  payload: response
})

export const fetchReportFailure = response => ({
  type: reportConstants.GET_ALL_REPORT_FAILURE,
  payload: []
})
export const copyReportSuccess = response => ({
  type: reportConstants.GET_ALL_REPORT_COPY_SUCCESS,
  payload: response
})

export const getAllReport = () => {
  return dispatch => {
    return reportService.getAllReport()
      .then(response => {
        dispatch(fetchReportSuccess(response.data));
        dispatch(copyReportSuccess(response.data));
        return response.data;
      },
        error => {
          dispatch(fetchReportFailure(error));
          return error;
        })
  }
}

export const getAllReportsByType = (params) => {
  return dispatch => {
    return reportService.getAllReportsByType(params)
      .then(response => {
        response = {
          data: [
            { ...response.data }
          ],
          page: params.page || 1
        }
        dispatch(fetchReportByTypeSuccess(response));
        dispatch(copyReportSuccess(response));
        return response.data;
      },
        error => {
          dispatch(fetchReportFailure(error));
          return error;
        })
  }
}

export const setSelectedReport = reportType => ({
  type: reportConstants.SET_SELECTED_REPORT,
  payload: reportType
})

export const nextReports = (param) => {
  return (dispatch, state) => {
    param = {
      ...param,
    }
    return reportService.getAllReportsByType(param)
      .then(response => {
        let res = state().report.reportsByType;
        res.data.push({ ...response.data });
        res.page = (res.page && res.page + 1) || param.page + 1;
        dispatch(fetchReportSuccess({ ...res }));
        return response.data;
      },
        error => {
          dispatch(fetchReportFailure(error));
          return error;
        })
  }
}

export const firstReports = (param) => {
  return (dispatch, state) => {
    let res = state().report.reportsByType
    res.data = [res.data[0]];
    res.page = 1;
    dispatch(fetchReportSuccess({ ...res }));
  }
}

export const prevReports = (param) => {
  return (dispatch, state) => {
    let res = state().report.reportsByType;
    res.data.pop();
    res.page = res.page - 1;
    dispatch(fetchReportSuccess({ ...res }));
  }
}

export const filterReport = (filter) => {
  return (dispatch, state) => {
    const data = state().report.allCopyReport
    const allcopyReport = JSON.parse(JSON.stringify(data));
    let filtered = allcopyReport || [];
    if (filter.search) {
      filtered = _.filter(filtered, (res) => {
        return res.ReportName && (
          res.ReportName.toLowerCase().indexOf(filter.search.toLowerCase()) !== -1
          //|| res.ReportID.toLowerCase().indexOf(filter.search.toLowerCase()) !== -1
        );
      })
    }
    if (filter.type) {
      filtered = _.filter(filtered, (res) => {
        return res.ApplicationType === filter.type;
      })
    }
    dispatch(fetchReportSuccess(filtered));
  }
}

export const downloadEndpointReports = (param) => {
  return reportService.downloadEndpointReports(param)
    .then(response => {
      return response.data;
    },
      error => {
        error.error = true;
        return error;
      })
}

export const fetchReportDetailSuccess = response => ({
  type: reportConstants.GET_REPORT_DETAIL_SUCCESS,
  payload: response
})

export const fetchReportDetailFailure = response => ({
  type: reportConstants.GET_REPORT_DETAIL_FAILURE,
  payload: {}
})

export const getReportById = (id) => {
  return dispatch => {
    return reportService.getReportById(id)
      .then(response => {
        let result = response.data || {};
        dispatch(fetchReportDetailSuccess(result));
        return result;
      },
        error => {
          dispatch(fetchReportDetailFailure(error));
          return error;
        })
  }
}


export const saveReport = (data) => {
  return dispatch => {
    return reportService.saveReport(data)
      .then(response => {
        if (data.ReportID) {
          dispatch(getReportById(data.ReportID));
        }
        else {
          dispatch(getAllReport());
        }
        return response;
      })
  }
}

export const updateReport = (data) => {
  return dispatch => {
    return reportService.updateReport(data)
      .then(response => {
        return dispatch(getReportById(data.ReportID));
      })
  }
}

export const deleteReportById = (id, reason) => {
  return dispatch => {
    return reportService.deleteReportById(id, reason)
      .then(response => {
        dispatch(getAllReport());
        return response;
      },
        error => {
          error.error = true;
          return error;
        })
  }
}

export const downloadVaccineReport = (param) => {
  return reportService.downloadVaccineReport(param)
    .then(response => {
      return response.data;
    },
      error => {
        error.error = true;
        return error;
      })
}