
export const integrationSettings = [
  {
    "DisplayName": "Kronos",
    "IntegrationID": "KronosConnector"
  },
  {
    "DisplayName": "Miral Access",
    "IntegrationID": "MiralAccess"
  },
  {
    "DisplayName": "Relay Access",
    "IntegrationID": "InvokeAdam6060Relay"
  },
  {
    "DisplayName": "ADP",
    "IntegrationID": "ADPCloudPunch"
  },
  {
    "DisplayName": "Wiegand Access",
    "IntegrationID": "WiegandAccess"
  }
]

