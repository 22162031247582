import icon from '../../../image/user_preview.png';
export const dataSource = [
  {
    key: '1',
    userId: 1,
    faces: icon,
    userName: 'Full Name 1',
    oloid: '3000',
    connection: 'Workday',
    primaryId: '1234',
    lastFaceAuthentication: 'May 30 2019 6:00 PM',
    totalFaceAuthentication: '121',
  },
  {
    key: '2',
    userId: 2,
    faces: icon,
    userName: 'Full Name 2',
    oloid: '3000',
    connection: 'Workday',
    primaryId: '1234',
    lastFaceAuthentication: 'May 30 2019 6:00 PM',
    totalFaceAuthentication: '121',
  },
  {
    key: '3',
    userId: 3,
    faces: icon,
    userName: 'Full Name 3',
    oloid: '3000',
    connection: 'Workday',
    primaryId: '1234',
    lastFaceAuthentication: 'May 30 2019 6:00 PM',
    totalFaceAuthentication: '121',
  },
  {
    key: '4',
    userId: 4,
    faces: icon,
    userName: 'Full Name 4',
    oloid: '3000',
    connection: 'Workday',
    primaryId: '1234',
    lastFaceAuthentication: 'May 30 2019 6:00 PM',
    totalFaceAuthentication: '121',
  },
  {
    key: '5',
    userId: 5,
    faces: icon,
    userName: 'Full Name 5',
    oloid: '3000',
    connection: 'Workday',
    primaryId: '1234',
    lastFaceAuthentication: 'May 30 2019 6:00 PM',
    totalFaceAuthentication: '121',
  },
  {
    key: '6',
    userId: 6,
    faces: icon,
    userName: 'Full Name 6',
    oloid: '3000',
    connection: 'Workday',
    primaryId: '1234',
    lastFaceAuthentication: 'May 30 2019 6:00 PM',
    totalFaceAuthentication: '121',
  },
  {
    key: '7',
    userId: 7,
    faces: icon,
    userName: 'Full Name 7',
    oloid: '3000',
    connection: 'Workday',
    primaryId: '1234',
    lastFaceAuthentication: 'May 30 2019 6:00 PM',
    totalFaceAuthentication: '121',
  },
  {
    key: '8',
    userId: 8,
    faces: icon,
    userName: 'Full Name 8',
    oloid: '3000',
    connection: 'Workday',
    primaryId: '1234',
    lastFaceAuthentication: 'May 30 2019 6:00 PM',
    totalFaceAuthentication: '121',
  },
  {
    key: '9',
    userId: 9,
    faces: icon,
    userName: 'Full Name 9',
    oloid: '3000',
    connection: 'Workday',
    primaryId: '1234',
    lastFaceAuthentication: 'May 30 2019 6:00 PM',
    totalFaceAuthentication: '121',
  },
  {
    key: '10',
    userId: 10,
    faces: icon,
    userName: 'Full Name 10',
    oloid: '3000',
    connection: 'Workday',
    primaryId: '1234',
    lastFaceAuthentication: 'May 30 2019 6:00 PM',
    totalFaceAuthentication: '121',
  },
  {
    key: '11',
    userId: 11,
    faces: icon,
    userName: 'Full Name 11',
    oloid: '3000',
    connection: 'Workday',
    primaryId: '1234',
    lastFaceAuthentication: 'May 30 2019 6:00 PM',
    totalFaceAuthentication: '121',
  },
]

export const userEndPoints = [
  {
    key: '1',
    id: 1,
    endPointName: 'EndPoint 1',
    applicationName: 'Application 1',
    applicationType: 'Application Type 1'
  },
  {
    key: '2',
    id: 2,
    endPointName: 'EndPoint 2',
    applicationName: 'Application 2',
    applicationType: 'Application Type 2'
  },
  {
    key: '3',
    id: 3,
    endPointName: 'EndPoint 3',
    applicationName: 'Application 3',
    applicationType: 'Application Type 3'
  },
  {
    key: '4',
    id: 4,
    endPointName: 'EndPoint 4',
    applicationName: 'Application 4',
    applicationType: 'Application Type 4'
  },
  {
    key: '5',
    id: 5,
    endPointName: 'EndPoint 5',
    applicationName: 'Application 5',
    applicationType: 'Application Type 5'
  },
  {
    key: '6',
    id: 6,
    endPointName: 'EndPoint 6',
    applicationName: 'Application 6',
    applicationType: 'Application Type 6'
  },
  {
    key: '7',
    id: 7,
    endPointName: 'EndPoint 7',
    applicationName: 'Application 7',
    applicationType: 'Application Type 7'
  },
  {
    key: '8',
    id: 8,
    endPointName: 'EndPoint 8',
    applicationName: 'Application 8',
    applicationType: 'Application Type 8'
  },
  {
    key: '9',
    id: 9,
    endPointName: 'EndPoint 9',
    applicationName: 'Application 9',
    applicationType: 'Application Type 9'
  },
  {
    key: '10',
    id: 10,
    endPointName: 'EndPoint 10',
    applicationName: 'Application 10',
    applicationType: 'Application Type 10'
  },
  {
    key: '11',
    id: 11,
    endPointName: 'EndPoint 11',
    applicationName: 'Application 11',
    applicationType: 'Application Type 11'
  },
]

export const userHistory = [
  {
    key: '1',
    id: 1,
    endPoint: 'EndPoint 1',
    applicationName: 'Application 1',
    dateTime: new Date(),
    location: 'location 2',
    event: 'event 2'
  },
  {
    key: '2',
    id: 2,
    endPoint: 'EndPoint 2',
    applicationName: 'Application 2',
    dateTime: new Date(),
    location: 'location 2',
    event: 'event 2'
  },
  {
    key: '3',
    id: 3,
    endPoint: 'EndPoint 3',
    applicationName: 'Application 3',
    dateTime: new Date(),
    location: 'location 2',
    event: 'event 2'
  },
  {
    key: '4',
    id: 4,
    endPoint: 'EndPoint 4',
    applicationName: 'Application 4',
    dateTime: new Date(),
    location: 'location 2',
    event: 'event 2'
  },
  {
    key: '5',
    id: 5,
    endPoint: 'EndPoint 5',
    applicationName: 'Application 5',
    dateTime: new Date(),
    location: 'location 2',
    event: 'event 2'
  },
  {
    key: '6',
    id: 6,
    endPoint: 'EndPoint 6',
    applicationName: 'Application 6',
    dateTime: new Date(),
    location: 'location 2',
    event: 'event 2'
  },
  {
    key: '7',
    id: 7,
    endPoint: 'EndPoint 7',
    applicationName: 'Application 7',
    dateTime: new Date(),
    location: 'location 2',
    event: 'event 2'
  },
  {
    key: '8',
    id: 8,
    endPoint: 'EndPoint 8',
    applicationName: 'Application 8',
    dateTime: new Date(),
    location: 'location 2',
    event: 'event 2'
  },
  {
    key: '9',
    id: 9,
    endPoint: 'EndPoint 9',
    applicationName: 'Application 9',
    dateTime: new Date(),
    location: 'location 2',
    event: 'event 2'
  },
  {
    key: '10',
    id: 10,
    endPoint: 'EndPoint 10',
    applicationName: 'Application 10',
    dateTime: new Date(),
    location: 'location 2',
    event: 'event 2'
  },
  {
    key: '11',
    id: 11,
    endPoint: 'EndPoint 11',
    applicationName: 'Application 11',
    dateTime: new Date(),
    location: 'location 2',
    event: 'event 2'
  },
]

export const userRawJson = {
  "email": "madhu@gmail.com",
  "name": "madhu",
  "email_verified": true,
  "userdata": {
    "customer_id": 1000035,
    "team_manager": true,
    "first_name": "Madhu",
    "last_name": "Madhusudhanan"
  }
}

export const userConsent = [
  {
    key: '1',
    id: 1,
    dateTime: new Date(),
    version: 'ver 1',
    consentFrom: 'modules/users/componet/GroupUsers_Wireframes_part1.pdf',
    consentFromFile: 'ConsentFrom_Date.pdf'
  },
  {
    key: '2',
    id: 2,
    dateTime: new Date(),
    version: 'ver 3',
    consentFrom: 'modules/users/componet/GroupUsers_Wireframes_part1.pdf',
    consentFromFile: 'ConsentFrom_Date.pdf'
  },
  {
    key: '3',
    id: 3,
    dateTime: new Date(),
    version: 'ver 3',
    consentFrom: 'modules/users/componet/GroupUsers_Wireframes_part1.pdf',
    consentFromFile: 'ConsentFrom_Date.pdf'
  },
  {
    key: '4',
    id: 4,
    dateTime: new Date(),
    version: 'ver 3',
    consentFrom: 'modules/users/componet/GroupUsers_Wireframes_part1.pdf',
    consentFromFile: 'ConsentFrom_Date.pdf'
  },
  {
    key: '5',
    id: 5,
    dateTime: new Date(),
    version: 'ver 1',
    consentFrom: 'modules/users/componet/GroupUsers_Wireframes_part1.pdf',
    consentFromFile: 'ConsentFrom_Date.pdf'
  },
  {
    key: '6',
    id: 6,
    dateTime: new Date(),
    version: 'ver 1',
    consentFrom: 'modules/users/componet/GroupUsers_Wireframes_part1.pdf',
    consentFromFile: 'ConsentFrom_Date.pdf'
  },
  {
    key: '7',
    id: 7,
    dateTime: new Date(),
    version: 'ver 1',
    consentFrom: 'modules/users/componet/GroupUsers_Wireframes_part1.pdf',
    consentFromFile: 'ConsentFrom_Date.pdf'
  },
  {
    key: '8',
    id: 8,
    dateTime: new Date(),
    version: 'ver 1',
    consentFrom: 'modules/users/componet/GroupUsers_Wireframes_part1.pdf',
    consentFromFile: 'ConsentFrom_Date.pdf'
  },
  {
    key: '9',
    id: 9,
    dateTime: new Date(),
    version: 'ver 1',
    consentFrom: 'modules/users/componet/GroupUsers_Wireframes_part1.pdf',
    consentFromFile: 'ConsentFrom_Date.pdf'
  },
  {
    key: '10',
    id: 10,
    dateTime: new Date(),
    version: 'ver 1',
    consentFrom: 'modules/users/componet/GroupUsers_Wireframes_part1.pdf',
    consentFromFile: 'ConsentFrom_Date.pdf'
  },
  {
    key: '11',
    id: 11,
    dateTime: new Date(),
    version: 'ver 1',
    consentFrom: 'modules/users/componet/GroupUsers_Wireframes_part1.pdf',
    consentFromFile: 'ConsentFrom_Date.pdf'
  },
]
export const userImport = [
  {
    key: '1',
    id: 1,
    date: new Date(),
    fileName: 'Base_Template_Screens.xd',
    result: true,
    log: '',
  },
  {
    key: '2',
    id: 2,
    date: new Date(),
    fileName: 'Base_Template_Screens.xd',
    result: false,
    log: 'LogBase_Template_Screens_31',
  },
  {
    key: '3',
    id: 3,
    date: new Date(),
    fileName: 'Base_Template_Screens.xd',
    result: true,
    log: '',
  },
  {
    key: '4',
    id: 4,
    date: new Date(),
    fileName: 'Base_Template_Screens.xd',
    result: false,
    log: 'LogBase_Template_Screens_31',
  },
  {
    key: '5',
    id: 5,
    date: new Date(),
    fileName: 'Base_Template_Screens.xd',
    result: true,
    log: '',
  },
  {
    key: '6',
    id: 6,
    date: new Date(),
    fileName: 'Base_Template_Screens.xd',
    result: true,
    log: '',
  },
  {
    key: '7',
    id: 7,
    date: new Date(),
    fileName: 'Base_Template_Screens.xd',
    result: false,
    log: 'LogBase_Template_Screens_31',
  },
  {
    key: '8',
    id: 8,
    date: new Date(),
    fileName: 'Base_Template_Screens.xd',
    result: false,
    log: 'LogBase_Template_Screens_31',
  },
  {
    key: '9',
    id: 9,
    date: new Date(),
    fileName: 'Base_Template_Screens.xd',
    result: true,
    log: '',
  },
  {
    key: '10',
    id: 10,
    date: new Date(),
    fileName: 'Base_Template_Screens.xd',
    result: true,
    log: '',
  },
  {
    key: '11',
    id: 11,
    date: new Date(),
    fileName: 'Base_Template_Screens.xd',
    result: false,
    log: 'LogBase_Template_Screens_31',
  },
]

export const userFaces = [
  {
    key: '1',
    id: 1,
    faces: 'coulmnIcon',
    attributes: 'Glasses, Hat',
    didEnabled: 'Yes',
    date: new Date(),
    isPrimary: true
  },
  {
    key: '2',
    id: 2,
    faces: 'coulmnIcon',
    attributes: 'Glasses, Hat',
    didEnabled: 'No',
    date: new Date(),
    isPrimary: false
  },
  {
    key: '3',
    id: 3,
    faces: 'coulmnIcon',
    attributes: 'Glasses, Hat',
    didEnabled: 'Yes',
    date: new Date(),
    isPrimary: false
  },
  {
    key: '4',
    id: 4,
    faces: 'coulmnIcon',
    attributes: 'Glasses, Hat',
    didEnabled: 'No',
    date: new Date(),
    isPrimary: false
  },
  {
    key: '5',
    id: 5,
    faces: 'coulmnIcon',
    attributes: 'Glasses, Hat',
    didEnabled: 'Yes',
    date: new Date(),
    isPrimary: false
  },
  {
    key: '6',
    id: 6,
    faces: 'coulmnIcon',
    attributes: 'Glasses, Hat',
    didEnabled: 'Yes',
    date: new Date(),
    isPrimary: false
  },
  {
    key: '7',
    id: 7,
    faces: 'coulmnIcon',
    attributes: 'Glasses, Hat',
    didEnabled: 'No',
    date: new Date(),
    isPrimary: false
  },
  {
    key: '8',
    id: 8,
    faces: '',
    attributes: 'Glasses, Hat',
    didEnabled: 'Yes',
    date: new Date(),
    isPrimary: false
  },
  {
    key: '9',
    id: 9,
    faces: 'coulmnIcon',
    attributes: 'Glasses, Hat',
    didEnabled: 'No',
    date: new Date(),
    isPrimary: false
  },
  {
    key: '10',
    id: 10,
    faces: 'coulmnIcon',
    attributes: 'Glasses, Hat',
    didEnabled: 'No',
    date: new Date(),
    isPrimary: false
  },
  {
    key: '11',
    id: 11,
    faces: 'coulmnIcon',
    attributes: 'Glasses, Hat',
    didEnabled: 'Yes',
    date: new Date(),
    isPrimary: false
  },
]
export const userGroup = [
  {
    key: '1',
    id: 1,
    group: 'Group 1',
    description: 'Desc 1',
    assignment: 'Manual'
  },
  {
    key: '2',
    id: 2,
    group: 'Group 2',
    description: 'Desc 2',
    assignment: 'Programmatic'
  },
  {
    key: '3',
    id: 3,
    group: 'Group 3',
    description: 'Desc 3',
    assignment: 'Manual'
  },
  {
    key: '4',
    id: 4,
    group: 'Group 4',
    description: 'Desc 4',
    assignment: 'Programmatic'
  },
  {
    key: '5',
    id: 5,
    group: 'Group 5',
    description: 'Desc 5',
    assignment: 'Manual'
  },
  {
    key: '6',
    id: 6,
    group: 'Group 6',
    description: 'Desc 6',
    assignment: 'Manual'
  },
  {
    key: '7',
    id: 7,
    group: 'Group 7',
    description: 'Desc 7',
    assignment: 'Programmatic'
  },
  {
    key: '8',
    id: 8,
    group: 'Group 8',
    description: 'Desc 8',
    assignment: 'Manual'
  },
  {
    key: '9',
    id: 9,
    group: 'Group 9',
    description: 'Desc 9',
    assignment: 'Programmatic'
  },
  {
    key: '10',
    id: 10,
    group: 'Group 10',
    description: 'Desc 10',
    assignment: 'Programmatic'
  },
  {
    key: '11',
    id: 11,
    group: 'Group 11',
    description: 'Desc 11',
    assignment: 'Manual'
  },
]

export const userSpoofAtempts = [
  {
    key: '1',
    id: 1,
    faces: 'coulmnIcon',
    name: 'First Name Last Name 1',
    primary: '101',
    application: 'Time Clock',
    endpoint: 'Tablet 1',
    location: 'Milpitas, Bld 15',
    date: new Date(),
    eventDetail: '7734hdshfs874w',
    secondaryId: '7734hdshfs874w',
    transactionId: 'sabsds73y87y33u',
    faceMatch: 'Success',
    faceMatchScore: '99%',
    liveliness: 'Success',
    livelinessScore: 'Success',
    made: 'Online',
    connection: 'Connection ABC',
    endpointId: '2DF54S',
    isPrimary: true
  },
  {
    key: '2',
    id: 2,
    faces: 'coulmnIcon',
    name: 'First Name Last Name 2',
    primary: '102',
    application: 'Access',
    endpoint: 'Tablet 2',
    location: 'Milpitas, Bld 15',
    date: new Date(),
    isPrimary: false
  },
  {
    key: '3',
    id: 3,
    faces: 'coulmnIcon',
    name: 'First Name Last Name 3',
    primary: '103',
    application: 'Edge',
    endpoint: 'Tablet 3',
    location: 'Milpitas, Bld 15',
    date: new Date(),
    isPrimary: false
  },
  {
    key: '4',
    id: 4,
    faces: 'coulmnIcon',
    name: 'First Name Last Name 4',
    primary: '104',
    application: 'Time Clock',
    endpoint: 'Tablet 4',
    location: 'Milpitas, Bld 15',
    date: new Date(),
    isPrimary: false
  },
  {
    key: '5',
    id: 5,
    faces: 'coulmnIcon',
    name: 'First Name Last Name 5',
    primary: '105',
    application: 'Access',
    endpoint: 'Tablet 5',
    location: 'Milpitas, Bld 15',
    date: new Date(),
    isPrimary: false
  },
  {
    key: '6',
    id: 6,
    faces: 'coulmnIcon',
    name: 'First Name Last Name 6',
    primary: '106',
    application: 'Edge',
    endpoint: 'Tablet 6',
    location: 'Milpitas, Bld 15',
    date: new Date(),
    isPrimary: false
  },
  {
    key: '7',
    id: 7,
    faces: 'coulmnIcon',
    name: 'First Name Last Name 7',
    primary: '107',
    application: 'Time Clock',
    endpoint: 'Tablet 7',
    location: 'Milpitas, Bld 15',
    date: new Date(),
    isPrimary: false
  },
  {
    key: '8',
    id: 8,
    faces: '',
    name: 'First Name Last Name 8',
    primary: '108',
    application: 'Time Clock',
    endpoint: 'Tablet 8',
    location: 'Milpitas, Bld 15',
    date: new Date(),
    isPrimary: false
  },
  {
    key: '9',
    id: 9,
    faces: 'coulmnIcon',
    name: 'First Name Last Name 9',
    primary: '109',
    application: 'Edge',
    endpoint: 'Tablet 9',
    location: 'Milpitas, Bld 15',
    date: new Date(),
    isPrimary: false
  },
  {
    key: '10',
    id: 10,
    faces: 'coulmnIcon',
    name: 'First Name Last Name 10',
    primary: '110',
    application: 'Edge',
    endpoint: 'Tablet 10',
    location: 'Milpitas, Bld 15',
    date: new Date(),
    isPrimary: false
  },
  {
    key: '11',
    id: 11,
    faces: 'coulmnIcon',
    name: 'First Name Last Name 11',
    primary: '111',
    application: 'Edge',
    endpoint: 'Tablet 11',
    location: 'Milpitas, Bld 15',
    date: new Date(),
    isPrimary: false
  },
]
