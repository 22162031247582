export const endpointTemplatesConstants = {
    GET_ALL_ENDPOINTS_TEMPLATES_SUCCESS: 'app/endpointTemplates/GET_ALL_ENDPOINTS_SUCCESS',
    GET_ALL_ENDPOINTS_TEMPLATES_FAILURE: 'app/endpointTemplates/GET_ALL_ENDPOINTS_TEMPLATES_FAILURE',
    GET_ALL_ENDPOINTS_TEMPLATES_COPY_SUCCESS: 'app/endpointTemplates/GET_ALL_ENDPOINTS_TEMPLATES_COPY_SUCCESS',
    
    GET_ENDPOINTS_TEMPLATES_DETAIL_SUCCESS: 'app/endpointTemplates/GET_ENDPOINTS_TEMPLATES_DETAIL_SUCCESS',
    GET_ENDPOINTS_TEMPLATES_DETAIL_FAILURE: 'app/endpointTemplates/GET_ENDPOINTS_TEMPLATES_DETAIL_FAILURE',

    GET_ALL_INTEGRATION_SUCCESS:'app/endpointTemplates/GET_ALL_INTEGRATION_SUCCESS',
    GET_ALL_INTEGRATION_FAILURE:'app/endpointTemplates/GET_ALL_INTEGRATION_FAILURE',

    GET_ALL_ENABLED_INTEGRATION_SUCCESS:'app/endpointTemplates/GET_ALL_ENABLED_INTEGRATION_SUCCESS',
    GET_ALL_ENABLED_INTEGRATION_FAILURE:'app/endpointTemplates/GET_ALL_ENABLED_INTEGRATION_FAILURE',
    
    GET_ALL_ENDPOINT_CONFIGURE_TAB_SUCCESS:'app/endpointTemplates/GET_ALL_ENDPOINT_CONFIGURE_TAB_SUCCESS',
    FILTER_UPDATE: 'TEMPLATE_FILTER_UPDATE',
    PAGINATE_UPDATE: 'TEMPLATE_PAGINATE_UPDATE',
};
