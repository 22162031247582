
import { useEffect } from 'react';
import { oktaLogin } from '../action/user.actions'
import { useDispatch } from 'react-redux';
import { history, tostor } from '../../../helpers'
import { getOktaAuth } from './oktaAuth'
import { remove } from 'lodash';


function LoginCallback() {
  const dispatch = useDispatch();

  useEffect(() => {
    setTokenMethod((authToken) => {
      handleLogin(authToken)
    })
  }, [])


  const getSubdomain = () => {
    var full = window.location.hostname;
    var domain, onlySubDomain, parts, subDomain;
    domain = process.env['REACT_APP_HOST_NAME'] || "";
    onlySubDomain = full.replace(domain, "")
    parts = onlySubDomain.split('.')
    remove(parts, (res) => { return ["", 'www', 'admin', 'api', 'dev', 'prod', 'ui', 'localhost'].indexOf(res) > -1 });
    if (parts.length > 0) {
      subDomain = parts[0];
    }
    return subDomain
  }

  const setTokenMethod = (callback) => {
    const oktaAuth = getOktaAuth()
    oktaAuth.token
      .parseFromUrl()
      .then(async function (res) {
        // manage token or tokens
        var tokens = res.tokens

        // Setting token in token manager to get fetch access token from SDK
        oktaAuth.tokenManager.setTokens(tokens)
        let token = await oktaAuth.tokenManager.getTokensSync()
        //setOktaToken(token)

        const { idToken } = token
        const authToken = idToken.idToken

        //setAuthToken(authToken)
        callback(authToken)
      })
      .catch(function (err) {
        console.error('Error OKTA set token', err);
        tostor.error(err);
        history.push('/login');
      })
  }

  const handleLogin = (token) => {
    const subDomain = getSubdomain();
    dispatch(oktaLogin({
      "TenantName": subDomain || "lift",
      "Token": token,
      "Platform": 'Web'
    })).then((res) => {
      if (res.accesstoken) {
        history.push('/applications')
      }
      if (res.message) {
        const msg = res.message.indexOf('Clientmatadata') > -1 ? 'Something went wrong please try again!' : res.message;
        tostor.error(msg);
        history.push('/login');
      }
    });
  }

  return (
    <div>
    </div>
  );
}

export default LoginCallback;
