import { userFaces, userRawJson, userGroup, userSpoofAtempts } from './constant';
import _ from 'lodash';
import { requestDelete, requestGet, requestPost, requestPut, requestFileUpload } from '../../../services/requests';


/* eslint eqeqeq: 0 */
export class UsersService {

  constructor(endpoint) {
    this.endpoint = endpoint;
  }

  getAllUsers(filter, isLoading) {
    const url = this.operationUrl('/users');
    filter = filter || {};
    filter.faceUrl = 1;
    filter.pageSize = 10;
    return requestGet(url, filter, isLoading);
  };

  getUsersByApplication(filter, isLoading) {
    const url = this.operationUrl(`/applications/${filter.applicationID}/users`);
    filter = filter || {};
    filter.faceUrl = 1;
    filter.pageSize = 10;
    return requestPost(url, filter, isLoading);
  };

  getUsersById(id) {
    const url = this.operationUrl(`/users/${id}`);
    return requestGet(url, { faceUrl: 1 });
  };

  deleteUserById(id) {
    const url = this.operationUrl(`/users/${id}`);
    return requestDelete(url);
  }

  deActivateOloidUserById(id) {
    const url = this.operationUrl(`/oloid-users/${id}`);
    return requestDelete(url);
  }

  createUser(data) {
    const url = this.operationUrl(`/users`);
    return requestPost(url, data);
  };

  addFaceOverride(id, params) {
    const url = this.operationUrl(`/utility/users/${id}/descriptor?bestShot=1&sunglassCheck=1&multiFaceCheck=0&faceExitsCheck=1`);
    return requestPost(url, params);
  };

  updateUser(id, data, isRawJson) {
    const url = this.operationUrl(`/users/${id}?rawJson=${isRawJson ? 1 : 0}`);
    return requestPut(url, data);
  };

  addUserFace(id, data, checkForSimilarFace) {

    let faceUrl = `/users/${id}/faces`
    if (checkForSimilarFace === 0) {
      faceUrl = faceUrl + '?checkForSimilarFace=0'
    }
    const url = this.operationUrl(faceUrl);
    return requestPost(url, data);
  };

  removeUserFace(id, data) {
    const url = this.operationUrl(`/users/${id}/faces/remove`);
    return requestPut(url, data);
  };

  removeUserGroups(oloID, data) {
    const url = this.operationUrl(`/users/${oloID}/groups/remove`);
    return requestPost(url, data);
  }

  resetUserPassword(id, data) {
    const url = this.operationUrl(`/users/${id}/resetPassword`);
    return requestPut(url, data);
  };

  deleteCognitoUser(id, email) {
    const url = this.operationUrl(`/users/${id}/cognito/${email}`);
    return requestDelete(url);
  };

  inviteCognitoUser(data) {
    const url = this.operationUrl(`/users/inivte`);
    return requestPost(url, data);
  };

  getS3Url(data) {
    const url = this.operationUrl(`/users/import/s3-url`);
    return requestPost(url, data);
  };

  processImport(data) {
    const url = this.operationUrl(`/users/import/users/process`);
    return requestPut(url, data);
  };

  ImportUserFace(data) {
    const url = this.operationUrl(`/users/import/faces`);
    return requestPut(url, data, false);
  };

  downloadUserFaceLog(data) {
    const url = this.operationUrl(`/users/import/s3-url`);
    return requestPost(url, data);
  }
  getImportStatus(fileId) {
    const url = this.operationUrl(`/users/import/users/status?fileId=${fileId}`);
    return requestGet(url, null, false);
  };

  uploadFile(url, data) {
    return requestFileUpload(url, data);
  };

  getUserEndPoints() {
    return new Promise((resolve) => {
      resolve([])
    });
  };

  getUserHistory() {
    return new Promise((resolve) => {
      resolve([])
    });
  };

  getUserFaces(id) {
    const url = this.operationUrl(`/users/${id}/faces`);
    return requestGet(url);
  };

  getUserGroup() {
    return new Promise((resolve) => {
      resolve(userGroup)
    });
  };


  setPrimaryUser(item) {
    return new Promise((resolve) => {
      let data = _.map(userFaces, (r) => {
        r.isPrimary = false;
        if (r.id == item.id) {
          r.isPrimary = true;
        }
        return r;
      });
      resolve(data)
    });
  }

  getUserRawJson() {
    return new Promise((resolve) => {
      resolve(userRawJson)
    });
  };

  getUserConsent() {
    return new Promise((resolve) => {
      resolve([])
    });
  };

  getUserImport(filter) {
    filter = filter || {};
    filter.signedUrl = 1;
    const url = this.operationUrl(`/users/import/files`);
    return requestGet(url, filter, false);
  }


  ImportUserGroupMapping(data) {
    const url = this.operationUrl(`/groups/users/import`);
    return requestPut(url, data);
  }

  getUserSpoofAtempts() {
    return new Promise((resolve) => {
      resolve(userSpoofAtempts)
    });
  };

  getUserSpoofAtemptsById(id) {
    return new Promise((resolve) => {
      const res = _.find(userSpoofAtempts, (res) => { return res.id == id })
      resolve(res)
    });
  };


  createQrCode(data) {
    const url = this.operationUrl(`/qrcode/create`);
    return requestPost(url, data);
  }

  deleteQrCode(oloID) {
    const url = this.operationUrl(`/qrcode/${oloID}`);
    return requestDelete(url);
  }

  migrateUser(data) {
    const url = this.operationUrl(`/users/migrate`);
    return requestPost(url, data);
  }

  findFace(data) {
    const url = this.operationUrl(`/users/faces/similar`);
    return requestPost(url, data);
  };

  getImproveFace(data) {
    const url = this.operationUrl(`/utility/transactions/improve-face-quality`);
    return requestPost(url, data);
  }


  saveBluetoothCred(data) {
    const url = this.operationUrl(`/credentials/bluetooth`);
    return requestPost(url, data);
  }

  createOloidAccount(id) {
    const url = this.operationUrl(`/oloid-users/${id}/invite`);
    return requestPost(url, {});
  }

  sendSignInUrlOloidAccount(id, params) {
    const url = this.operationUrl(`/oloid-users/${id}/login-url`);
    return requestPost(url, params);
  }

  skipWellnessCheck(data) {
    const url = this.operationUrl(`/utility/users/skip-wellness-check`);
    return requestPost(url, data);
  }

  saveCloudKeyCred(data) {
    const url = this.operationUrl(`/cloudkey/create`);
    return requestPost(url, data);
  }

  deleteCloudKeyCred(oloID) {
    const url = this.operationUrl(`/cloudkey/${oloID}`);
    return requestDelete(url);
  }

  ocrDetect(param) {
    const url = this.operationUrl(`/vaccine/ocr/detect`);
    return requestPost(url, param);
  };

  ocrSave(params) {
    const url = this.operationUrl(`/vaccine/ocr/save`);
    return requestPost(url, params);
  };

  userActiveAndInactive(OloID, isActive) {
    const url = this.operationUrl(`/users/${OloID}/${isActive ? 'deactivate' : 'activate'}`);
    return requestPut(url, {});
  };

  inviteBLEAPP(data) {
    const url = this.operationUrl('/credentials/appinvite');
    return requestPost(url, data);
  };

  generateBluetoothTokens(data) {
    const url = this.operationUrl(`/credentials/bluetooth/generateTokens`);
    return requestPost(url, data);
  }
  deleteBluetoothTokens(data) {
    const url = this.operationUrl(`/credentials/bluetooth/deleteTokens`);
    return requestPost(url, data);
  }

  operationUrl(append) {
    return this.endpoint + append;
  }
}
