export function fromValidate(formEl) {
  const formLength = formEl.length;
  let invalidationEle = {};
  if (formEl.checkValidity() === false) {
    for (let i = 0; i < formLength; i++) {
      const elem = formEl[i];
      if (!elem.validity.valid) {
        invalidationEle[elem.name] = elem.validity;
      }
    }
    return { valid: false, invalidationEle: invalidationEle };
  }
  else {
    return { valid: true, invalidationEle: invalidationEle };
  }
}
export function ValidateEmail(email) {
  if (/^\w+([\.\+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,50})+$/.test(email)) {
    return (true)
  }
  return (false)
}

export function ValidateIPaddress(ipaddress) {
  if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipaddress)) {
    return (true)
  }
  return (false)
}