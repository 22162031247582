import React from 'react';

export class DeleteContent extends React.Component {
    render() {
        return (
            <div className="del-box">
                <div className="icon-container">
                    <div className="warnnig-icon"></div>
                </div>
                <div className="warrning-msg">
                    <div>
                        <div className="warnnig-text">{this.props.warningText}</div>
                        {this.props.warningSubText && <div className="warnnig-sub-text">{this.props.warningSubText}</div>}
                    </div>
                </div>
                <div className="btn-cantainer">
                    <div className="button-box-container">
                        <button
                            onClick={() => { this.props.onDeleteHandle() }}
                            type="button" className="btn-delete">{this.props.btnText || 'DELETE'}</button>
                    </div>
                </div>
            </div>
        );
    }
};
